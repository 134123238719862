import { useEffect, type Dispatch } from 'react'
import { AxiosError, V3ProjectSdk } from '@cango-app/sdk'
import { V3ClientTypes } from '@cango-app/types'
import { useSelector } from 'react-redux'

import { showSnackbar } from '../../../../helpers/snackbarManager'
import { selectors as authSelectors } from '../../../../store/modules/auth'
import * as bidBuilder from '../bid-builder'

type UseProjectBidProps = {
	dispatch: Dispatch<bidBuilder.BidBuilderAction>
	projectId: string
}

type BidToSave = Omit<V3ClientTypes.Project.Bid, 'created_at' | 'updated_at' | '_id'> & {
	_id?: string
}

export function useProjectBid(props: UseProjectBidProps): {
	onSaveBid: (data: BidToSave) => void
} {
	const { projectId, dispatch } = props
	const authHeaders = useSelector(authSelectors.getAuthHeaders)

	useEffect(() => {
		async function fetchProjectBid() {
			try {
				dispatch(bidBuilder.setLoading(true))
				const data = await V3ProjectSdk.getBidByProjectId(
					import.meta.env.VITE_API as string,
					authHeaders,
					projectId,
				)
				dispatch(bidBuilder.initBid(data))
			} catch (e) {
				const error = e as AxiosError
				if (error.response?.status === 404) {
					dispatch(bidBuilder.initBid(null))
					return
				}
				throw error
			}
		}

		fetchProjectBid().catch((error) => {
			showSnackbar(`Error fetching project bid: ${error.message}`, { variant: 'error' })
			dispatch(bidBuilder.setError(error.message))
		})
	}, [projectId])

	async function onSaveBid(data: BidToSave) {
		if (!data._id) {
			try {
				dispatch(bidBuilder.setLoading(true))
				const newBid = await V3ProjectSdk.createBid(
					import.meta.env.VITE_API as string,
					authHeaders,
					{ projectId, bid: data },
				)
				dispatch(bidBuilder.updateBid(newBid))
			} catch (error) {
				showSnackbar(`Error creating bid: ${(error as Error).message}`, { variant: 'error' })
				dispatch(bidBuilder.setError((error as Error).message))
			}
			return
		}

		try {
			dispatch(bidBuilder.setLoading(true))
			const updatedBid = await V3ProjectSdk.updateBid(
				import.meta.env.VITE_API as string,
				authHeaders,
				{ projectId, bidId: data._id, bid: data },
			)
			dispatch(bidBuilder.updateBid(updatedBid))
		} catch (error) {
			showSnackbar(`Error updating bid: ${(error as Error).message}`, { variant: 'error' })
			dispatch(bidBuilder.setError((error as Error).message))
		}
	}

	return {
		onSaveBid,
	}
}
