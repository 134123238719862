import { ComponentType, useMemo, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import Avatar from '@mui/material/Avatar'
import Popover from '@mui/material/Popover'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Divider from '@mui/material/Divider'
import Collapse from '@mui/material/Collapse'
import Backdrop from '@mui/material/Backdrop'
import Card from '@mui/material/Card'
import { useDispatch, useSelector } from 'react-redux'
import { UserTypes } from '@cango-app/types'
import { useNavigate } from 'react-router-dom'
import { PulseLoader } from 'react-spinners'

import { actions as myTasksActions } from 'src/store/modules/my-tasks-v3'
import { ROUTES, RouteId } from 'src/constants/routes'
import { ChevronDown, SettingsCogIcon } from 'src/assets/icons'
import { selectors as userSelectors } from 'src/store/modules/user'
import { selectors as authSelectors, actions as authActions } from 'src/store/modules/auth'
import { selectors as remoteConfigSelectors } from 'src/store/modules/remote-config'
import { selectors as configSelectors } from 'src/store/modules/config'
import { colors } from 'src/theme/colors'
import type { RootState } from 'src/store/types'
import { UserAvatar } from 'src/components'

import packageInfo from '../../../../package.json'
import { Text } from '../../../components/text'
import { Box } from '../../../components/box'
import { getRandomColour } from '../utils'

type SettingsNavProps = {
	onSettingsClick: (event: React.MouseEvent<HTMLButtonElement>) => void
	onUserClick: (event: React.MouseEvent<HTMLButtonElement>) => void
	settingsPopover: HTMLButtonElement | null
	userPopover: HTMLButtonElement | null
	onSettingsClose: () => void
	onUserClose: () => void
	onRouteClick: (routeId: RouteId) => void
	onLogout: () => Promise<void>
}

export const SettingsNav: ComponentType<SettingsNavProps> = ({
	onSettingsClick,
	settingsPopover,
	onSettingsClose,
	onRouteClick,
	onUserClick,
	userPopover,
	onUserClose,
	onLogout,
}) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const user = useSelector(userSelectors.getUserDetails)
	const userInitials = useSelector(userSelectors.getUserInitials)
	const selectedOrganisationId = useSelector(configSelectors.getOrganisationId)
	const organisationName = useSelector(authSelectors.getOrganisationName)
	const isUserAuthenticating = useSelector(authSelectors.isUserSigningIn)
	const isDatabasesEnabled = useSelector(remoteConfigSelectors.isDatabasesEnabled)
	const allUserOrganisations = useSelector((state: RootState) =>
		authSelectors.getUserOrganisationsList(state, false),
	)
	const [isOrgListOpen, setIsOrgListOpen] = useState(false)
	const userHasMultipleOrganisations = allUserOrganisations.length > 1
	const userHasMultiOrgPermissions = [
		UserTypes.Permission.Consultant,
		UserTypes.Permission.Techmaster,
	].includes(user?.permissions as UserTypes.Permission)

	const handleOrgClick = () => {
		setIsOrgListOpen(!isOrgListOpen)
	}

	const handleOrganisationChange = async (organisationId: string) => {
		await dispatch(authActions.switchOrganisation({ organisationId }))
		onUserClose()
		navigate(`/${RouteId.MyTasks}`)
	}

	const routes = useMemo(
		() =>
			ROUTES.filter((route) => {
				if (!user?.permissions) return false
				return route.permissions.includes(user.permissions)
			}),
		[user?.permissions],
	)

	const settingsRoutes = routes.filter(({ id }) => {
		const routesToDispay = [
			RouteId.Users,
			RouteId.Roles,
			...(isDatabasesEnabled ? [RouteId.Tables] : []),
		]
		return routesToDispay.includes(id)
	})

	return (
		<>
			<IconButton onClick={onSettingsClick}>
				<SettingsCogIcon width={20} />
			</IconButton>
			<Popover
				open={!!settingsPopover}
				anchorEl={settingsPopover}
				onClose={onSettingsClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}>
				<Box width={300} pt={2}>
					<Box>
						<Text variant="overline" fontWeight="bold" sx={{ px: 2 }}>
							Settings
						</Text>
						<List>
							{settingsRoutes.map((route) => {
								const RouteIcon = route.icon
								if (!RouteIcon) return null
								return (
									<ListItemButton
										sx={{ px: 2 }}
										key={route.id}
										onClick={() => onRouteClick(route.id)}>
										<ListItemIcon sx={{ minWidth: 40 }}>
											<Avatar
												variant="rounded"
												sx={{
													width: 24,
													height: 24,
													bgcolor: colors.feldgrau['80'],
												}}>
												<RouteIcon width={16} stroke={colors.neutral['0']} />
											</Avatar>
										</ListItemIcon>
										<ListItemText
											primaryTypographyProps={{ fontSize: 14, fontWeight: 'bold' }}
											secondaryTypographyProps={{ fontSize: 12 }}
											primary={route.label}
											secondary={route.description}
										/>
									</ListItemButton>
								)
							})}
						</List>
					</Box>
				</Box>
			</Popover>

			<IconButton onClick={onUserClick}>
				<UserAvatar userInitials={userInitials} picture={user?.picture} />
			</IconButton>

			<Popover
				open={!!userPopover}
				anchorEl={userPopover}
				onClose={onUserClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}>
				<Box width={300} py={2}>
					<Box>
						<Box display="flex" justifyContent="space-between" alignItems="center">
							<Text variant="overline" fontWeight="bold" sx={{ px: 2 }}>
								Account
							</Text>
							<Text fontSize={12} px={2}>{`v${packageInfo.version}`}</Text>
						</Box>
						<List>
							<ListItem sx={{ px: 2 }}>
								<ListItemAvatar>
									<Avatar
										sx={{
											bgcolor: getRandomColour(userInitials),
											width: 36,
											height: 36,
											fontSize: 16,
										}}>
										{userInitials}
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primaryTypographyProps={{ fontSize: 14, fontWeight: 'bold' }}
									secondaryTypographyProps={{ fontSize: 12 }}
									primary={user?.name}
									secondary={user?.email}
								/>
							</ListItem>
							<ListItemButton sx={{ px: 2 }} onClick={handleOrgClick}>
								<ListItemAvatar>
									<Avatar
										variant="rounded"
										sx={{
											bgcolor: colors.feldgrau['80'],
											color: colors.sunglow['60'],
											width: 36,
											height: 36,
											fontSize: 14,
										}}>
										{selectedOrganisationId}
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primaryTypographyProps={{ fontSize: 14, fontWeight: 'bold' }}
									secondaryTypographyProps={{ fontSize: 12 }}
									primary={organisationName}
									secondary={selectedOrganisationId}
								/>
								{userHasMultipleOrganisations && userHasMultiOrgPermissions && <ChevronDown />}
							</ListItemButton>
							{userHasMultipleOrganisations && userHasMultiOrgPermissions && (
								<Collapse in={isOrgListOpen} timeout="auto" unmountOnExit>
									<List component="div" disablePadding>
										{allUserOrganisations.map((org) => (
											<ListItemButton
												key={org.id}
												sx={{ pl: 4 }}
												onClick={() => handleOrganisationChange(org.id)}>
												<ListItemAvatar>
													<Avatar
														variant="rounded"
														sx={{
															bgcolor: colors.feldgrau['80'],
															color: colors.sunglow['60'],
															width: 36,
															height: 36,
															fontSize: 14,
														}}>
														{org.id}
													</Avatar>
												</ListItemAvatar>
												<ListItemText
													primary={org.name}
													primaryTypographyProps={{ fontSize: 14 }}
													secondaryTypographyProps={{ fontSize: 12 }}
													secondary={org.id}
												/>
											</ListItemButton>
										))}
									</List>
								</Collapse>
							)}
						</List>
					</Box>
					<Divider sx={{ borderColor: colors.neutral['40'], borderWidth: 1 }} />
					<ListItemButton onClick={onLogout} sx={{ cursor: 'pointer', py: 2, mt: 2 }}>
						<Text fontSize={14}>Log out</Text>
					</ListItemButton>
				</Box>
			</Popover>
			<Backdrop open={isUserAuthenticating}>
				<Card
					sx={{
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						color: colors.neutral['80'],
						bgcolor: colors.neutral['10'],
						p: 4,
					}}>
					<PulseLoader size={8} color={colors.neutral['80']} />
					<Text>Loading organisation...</Text>
				</Card>
			</Backdrop>
		</>
	)
}
