import { ComponentType } from 'react'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import { TablesSdk } from '@cango-app/sdk'

import { TablesIcon } from '../../../../assets/icons'
import { Box, Modal, Text } from '../../../../components'

type DatabasesModalProps = {
	onClose: () => void
	onSelect: (databaseId: string) => void
	open: boolean
	tables: TablesSdk.MenuTable[]
}

export const DatabasesModal: ComponentType<DatabasesModalProps> = (props) => {
	const { tables, onClose, open, onSelect } = props
	const onClick = (tableId: string) => {
		onSelect(tableId)
		onClose()
	}

	return (
		<Modal open={open} onClose={onClose} title="Select Database">
			<Stack direction="column" maxHeight="100%">
				<Box display="flex" alignItems="center">
					<Text mr={2} variant="h5">
						Browse databases
					</Text>
					<TablesIcon />
				</Box>
				<Box flex={1} overflow="auto">
					<List dense={true}>
						{tables.map((table) => (
							<ListItemButton onClick={() => onClick(table._id)} key={table._id}>
								<ListItemText primary={table.name} />
							</ListItemButton>
						))}
					</List>
				</Box>
			</Stack>
		</Modal>
	)
}
