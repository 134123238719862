import { Dispatch, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { V3ProjectSdk } from '@cango-app/sdk'

import { selectors as authSelectors } from '../../../../store/modules/auth'
import * as bidBuilder from '../bid-builder'
import { showSnackbar } from '../../../../helpers/snackbarManager'
import { BidItemData } from '../types'

type UseBidItemsProps = {
	projectId: string
	bidId: string | undefined
	dispatch: Dispatch<bidBuilder.BidBuilderAction>
}

export function useBidItems(props: UseBidItemsProps): {
	onSaveBidItem: (data: BidItemData) => Promise<void>
	onDeleteBidItem: (bidItemId: string) => void
} {
	const { projectId, bidId, dispatch } = props
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const [lastUpdateAt, setLastUpdateAt] = useState<number | null>(null)

	useEffect(() => {
		async function fetchBidItems() {
			if (!bidId) {
				return
			}

			dispatch(bidBuilder.setLoading(true))
			const data = await V3ProjectSdk.findBidItems(
				import.meta.env.VITE_API as string,
				authHeaders,
				{
					projectId,
					bidId,
				},
			)
			dispatch(bidBuilder.setBidItems(data))
		}

		fetchBidItems().catch((error) => {
			showSnackbar(`Error fetching bid items: ${error.message}`, { variant: 'error' })
			dispatch(bidBuilder.setError(error.message))
		})
	}, [projectId, lastUpdateAt, bidId, authHeaders, dispatch])

	async function onSaveBidItem(data: BidItemData) {
		if (!bidId) {
			return
		}

		if (!data._id) {
			try {
				dispatch(bidBuilder.setLoading(true))
				const newBidItem = await V3ProjectSdk.createBidItem(
					import.meta.env.VITE_API as string,
					authHeaders,
					{ projectId, bidId, bidItem: data },
				)
				setLastUpdateAt(newBidItem.updated_at)
				dispatch(bidBuilder.setActiveBidItemId(newBidItem._id))
			} catch (error) {
				showSnackbar(`Error creating bid item: ${(error as Error).message}`, { variant: 'error' })
				dispatch(bidBuilder.setError((error as Error).message))
				throw error
			}
			return
		}

		try {
			dispatch(bidBuilder.setLoading(true))
			const updatedBidItem = await V3ProjectSdk.updateBidItem(
				import.meta.env.VITE_API as string,
				authHeaders,
				{ projectId, bidId, bidItemId: data._id, bidItem: data },
			)
			setLastUpdateAt(updatedBidItem.updated_at)
		} catch (error) {
			showSnackbar(`Error updating bid item: ${(error as Error).message}`, { variant: 'error' })
			dispatch(bidBuilder.setError((error as Error).message))
			throw error
		}
	}

	async function onDeleteBidItem(bidItemId: string) {
		if (!bidId) {
			return
		}

		try {
			dispatch(bidBuilder.setLoading(true))
			await V3ProjectSdk.deleteBidItem(import.meta.env.VITE_API as string, authHeaders, {
				projectId,
				bidId,
				bidItemId,
			})
			setLastUpdateAt(Date.now())
		} catch (error) {
			showSnackbar(`Error deleting bid item: ${(error as Error).message}`, { variant: 'error' })
			dispatch(bidBuilder.setError((error as Error).message))
		}
	}

	return {
		onSaveBidItem,
		onDeleteBidItem,
	}
}
