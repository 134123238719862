import React, { ComponentType, useMemo, useState } from 'react'
import { V3ClientTypes } from '@cango-app/types'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Input from '@mui/material/Input'
import Stack from '@mui/material/Stack'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import CircularProgress from '@mui/material/CircularProgress'

import { Box, Text, Grid, Button } from '../../../components'
import { useTables } from '../../../hooks/useTables'

import { DatabasesModal } from './components/databases-modal'
import { BidData } from './types'

const markupValues = [
	{ label: 'Labor (%)', id: 'labor' },
	{ label: 'Equipment (%)', id: 'equipment' },
	{ label: 'Material (%)', id: 'material' },
	{ label: 'Profit (%)', id: 'profit' },
] as const

const resourceTables = [
	// { label: 'Proposal groups', id: 'proposalGroupsTableId' },
	// { label: 'Units of measure', id: 'unitsOfMeasurementTableId' },
	{ label: 'Production items', id: 'productionItemsTableId' },
	{ label: 'Labor pricing', id: 'laborPricingTableId' },
	{ label: 'Equipment pricing', id: 'equipmentPricingTableId' },
	{ label: 'Material pricing', id: 'materialPricingTableId' },
	// { label: 'Labor contents', id: 'laborContentsTableId' },
	// { label: 'Equipment contents', id: 'equipmentContentsTableId' },
	// { label: 'Material contents', id: 'materialContentsTableId' },
] as const
type ResourceTableIds = (typeof resourceTables)[number]['id']

const percentage = z.union([z.string().min(1), z.number()]).pipe(z.coerce.number().min(0).max(100))
const schema = z.object({
	_id: z.string().optional(),
	projectId: z.string(),
	markupValues: z.object({
		labor: percentage,
		equipment: percentage,
		material: percentage,
		profit: percentage,
	}),
	// proposalGroupsTableId: z.string(),
	productionItemsTableId: z.string(),
	laborPricingTableId: z.string().optional(),
	equipmentPricingTableId: z.string().optional(),
	materialPricingTableId: z.string().optional(),
	// laborContentsTableId: z.string().optional(),
	// equipmentContentsTableId: z.string().optional(),
	// materialContentsTableId: z.string().optional(),
	// unitsOfMeasurementTableId: z.string(),
})

type BidFormProps = {
	projectId: string
	loading: boolean
	goToNextStep: () => void
	data: V3ClientTypes.Project.Bid | null
	onSave: (data: BidData) => void
}

export const BidForm: ComponentType<BidFormProps> = (props) => {
	const { projectId, data, onSave, loading, goToNextStep } = props
	const isEditing = data?._id != null
	const title = isEditing ? 'Edit Bid' : 'Create Bid'
	const form = useForm<z.infer<typeof schema>>({
		defaultValues: {
			projectId,
			_id: undefined,
			markupValues: {},
			productionItemsTableId: 'production-items',
			laborPricingTableId: 'labor-pricing',
			equipmentPricingTableId: 'equipment-pricing',
			materialPricingTableId: 'material-pricing',
			// laborContentsTableId: undefined,
			// equipmentContentsTableId: undefined,
			// materialContentsTableId: undefined,
			// unitsOfMeasurementTableId: undefined,
			...data,
		},
		resolver: zodResolver(schema),
	})
	const [selectedResourceTableId, setSelectedResourceTableId] = useState<ResourceTableIds | null>(
		null,
	)
	const tables = useTables()

	const tableNames = useMemo(
		() =>
			tables.reduce<Record<string, string>>((acc, table) => {
				acc[table._id] = table.name
				return acc
			}, {}),
		[tables],
	)

	const { isValid, isDirty } = form.formState

	return (
		<Box px={4}>
			<Box display="flex" alignItems="center">
				<Text variant="h5">{title}</Text>
				{loading && <CircularProgress size={20} thickness={5} sx={{ ml: 2 }} />}
			</Box>
			<form onSubmit={form.handleSubmit(onSave)}>
				<Grid container py={2} columnSpacing={12} rowSpacing={6}>
					<Grid item md={4}>
						<Text variant="h6" mb={4}>
							Markup Values (%)
						</Text>
						<Stack direction="column" useFlexGap spacing={{ md: 2 }}>
							{markupValues.map((markupValue) => (
								<Controller
									key={markupValue.id}
									name={`markupValues.${markupValue.id}`}
									control={form.control}
									render={({ field, fieldState }) => (
										<FormControl key={markupValue.id}>
											<InputLabel htmlFor={field.name}>{markupValue.label}</InputLabel>
											<Input {...field} type="number" id={field.name} />
											<Text color="error">{fieldState.error?.message}</Text>
										</FormControl>
									)}
								/>
							))}
						</Stack>
					</Grid>
					<Grid item md={8}>
						<Text variant="h6" mb={4}>
							Resource Tables
						</Text>
						<Stack direction="column" useFlexGap spacing={{ md: 2 }}>
							{resourceTables.map((resourceTable) => (
								<Controller
									key={resourceTable.id}
									control={form.control}
									render={({ field }) => (
										<Grid container spacing={2}>
											<Grid xs={4} item display="flex" alignItems="center">
												<Text key={field.name}>{resourceTable.label}</Text>
											</Grid>
											<Grid xs={4} item display="flex" alignItems="center">
												{field.value != null && (
													<Text>{tableNames[field.value] ?? field.value}</Text>
												)}
												{field.value == null && (
													<Button
														variant="contained"
														color="feldgrau40"
														onClick={() => {
															setSelectedResourceTableId(resourceTable.id)
														}}
													>
														Browse databases
													</Button>
												)}
											</Grid>
											<Grid
												xs={4}
												item
												display="flex"
												alignItems="center"
												justifyContent="flex-end"
											>
												{field.value != null && (
													<Button
														variant="outlined"
														color="error"
														disabled
														onClick={() => {
															field.onChange(null)
														}}
													>
														Remove
													</Button>
												)}
											</Grid>
										</Grid>
									)}
									name={resourceTable.id}
								/>
							))}
						</Stack>
					</Grid>
					<Grid item md={12}>
						<Stack direction="row" justifyContent="flex-end" spacing={{ md: 2 }} useFlexGap>
							<Button disabled={!isValid} variant="contained" color="primary" type="submit">
								Save
							</Button>
							{isEditing && !isDirty && (
								<Button variant="outlined" color="feldgrau40" onClick={() => goToNextStep()}>
									Continue
								</Button>
							)}
							{isEditing && isDirty && (
								<Button variant="outlined" color="neutral" onClick={() => form.reset()}>
									Reset
								</Button>
							)}
						</Stack>
					</Grid>
				</Grid>
			</form>
			<DatabasesModal
				tables={tables}
				onSelect={(tableId) => {
					if (selectedResourceTableId) {
						form.setValue(selectedResourceTableId, tableId ?? undefined)
						form.trigger(selectedResourceTableId)
					}
				}}
				open={selectedResourceTableId != null}
				onClose={() => setSelectedResourceTableId(null)}
			/>
		</Box>
	)
}
