import { ComponentType, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { TaskListType, selectors as myTasksSelectors } from 'src/store/modules/my-tasks-v3'
import { selectors as sessionSelectors } from 'src/store/modules/session-config'
import { RouteId } from 'src/constants/routes'

import TaskListItem from './task-list-item'
import InstanceListItem from './task-list-item/instance-list'
import { SectionComplete } from './section-complete'

export const TaskList: ComponentType = () => {
	const navigate = useNavigate()
	const taskList = useSelector(myTasksSelectors.getTaskList)
	const activeTasks = useSelector(myTasksSelectors.getActiveTasks)
	const masterTasks = useSelector(myTasksSelectors.getMasterTasks)
	const instanceTasks = useSelector(myTasksSelectors.getInstanceTasks)
	const taskListType = useSelector(myTasksSelectors.getTaskListType)
	const canCompleteSection = useSelector(myTasksSelectors.canCompleteSection)
	const [openParentTasks, setOpenParentTasks] = useState<string[]>([])

	const activeList = useMemo(() => {
		if (taskListType === TaskListType.Active) {
			return activeTasks
		}
		if (taskListType === TaskListType.ProjectTasks) {
			return masterTasks
		}
		return []
	}, [taskListType, activeTasks, masterTasks])

	const handleTaskClick = (taskId: string, sectionId?: string) => {
		navigate(`/${RouteId.MyTasks}/${sectionId}/${taskId}`)
	}

	const updateOpenParentTasks = (taskId: string) => {
		if (openParentTasks.includes(taskId)) {
			setOpenParentTasks(openParentTasks.filter((id) => id !== taskId))
		} else {
			setOpenParentTasks([...openParentTasks, taskId])
		}
	}
	return (
		<>
			{activeList.map((task, index) => {
				if (
					(!!task.subtaskParentId && !openParentTasks.includes(task.subtaskParentId)) ||
					!!task.instance?._id
				) {
					return null
				}
				return (
					<TaskListItem
						key={task._id}
						task={task}
						isFirstTask={index === 0}
						isLastTask={index === taskList.length - 1 && !instanceTasks.length}
						onClick={() => handleTaskClick(task._id, task.section_id)}
						onViewSubtasksClick={updateOpenParentTasks}
						shouldShowSubtasks={openParentTasks.includes(task._id)}
						hasIndent={!!task.subtaskParentId}
					/>
				)
			})}

			{taskListType === TaskListType.Active &&
				instanceTasks.map((instance, index) => {
					const isLastInstance = index === instanceTasks.length - 1
					return (
						<InstanceListItem
							key={instance.multiUseId}
							isFirstTask={!taskList.length && index === 0}
							isLastTask={isLastInstance}
							parentName={instance.multiUseName}
							tasks={instance.tasks}
							isLastInstance={isLastInstance}
							onTaskClick={handleTaskClick}
							openParentIds={openParentTasks}
							onOpenSubtasksClick={updateOpenParentTasks}
						/>
					)
				})}
			{!!canCompleteSection && <SectionComplete />}
		</>
	)
}
