import React, { ComponentType, useEffect, useMemo } from 'react'
import { Controller, useFieldArray, useFormContext, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { V3BlueprintTypes } from '@cango-app/types'
import { V3BlueprintSdk } from '@cango-app/sdk'

import { Box, Button, Checkbox, FormCard, IconButton, Select, Text, Toggle } from 'src/components'
import { selectors as blueprintSelectors } from 'src/store/modules/blueprints-v3'
import { TrashIcon } from 'src/assets/icons'
import type { RootState } from 'src/store/types'

import { StepFormControl, StepFormType } from './step-form-container'

type DependencyFieldsProps = {
	control: StepFormControl
	shouldShowSections?: boolean
}

const isUniqueId = (id: string, allParents: V3BlueprintSdk.Parent[], currentIndex: number) => {
	return !allParents.some((parent, index) => index !== currentIndex && parent._id === id)
}

export const parentOrSectionOptions = [
	{
		_id: V3BlueprintTypes.TaskPhase.Commence,
		label: 'Start',
	},
	{
		_id: V3BlueprintTypes.TaskPhase.Complete,
		label: 'Completion',
	},
]

type MultiOptionSelect = {
	isMulti: true
	options: string[]
}

type SingleOptionSelect = {
	isMulti: false
	options: string
}

const DependantItem: ComponentType<{
	control: StepFormControl
	index: number
	onRemove: () => void
	shouldShowSections?: boolean
}> = ({ control, index, onRemove, shouldShowSections }) => {
	const { setValue } = useFormContext<StepFormType>()
	const parents = useWatch({ control, name: 'parents' })
	const parentId = useWatch({ control, name: `parents.${index}._id` })
	const { stepOptions, stepChildren } = useSelector((state: RootState) =>
		blueprintSelectors.getBlueprintSelectDependancyOptions(state, shouldShowSections),
	)

	const parent = useMemo(() => {
		return stepOptions.find(({ _id }) => _id === parentId)
	}, [parentId])

	const handleOptionSelect = (props: SingleOptionSelect | MultiOptionSelect) => {
		if (props.isMulti) {
			const parent = stepChildren.get(parentId ?? '')
			setValue(
				`parents.${index}.options`,
				props.options.map((option) => ({
					_id: option,
					label: parent?.options.find(({ _id }) => _id === option)?.label ?? '',
				})),
				{ shouldDirty: true },
			)
		} else {
			setValue(
				`parents.${index}.options`,
				[
					{
						_id: props.options,
						label: props.options,
					},
				],
				{ shouldDirty: true },
			)
		}
	}

	const handleSelectOnCompleteTask = (isChecked: boolean) => {
		if (!isChecked) {
			setValue(`parents.${index}.options`, [], { shouldDirty: true })
			return
		}
		handleOptionSelect({
			isMulti: false,
			options: V3BlueprintTypes.TaskPhase.Complete,
		})
	}

	const handleParentSelect = (id: string) => {
		setValue(`parents.${index}.options`, [], { shouldDirty: true })
		setValue(`parents.${index}._id`, id, { shouldDirty: true })
		if (stepOptions.find(({ _id }) => _id === id)?.isBlockedType) {
			setValue('isLastStepInChain', true)
		}
	}

	const { options: parentOptions, hasExtraOptions } = useMemo(() => {
		const parent = stepChildren.get(parentId ?? '')

		if (!parent) {
			return {
				options: [],
				hasExtraOptions: false,
			}
		}

		if (!parent.hasExtraOptions && parent.options.length === 1) {
			handleOptionSelect({
				isMulti: false,
				options: parent.options[0]._id,
			})
		}

		return parent
	}, [parentId])

	useEffect(() => {
		if (parent) {
			setValue(`parents.${index}.name`, parent.label)
		}
	}, [parent])

	return (
		<Box
			sx={{
				backgroundColor: 'rgba(164, 198, 188, 0.1)',
				p: 2,
				borderRadius: '8px',
				mb: 2,
			}}
		>
			<Box display="flex" alignItems="flex-start">
				<Box flex={1}>
					<Controller
						control={control}
						name={`parents.${index}._id`}
						rules={{
							required: 'Parent cannot be empty',
							validate: {
								unique: (value) => isUniqueId(value, parents, index) || 'Parent must be unique',
							},
						}}
						render={({ fieldState: { error } }) => {
							return (
								<Select
									options={stepOptions}
									label={`Parent`}
									value={parentId}
									onChange={(e) => handleParentSelect(e.target.value as string)}
									error={!!error}
									containerProps={{ width: 340 }}
									helperText={error?.message}
								/>
							)
						}}
					/>
				</Box>
				<IconButton sx={{ mt: 3 }} onClick={onRemove}>
					<TrashIcon />
				</IconButton>
			</Box>
			{!!parentOptions.length && (
				<Controller
					control={control}
					rules={{ required: 'Please select an option' }}
					name={`parents.${index}.options`}
					render={({ field: { value }, fieldState: { error } }) => {
						const onlyHasCompleteOption =
							parentOptions.length === 1 &&
							parentOptions[0]._id === V3BlueprintTypes.TaskPhase.Complete
						const isCompleteChecked =
							value.length === 1 && value[0]._id === V3BlueprintTypes.TaskPhase.Complete

						const onlyHasBasicOptions =
							parentOptions.length === 2 &&
							parentOptions.every(
								(parent) =>
									parent._id === V3BlueprintTypes.TaskPhase.Commence ||
									parent._id === V3BlueprintTypes.TaskPhase.Complete,
							)

						if (onlyHasBasicOptions) {
							return (
								<Toggle
									options={parentOptions.map((option) => ({
										label: option.label,
										value: option._id,
									}))}
									value={value?.length ? value[0]._id : ''}
									onChange={(newValue) => handleOptionSelect({ isMulti: false, options: newValue })}
									sx={{ mt: 1 }}
									formControlProps={{ error: !!error, helperText: error?.message }}
								/>
							)
						}

						return (
							<>
								{!!hasExtraOptions && (
									<>
										<Select
											multiple={parent?.isMenu}
											options={parentOptions}
											label={`Option(s)`}
											value={value.map(({ _id }) => _id)}
											renderValue={!isCompleteChecked ? undefined : () => 'On complete'}
											disabled={isCompleteChecked}
											onChange={(e) =>
												handleOptionSelect({
													isMulti: !!parent?.isMenu,
													options: e.target.value as any,
												})
											}
											error={!!error}
											containerProps={{ width: 386, mt: 1 }}
										/>
										<Text fontSize={14} mt={1} textAlign="center">
											or
										</Text>
									</>
								)}
								<Checkbox
									label={`Create this task on parent completion ${
										hasExtraOptions ? '(whatever the option selected)' : ''
									}`}
									checked={isCompleteChecked}
									disabled={onlyHasCompleteOption}
									onChange={(e) => handleSelectOnCompleteTask(e.target.checked)}
									containerStyle={{ mt: 1 }}
								/>
							</>
						)
					}}
				/>
			)}
		</Box>
	)
}

export const DependencyFields: ComponentType<DependencyFieldsProps> = ({
	control,
	shouldShowSections,
}) => {
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'parents',
	})

	const handleAddParent = () => {
		append({
			_id: '',
			name: '',
			options: [],
		})
	}

	return (
		<FormCard
			title="Follows"
			mb={2}
			cta={
				<Button onClick={handleAddParent} variant="outlined" size="small" sx={{ minWidth: 100 }}>
					Add
				</Button>
			}
		>
			<Box>
				{!fields.length && !fields?.length && (
					<Box>
						<p>Start of Project</p>
					</Box>
				)}

				{fields.map((item, index) => (
					<Box key={item._id}>
						<DependantItem
							index={index}
							control={control}
							onRemove={() => remove(index)}
							shouldShowSections={shouldShowSections}
						/>
					</Box>
				))}
			</Box>
			<Box>
				{fields.length > 1 && (
					<Box mt={2}>
						<Controller
							control={control}
							name="requiresEveryParent"
							render={({ field: { onChange, value } }) => (
								<Toggle
									value={value}
									onChange={onChange}
									label="This task should appear when ANY or ALL of the parent conditions are met?"
									options={[
										{
											label: 'Any',
											value: false,
										},
										{
											label: 'All',
											value: true,
										},
									]}
								/>
							)}
						/>
					</Box>
				)}
			</Box>
		</FormCard>
	)
}
