import {
	GridApiPro,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarFilterButton,
} from '@mui/x-data-grid-pro'
import { ComponentType, MutableRefObject, useContext } from 'react'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import RestoreIcon from '@mui/icons-material/Restore'

import { PlusIcon, SaveIcon } from 'src/assets/icons'
import { Box, Button } from 'src/components'
import { TableContext } from 'src/providers/table-provider'
import { colors } from 'src/theme/colors'

import { ExportTableButton } from './export-table-button'

export const CustomToolbar: ComponentType<{
	apiRef: MutableRefObject<GridApiPro>
	isStatic?: boolean
	isLocked?: boolean
}> = ({ apiRef, isStatic, isLocked }) => {
	const { onAddColumn, unsavedChanges, isUpdatingTable, saveChanges, discardAllChanges } =
		useContext(TableContext)

	const handleAddColumn = async () => {
		await onAddColumn()
		apiRef.current.autosizeColumns({
			includeHeaders: true,
			includeOutliers: true,
		})
	}

	const handleDiscardChanges = () => {
		const resetChanges = _map(unsavedChanges.rowsBeforeChange, (row, id) => ({ ...row, id }))
		const resetNewRows = _map(unsavedChanges.newRows, (row, id) => ({
			id,
			_id: id,
			_action: 'delete',
		}))
		apiRef.current.updateRows([...resetChanges, ...resetNewRows])
		discardAllChanges()
	}

	const isSaveDisabled = _isEmpty(unsavedChanges.unsavedRows) || isUpdatingTable
	return (
		<GridToolbarContainer sx={{ flexDirection: 'column', alignItems: 'flex-start' }}>
			<Box display="flex" justifyContent="space-between" width="100%">
				<Box display="flex">
					{!isStatic && (
						<Button
							variant="text"
							size="small"
							sx={{ minWidth: 100, px: 2 }}
							startIcon={<PlusIcon width={16} />}
							onClick={handleAddColumn}
						>
							New column
						</Button>
					)}
				</Box>
				<Box display="flex">
					<ExportTableButton />
					{!isLocked && (
						<>
							<Button
								variant="text"
								disabled={isSaveDisabled}
								startIcon={
									<SaveIcon fill={isSaveDisabled ? 'rgba(0, 0, 0, 0.26)' : colors.feldgrau['80']} />
								}
								isLoading={isUpdatingTable}
								onClick={saveChanges}
							>
								Save
							</Button>
							<Button
								variant="text"
								startIcon={<RestoreIcon />}
								disabled={_isEmpty(unsavedChanges.unsavedRows) || isUpdatingTable}
								onClick={handleDiscardChanges}
							>
								Discard all changes
							</Button>
						</>
					)}
				</Box>
			</Box>

			<Box display="flex">
				<GridToolbarColumnsButton variant="text" sx={{ minWidth: 100 }} />
				<GridToolbarFilterButton
					componentsProps={{
						button: {
							variant: 'text',
							sx: { minWidth: 100 },
						},
					}}
				/>
			</Box>
		</GridToolbarContainer>
	)
}
