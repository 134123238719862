/* eslint-disable @typescript-eslint/no-empty-function */
import {
	useState,
	useCallback,
	ComponentType,
	createContext,
	useEffect,
	useMemo,
	PropsWithChildren,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AxiosError, TablesSdk } from '@cango-app/sdk'
import _forEach from 'lodash/forEach'
import _map from 'lodash/map'
import { ClientTypes, TableTypes } from '@cango-app/types'
import { useNavigate, useParams } from 'react-router-dom'
import {
	DataGridProProps,
	GridColumnOrderChangeParams,
	GridRowOrderChangeParams,
	GridValidRowModel,
} from '@mui/x-data-grid-pro'

import { selectors as authSelectors } from 'src/store/modules/auth'
import { errorHandler } from 'src/helpers/api'
import { ResolvedRowData, resolveAnyRowCalculations } from 'src/modules/tables/utils'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { RouteId } from 'src/constants/routes'
import { Contents } from 'src/modules/tables/contents-modal'

type TableProviderProps = {
	tableId?: string
}

export type TaskProviderChildProps = {
	table: ClientTypes.Table.CangoTable | undefined
	isLoadingTable: boolean
	saveChanges: () => Promise<void>
	isUpdatingTable: boolean
	cacheNewRow: (newRow: GridValidRowModel) => ResolvedRowData
	cacheRowUpdate: NonNullable<DataGridProProps['processRowUpdate']>
	unsavedChanges: TableUnsavedChanges
	discardChange: (id: string) => void
	deleteRow: (id: string, row: any) => void
	discardAllChanges: () => void
	onColumnOrderChange: (params: GridColumnOrderChangeParams) => void
	onRowOrderChange: (params: GridRowOrderChangeParams) => void
	onAddRow: () => void
	onAddColumn: () => void
	mappedColumns: Map<string, ClientTypes.Table.Field>
	mappedRows: Map<string, ClientTypes.Table.Record>
	onUpdateColumn: (
		updates: Omit<TablesSdk.UpdateFieldRequest, 'tableId'>,
	) => Promise<{ result: 'success' | 'error' }>
	onDeleteColumn: (fieldId: string) => Promise<void>
	saveContents: (rowId: string, row: GridValidRowModel, contents: Contents) => Promise<void>
}

type TableUnsavedChanges = {
	unsavedRows: Record<string, GridValidRowModel>
	rowsBeforeChange: Record<string, GridValidRowModel>
	newRows: Record<string, GridValidRowModel>
}

export const TableContext = createContext<TaskProviderChildProps>({
	table: undefined,
	isLoadingTable: false,
	saveChanges: async () => {},
	isUpdatingTable: false,
	cacheRowUpdate: () => {},
	unsavedChanges: {
		unsavedRows: {},
		rowsBeforeChange: {},
		newRows: {},
	},
	discardChange: () => {},
	deleteRow: () => {},
	discardAllChanges: () => {},
	onColumnOrderChange: () => {},
	onRowOrderChange: () => {},
	onAddRow: () => {},
	onAddColumn: () => {},
	mappedColumns: new Map(),
	mappedRows: new Map(),
	onUpdateColumn: async () => {
		return { result: 'success' }
	},
	onDeleteColumn: async () => {},
	cacheNewRow: () => ({ _id: 'test' }),
	saveContents: async () => {},
})

export const TableProvider: ComponentType<PropsWithChildren<TableProviderProps>> = (props) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const { tableId: tableIdParam } = useParams<{
		tableId?: string
	}>()
	const tableId = props.tableId || tableIdParam
	const [isLoadingTable, setIsLoadingTable] = useState(false)
	const [isUpdatingTable, setIsUpdatingTable] = useState(false)
	const [table, setTable] = useState<ClientTypes.Table.CangoTable | undefined>()
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const [unsavedChanges, setUnsavedChanges] = useState<TableUnsavedChanges>({
		unsavedRows: {},
		rowsBeforeChange: {},
		newRows: {},
	})
	const mappedColumns = useMemo(() => {
		if (!table?.fields) return new Map()
		return new Map(table.fields.map((_field) => [_field._id, _field]))
	}, [table?.fields])

	const mappedRows = useMemo(() => {
		if (!table?.records) return new Map()
		return new Map(table.records.map((_row) => [_row._id, { ..._row.data, _id: _row._id }]))
	}, [table?.records])

	const saveChanges = useCallback(async () => {
		if (!table) return
		try {
			setIsUpdatingTable(true)

			const updatedRecords = _map(unsavedChanges.unsavedRows, ({ isNew, _id, ...row }, id) => ({
				_id: id,
				fields: row,
			}))

			const newRows = _map(unsavedChanges.newRows, (row, id) => ({ _id: id, fields: row }))

			const response = await TablesSdk.updateRecords(
				import.meta.env.VITE_API as string,
				authHeaders,
				{
					tableId: table._id,
					records: updatedRecords,
					newRecords: newRows,
				},
			)
			setTable(response)
			setUnsavedChanges({
				unsavedRows: {},
				rowsBeforeChange: {},
				newRows: {},
			})
		} catch (error) {
			errorHandler({ error, dispatch, message: 'Could not update table' })
		} finally {
			setIsUpdatingTable(false)
		}
	}, [authHeaders, unsavedChanges])

	const cacheNewRow = useCallback(
		(newRow: GridValidRowModel) => {
			if (!table) {
				return { ...newRow, _id: newRow.id }
			}
			const isLockedMap = new Map(
				table.fields.map((_field) => [
					_field._id,
					_field.locked || _field.type === TableTypes.FieldType.CALCULATION,
				]),
			)

			_forEach(newRow, (value, id) => {
				const isLocked = isLockedMap.get(id)
				let newValue = value
				if (isLocked) {
					newValue = undefined
				}

				newRow[id] = newValue
			})

			const resolved = resolveAnyRowCalculations(table.fields, { _id: newRow._id, data: newRow })

			setUnsavedChanges((prev) => {
				const rowId = resolved._id
				const unsavedChangesCopy = { ...prev }
				unsavedChangesCopy.newRows[rowId] = resolved
				return unsavedChangesCopy
			})

			return resolved
		},
		[table],
	)

	const saveContents = useCallback(
		async (rowId: string, row: GridValidRowModel, newContents: Contents) => {
			if (!table) return
			try {
				setIsUpdatingTable(true)
				const response = await TablesSdk.updateRecords(
					import.meta.env.VITE_API as string,
					authHeaders,
					{
						tableId: table._id,
						records: [
							{
								_id: rowId,
								fields: {
									...row,
									[TableTypes.ProductionFieldId.RESOURCES as string]: newContents as any,
								},
							},
						],
						newRecords: [],
					},
				)
				setTable(response)
			} catch (error) {
				errorHandler({ error, dispatch, message: 'Could not update contents' })
			} finally {
				setIsUpdatingTable(false)
			}
		},
		[table],
	)

	const cacheRowUpdate: DataGridProProps['processRowUpdate'] = (
		newRow: GridValidRowModel,
		oldRow: GridValidRowModel,
	) => {
		if (!table) return oldRow
		const isLockedMap = new Map(
			table.fields.map((_field) => [
				_field._id,
				_field.locked || _field.type === TableTypes.FieldType.CALCULATION,
			]),
		)
		const tableRecord = table.records.find((_record) => _record._id === newRow._id)

		if (!tableRecord) {
			return oldRow
		}

		_forEach(newRow, (value, id) => {
			const isLocked = isLockedMap.get(id)
			let newValue = value
			if (isLocked) {
				newValue = tableRecord.data[id]
			}

			newRow[id] = newValue
		})

		const resolved = resolveAnyRowCalculations(table.fields, { _id: newRow._id, data: newRow })

		setUnsavedChanges((prev) => {
			const rowId = resolved._id
			const unsavedChangesCopy = { ...prev }
			unsavedChangesCopy.unsavedRows[rowId] = resolved
			if (!unsavedChangesCopy.rowsBeforeChange[rowId]) {
				unsavedChangesCopy.rowsBeforeChange[rowId] = oldRow
			}

			return unsavedChangesCopy
		})

		return resolved
	}

	const discardChange = useCallback((id: string) => {
		setUnsavedChanges((prev) => {
			const unsavedChangesCopy = { ...prev }
			delete unsavedChangesCopy.unsavedRows[id]
			delete unsavedChangesCopy.rowsBeforeChange[id]
			delete unsavedChangesCopy.newRows[id]
			return unsavedChangesCopy
		})
	}, [])

	const discardAllChanges = useCallback(() => {
		setUnsavedChanges({
			unsavedRows: {},
			rowsBeforeChange: {},
			newRows: {},
		})
	}, [])

	const deleteRow = useCallback((id: string, row: any) => {
		setUnsavedChanges((prev) => {
			const unsavedChangesCopy = { ...prev }
			unsavedChangesCopy.unsavedRows[id] = {
				...row,
				_action: 'delete',
			}
			if (!unsavedChangesCopy.rowsBeforeChange[id]) {
				unsavedChangesCopy.rowsBeforeChange[id] = row
			}
			return unsavedChangesCopy
		})
	}, [])

	const handleAddRow = useCallback(async () => {
		if (!table) return
		setIsUpdatingTable(true)
		try {
			const response = await TablesSdk.addRow(import.meta.env.VITE_API as string, authHeaders, {
				tableId: table._id,
			})
			setTable((prev) => {
				if (!prev) return prev
				return {
					...prev,
					records: [...prev.records, response],
				}
			})
		} catch (error) {
			errorHandler({ error, dispatch, message: 'Could not add row' })
		} finally {
			setIsUpdatingTable(false)
		}
	}, [table?._id, authHeaders])

	const handleAddColumn = useCallback(async () => {
		if (!table) return
		setIsUpdatingTable(true)
		try {
			const response = await TablesSdk.addColumn(import.meta.env.VITE_API as string, authHeaders, {
				tableId: table._id,
			})
			setTable((prev) => {
				if (!prev) return prev
				return {
					...prev,
					fields: [...prev.fields, response],
				}
			})
		} catch (error) {
			let errorMessage = 'Could not add column'
			if ((error as AxiosError<{ message?: string }>).response?.data?.message) {
				errorMessage = (error as AxiosError<{ message?: string }>).response?.data?.message as string
			}
			errorHandler({ error, dispatch, message: errorMessage })
		} finally {
			setIsUpdatingTable(false)
		}
	}, [table?._id, authHeaders])

	const handleDeleteColumn = useCallback(
		async (fieldId: string) => {
			if (!table) return
			setIsUpdatingTable(true)
			try {
				const response = await TablesSdk.deleteField(
					import.meta.env.VITE_API as string,
					authHeaders,
					{
						tableId: table._id,
						fieldId,
					},
				)
				setTable(response)
			} catch (error) {
				errorHandler({ error, dispatch, message: 'Could not delete column' })
			} finally {
				setIsUpdatingTable(false)
			}
		},
		[table?._id, authHeaders],
	)

	const handleUpdateColumn = useCallback(
		async (
			updates: Omit<TablesSdk.UpdateFieldRequest, 'tableId'>,
		): Promise<{ result: 'success' | 'error' }> => {
			if (!table) return { result: 'error' }
			setIsUpdatingTable(true)
			try {
				const response = await TablesSdk.updateField(
					import.meta.env.VITE_API as string,
					authHeaders,
					{
						...updates,
						tableId: table._id,
					},
				)
				setTable(response)
				showSnackbar('Column updated', { variant: 'success' })
				setIsUpdatingTable(false)
				return { result: 'success' }
			} catch (error) {
				errorHandler({ error, dispatch, message: 'Could not update column' })
				setIsUpdatingTable(false)
				return { result: 'error' }
			}
		},
		[table?._id, authHeaders],
	)

	const handleColumnOrderChange = useCallback(
		async (params: GridColumnOrderChangeParams) => {
			if (!table) return
			setIsUpdatingTable(true)
			try {
				const response = await TablesSdk.updateColumnOrder(
					import.meta.env.VITE_API as string,
					authHeaders,
					{
						tableId: table._id,
						targetIndex: params.targetIndex - 2,
						oldIndex: params.oldIndex - 2,
					},
				)
				setTable(response)
			} catch (error) {
				errorHandler({ error, dispatch, message: 'Could not update column order' })
			} finally {
				setIsUpdatingTable(false)
			}
		},
		[table?._id, authHeaders],
	)

	const handleRowOrderChange = useCallback(
		async (params: GridRowOrderChangeParams) => {
			if (!table) return
			setIsUpdatingTable(true)
			try {
				const response = await TablesSdk.updateRowOrder(
					import.meta.env.VITE_API as string,
					authHeaders,
					{
						tableId: table._id,
						targetIndex: params.targetIndex,
						oldIndex: params.oldIndex,
					},
				)
				setTable(response)
			} catch (error) {
				errorHandler({ error, dispatch, message: 'Could not update column order' })
			} finally {
				setIsUpdatingTable(false)
			}
		},
		[table?._id, authHeaders],
	)

	const fetchTable = useCallback(
		async (newTableId: string) => {
			try {
				setIsLoadingTable(true)
				const response = await TablesSdk.getTable(import.meta.env.VITE_API as string, authHeaders, {
					tableId: newTableId,
				})
				setTable(response)
			} catch (error) {
				if ((error as AxiosError).response?.status === 404) {
					showSnackbar('Table not found', { variant: 'error' })
					navigate(`/${RouteId.Tables}`)
					return
				}
				errorHandler({ error, dispatch, message: 'Could not fetch table' })
			} finally {
				setIsLoadingTable(false)
			}
		},
		[authHeaders],
	)

	useEffect(() => {
		if (tableId) {
			fetchTable(tableId)
		}
	}, [tableId])

	return (
		<TableContext.Provider
			value={{
				table,
				isLoadingTable,
				saveChanges,
				isUpdatingTable,
				cacheRowUpdate,
				unsavedChanges,
				discardChange,
				deleteRow,
				discardAllChanges,
				onColumnOrderChange: handleColumnOrderChange,
				onRowOrderChange: handleRowOrderChange,
				onAddRow: handleAddRow,
				onAddColumn: handleAddColumn,
				mappedColumns,
				mappedRows,
				onUpdateColumn: handleUpdateColumn,
				onDeleteColumn: handleDeleteColumn,
				cacheNewRow,
				saveContents,
			}}>
			{props.children}
		</TableContext.Provider>
	)
}
