import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'

import { Box, FormCard } from 'src/components'
import { selectors as myTasksSelectors } from 'src/store/modules/my-tasks-v3'
import { getActionLabel } from 'src/helpers/labels'

import { FileTemplate } from './file-tasks/file-template'
import { FileUpload } from './file-tasks/file-upload'
import { VideoEmbed } from './video-embed'
import { CreateContact } from './create-contact'
import { Note } from './note'
import { ContactInfo } from './contact-info'
import { FileView } from './file-tasks/file-view'
import { Archive } from './archive'
import { Software } from './software'
import { LinkView } from './link-view'

export const TaskAction: ComponentType = () => {
	const project = useSelector(myTasksSelectors.getSelectedProject)
	const task = useSelector(myTasksSelectors.getSelectedTask)

	const getAction = (action: V3ClientTypes.Project.TaskAction) => {
		if (!task || !project) return null

		// if (task.type === BlueprintTypes.TaskType.milestone) {
		// 	return <Milestone task={task} />
		// }

		switch (action.type) {
			case V3BlueprintTypes.ActionEnum.FileTemplate:
				return <FileTemplate task={task} project={project} action={action} />
			case V3BlueprintTypes.ActionEnum.Archive:
				return <Archive task={task} />
			case V3BlueprintTypes.ActionEnum.Invite:
			case V3BlueprintTypes.ActionEnum.Meeting:
			case V3BlueprintTypes.ActionEnum.Call:
			case V3BlueprintTypes.ActionEnum.Email:
				return <ContactInfo project={project} />
			case V3BlueprintTypes.ActionEnum.Contact:
				return <CreateContact task={task} />
			case V3BlueprintTypes.ActionEnum.FileUpload:
				return <FileUpload project={project} task={task} action={action} />
			case V3BlueprintTypes.ActionEnum.FileView:
				return <FileView task={task} project={project} action={action} />
			case V3BlueprintTypes.ActionEnum.Note:
				return <Note note={action.note} />
			case V3BlueprintTypes.ActionEnum.Software:
				return <Software links={action.links} />
			case V3BlueprintTypes.ActionEnum.Video:
				return <VideoEmbed urls={action.links} />
			case V3BlueprintTypes.ActionEnum.LinkView:
				return <LinkView task={task} />
		}
	}

	if (!task) return null

	return (
		<Box display="flex" justifyContent="center" mb={2}>
			<Box maxWidth="800px" width="100%" pt={3} minHeight={100}>
				{/* ===== Action goes below here ===== */}
				{task.actions.map((action) => (
					<FormCard key={action._id} title={getActionLabel(action.type)} mb={2}>
						<React.Fragment>{getAction(action)}</React.Fragment>
					</FormCard>
				))}
				{/* ===== Action goes above here ===== */}
			</Box>
		</Box>
	)
}
