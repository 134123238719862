import {
	ComponentType,
	useCallback,
	useEffect,
	useState,
	useRef,
	FormEvent,
	KeyboardEvent,
	useMemo,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PulseLoader } from 'react-spinners'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import IconButton from '@mui/material/IconButton'
import AvatarGroup from '@mui/material/AvatarGroup'
import { FirebaseDirectory } from '@cango-app/sdk'
import mapKeys from 'lodash/mapKeys'
import { Mention, MentionsInput } from 'react-mentions'
import { V3ClientTypes } from '@cango-app/types'

import { Box, Button, DriveUpload, PulsatingDot, Text, UserAvatar } from 'src/components'
import { AttachmentIcon } from 'src/assets/icons'
import { selectors as notesSelectors, actions as notesActions } from 'src/store/modules/notes'
import { selectors as userSelectors } from 'src/store/modules/user'
import { colors } from 'src/theme/colors'
import { getNameInitials } from 'src/routing/navigation/utils'
import { AnyListener, useFirebaseRealtimeChatMeta } from 'src/hooks/useFirebaseRealtime'
import { AsyncDispatchType } from 'src/store/types'
import { taggedInputStandard, taggedMentionStandard } from 'src/helpers/ui'
import { useIsMobile } from 'src/hooks/useMobile'
import { usePrevious } from 'src/hooks/usePrevious'

import { NotesList } from './task-chat-notes-list'

dayjs.extend(relativeTime)

export type TaskChatProps = {
	project?: Pick<V3ClientTypes.Project.Project, '_id' | 'googleDriveId' | 'name'>
	task?: Pick<V3ClientTypes.Project.Task, '_id' | 'name'>
	onTaskNameClick?: () => void
}

export const TaskChat: ComponentType<TaskChatProps> = ({ task, project, onTaskNameClick }) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const notesContainerRef = useRef<HTMLDivElement | null>(null)
	const isMobile = useIsMobile()
	const previousTaskId = usePrevious(task?._id)
	const [inputValue, setInputValue] = useState('')
	const users = useSelector(userSelectors.getAllUsers)
	const taggingUsers = useSelector(userSelectors.getAllUsersForTagging)
	const userId = useSelector(userSelectors.getCurrentUserId)
	const latestNoteTime = useSelector(notesSelectors.getLatestNoteTime)
	const [latestFirebaseNoteTime, setLatestFirebaseNoteTime] = useState<number>()
	const notes = useSelector(notesSelectors.getTaskNotes)
	const currentUserId = useSelector(userSelectors.getCurrentUserId)
	const isNotesLoading = useSelector(notesSelectors.isLoadingTaskNotes)
	const [connectedUsers, setConnectedUsers] = useState<
		{ _id: string; initials: string; picture: string | undefined }[]
	>([])

	const resetNotes = useCallback(() => {
		dispatch(notesActions.resetTaskNotes())
	}, [dispatch])

	const handleSubmitNote = (e: FormEvent<HTMLFormElement> | KeyboardEvent) => {
		e.preventDefault()
		if (inputValue.trim() === '' || !task || !currentUserId) return
		const tempId = uuidv4()
		dispatch(
			notesActions.sendNote({
				taskId: task._id,
				note: inputValue,
				tempId,
				userId: currentUserId,
			}),
		)
		setInputValue('')
	}

	const handleUploadedFiles = (uploadedFileIds: string[]) => {
		if (!task || !currentUserId || !uploadedFileIds.length) return
		const tempId = uuidv4()
		dispatch(
			notesActions.sendNote({
				taskId: task._id,
				note: ' ',
				fileIds: uploadedFileIds,
				tempId,
				userId: currentUserId,
			}),
		)
	}

	const scrollToBottom = () => {
		if (notesContainerRef.current) {
			notesContainerRef.current.scrollTop = notesContainerRef.current.scrollHeight
		}
	}

	const updateMessages = async () => {
		if (!task) return
		dispatch(
			notesActions.updateTaskNotes({
				taskId: task._id,
				latestMessage: String(latestNoteTime),
			}),
		)
	}

	const fetchMessages = useCallback(async (taskId: string) => {
		if (!taskId) return

		dispatch(
			notesActions.fetchAllTaskNotes({
				taskId,
			}),
		)
	}, [])

	const listeners = useMemo((): AnyListener[] => {
		if (!task?._id) return []
		return [
			{
				directory: FirebaseDirectory.LatestNote,
				reference: `${task?._id}/timestamp`,
				taskId: task._id,
				handler: ({ taskId, data }) => {
					if (taskId === task._id && latestFirebaseNoteTime && data > latestFirebaseNoteTime) {
						updateMessages()
					}
					setLatestFirebaseNoteTime(data)
				},
			},
			{
				directory: FirebaseDirectory.NoteParticiants,
				reference: `${task?._id}`,
				taskId: task._id,
				handler: (payload) => {
					const { data, taskId } = payload
					if (taskId !== task?._id) return
					const updatedConnectedUsers: {
						_id: string
						initials: string
						picture: string | undefined
					}[] = []
					mapKeys(data, (value, key) => {
						if (key !== userId) {
							const user = users.find((user) => user._id === key)
							if (!user) return
							updatedConnectedUsers.push({
								_id: key,
								initials: getNameInitials(user.name, user.surname),
								picture: user.picture,
							})
						}
					})
					setConnectedUsers(updatedConnectedUsers)
				},
			},
		]
	}, [task?._id, latestFirebaseNoteTime])

	const { switchReferences, logUserEntry } = useFirebaseRealtimeChatMeta()

	const handleKeyDown = (e: KeyboardEvent) => {
		if (e.key === 'Enter' && !e.shiftKey) {
			e.preventDefault()
			handleSubmitNote(e)
		}
	}

	useEffect(() => {
		if (latestNoteTime && !latestFirebaseNoteTime) {
			setLatestFirebaseNoteTime(latestNoteTime)
		}
	}, [latestFirebaseNoteTime, latestNoteTime])

	useEffect(() => {
		if (task?._id && previousTaskId !== task._id) {
			fetchMessages(task._id)
			switchReferences(listeners)
			logUserEntry(task._id)
			scrollToBottom()
			setInputValue('')
		}
	}, [previousTaskId, task?._id])

	useEffect(() => {
		return () => {
			resetNotes()
		}
	}, [])

	useEffect(() => {
		const timerId = setTimeout(scrollToBottom, 0)
		return () => clearTimeout(timerId)
	}, [notes.length])

	if (!task) {
		return null
	}

	return (
		<Box p={2} display="flex" flexDirection="column" flex={1}>
			<Box
				display="flex"
				justifyContent="space-between"
				borderBottom={1}
				borderColor={colors.neutral['40']}
				pb={1}
			>
				<Box>
					<Text
						fontWeight={500}
						fontSize={18}
						color={colors.neutral['80']}
						onClick={onTaskNameClick}
					>{`Add comments to "${task.name}"`}</Text>
					<Text fontWeight={400} fontSize={12}>
						{"Tag people with '@'"}
					</Text>
				</Box>
				<Box display="flex" alignItems="center">
					{!!connectedUsers.length && (
						<AvatarGroup sx={{ mr: 1 }}>
							{connectedUsers.map((user) => (
								<UserAvatar
									key={user._id}
									userInitials={user.initials}
									fontSize={8}
									size={20}
									picture={user?.picture}
								/>
							))}
						</AvatarGroup>
					)}
					<PulsatingDot size="small" />
				</Box>
			</Box>
			<div
				ref={notesContainerRef}
				style={{
					flexGrow: 1,
					overflowY: 'auto',
					height: isMobile ? '100dvh' : undefined,
				}}
			>
				{isNotesLoading && (
					<Box display="flex" justifyContent="center" alignItems="center">
						<PulseLoader size={8} />
					</Box>
				)}
				{!!notes.length && <NotesList />}
			</div>
			<Box display="flex" width="100%" borderTop={1} borderColor={colors.neutral['40']} pt={1}>
				<form
					onSubmit={handleSubmitNote}
					style={{
						display: 'flex',
						width: '100%',
					}}
				>
					<Box flex={1} bgcolor="#fff" borderRadius="8px">
						<MentionsInput
							className="cango-mentions-input"
							value={inputValue}
							onChange={(event: { target: { value: string } }) => setInputValue(event.target.value)}
							style={{ ...taggedInputStandard }}
							forceSuggestionsAboveCursor
							onKeyDown={handleKeyDown}
						>
							<Mention
								className="cango-mention"
								trigger="@"
								data={taggingUsers}
								style={taggedMentionStandard}
							/>
						</MentionsInput>
					</Box>
					{inputValue ? (
						<Button type="submit" variant="text">
							Send
						</Button>
					) : (
						!!project && (
							<DriveUpload
								parentFolderId={project.googleDriveId}
								parentFolderName={project.name}
								onChange={handleUploadedFiles}
								fileIds={[]}
							>
								{({ onClick }) => (
									<IconButton
										sx={{ ml: 1, borderRadius: '10px', bgcolor: '#fff', width: '50px' }}
										onClick={onClick}
									>
										<AttachmentIcon />
									</IconButton>
								)}
							</DriveUpload>
						)
					)}
				</form>
			</Box>
		</Box>
	)
}
