import { V3ClientTypes } from '@cango-app/types'
import Stack from '@mui/material/Stack'
import React from 'react'

import { Box, Text } from '../../../../components'
import { colors } from '../../../../theme/colors'
import { PricingTableMap } from '../types'
import {
	getMaterialContentsTotalCost,
	getLaborContentsTotalCost,
	getEquipmentContentsTotalCost,
} from '../total-costs'
import { formatCurrency } from '../format-number'

export function BidOverviewColumn({
	bid,
	bidItems,
	laborPricing,
	materialPricing,
	equipmentPricing,
}: {
	bid: V3ClientTypes.Project.Bid | null
	bidItems: V3ClientTypes.Project.BidItem[]
	laborPricing: PricingTableMap
	materialPricing: PricingTableMap
	equipmentPricing: PricingTableMap
}) {
	const totalMaterialCost = bidItems.reduce((acc, item) => {
		const materialCost = getMaterialContentsTotalCost(
			item.materialContents,
			materialPricing,
			bid?.markupValues.material,
			{ profitMargin: item.profitMargin },
		)
		return acc + materialCost.priceWithProfitMargin
	}, 0)

	const totalEquipmentCost = bidItems.reduce((acc, item) => {
		const equipmentCost = getEquipmentContentsTotalCost(
			item.equipmentContents,
			equipmentPricing,
			bid?.markupValues.equipment,
			{ hours: item.hours, profitMargin: item.profitMargin },
		)
		return acc + equipmentCost.priceWithProfitMargin
	}, 0)

	const totalLaborCost = bidItems.reduce((acc, item) => {
		const laborCost = getLaborContentsTotalCost(
			item.laborContents,
			laborPricing,
			bid?.markupValues.labor,
			{ hours: item.hours, profitMargin: item.profitMargin },
		)
		return acc + laborCost.priceWithProfitMargin
	}, 0)

	const totalCosts = [
		{
			title: 'Total material cost',
			value: formatCurrency(totalMaterialCost),
		},
		{
			title: 'Total equipment cost',
			value: formatCurrency(totalEquipmentCost),
		},
		{
			title: 'Total labor cost',
			value: formatCurrency(totalLaborCost),
		},
		{
			title: 'Total estimated cost',
			value: formatCurrency(totalMaterialCost + totalEquipmentCost + totalLaborCost),
		},
	]
	return (
		<Stack pr={4} direction="column" spacing={{ md: 6 }} useFlexGap>
			<Box>
				<Text variant="h5">Bid {bid?._id.substring(0, 6)}</Text>
			</Box>
			<Box>
				<Text variant="h6">Markup</Text>
				<Box mt={2}>
					{Object.keys(bid?.markupValues ?? {}).map((key) => (
						<Box
							key={key}
							display="flex"
							flexDirection="row"
							alignItems="center"
							justifyContent="space-between"
						>
							<Text textTransform="capitalize">{key}</Text>
							<Text>{bid?.markupValues[key]}%</Text>
						</Box>
					))}
				</Box>
			</Box>
			<Box>
				<Text variant="h6">Global summary</Text>
				<Stack direction="column" mt={2} spacing={{ md: 1 }} useFlexGap>
					{totalCosts?.map((cost) => (
						<Box key={cost.title} display="column">
							<Text variant="body1">{cost.title}</Text>
							<Text variant="body2" color={colors.neutral[60]}>
								{cost.value}
							</Text>
						</Box>
					))}
				</Stack>
			</Box>
		</Stack>
	)
}
