import React, { ComponentType, useContext } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { SxProps } from '@mui/material'
import _map from 'lodash/map'
import _find from 'lodash/find'

import { AttachedFile, Box, DriveUpload, Text } from 'src/components'
import { selectors as blueprintSelectors } from 'src/store/modules/blueprints-v3'
import { DriveFilesContext } from 'src/providers'

import { StepFormType } from './step-form-container'

type TemplateUploadFieldsProps = {
	containerStyles?: SxProps
	isLoading?: boolean
	optionId?: string
	actionIndex?: number
}

const UndecoratedTemplateUploadFields: ComponentType<TemplateUploadFieldsProps> = ({
	isLoading,
	containerStyles,
	optionId,
	actionIndex,
}) => {
	const { control } = useFormContext<StepFormType>()
	const { allFiles, parentFolderId } = useContext(DriveFilesContext)
	const blueprint = useSelector(blueprintSelectors.getSelectedBlueprint)

	if (!blueprint) return null

	return (
		<Box>
			<Controller
				control={control}
				name={`actions.${optionId}.${actionIndex}.file_ids`}
				render={({ field: { value, onChange }, fieldState: { error } }) => (
					<Box>
						{!!value.length && (
							<Box display="flex" flexWrap="wrap" sx={containerStyles}>
								{value.map((fileId) => (
									<AttachedFile
										key={fileId}
										fileId={fileId}
										containerStyles={{ mb: 0.5, mr: 1 }}
										_file={allFiles.find((file) => file.id === fileId)}
										_isLoadingFile={!!isLoading}
										folderId={parentFolderId}
									/>
								))}
							</Box>
						)}
						<DriveUpload
							parentFolderId={blueprint.googleDriveId}
							parentFolderName={blueprint.name}
							containerStyles={{ mt: value.length ? 1 : 0 }}
							fileIds={value}
							onChange={onChange}
							ctaVariant={value.length ? 'replaceIcon' : undefined}
							isLoading={isLoading}
						/>
						{!!error && (
							<Text color="red" fontSize={12}>
								{error.message}
							</Text>
						)}
					</Box>
				)}
			/>
		</Box>
	)
}

export const TemplateUploadFields = React.memo(UndecoratedTemplateUploadFields)
