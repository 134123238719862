import { createAction, createReducer } from '@reduxjs/toolkit'
import { V3ClientTypes, ClientTypes, TableTypes } from '@cango-app/types'

import { PricingTableLookup, PricingTableMap, ProductionItemTableLookup } from './types'

export enum Steps {
	idle = '',
	bidForm = 'bid-form',
	bidItemsTable = 'bid-items-table',
	bidItemForm = 'bid-item-form',
	bidItemContentsForm = 'bid-item-contents-form',
	bidSummary = 'bid-item-summary',
}
function getStepsOrder(state: BidBuilderState): {
	availableSteps: Steps[]
	currentStepPosition: number
} {
	const hasItems = state.bidItems.length > 0
	const hasSelectedBidItem = state.activeBidItemId != null

	const stepsOrder = [
		Steps.bidForm,
		hasItems && Steps.bidItemsTable,
		Steps.bidItemForm,
		hasSelectedBidItem && Steps.bidItemContentsForm,
		hasSelectedBidItem && Steps.bidSummary,
	]

	const availableSteps = stepsOrder.filter(Boolean) as Steps[]
	const currentStepPosition = availableSteps.findIndex((step) => step === state.resolvedStep)

	return { availableSteps, currentStepPosition }
}

export type BidBuilderState = {
	resolvedStep: Steps
	loading: boolean
	error: string | null
	bid: V3ClientTypes.Project.Bid | null
	bidItems: V3ClientTypes.Project.BidItem[]
	activeBidItemId: string | null
	pricingTables: {
		labor: PricingTableMap
		equipment: PricingTableMap
		material: PricingTableMap
	}
	productionItemsTable: ProductionItemTableLookup[]
}

export const setLoading = createAction<boolean>('setLoading')
export const initBid = createAction<V3ClientTypes.Project.Bid | null>('setBidAndBidItems')
export const updateBid = createAction<V3ClientTypes.Project.Bid>('updateBid')
export const setError = createAction<string>('setError')
export const setBidItems = createAction<V3ClientTypes.Project.BidItem[]>('setBidItems')
export const setActiveBidItemId = createAction<string | null>('setActiveBidItemId')
export const goToPrevStep = createAction<Pick<BidBuilderState, 'activeBidItemId'> | undefined>(
	'goToPrevStep',
)
export const goToNextStep = createAction<Pick<BidBuilderState, 'activeBidItemId'> | undefined>(
	'goToNextStep',
)
export const closeBidItemView = createAction('closeBidItemView')
export const setPricingTable = createAction<{
	key: keyof BidBuilderState['pricingTables']
	data: TableTypes.PricingTable
}>('setPricingTable')
export const setUnitsTable = createAction<ClientTypes.Table.CangoTable>('setUnitsTable')
export const setProposalGroupsTable =
	createAction<ClientTypes.Table.CangoTable>('setProposalGroupsTable')
export const setProductionItemsTable =
	createAction<ClientTypes.Table.CangoTable>('setProductionItemsTable')

export type BidBuilderAction =
	| ReturnType<typeof setLoading>
	| ReturnType<typeof initBid>
	| ReturnType<typeof updateBid>
	| ReturnType<typeof setError>
	| ReturnType<typeof setBidItems>
	| ReturnType<typeof setActiveBidItemId>
	| ReturnType<typeof goToPrevStep>
	| ReturnType<typeof goToNextStep>
	| ReturnType<typeof closeBidItemView>
	| ReturnType<typeof setPricingTable>
	| ReturnType<typeof setUnitsTable>
	| ReturnType<typeof setProposalGroupsTable>
	| ReturnType<typeof setProductionItemsTable>

export const initialState = {
	resolvedStep: Steps.idle,
	bid: null,
	error: null,
	loading: false,
	bidItems: [],
	activeBidItemId: null,
	pricingTables: {
		labor: {},
		equipment: {},
		material: {},
	},
	productionItemsTable: [],
}

export const reducer = createReducer<BidBuilderState>(initialState, (builder) => {
	builder
		.addCase(setLoading, (state, action) => {
			state.loading = action.payload
		})
		.addCase(setError, (state, action) => {
			state.error = action.payload
			state.loading = false
		})
		.addCase(initBid, (state, action) => {
			const bid = action.payload
			state.bid = bid

			if (state.bid == null && state.resolvedStep === Steps.idle) {
				state.resolvedStep = Steps.bidForm
				state.loading = false
			}
		})
		.addCase(updateBid, (state, action) => {
			state.resolvedStep = Steps.bidForm
			state.bid = action.payload
			state.loading = false
		})
		.addCase(setBidItems, (state, action) => {
			state.bidItems = action.payload
			state.loading = false
			if (state.resolvedStep === Steps.idle) {
				if (state.bid != null && action.payload.length > 0) {
					state.resolvedStep = Steps.bidItemsTable
				} else {
					state.resolvedStep = Steps.bidItemForm
				}
			}
		})
		.addCase(setActiveBidItemId, (state, action) => {
			state.activeBidItemId = action.payload
		})
		.addCase(goToPrevStep, (state, action) => {
			const hasBid = state.bid != null

			if (!hasBid) return state
			const { availableSteps, currentStepPosition } = getStepsOrder(state)
			if (currentStepPosition === -1) return state
			const prevStep = availableSteps[currentStepPosition - 1]
			if (prevStep == null) return state
			state.resolvedStep = prevStep

			if (action.payload != null) {
				state.activeBidItemId = action.payload.activeBidItemId
			}
		})
		.addCase(goToNextStep, (state, action) => {
			const hasBid = state.bid != null
			if (!hasBid) return state
			const { availableSteps, currentStepPosition } = getStepsOrder(state)
			// if (currentStepPosition === -1) return state
			const nextStep = availableSteps[currentStepPosition + 1]
			if (nextStep == null) return state
			state.resolvedStep = nextStep

			if (action.payload != null) {
				state.activeBidItemId = action.payload.activeBidItemId
			}
		})
		.addCase(closeBidItemView, (state) => {
			state.resolvedStep = Steps.bidItemsTable
			state.activeBidItemId = null
		})
		.addCase(setPricingTable, (state, action) => {
			const { key, data } = action.payload

			state.pricingTables[key] = data.records.reduce((acc, record) => {
				const item = record.data
				acc[record._id] = {
					_id: record._id,
					[TableTypes.PricingTableFieldId.NAME]: item[TableTypes.PricingTableFieldId.NAME],
					[TableTypes.PricingTableFieldId.PRICE]: item[TableTypes.PricingTableFieldId.PRICE],
				} as PricingTableLookup
				return acc
			}, {} as PricingTableMap)
		})
		.addCase(setProductionItemsTable, (state, action) => {
			const data = action.payload

			const fieldIds = data.fields.reduce((acc: string[], field) => {
				return [...acc, field._id]
			}, [])

			state.productionItemsTable = data.records.map((record) => {
				const item: Record<string, string | number> = {}
				fieldIds.forEach((fieldId) => {
					// @ts-ignore
					item[fieldId] = record.data[fieldId]
				})

				return { ...item, _id: record._id } as unknown as ProductionItemTableLookup
			})
		})
})
