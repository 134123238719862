import { ComponentType, MouseEvent } from 'react'

import { ChevronRight } from 'src/assets/icons'
import { Button, Box, Text } from 'src/components'
import { useIsMobile } from 'src/hooks/useMobile'
import { colors } from 'src/theme/colors'

type ParentTaskCtaProps = {
	isOpen: boolean
	onClick: (event: MouseEvent<HTMLButtonElement>) => void
}

export const ParentTaskCta: ComponentType<ParentTaskCtaProps> = ({ isOpen, onClick }) => {
	const isMobile = useIsMobile()
	return (
		<Box display="flex" alignItems="center">
			{!isMobile && (
				<Text mr={2} fontSize={14} color={colors.neutral['60']}>
					Incomplete subtasks
				</Text>
			)}
			<Button
				variant="outlined"
				sx={{ bgcolor: '#fff' }}
				endIcon={
					<ChevronRight
						style={{
							transform: isOpen ? 'rotate(90deg)' : undefined,
						}}
					/>
				}
				onClick={onClick}>
				{isOpen ? 'Close subtasks' : 'View subtasks'}
			</Button>
		</Box>
	)
}
