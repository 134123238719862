/* eslint react/jsx-key: 0 */

import CircularProgress from '@mui/material/CircularProgress'
import { TableTypes, V3ClientTypes } from '@cango-app/types'
import React, { useMemo } from 'react'
import { z } from 'zod'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import Stack from '@mui/material/Stack'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import * as Sentry from '@sentry/react'
import { GridActionsCellItem, GridValidRowModel } from '@mui/x-data-grid-pro'

import { Box, Grid, Text, Button } from '../../../components'
import { ChevronRight } from '../../../assets/icons'

import { BidOverviewColumn } from './components/bid-overview-column'
import { ColumnStepsLayout } from './components/column-steps-layout'
import { BidItemData, PricingTableMap, ProductionItem } from './types'
import { bidItemSchema } from './schemas'
import { BidItemOverviewColumn } from './components/bid-item-overview-column'
import { ContentsTable } from './components/contents-table'
import { formatCurrency } from './format-number'

type BidItemContentsProps = {
	loading: boolean
	data: V3ClientTypes.Project.BidItem | null
	goToPrevStep: () => void
	goToNextStep: () => void
	onSave: (data: BidItemData) => Promise<void>
	bid: V3ClientTypes.Project.Bid | null
	bidItems: V3ClientTypes.Project.BidItem[]
	laborPricing: PricingTableMap
	materialPricing: PricingTableMap
	equipmentPricing: PricingTableMap
	productionItems: ProductionItem[]
}

export const BidItemContentsForm = (props: BidItemContentsProps) => {
	const {
		loading,
		bid,
		data,
		onSave,
		goToPrevStep,
		goToNextStep,
		bidItems,
		laborPricing,
		materialPricing,
		equipmentPricing,
		productionItems,
	} = props
	const form = useForm<z.infer<typeof bidItemSchema>>({
		defaultValues: {
			...data,
		},
		resolver: zodResolver(bidItemSchema),
	})

	const productionItem = productionItems.find((item) => item._id === data?.productionItemId) ?? null

	const { laborContents = [], materialContents = [], equipmentContents = [] } = data ?? {}
	const { isValid, isDirty } = form.formState
	const inputs = form.watch()

	const reactiveBidItems = useMemo(
		() =>
			bidItems.map((item) => {
				if (item._id === inputs._id) {
					return {
						...item,
						...inputs,
					}
				}
				return item
			}),
		[bidItems, inputs],
	)

	const saveRow =
		(type: 'laborContents' | 'materialContents' | 'equipmentContents') =>
		async (updatedRow: GridValidRowModel, oldRow: GridValidRowModel) => {
			const contents = form.getValues(type)

			const updatedContents = contents.map((row) => {
				if (row._id === updatedRow._id) {
					return {
						...row,
						...updatedRow,
					}
				}
				return row
			})
			form.setValue(type, updatedContents)

			try {
				await form.handleSubmit(onSave)()
				return updatedRow
			} catch (error) {
				return oldRow
			}
		}

	const addRow =
		(type: 'laborContents' | 'materialContents' | 'equipmentContents') =>
		async (optionId: string) => {
			const contents = form.getValues(type)
			let newContents: { _id: string; quantity: number; usage?: number }
			if (type === 'laborContents') {
				const price = laborPricing[optionId]?.[TableTypes.PricingTableFieldId.PRICE]
				newContents = { _id: optionId, quantity: 1 }
			} else if (type === 'materialContents') {
				const price = materialPricing[optionId]?.[TableTypes.PricingTableFieldId.PRICE]
				newContents = { _id: optionId, quantity: 1 }
			} else {
				const price = equipmentPricing[optionId]?.[TableTypes.PricingTableFieldId.PRICE]
				newContents = { _id: optionId, quantity: 1, usage: 1 }
			}

			form.setValue(type, [...contents, newContents])

			try {
				await form.handleSubmit(onSave)()
			} catch (error) {
				Sentry.captureException(error)
			}
		}

	const deleteRow = async (
		type: 'laborContents' | 'materialContents' | 'equipmentContents',
		rowId: string,
	) => {
		const contents = form.getValues(type)
		const updatedContents = contents.filter((row) => row._id !== rowId)
		form.setValue(type, updatedContents)

		try {
			await form.handleSubmit(onSave)()
		} catch (error) {
			Sentry.captureException(error, {
				extra: {
					type,
					rowId,
				},
			})
		}
	}

	return (
		<ColumnStepsLayout
			columns={[
				<BidOverviewColumn
					bid={bid}
					bidItems={reactiveBidItems}
					laborPricing={laborPricing}
					equipmentPricing={equipmentPricing}
					materialPricing={materialPricing}
				/>,
				<BidItemOverviewColumn bidItem={data} productionItem={productionItem} />,
				<Box display="flex" flexDirection="column" px={4} width="100%">
					<Box width="100px">
						<Button
							variant="text"
							sx={{ minWidth: 'auto' }}
							startIcon={<ChevronRight style={{ transform: 'rotate(180deg)' }} />}
							onClick={() => goToPrevStep()}
						>
							Back
						</Button>
					</Box>
					<Box display="flex" alignItems="center">
						<Text variant="h4">Bid Item contents</Text>
						{loading && <CircularProgress size={20} thickness={5} sx={{ ml: 2 }} />}
					</Box>
					<Box>
						<Grid container py={2}>
							<Grid item md={6}>
								<ContentsTable
									title="Labor"
									onRowUpdate={saveRow('laborContents')}
									onAddOption={addRow('laborContents')}
									addRowOptions={Object.keys(laborPricing).map((key) => ({
										_id: key,
										label: laborPricing[key]?.[TableTypes.PricingTableFieldId.NAME],
									}))}
									columns={[
										{
											field: 'quantity',
											headerName: 'Qty.',
											type: 'number',
											minWidth: 65,
											sortable: false,
											editable: true,
										},
										{
											field: 'type',
											headerName: 'Class',
											type: 'string',
											minWidth: 155,
											sortable: false,
											valueGetter: ({ row }) => {
												return laborPricing[row._id]?.[TableTypes.PricingTableFieldId.NAME]
											},
										},
										{
											field: 'value',
											headerName: 'Hourly rate',
											type: 'number',
											minWidth: 110,
											sortable: false,
											editable: true,
											valueGetter: ({ row }) => {
												return laborPricing[row._id]?.[TableTypes.PricingTableFieldId.PRICE]
											},
											valueFormatter: ({ value }) => {
												return formatCurrency(value)
											},
										},
										{
											field: 'options',
											headerName: '',
											type: 'actions',
											minWidth: 50,
											sortable: false,
											editable: false,
											getActions: ({ id, row }) => [
												<GridActionsCellItem
													key={`${id}-delete`}
													icon={<DeleteIcon />}
													label="Delete"
													onClick={() => deleteRow('laborContents', id as string)}
												/>,
											],
										},
									]}
									data={laborContents}
								/>
							</Grid>
							<Grid item md={6}>
								<ContentsTable
									title="Material"
									onRowUpdate={saveRow('materialContents')}
									onAddOption={addRow('materialContents')}
									addRowOptions={Object.keys(materialPricing).map((key) => ({
										_id: key,
										label: materialPricing[key]?.[TableTypes.PricingTableFieldId.NAME],
									}))}
									columns={[
										{
											field: 'quantity',
											headerName: 'Qty.',
											type: 'number',
											minWidth: 65,
											sortable: false,
											editable: true,
										},
										{
											field: 'type',
											headerName: 'Class',
											type: 'string',
											minWidth: 155,
											sortable: false,
											editable: true,
											valueGetter: ({ row }) => {
												return materialPricing[row._id]?.[TableTypes.PricingTableFieldId.NAME]
											},
										},
										{
											field: 'value',
											headerName: 'Cost / unit',
											type: 'number',
											minWidth: 90,
											sortable: false,
											editable: true,
											valueGetter: ({ row }) => {
												return materialPricing[row._id]?.[TableTypes.PricingTableFieldId.PRICE]
											},
											valueFormatter: ({ value }) => {
												return formatCurrency(value)
											},
										},
										{
											field: 'options',
											headerName: '',
											type: 'actions',
											minWidth: 50,
											sortable: false,
											editable: false,
											getActions: ({ id, row }) => [
												<GridActionsCellItem
													key={`${id}-delete`}
													icon={<DeleteIcon />}
													label="Delete"
													onClick={() => deleteRow('materialContents', id as string)}
												/>,
											],
										},
									]}
									data={materialContents}
								/>
							</Grid>
							<Grid item md={8}>
								<ContentsTable
									title="Equipment"
									onRowUpdate={saveRow('equipmentContents')}
									onAddOption={addRow('equipmentContents')}
									addRowOptions={Object.keys(equipmentPricing).map((key) => ({
										_id: key,
										label: equipmentPricing[key]?.[TableTypes.PricingTableFieldId.NAME],
									}))}
									columns={[
										{
											field: 'quantity',
											headerName: 'Qty.',
											type: 'number',
											minWidth: 65,
											sortable: false,
											editable: true,
										},
										{
											field: 'type',
											headerName: 'Class',
											type: 'string',
											minWidth: 190,
											sortable: false,
											editable: true,
											valueGetter: ({ row }) => {
												return equipmentPricing[row._id]?.[TableTypes.PricingTableFieldId.NAME]
											},
										},
										{
											field: 'usage',
											headerName: 'Usage',
											type: 'number',
											minWidth: 90,
											sortable: false,
											editable: true,
											valueFormatter: ({ value }) => {
												return value * 100 + '%'
											},
										},
										{
											field: 'value',
											headerName: 'Hourly rate',
											type: 'number',
											width: 110,
											sortable: false,
											editable: true,
											valueGetter: ({ row }) => {
												return equipmentPricing[row._id]?.[TableTypes.PricingTableFieldId.PRICE]
											},
											valueFormatter: ({ value }) => {
												return formatCurrency(value)
											},
										},
										{
											field: 'options',
											headerName: '',
											type: 'actions',
											minWidth: 50,
											sortable: false,
											editable: false,
											getActions: ({ id, row }) => [
												<GridActionsCellItem
													key={`${id}-delete`}
													icon={<DeleteIcon />}
													label="Delete"
													onClick={() => deleteRow('equipmentContents', id as string)}
												/>,
											],
										},
									]}
									data={equipmentContents}
								/>
							</Grid>
							<Grid item md={12}>
								<Stack direction="row" spacing={{ md: 2 }} justifyContent="flex-end">
									<Button
										variant="contained"
										color="primary"
										type="submit"
										disabled={!isDirty || !isValid}
									>
										Save
									</Button>
									{!isDirty && (
										<Button variant="outlined" color="feldgrau40" onClick={() => goToNextStep()}>
											Continue
										</Button>
									)}
									{isDirty && (
										<Button variant="outlined" color="neutral" onClick={() => form.reset()}>
											Reset
										</Button>
									)}
								</Stack>
							</Grid>
						</Grid>
					</Box>
				</Box>,
			]}
		/>
	)
}
