import { TableTypes } from '@cango-app/types'
import { GridNativeColTypes } from '@mui/x-data-grid-pro'

export const getFieldType = (type: TableTypes.FieldType): GridNativeColTypes => {
	switch (type) {
		case TableTypes.FieldType.NUMBER:
		case TableTypes.FieldType.CALCULATION:
			return 'number'
		case TableTypes.FieldType.DATE:
			return 'date'
		case TableTypes.FieldType.CONTACT:
		case TableTypes.FieldType.TABLE_SELECT:
			return 'singleSelect'
		case TableTypes.FieldType.RESOURCES:
			return 'actions'
		default:
			return type
	}
}
