import React, { ComponentType, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GridRowParams } from '@mui/x-data-grid-pro'
import { V3BlueprintTypes } from '@cango-app/types'

import {
	selectors as blueprintSelectors,
	actions as blueprintActions,
	V3BlueprintStep,
} from 'src/store/modules/blueprints-v3'
import { selectors as roleSelectors } from 'src/store/modules/roles'
import { AnalyticsEvent, analytics } from 'src/biz'
import SectionTasks from 'src/components/section-tasks-v3'

import StepDrawer from '../step-drawer'

import { BlueprintRow, columns } from './index'

const BlueprintOrphanedSteps: ComponentType = () => {
	const dispatch = useDispatch()
	const [showNewStepDrawer, setShowNewStepDrawer] = useState(false)
	const selectedStep = useSelector(blueprintSelectors.getSelectedStep)
	const orphanedSteps = useSelector(blueprintSelectors.getOrphanedSteps)
	const mappedRoles = useSelector(roleSelectors.getMappedRoles)

	const handleStepEditClick = (row: GridRowParams<BlueprintRow>) => {
		dispatch(blueprintActions.setSelectedStepId(row.id as string))
		analytics.track({ eventName: AnalyticsEvent.stepRowClick, properties: row })
	}

	const shouldOpenStepDrawer = useMemo(() => {
		if (showNewStepDrawer) return true
		if (!selectedStep) return false
		const orphanedStepIds = orphanedSteps.map((step) => step._id)
		return orphanedStepIds.includes(selectedStep._id)
	}, [selectedStep?._id, showNewStepDrawer, orphanedSteps])

	const rows: BlueprintRow[] = useMemo(
		() =>
			orphanedSteps.map((step: V3BlueprintStep) => {
				return {
					id: step._id,
					name: step.name,
					owner: mappedRoles.get(step.role)?.label,
					parents: step.parents,
					requiresEveryParent: step.requiresEveryParent,
					options: step.children,
					action: V3BlueprintTypes.ActionEnum.None,
					hierarchy: [step._id],
					__reorder__: step.name,
					when: step.when.type,
					createForEveryOption: !!step.createForEveryOption,
					isMenu: step.isMenu,
					chains: new Map(),
				}
			}),
		[[...orphanedSteps]],
	)

	const handleCloseStepDrawer = () => {
		if (showNewStepDrawer) {
			setShowNewStepDrawer(false)
		}
		if (selectedStep) {
			dispatch(blueprintActions.setSelectedStepId(undefined))
		}
	}

	if (!orphanedSteps.length) {
		return null
	}

	return (
		<>
			<StepDrawer open={shouldOpenStepDrawer} onClose={handleCloseStepDrawer} />
			<SectionTasks
				sectionId={'orphaned'}
				sectionName={'Orphaned Steps'}
				columns={columns}
				rows={rows}
				rowCount={rows.length}
				selectedTaskId={selectedStep?._id}
				onNewTaskClick={() => setShowNewStepDrawer(true)}
				onRowClick={(e) => handleStepEditClick(e.row)}
				sx={{ backgroundColor: '#FFD580' }}
				isBlueprint={true}
			/>
		</>
	)
}

export default BlueprintOrphanedSteps
