import { V3ClientTypes } from '@cango-app/types'
import { ComponentType, useState } from 'react'

import { Box, Button, Modal } from 'src/components'
import { CoreTable } from 'src/modules/tables/core-table'
import { TableProvider } from 'src/providers/table-provider'

type ResourcesProps = {
	task: V3ClientTypes.Project.Task
	tableId: string | undefined
}

export const Resources: ComponentType<ResourcesProps> = ({ task, tableId }) => {
	const [resourceModalOpen, setResourceModalOpen] = useState(false)

	if (!tableId) {
		return null
	}

	return (
		<>
			<Modal onClose={() => setResourceModalOpen(false)} open={resourceModalOpen}>
				<Box width="80vw" minHeight={200}>
					<Box height={700}>
						<TableProvider tableId={tableId}>
							<CoreTable
								filters={task.resource.filters}
								columnFilters={task.resource.columns}
								rowReordering={false}
								isStatic
							/>
						</TableProvider>
					</Box>
				</Box>
			</Modal>
			<Box>
				<Button variant="outlined" onClick={() => setResourceModalOpen(true)}>
					Set resources
				</Button>
			</Box>
		</>
	)
}
