import { V3ClientTypes } from '@cango-app/types'
import { ComponentType, useMemo, useState } from 'react'
import dayjs from 'dayjs'
import _orderBy from 'lodash/orderBy'

import { Box, Divider, Select, Text } from 'src/components'
import { colors } from 'src/theme/colors'

import { FileViewItemType } from './file-tasks/file-view'
import { Software } from './software'

type LinkViewProps = {
	task: V3ClientTypes.Project.Task
}

const LinkViewItem: ComponentType<{
	item: FileViewItemType
	isLastItem: boolean
}> = ({ item, isLastItem }) => {
	const [selectedTaskId, setSelectedTaskId] = useState<string>(item.tasks[0]._id)

	const selectedTask = useMemo(() => {
		return item.tasks.find((_task) => _task._id === selectedTaskId)
	}, [selectedTaskId])

	const completedAtTime = selectedTask?.lifecycle?.completed_at?.length
		? selectedTask.lifecycle?.completed_at[selectedTask.lifecycle?.completed_at.length - 1]
		: undefined

	const taskLabel = selectedTask?.chain?.labels.length ? (
		<>
			{item.chain?.labels[item.chain?.labels.length - 1].custom_chain_label}:{' '}
			<b>{item.chain?.labels[item.chain?.labels.length - 1].selected_option}</b>
		</>
	) : undefined

	const options = useMemo(() => {
		if (item.tasks.length === 1) return []

		return item.tasks.map((_task) => {
			const _taskCompletedTime = _task.lifecycle?.completed_at?.length
				? _task.lifecycle?.completed_at[_task.lifecycle?.completed_at.length - 1]
				: undefined
			return {
				_id: _task._id,
				label: (
					<Box>
						<Text fontWeight="bold" fontSize={12}>
							Iteration {_task.iteration}
						</Text>
						{!!_taskCompletedTime && (
							<Text fontSize={12}>
								{dayjs.unix(_taskCompletedTime).format('MMM DD, YYYY hh:mma')}
							</Text>
						)}
					</Box>
				),
			}
		})
	}, [])

	const allLinks = useMemo(() => {
		return (
			selectedTask?.actions.reduce((_acc: { _id: string; link: string }[], _action) => {
				_acc = [...new Set([..._acc, ..._action.links])]
				return _acc
			}, []) ?? []
		)
	}, [selectedTask])

	if (!selectedTask) return null

	return (
		<Box key={`add-links-for-${selectedTask._id}`}>
			<Box mb={1}>
				<Text fontSize={14}>
					Task: <b>{item.taskName}</b>
				</Text>
				<Text fontSize={14}>{!!selectedTask.chain?.chain_id && <>{taskLabel}</>}</Text>
				{options.length ? (
					<Select
						options={options}
						value={selectedTask._id}
						onChange={(e) => setSelectedTaskId(e.target.value as string)}
						containerProps={{ sx: { mt: 1 } }}
					/>
				) : (
					<>
						{(selectedTask.iteration ?? 0) > 1 && (
							<Text fontSize={14}>
								Iteration: <b>{selectedTask.iteration}</b>
							</Text>
						)}
						<Text fontSize={14}>
							{completedAtTime ? (
								<>
									Completed: <b>{dayjs.unix(completedAtTime).format('MMM DD, YYYY hh:mma')}</b>
								</>
							) : (
								<b>Incomplete</b>
							)}
						</Text>
					</>
				)}
			</Box>
			<Software links={allLinks} />
			{!isLastItem && <Divider color={colors.neutral['60']} sx={{ my: 2 }} />}
		</Box>
	)
}

export const LinkView: ComponentType<LinkViewProps> = ({ task }) => {
	const linkFromTaskReferences = useMemo(() => {
		if (!task.actions.length) return []
		return task.actions.reduce((linkViewItems: FileViewItemType[], _action) => {
			const actionLinksFromTasks = _action.links_from_tasks.reduce(
				(acc: FileViewItemType[], _task, _index) => {
					if (!_task.step_id) {
						return [
							...acc,
							{
								stepId: `${_task._id}-${_index}-no-step`,
								taskName: _task.name,
								chain: _task.chain,
								tasks: [_task],
							},
						]
					}

					const accIndex = acc.findIndex(
						(accTask) =>
							accTask.stepId === _task.step_id &&
							(accTask.chain?.chain_id === _task.chain?.chain_id || !accTask.chain?.chain_id),
					)

					const rootAccIndex = linkViewItems.findIndex(
						(_accTask) =>
							_accTask.stepId === _task.step_id &&
							(_accTask.chain?.chain_id === _task.chain?.chain_id || !_task.chain?.chain_id),
					)

					if (rootAccIndex >= 0) {
						linkViewItems[rootAccIndex].tasks.push(_task)
						linkViewItems[rootAccIndex].tasks = _orderBy(
							acc[rootAccIndex].tasks,
							'iteration',
							'desc',
						)
						return acc
					}

					if (accIndex >= 0) {
						acc[accIndex].tasks.push(_task)
						acc[accIndex].tasks = _orderBy(acc[accIndex].tasks, 'iteration', 'desc')
						return acc
					}

					return [
						...acc,
						{
							stepId: _task.step_id,
							taskName: _task.name,
							chain: _task.chain,
							tasks: [_task],
						},
					]
				},
				[],
			)
			return [...linkViewItems, ...actionLinksFromTasks]
		}, [])
	}, [task.actions])

	return (
		<Box>
			{linkFromTaskReferences.map((_task, index) => (
				<LinkViewItem
					key={`${_task.stepId}-${_task.chain?.chain_id}-${index}`}
					item={_task}
					isLastItem={index === linkFromTaskReferences.length - 1}
				/>
			))}
		</Box>
	)
}
