import React, { ComponentType, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
	selectors as myTasksSelectors,
	actions as myTasksActions,
} from 'src/store/modules/my-tasks-v3'
import { useIsMobile } from 'src/hooks/useMobile'

import { ErrorMyTasks } from './error'
import { MyTasksDesktopContainer } from './my-tasks-desktop-container'
import { MyTasksMobileContainer } from './my-tasks-mobile-container'

const MyTasksV3: ComponentType = () => {
	const dispatch = useDispatch()
	const hasError = useSelector(myTasksSelectors.hasMyTasksError)
	const isMobile = useIsMobile()

	useEffect(() => {
		return () => {
			dispatch(myTasksActions.endSession())
		}
	}, [])

	if (hasError) {
		return <ErrorMyTasks />
	}

	if (isMobile) {
		return <MyTasksMobileContainer />
	}

	return <MyTasksDesktopContainer />
}

export default MyTasksV3
