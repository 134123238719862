import React, { ComponentType } from 'react'
import { V3BlueprintTypes, Utils } from '@cango-app/types'
import { SelectChangeEvent } from '@mui/material'
import { Controller, useWatch } from 'react-hook-form'
import capitalize from 'lodash/capitalize'

import { Box, IntegerField, Select, Toggle } from 'src/components'

import { StepFormControl } from './step-form-container'

type RecurringFieldsProps = {
	control: StepFormControl
}

const getWeekdaysForSelector = () => {
	const daysOfWeek = Object.keys(Utils.Weekdays).filter(
		(key) => !isNaN(Number(Utils.Weekdays[key as any])),
	)
	return daysOfWeek.map((day) => ({
		_id: Utils.Weekdays[day as any],
		label: capitalize(day),
	}))
}

export const RecurringFields: ComponentType<RecurringFieldsProps> = ({ control }) => {
	const when = useWatch({ control, name: 'when' })
	const isRecurrencePlural = !!when.recurrence?.frequency && when.recurrence.frequency > 1
	return (
		<>
			<Controller
				control={control}
				name="when.recurrence.defined_in_blueprint"
				render={({ field: { value, onChange } }) => (
					<Toggle
						options={[
							{ label: 'Recurrence set per project', value: true },
							{ label: 'Recurrence set in the Blueprint', value: false },
						]}
						value={value}
						onChange={() => onChange(!value)}
					/>
				)}
			/>

			{!!when.recurrence?.defined_in_blueprint && (
				<>
					<Box display={'flex'}>
						<Controller
							control={control}
							name="when.recurrence.frequency"
							rules={{ required: true }}
							render={({ field: { value, onChange }, fieldState: { error } }) => (
								<IntegerField
									label={`Repeats every ${value} ${
										isRecurrencePlural
											? when.recurrence?.interval + 's'
											: when.recurrence?.interval ?? ''
									}`}
									error={!!error}
									defaultValue="1"
									value={value}
									fullWidth
									required
									onChange={(event) => onChange(event.target.value)}
									sx={{ mb: 3, mr: 2 }}
								/>
							)}
						/>
						<Controller
							control={control}
							name="when.recurrence.interval"
							render={({ field: { value, onChange } }) => (
								<Select
									label="Interval"
									onChange={(event) =>
										onChange(event.target.value as V3BlueprintTypes.RecurrenceIntervalEnum)
									}
									value={value}
									options={Object.values(V3BlueprintTypes.RecurrenceIntervalEnum).map((item) => ({
										_id: item,
										label: `${isRecurrencePlural ? item + 's' : item}`,
									}))}
									containerProps={{ mb: 3 }}
								/>
							)}
						/>
					</Box>

					{when.recurrence?.interval === V3BlueprintTypes.RecurrenceIntervalEnum.Week && (
						<Controller
							control={control}
							name="when.recurrence.weekdays"
							render={({ field: { value, onChange } }) => {
								const handleChange = (event: SelectChangeEvent<unknown>) => {
									const { value } = event.target
									onChange(typeof value === 'string' ? value.split(',') : value)
								}
								return (
									<Select
										label="Which day(s) of the week?"
										onChange={handleChange}
										value={value || []}
										multiple
										multiline
										options={getWeekdaysForSelector()}
										containerProps={{ mb: 3 }}
									/>
								)
							}}
						/>
					)}

					{when.recurrence?.interval === V3BlueprintTypes.RecurrenceIntervalEnum.Month && (
						<Controller
							control={control}
							name="when.recurrence.monthday"
							render={({ field: { value, onChange } }) => (
								<IntegerField
									label="Which day of the month?"
									value={value}
									fullWidth
									required
									onChange={(event) => onChange(event.target.value)}
									sx={{ mb: 3 }}
								/>
							)}
						/>
					)}
				</>
			)}
		</>
	)
}
