import { ComponentType, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { V3ProjectSdk } from '@cango-app/sdk'
import PulseLoader from 'react-spinners/PulseLoader'
import Timeline from '@mui/lab/Timeline'
import timelineOppositeContentClasses from '@mui/lab/TimelineOppositeContent/timelineOppositeContentClasses'
import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import _difference from 'lodash/difference'
import _isArray from 'lodash/isArray'
import _has from 'lodash/has'

import { TabPanel } from 'src/components/tab-panel'
import { Box, Button, Select, Text } from 'src/components'
import { TaskContext } from 'src/providers/task-provider'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { actions as projectActions } from 'src/store/modules/projects-v3'
import { colors } from 'src/theme/colors'
import { AsyncDispatchType } from 'src/store/types'

import { SubmitButtons } from './submit-buttons'
import { TimelineItem } from './timeline-item'

const initialState: V3ProjectSdk.TaskResetUpdates = {
	optionsAdded: [],
	optionsRemoved: [],
	tasksToDelete: [],
	instanceIdsToRemove: [],
}

const TaskHierarchyComponent: ComponentType<{
	_task?: V3ClientTypes.Project.Task
	onResetTaskComplete: (
		data: V3ProjectSdk.ResetTaskRequest & V3ProjectSdk.ResetTaskResponse & { projectId: string },
	) => void
}> = ({ _task, onResetTaskComplete }) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const { task: taskContext, onCloseDrawer } = useContext(TaskContext)
	const task = _task ?? taskContext
	const [isLoading, setIsLoading] = useState(false)
	const [hierarchy, setHierarchy] = useState<V3ProjectSdk.GetTaskHierarchyResponse>({
		ancestors: [],
		descendants: [],
	})
	const [updates, setUpdates] = useState<V3ProjectSdk.TaskResetUpdates>(initialState)

	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const [selectedChildren, setSelectedChildren] = useState<string[]>(
		task?.isMultiUse
			? task.instance?.instances.map((_instance) => _instance._id) ?? []
			: task?.lifecycle.completed_options ?? [],
	)

	const downstreamBlockingTasks = useMemo(() => {
		return hierarchy.descendants
			.filter(
				(_item) =>
					_item.lifecycle.complete &&
					updates.tasksToDelete.includes(_item._id) &&
					_item.lifecycle.completed_options?.length,
			)
			.map((_item) => _item._id)
	}, [hierarchy.descendants, updates.tasksToDelete])

	const selectOptions = useMemo(() => {
		if (task?.isMultiUse) {
			return (
				task?.instance?.instances.map((_instance) => ({
					_id: _instance._id,
					label: _instance.name,
				})) ?? []
			)
		}

		if (!task?.children) {
			return []
		}
		return task.children.filter(
			({ _id }) =>
				![V3BlueprintTypes.TaskPhase.Commence, V3BlueprintTypes.TaskPhase.Complete].includes(
					_id as V3BlueprintTypes.TaskPhase,
				),
		)
	}, [task?.children])

	const fetchHierarchy = useCallback(async () => {
		if (!task) return
		try {
			setIsLoading(true)
			const response = await V3ProjectSdk.getTaskHierarchy(
				import.meta.env.VITE_API as string,
				authHeaders,
				task._id,
			)
			setHierarchy(response)
		} catch (error) {
			showSnackbar('Failed to fetch hierarchy', { variant: 'error' })
		} finally {
			setIsLoading(false)
		}
	}, [task?._id])

	const handleResetTask = useCallback(
		async (updates: V3ProjectSdk.TaskResetUpdates) => {
			if (!task) return

			try {
				const payload = { taskId: task._id, projectId: task.project_id, updates }
				const response = await V3ProjectSdk.resetTask(
					import.meta.env.VITE_API as string,
					authHeaders,
					payload,
				)

				onResetTaskComplete({ ...response, ...payload })

				if (onCloseDrawer) {
					onCloseDrawer()
					return
				}
				fetchHierarchy()
				setUpdates(initialState)
			} catch (error) {
				showSnackbar('Failed to reset task', { variant: 'error' })
			}
		},
		[task?._id],
	)

	const handleClearAllDescendants = useCallback(() => {
		setUpdates({
			optionsAdded: [],
			optionsRemoved: task?.lifecycle.completed_options ?? [],
			tasksToDelete: hierarchy.descendants.map((item) => item._id),
			instanceIdsToRemove: task?.instance?.instances.map((_instance) => _instance._id) ?? [],
		})
		setSelectedChildren([])
	}, [hierarchy.descendants])

	const handleCancelDeletion = useCallback(() => {
		setUpdates(initialState)
		setSelectedChildren(task?.lifecycle.completed_options ?? [])
	}, [task])

	const handleChangeSelection = (newChildren: string[]) => {
		if (task?.isMultiUse) {
			const instanceIds = task?.instance?.instances.map((_instance) => _instance._id) ?? []
			const removedOptions = _difference(instanceIds, newChildren)
			const matchingDescendantsWithInstance = hierarchy.descendants.filter((_desc) =>
				removedOptions.includes(_desc.instance?._id ?? ''),
			)

			const directChildrenOfRemovedInstances = matchingDescendantsWithInstance.map(
				(descendant) => descendant._id,
			)

			setUpdates({
				optionsAdded: [],
				optionsRemoved: [],
				tasksToDelete: directChildrenOfRemovedInstances,
				instanceIdsToRemove: removedOptions,
			})
			setSelectedChildren(newChildren)
			return
		}

		const removedOptions = _difference(task?.lifecycle.completed_options ?? [], newChildren)
		const addedOptions = _difference(newChildren, task?.lifecycle.completed_options ?? [])
		const matchingDescendantsWithParent = hierarchy.descendants.filter((_desc) =>
			_desc.selected_parent_options.every((_option) => removedOptions.includes(_option._id)),
		)

		const directChildrenOfRemovedOptions = matchingDescendantsWithParent.map(
			(descendant) => descendant._id,
		)

		setUpdates({
			optionsAdded: addedOptions,
			optionsRemoved: removedOptions,
			tasksToDelete: directChildrenOfRemovedOptions,
			instanceIdsToRemove: [],
		})
		setSelectedChildren(newChildren)
	}

	useEffect(() => {
		fetchHierarchy()
	}, [])

	if (!_has(task, 'selected_parent_options')) {
		return (
			<Box>
				<Text>{'Hierarchy changes only work for Projects created in Cango >= v5.24.2'}</Text>
			</Box>
		)
	}

	return (
		<Box>
			{isLoading && (
				<Box display="flex" justifyContent="center">
					<PulseLoader size={8} />
				</Box>
			)}

			{!isLoading && (
				<Timeline
					sx={{
						[`& .${timelineOppositeContentClasses.root}`]: {
							flex: 0.4,
						},
					}}
				>
					{hierarchy.ancestors.map((item) => (
						<TimelineItem key={item._id} hierarchyItem={item} />
					))}
					{(!!hierarchy.ancestors.length || !!hierarchy.descendants.length) && !!task && (
						<Box position="relative">
							<Box
								sx={{
									backgroundColor: 'rgba(164, 198, 188, 0.1)',
									borderRadius: '8px',
									position: 'absolute',
									width: '100%',
									height: '100%',
									mt: 0.5,
								}}
							/>
							<TimelineItem
								hierarchyItem={{ ...task, depth: 0, chain: task.chain, instance: task.instance }}
								hideConnector={!hierarchy.descendants.length}
								customContent={
									<>
										{!task.isMultiUse && !!updates.tasksToDelete.length && (
											<Text fontSize={12}>
												This task will <span style={{ textDecoration: 'underline' }}>not</span> be
												deleted.
												{!selectOptions.length
													? ' It will be made incomplete.'
													: ' Any new selections will be created. If all selections are cleared the task will be marked as incomplete.'}
											</Text>
										)}
										{!task.isMultiUse &&
											!selectOptions.length &&
											!updates.tasksToDelete.length &&
											(!!hierarchy.descendants.length || task.lifecycle.complete) && (
												<Button
													variant="outlined"
													color="warning"
													onClick={handleClearAllDescendants}
													size="small"
												>
													Reset task
												</Button>
											)}
										{!!selectOptions.length && (
											<Box>
												<Select
													options={selectOptions}
													multiple={task.isMenu || task.isMultiUse}
													onChange={(e) =>
														handleChangeSelection(
															_isArray(e.target.value)
																? (e.target.value as string[])
																: [e.target.value as string],
														)
													}
													value={selectedChildren}
													size="small"
													disabled={!task.lifecycle.complete && !task.isMultiUse}
													fullWidth
													containerProps={{ maxWidth: 250 }}
												/>
												<Button
													variant="text"
													size="small"
													color="warning"
													disabled={!task.lifecycle.complete}
													onClick={handleClearAllDescendants}
												>
													Clear all selections
												</Button>
											</Box>
										)}
									</>
								}
							/>
						</Box>
					)}

					{hierarchy.descendants.map((item, index) => {
						return (
							<Box position="relative" key={item._id}>
								<Box
									sx={{
										backgroundColor: updates.tasksToDelete.includes(item._id)
											? colors.error.light[20]
											: undefined,
										borderRadius: '8px',
										position: 'absolute',
										width: '100%',
										height: '100%',
										mt: 0.5,
									}}
								/>
								<TimelineItem
									key={item._id}
									hierarchyItem={item}
									hideConnector={hierarchy.descendants.length - 1 === index}
									customContent={
										<>
											{
												<Text fontSize={12} fontWeight="bold">
													{item.chain?.chain_id
														? item.chain?.labels.map((label) => label.selected_option).join(', ')
														: item.instance?._id
															? item.instance?.name
															: ''}
												</Text>
											}
											{downstreamBlockingTasks.includes(item._id) && item.lifecycle.complete && (
												<Text fontSize={12}>
													{item.lifecycle.completed_options?.length ?? 0} selection(s) made here
												</Text>
											)}
											{!!updates.tasksToDelete.includes(item._id) && (
												<Text color="error" fontSize={12} fontWeight="bold">
													Will be deleted
												</Text>
											)}
										</>
									}
								/>
							</Box>
						)
					})}
				</Timeline>
			)}
			{!!downstreamBlockingTasks.length && (
				<Text fontSize={12} color="warning">
					{
						"This selection can't be modified becuase another selection has been made further downstream."
					}
				</Text>
			)}
			{!isLoading && (
				<SubmitButtons
					onResetTask={handleResetTask}
					updates={updates}
					onCancelClick={handleCancelDeletion}
					disabled={!!downstreamBlockingTasks.length}
				/>
			)}
		</Box>
	)
}
export default TaskHierarchyComponent
