import { ComponentType, useCallback, useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Action, ThunkDispatch } from '@reduxjs/toolkit'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { TablesSdk } from '@cango-app/sdk'
import PulseLoader from 'react-spinners/PulseLoader'

import { selectors as tableSelectors, actions as tableActions } from 'src/store/modules/tables'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { Box, Button, IconButton, Modal, Text, TextField } from 'src/components'
import '../../assets/css/Databases.scss'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { RootState } from 'src/store/types'
import { CircledCheckIcon, DatabaseIcon, EditPenIcon } from 'src/assets/icons'
import { RouteId } from 'src/constants/routes'
import { errorHandler } from 'src/helpers/api'
import { colors } from 'src/theme/colors'
import { APP_BAR_HEIGHT } from 'src/routing/navigation/desktop-navigation'

type TableMenuProps = {
	tableList: TablesSdk.MenuTable[]
	setTableList: (list: TablesSdk.MenuTable[]) => void
}

const BrowseListItem: ComponentType<TablesSdk.MenuTable> = ({ name, _id: tableId }) => {
	const navigate = useNavigate()
	const location = useLocation()
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const tableIdLoading = useSelector(tableSelectors.getTableIdLoading)
	const isDatabaseSelected = location.pathname.includes(tableId)
	const [isEditingTitle, setIsEditingTitle] = useState(false)
	const [isUpdatingName, setIsUpdatingName] = useState(false)
	const [tableName, setTableName] = useState(name)
	const handleDatabaseClick = () => {
		navigate(`/${RouteId.Tables}/${tableId}`)
	}

	const updateName = async () => {
		try {
			setIsUpdatingName(true)
			await TablesSdk.updateName(import.meta.env.VITE_API as string, authHeaders, {
				tableId,
				name: tableName,
			})
		} catch (error) {
			showSnackbar('Error updating table name', { variant: 'error' })
		} finally {
			setIsUpdatingName(false)
			setIsEditingTitle(false)
		}
	}

	if (isEditingTitle) {
		return (
			<Box mx={2} display="flex" mb={1}>
				<TextField
					value={tableName}
					onChange={(e) => setTableName(e.target.value)}
					fullWidth
					containerProps={{ flex: 1 }}
				/>
				<IconButton onClick={updateName} isLoading={isUpdatingName}>
					<CircledCheckIcon />
				</IconButton>
			</Box>
		)
	}

	return (
		<ListItem sx={{ pt: 0, pb: 1 }}>
			<ListItemButton
				selected={isDatabaseSelected}
				onClick={handleDatabaseClick}
				sx={{ borderRadius: 2 }}>
				<ListItemIcon sx={{ minWidth: 40 }}>
					<DatabaseIcon />
				</ListItemIcon>
				<ListItemText primaryTypographyProps={{ fontSize: 14 }} primary={tableName} />
				<ListItemIcon>
					{tableIdLoading === tableId ? (
						<PulseLoader size={4} />
					) : (
						<IconButton
							onClick={(event) => {
								event.stopPropagation()
								setIsEditingTitle(true)
							}}
							sx={{ ml: 1 }}>
							<EditPenIcon stroke={colors.feldgrau['60']} width={16} />
						</IconButton>
					)}
				</ListItemIcon>
			</ListItemButton>
		</ListItem>
	)
}

export const TableMenu: ComponentType<TableMenuProps> = ({ tableList, setTableList }) => {
	const dispatch = useDispatch<ThunkDispatch<RootState, void, Action>>()
	const navigate = useNavigate()
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const [newTableName, setNewTableName] = useState('')
	const [isCreating, setIsCreating] = useState(false)
	const [showingCreateModal, showCreateModal] = useState(false)
	const [isFetchingTables, setIsFetchingTables] = useState(false)

	const createTable = async () => {
		try {
			setIsCreating(true)
			const response = await dispatch(
				tableActions.createTable({
					name: newTableName,
				}),
			)
			const { meta, payload } = response
			if (meta.requestStatus === 'fulfilled') {
				const newTable = payload as TablesSdk.MenuTable
				navigate(`/${RouteId.Tables}/${newTable._id}`)
			}
		} catch (error) {
			showSnackbar('Error creating table', { variant: 'error' })
		} finally {
			setIsCreating(false)
			showCreateModal(false)
			setNewTableName('')
		}
	}

	const fetchTableList = useCallback(async () => {
		try {
			setIsFetchingTables(true)
			const response = await TablesSdk.getTables(import.meta.env.VITE_API as string, authHeaders)
			setTableList(response)
		} catch (error) {
			errorHandler({ error, dispatch, message: 'Could not fetch tables' })
		} finally {
			setIsFetchingTables(false)
		}
	}, [authHeaders])

	useEffect(() => {
		fetchTableList()
	}, [])

	return (
		<>
			<Modal open={showingCreateModal} onClose={() => showCreateModal(false)}>
				<Box display="flex" flexDirection="column">
					<TextField
						onChange={(e) => setNewTableName(e.target.value)}
						value={newTableName}
						placeholder="New table name"
						sx={{ mb: 2 }}
					/>
					<Button onClick={createTable} isLoading={isCreating}>
						Create Table
					</Button>
				</Box>
			</Modal>
			<Box sx={{ minWidth: 300, maxHeight: `calc(100vh - ${APP_BAR_HEIGHT}px)`, overflow: 'auto' }}>
				<List>
					<ListItem>
						<Button
							onClick={() => showCreateModal(true)}
							isLoading={isCreating}
							startIcon={<AddIcon />}
							fullWidth>
							New Table
						</Button>
					</ListItem>
					{isFetchingTables ? (
						<Box display="flex" justifyContent="center" mt={10}>
							<PulseLoader size={6} />
						</Box>
					) : (
						<>
							<ListItem>
								<Text variant="overline" fontWeight="bold">
									Browse
								</Text>
							</ListItem>
							{tableList.map((table) => (
								<BrowseListItem key={table._id} {...table} />
							))}
						</>
					)}
				</List>
			</Box>
		</>
	)
}
