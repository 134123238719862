import { ComponentType, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Skeleton from '@mui/material/Skeleton'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import LinearProgress from '@mui/material/LinearProgress'
import { useNavigate } from 'react-router-dom'

import { Box, Button, Text } from 'src/components'
import {
	selectors as myTasksSelectors,
	actions as myTasksActions,
	TaskListType,
} from 'src/store/modules/my-tasks-v3'
import { UniversalDrawerType, actions as configActions } from 'src/store/modules/config'
import { RouteId } from 'src/constants/routes'

import { SingleTaskContainer } from './single-task-container'
import { TaskList } from './task-list'

export const DesktopSectionContainer: ComponentType = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const sectionIdLoading = useSelector(myTasksSelectors.getSectionIdLoading)
	const section = useSelector(myTasksSelectors.getSelectedSection)
	const selectedTask = useSelector(myTasksSelectors.getSelectedTask)
	const activeTasks = useSelector(myTasksSelectors.getActiveTasks)
	const masterTasks = useSelector(myTasksSelectors.getMasterTasks)
	const taskListType = useSelector(myTasksSelectors.getTaskListType)
	const totalFilesRequiringUpload = useSelector(
		myTasksSelectors.getTotalNumberOfFilesRequiringUpload,
	)
	const numberOfFilesPendingUpload = useSelector(myTasksSelectors.getNumberOfFilesPendingUpload)
	const taskFilesUploadedSize = useSelector(myTasksSelectors.getPercentageOfTasksUploaded)

	const handleTaskTypeChange = (type: TaskListType) => {
		dispatch(myTasksActions.setTaskListType(type))
	}

	const handleNewTaskClick = () => {
		dispatch(configActions.setUniversalDrawerType(UniversalDrawerType.Task))
	}

	const handleTabClick = () => {
		if (taskListType === TaskListType.Active) {
			return
		}
		navigate(`${RouteId.MyTasks}/${section?._id}`)
	}

	const handleNewProjectClick = () => {
		dispatch(configActions.setUniversalDrawerType(UniversalDrawerType.Project))
	}

	return (
		<Box flex={3} ml={{ laptop: 3 }} height="100%" sx={{ overflowY: 'auto' }}>
			<Box display="flex" justifyContent="flex-end" mt={2} mb={4}>
				<Box>
					<Button onClick={handleNewProjectClick}>New project</Button>
				</Box>
			</Box>
			{sectionIdLoading || !section ? (
				<Skeleton variant="rectangular" width="100%" height={400} sx={{ borderRadius: '30px' }} />
			) : (
				<Box bgcolor="white" borderRadius="30px" px={3} py={2}>
					{!selectedTask && (
						<Box display="flex" alignItems="center">
							<Tabs
								value={taskListType}
								sx={{ flex: 1 }}
								onChange={(e, value) => handleTaskTypeChange(value)}
							>
								<Tab
									value={TaskListType.Active}
									label={`Active tasks (${activeTasks.length})`}
									onClick={handleTabClick}
								/>
								<Tab
									value={TaskListType.ProjectTasks}
									label={`Project tasks (${masterTasks.length})`}
									disabled={!masterTasks.length}
								/>
							</Tabs>
							<Box display="flex">
								<Button sx={{ mr: 1 }} onClick={handleNewTaskClick} variant="outlined">
									New task
								</Button>
							</Box>
						</Box>
					)}
					<Box mt={2}>
						<Box height="4px">
							{taskFilesUploadedSize !== 100 && (
								<>
									<LinearProgress variant="determinate" value={taskFilesUploadedSize} />
									<Text fontSize={12} textAlign="center">
										{'Uploading '}
										{totalFilesRequiringUpload - numberOfFilesPendingUpload + 1}
										{' of '}
										{totalFilesRequiringUpload}
									</Text>
								</>
							)}
						</Box>
						{selectedTask ? <SingleTaskContainer /> : <TaskList />}
					</Box>
				</Box>
			)}
		</Box>
	)
}
