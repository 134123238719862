/* eslint react/jsx-key: 0 */

import React, { useMemo } from 'react'
import { V3ClientTypes } from '@cango-app/types'
import CircularProgress from '@mui/material/CircularProgress'
import { Controller, useForm } from 'react-hook-form'
import { z } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormControl, Grid } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import Input from '@mui/material/Input'
import Stack from '@mui/material/Stack'

import { Box, Button, Text } from '../../../components'
import { ChevronRight } from '../../../assets/icons'

import { BidItemData, PricingTableMap, ProductionItem } from './types'
import { ColumnStepsLayout } from './components/column-steps-layout'
import { BidOverviewColumn } from './components/bid-overview-column'
import { BidItemOverviewColumn } from './components/bid-item-overview-column'
import { BidItemContentsOverviewColumn } from './components/bid-item-contents-overview-column'
import { bidItemSchema } from './schemas'
import {
	getEquipmentContentsTotalCost,
	getLaborContentsTotalCost,
	getMaterialContentsTotalCost,
} from './total-costs'
import { formatCurrency } from './format-number'

type BidSummaryProps = {
	loading: boolean
	data: V3ClientTypes.Project.BidItem | null
	goToPrevStep: () => void
	close: () => void
	onSave: (data: BidItemData) => Promise<void>
	bid: V3ClientTypes.Project.Bid | null
	bidItems: V3ClientTypes.Project.BidItem[]
	laborPricing: PricingTableMap
	materialPricing: PricingTableMap
	equipmentPricing: PricingTableMap
	productionItems: ProductionItem[]
}

export function BidItemSummary(props: BidSummaryProps) {
	const {
		loading,
		bid,
		data,
		goToPrevStep,
		onSave,
		close,
		bidItems,
		laborPricing,
		materialPricing,
		equipmentPricing,
		productionItems,
	} = props

	const form = useForm<z.infer<typeof bidItemSchema>>({
		defaultValues: {
			profitMargin: 0,
			...data,
		},
		resolver: zodResolver(bidItemSchema),
	})

	const productionItem = productionItems.find((item) => item._id === data?.productionItemId) ?? null

	const inputs = form.watch()

	const laborCost = getLaborContentsTotalCost(
		data?.laborContents,
		laborPricing,
		bid?.markupValues.labor,
		{ hours: data?.hours, profitMargin: inputs.profitMargin },
	)
	const equipmentCost = getEquipmentContentsTotalCost(
		data?.equipmentContents,
		equipmentPricing,
		bid?.markupValues.equipment,
		{ hours: data?.hours, profitMargin: inputs.profitMargin },
	)
	const materialCost = getMaterialContentsTotalCost(
		data?.materialContents,
		materialPricing,
		bid?.markupValues.material,
		{ profitMargin: inputs.profitMargin },
	)
	const totalHourlyRate = laborCost.totalRate + equipmentCost.totalRate

	const totalCost =
		laborCost.totalCostWithOH + equipmentCost.totalCostWithOH + materialCost.totalCostWithOH
	const priceWithProfitMarkup =
		laborCost.priceWithProfitMargin +
		equipmentCost.priceWithProfitMargin +
		materialCost.priceWithProfitMargin

	const costPerUnit = totalCost / (data?.quantity ?? 1)
	const pricePerUnit = priceWithProfitMarkup / (data?.quantity ?? 1)

	const breakdown = [
		{
			label: 'Labor cost with OH',
			id: 'laborCostWithOH',
			value: formatCurrency(laborCost.totalCostWithOH),
		},
		{
			label: 'Equipment cost with OH',
			id: 'equipmentCostWithOH',
			value: formatCurrency(equipmentCost.totalCostWithOH),
		},
		{
			label: 'Material cost with OH',
			id: 'materialCostWithOH',
			value: formatCurrency(materialCost.totalCostWithOH),
		},
		{ label: 'Total hourly rate', id: 'totalHourlyRate', value: formatCurrency(totalHourlyRate) },
		{
			label: `Cost per ${productionItem?.unitOfMeasurement}`,
			id: 'costPerUnit',
			value: formatCurrency(costPerUnit),
		},
		{
			label: 'Price with profit markup',
			id: 'priceWithProfitMarkup',
			value: formatCurrency(priceWithProfitMarkup),
		},
		{
			label: `Price per ${productionItem?.unitOfMeasurement}`,
			id: 'pricePerUnit',
			value: formatCurrency(pricePerUnit),
		},
	]

	const { isValid, isDirty } = form.formState
	const reactiveBidItems = useMemo(
		() =>
			bidItems.map((item) => {
				if (item._id === inputs._id) {
					return {
						...item,
						...inputs,
					}
				}
				return item
			}),
		[bidItems, inputs],
	)

	return (
		<ColumnStepsLayout
			columns={[
				<BidOverviewColumn
					bid={bid}
					bidItems={reactiveBidItems}
					materialPricing={materialPricing}
					laborPricing={laborPricing}
					equipmentPricing={equipmentPricing}
				/>,
				<BidItemOverviewColumn bidItem={data} productionItem={productionItem} />,
				<BidItemContentsOverviewColumn
					bidItem={data}
					laborPricing={laborPricing}
					materialPricing={materialPricing}
					equipmentPricing={equipmentPricing}
				/>,
				<Box display="flex" flexDirection="column" px={4} width="100%">
					<Box width="100px">
						<Button
							variant="text"
							sx={{ minWidth: 'auto' }}
							startIcon={<ChevronRight style={{ transform: 'rotate(180deg)' }} />}
							onClick={() => goToPrevStep()}
						>
							Back
						</Button>
					</Box>
					<Box display="flex" alignItems="center">
						<Text variant="h4">Bid Item summary</Text>
						{loading && <CircularProgress size={20} thickness={5} sx={{ ml: 2 }} />}
					</Box>
					<form onSubmit={form.handleSubmit(onSave)}>
						<Grid container py={2} columnSpacing={12} rowSpacing={6}>
							<Grid item md={12}>
								<Box display="flex" justifyContent="flex-end">
									<Controller
										name="profitMargin"
										control={form.control}
										render={({ field, fieldState }) => (
											<FormControl>
												<InputLabel htmlFor={field.name}>Profit margin</InputLabel>
												<Input {...field} type="number" id={field.name} value={field.value ?? ''} />
												<Text color="error">{fieldState.error?.message}</Text>
											</FormControl>
										)}
									/>
								</Box>
							</Grid>
							<Grid item md={12}>
								<Stack direction="column" useFlexGap spacing={{ md: 2 }}>
									{breakdown.map((item) => (
										<FormControl key={item.id}>
											<InputLabel htmlFor={item.id}>{item.label}</InputLabel>
											<Input id={item.id} readOnly value={item.value ?? ''} disabled />
										</FormControl>
									))}
								</Stack>
							</Grid>
							<Grid item md={12}>
								<Stack direction="row" spacing={{ md: 2 }} justifyContent="flex-end">
									<Button
										variant="contained"
										color="primary"
										type="submit"
										disabled={!isDirty || !isValid}
									>
										Save
									</Button>
									<Button variant="outlined" color="feldgrau40" onClick={() => close()}>
										Close
									</Button>
								</Stack>
							</Grid>
						</Grid>
					</form>
				</Box>,
			]}
		/>
	)
}
