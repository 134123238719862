import { useDispatch, useSelector } from 'react-redux'
import { useEffectOnce } from 'usehooks-ts'

import { selectors as tableSelectors, actions as tableActions } from 'src/store/modules/tables'

export const useTables = () => {
	const dispatch = useDispatch()
	const tables = useSelector(tableSelectors.getAllTables)

	useEffectOnce(() => {
		if (!tables.length) {
			dispatch(tableActions.fetchAllTables())
		}
	})

	return tables
}
