import { ComponentType, useCallback, useContext, useEffect, useState } from 'react'
import { TablesSdk } from '@cango-app/sdk'
import { useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import PulseLoader from 'react-spinners/PulseLoader'
import { GridActionsCellItem, GridDeleteIcon, GridDeleteForeverIcon } from '@mui/x-data-grid-pro'
import { ClientTypes, TableTypes } from '@cango-app/types'
import _map from 'lodash/map'

import { TableContext } from 'src/providers/table-provider'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { Box, Modal, Text } from 'src/components'

import { ContentsTable } from '../projects-v3/bid/components/contents-table'

export type Contents = TableTypes.ProductionItemContents

export const ProductionItemsContentsModal: ComponentType<{
	onCloseModal: () => void
	row: any
}> = ({ onCloseModal, row }) => {
	const { saveContents } = useContext(TableContext)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const defaultContents = row?.[TableTypes.ProductionFieldId.RESOURCES] as Contents
	const [isLoadingPricing, setIsLoadingPricing] = useState(false)
	const [pricing, setPricing] = useState<{
		labor: Map<string, ClientTypes.Table.Record['data']>
		equipment: Map<string, ClientTypes.Table.Record['data']>
		material: Map<string, ClientTypes.Table.Record['data']>
	}>()

	const fetchPricing = useCallback(async () => {
		try {
			setIsLoadingPricing(true)
			const response = await TablesSdk.getPricingTables(
				import.meta.env.VITE_API as string,
				authHeaders,
			)
			const { labor, equipment, material } = response
			const newPricing = {
				labor: labor.records.reduce((_acc, record) => {
					return _acc.set(record._id, record.data)
				}, new Map<string, ClientTypes.Table.Record['data']>()),
				equipment: equipment.records.reduce((_acc, record) => {
					return _acc.set(record._id, record.data)
				}, new Map<string, ClientTypes.Table.Record['data']>()),
				material: material.records.reduce((_acc, record) => {
					return _acc.set(record._id, record.data)
				}, new Map<string, ClientTypes.Table.Record['data']>()),
			}
			setPricing(newPricing)
		} catch (error) {
			Sentry
		} finally {
			setIsLoadingPricing(false)
		}
	}, [authHeaders])

	const handleAddContent = (
		optionId: string,
		table:
			| TableTypes.PricingTableId.LABOR
			| TableTypes.PricingTableId.EQUIPMENT
			| TableTypes.PricingTableId.MATERIAL,
	) => {
		if (!defaultContents) return
		const contents = { ...defaultContents }

		if (table === TableTypes.PricingTableId.LABOR) {
			const contentsCopy = [...contents.laborContents]
			contentsCopy.push({
				_id: optionId,
				quantity: 1,
			})
			contents.laborContents = contentsCopy
		} else if (table === TableTypes.PricingTableId.EQUIPMENT) {
			const contentsCopy = [...contents.equipmentContents]
			contentsCopy.push({
				_id: optionId,
				quantity: 1,
				usage: 1,
			})
			contents.equipmentContents = contentsCopy
		} else if (table === TableTypes.PricingTableId.MATERIAL) {
			const contentsCopy = [...contents.materialContents]
			contentsCopy.push({
				_id: optionId,
				quantity: 1,
			})
			contents.materialContents = contentsCopy
		}

		const rowContentsCopy: TableTypes.ProductionItemContents = {
			laborContents: contents.laborContents,
			equipmentContents: contents.equipmentContents,
			materialContents: contents.materialContents,
		}

		saveContents(row._id, row, rowContentsCopy)
	}

	const handleUpdateContents = async (
		newRow: { _id: string; quantity: number; usage?: number },
		table:
			| TableTypes.PricingTableId.LABOR
			| TableTypes.PricingTableId.EQUIPMENT
			| TableTypes.PricingTableId.MATERIAL,
	) => {
		if (!defaultContents) return
		const contents = { ...defaultContents }

		if (table === TableTypes.PricingTableId.LABOR) {
			const contentsCopy = [...contents.laborContents].map((_content) => {
				if (_content._id === newRow._id) {
					return newRow
				}
				return _content
			})
			contents.laborContents = contentsCopy
		} else if (table === TableTypes.PricingTableId.EQUIPMENT) {
			const contentsCopy = [...contents.equipmentContents].map((_content) => {
				if (_content._id === newRow._id) {
					return newRow as { _id: string; quantity: number; usage: number }
				}
				return _content
			})
			contents.equipmentContents = contentsCopy
		} else if (table === TableTypes.PricingTableId.MATERIAL) {
			const contentsCopy = [...contents.materialContents].map((_content) => {
				if (_content._id === newRow._id) {
					return newRow
				}
				return _content
			})
			contents.materialContents = contentsCopy
		}

		const rowContentsCopy: TableTypes.ProductionItemContents = {
			laborContents: contents.laborContents,
			equipmentContents: contents.equipmentContents,
			materialContents: contents.materialContents,
		}

		saveContents(row._id, row, rowContentsCopy)
		return newRow
	}

	const handleDeleteRow = async (
		table: 'laborContents' | 'materialContents' | 'equipmentContents',
		rowId: string,
	) => {
		if (!defaultContents) return
		const contents = { ...defaultContents }

		if (table === 'laborContents') {
			const contentsCopy = [...contents.laborContents].filter((_content) => _content._id !== rowId)
			contents.laborContents = contentsCopy
		} else if (table === 'equipmentContents') {
			const contentsCopy = [...contents.equipmentContents].filter(
				(_content) => _content._id !== rowId,
			)
			contents.equipmentContents = contentsCopy
		} else if (table === 'materialContents') {
			const contentsCopy = [...contents.materialContents].filter(
				(_content) => _content._id !== rowId,
			)
			contents.materialContents = contentsCopy
		}

		const rowContentsCopy: TableTypes.ProductionItemContents = {
			laborContents: contents.laborContents,
			equipmentContents: contents.equipmentContents,
			materialContents: contents.materialContents,
		}

		saveContents(row._id, row, rowContentsCopy)
	}

	useEffect(() => {
		if (!row) {
			fetchPricing()
		}
	}, [!!defaultContents])

	return (
		<Modal open={!!row} onClose={onCloseModal}>
			<>
				{!!row && (
					<Box minWidth={600}>
						<Text variant="h4" mb={3}>
							Contents
						</Text>
						{!!pricing && !isLoadingPricing && (
							<>
								<Box mb={2}>
									<ContentsTable
										title="Labor"
										onRowUpdate={(newRow) =>
											handleUpdateContents(newRow, TableTypes.PricingTableId.LABOR)
										}
										addRowOptions={_map([...pricing.labor.keys()], (laborKey) => {
											const laborValue = pricing.labor.get(laborKey)
											return {
												_id: laborKey,
												label: (laborValue?.[TableTypes.PricingTableFieldId.NAME] as string) ?? '',
											}
										})}
										onAddOption={(option) =>
											handleAddContent(option, TableTypes.PricingTableId.LABOR)
										}
										columns={[
											{
												field: 'quantity',
												headerName: 'Qty.',
												type: 'number',
												minWidth: 65,
												sortable: false,
												editable: true,
											},
											{
												field: 'type',
												headerName: 'Class',
												type: 'string',
												minWidth: 155,
												sortable: false,
												editable: false,
												valueGetter: ({ row }) => {
													return pricing.labor.get(row._id)?.[TableTypes.PricingTableFieldId.NAME]
												},
											},
											{
												field: 'value',
												headerName: 'Hourly rate',
												type: 'number',
												minWidth: 110,
												sortable: false,
												editable: false,
												valueGetter: ({ row }) => {
													const price =
														pricing.labor.get(row._id)?.[TableTypes.PricingTableFieldId.PRICE] ?? 0
													return price
												},
												valueFormatter: ({ value }) => {
													return value ? '$' + Number(value).toFixed(2) : null
												},
											},
											{
												field: 'options',
												headerName: '',
												type: 'actions',
												minWidth: 50,
												sortable: false,
												editable: false,
												getActions: ({ id, row }) => [
													<GridActionsCellItem
														key={`${id}-delete`}
														icon={<GridDeleteIcon />}
														label="Delete"
														onClick={() => {
															handleDeleteRow('laborContents', row._id)
														}}
													/>,
												],
											},
										]}
										data={defaultContents.laborContents}
									/>
								</Box>
								<Box mb={2}>
									<ContentsTable
										title="Material"
										onRowUpdate={async (newRow) =>
											await handleUpdateContents(newRow, TableTypes.PricingTableId.MATERIAL)
										}
										addRowOptions={_map([...pricing.material.keys()], (materialKey) => {
											const materialValue = pricing.material.get(materialKey)
											return {
												_id: materialKey,
												label:
													(materialValue?.[TableTypes.PricingTableFieldId.NAME] as string) ?? '',
											}
										})}
										onAddOption={(option) =>
											handleAddContent(option, TableTypes.PricingTableId.MATERIAL)
										}
										columns={[
											{
												field: 'quantity',
												headerName: 'Qty.',
												type: 'number',
												minWidth: 65,
												sortable: false,
												editable: true,
											},
											{
												field: 'type',
												headerName: 'Class',
												type: 'string',
												minWidth: 155,
												sortable: false,
												editable: true,
												valueGetter: ({ row }) => {
													return pricing.material.get(row._id)?.[
														TableTypes.PricingTableFieldId.NAME
													]
												},
											},
											{
												field: 'value',
												headerName: 'Cost / unit',
												type: 'number',
												minWidth: 90,
												sortable: false,
												editable: true,
												valueGetter: ({ row }) => {
													return pricing.material.get(row._id)?.[
														TableTypes.PricingTableFieldId.PRICE
													]
												},
												valueFormatter: ({ value }) => {
													return value ? '$' + Number(value).toFixed(2) : null
												},
											},
											{
												field: 'options',
												headerName: '',
												type: 'actions',
												minWidth: 50,
												sortable: false,
												editable: false,
												getActions: ({ id, row }) => [
													<GridActionsCellItem
														key={`${id}-delete`}
														icon={<GridDeleteForeverIcon />}
														label="Delete"
														onClick={() => {
															handleDeleteRow('materialContents', row._id)
														}}
													/>,
												],
											},
										]}
										data={defaultContents.materialContents}
									/>
								</Box>

								<Box mb={2}>
									<ContentsTable
										title="Equipment"
										onRowUpdate={(newRow) =>
											handleUpdateContents(newRow, TableTypes.PricingTableId.EQUIPMENT)
										}
										addRowOptions={_map([...pricing.equipment.keys()], (equipmentKey) => {
											const equipmentValue = pricing.equipment.get(equipmentKey)
											return {
												_id: equipmentKey,
												label:
													(equipmentValue?.[TableTypes.PricingTableFieldId.NAME] as string) ?? '',
											}
										})}
										onAddOption={(option) =>
											handleAddContent(option, TableTypes.PricingTableId.EQUIPMENT)
										}
										columns={[
											{
												field: 'quantity',
												headerName: 'Qty.',
												type: 'number',
												minWidth: 65,
												sortable: false,
												editable: true,
											},
											{
												field: 'type',
												headerName: 'Class',
												type: 'string',
												minWidth: 190,
												sortable: false,
												editable: true,
												valueGetter: ({ row }) => {
													return pricing.equipment.get(row._id)?.[
														TableTypes.PricingTableFieldId.NAME
													]
												},
											},
											{
												field: 'usage',
												headerName: 'Usage',
												type: 'number',
												minWidth: 90,
												sortable: false,
												editable: true,
												valueFormatter: ({ value }) => {
													return value * 100 + '%'
												},
											},
											{
												field: 'price',
												headerName: 'Hourly rate',
												type: 'number',
												width: 110,
												sortable: false,
												editable: true,
												valueGetter: ({ row }) => {
													return pricing.equipment.get(row._id)?.[
														TableTypes.PricingTableFieldId.PRICE
													]
												},
												valueFormatter: ({ value }) => {
													return value ? '$' + Number(value).toFixed(2) : null
												},
											},
											{
												field: 'options',
												headerName: '',
												type: 'actions',
												minWidth: 50,
												sortable: false,
												editable: false,
												getActions: ({ id, row }) => [
													<GridActionsCellItem
														key={`${id}-delete`}
														icon={<GridDeleteIcon />}
														label="Delete"
														onClick={() => {
															handleDeleteRow('equipmentContents', row._id)
														}}
													/>,
												],
											},
										]}
										data={defaultContents.equipmentContents}
									/>
								</Box>
							</>
						)}
						{isLoadingPricing && <PulseLoader size={8} />}
					</Box>
				)}
			</>
		</Modal>
	)
}
