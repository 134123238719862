import { ComponentType } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Modal } from 'src/components'
import {
	selectors as myTasksSelectors,
	actions as myTasksActions,
} from 'src/store/modules/my-tasks-v3'
import { colors } from 'src/theme/colors'
import { AsyncDispatchType } from 'src/store/types'

import { TaskChat, TaskChatProps } from './task-chat'

export const ChatModal: ComponentType<TaskChatProps> = (props) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const isOpen = useSelector(myTasksSelectors.isChatModalOpen)

	const handleCloseModal = () => {
		dispatch(myTasksActions.toggleChatModal(false))
	}

	return (
		<Modal
			open={isOpen}
			containerStyle={{
				width: 663,
				maxWidth: '100%',
				height: 493,
				maxHeight: '100%',
				bgcolor: colors.neutral['10'],
				borderRadius: '14px',
				flex: 1,
				display: 'flex',
			}}
			sx={{
				display: 'flex',
				flexDirection: 'column',
			}}
			onClose={handleCloseModal}>
			<TaskChat {...props} />
		</Modal>
	)
}
