import { ClientTypes, TableTypes } from '@cango-app/types'
import { ComponentType, useContext, useEffect } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { v4 } from 'uuid'
import _orderBy from 'lodash/orderBy'

import { Box, Button, Divider, IconButton, Modal, Text, TextField } from 'src/components'
import { TrashIcon } from 'src/assets/icons'
import { TableContext } from 'src/providers/table-provider'

import { CalculationModal } from './calculation-modal/calculation-modal'

type ColumnSettingsModalProps = {
	column: ClientTypes.Table.Field
	shouldOpen: boolean
	onClose: () => void
}

export const columnsWithSettings = [
	TableTypes.FieldType.CALCULATION,
	TableTypes.FieldType.SINGLE_SELECT,
	// TableTypes.FieldType.TABLE_SELECT,
]

type SingleSelectForm = {
	options: { id: string; label: string }[]
}

const SingleSelectOptions: ComponentType<{
	defaultOptions: string[]
	columnId: string
	onClose: () => void
}> = ({ defaultOptions, columnId, onClose }) => {
	const { onUpdateColumn, isUpdatingTable } = useContext(TableContext)
	const { control, watch, handleSubmit } = useForm<SingleSelectForm>({
		defaultValues: {
			options: [
				...defaultOptions.map((option) => ({ id: v4(), label: option })),
				{ id: v4(), label: '' },
			],
		},
	})
	const { fields, append, remove } = useFieldArray({ control, name: 'options' })
	const lastOption = watch(`options.${fields.length - 1}`)
	const penultimateOption = watch(`options.${fields.length - 2}`)

	const handleSubmitOptions = async (data: SingleSelectForm) => {
		const options = data.options.reduce((_options: string[], _option) => {
			if (_option.label) {
				_options.push(_option.label)
			}
			return _options
		}, [])

		const orderedOptions = _orderBy(options)
		const response = await onUpdateColumn({ fieldId: columnId, valueOptions: orderedOptions })
		if (response.result === 'success') {
			onClose()
		}
	}

	useEffect(() => {
		if (!fields.length) return
		const lastField = lastOption.label
		if (lastField !== '') {
			append({ id: v4(), label: '' })
		}

		if (penultimateOption?.label === '' && fields.length > 1) {
			remove(fields.length - 1)
		}
	}, [lastOption?.label, penultimateOption?.label])

	return (
		<Box pb={2}>
			<Text variant="h6">Single select options</Text>
			<Divider />
			<Box my={1}>
				{fields.map((_field, index) => (
					<Box key={_field.id} display="flex" alignItems="center" width="100%" mb={1}>
						<Controller
							control={control}
							name={`options.${index}.label`}
							render={({ field }) => (
								<TextField
									{...field}
									label={`Option ${index + 1}`}
									fullWidth
									size="small"
									containerProps={{ width: 400 }}
								/>
							)}
						/>
						{index !== fields.length - 1 && (
							<Box display="flex" alignItems="center" pt={2}>
								<IconButton onClick={() => remove(index)}>
									<TrashIcon />
								</IconButton>
							</Box>
						)}
					</Box>
				))}
			</Box>
			<Box mb={2}>
				<Button onClick={handleSubmit(handleSubmitOptions)} isLoading={isUpdatingTable}>
					Save
				</Button>
			</Box>
		</Box>
	)
}

export const ColumnSettingsModal: ComponentType<ColumnSettingsModalProps> = ({
	column,
	shouldOpen,
	onClose,
}) => {
	if (!columnsWithSettings.includes(column.type)) return null

	return (
		<>
			<Modal
				open={shouldOpen}
				onClose={onClose}
				onKeyDown={(e) => e.stopPropagation()}
				containerStyle={{
					maxWidth: 'lg',
					overflowX: 'hidden',
					overflowY: 'auto',
				}}
			>
				<Box
					minWidth={500}
					minHeight={column.type === TableTypes.FieldType.CALCULATION ? 400 : 300}
					width={column.type === TableTypes.FieldType.CALCULATION ? '100%' : undefined}
				>
					{column.type === TableTypes.FieldType.SINGLE_SELECT && (
						<SingleSelectOptions
							defaultOptions={column.valueOptions}
							columnId={column._id}
							onClose={onClose}
						/>
					)}
					{column.type === TableTypes.FieldType.CALCULATION && (
						<CalculationModal
							defaultCalculation={column.calculation}
							columnId={column._id}
							onClose={onClose}
						/>
					)}
				</Box>
			</Modal>
		</>
	)
}
