import { V3ClientTypes } from '@cango-app/types'
import { ComponentType, useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ContactForm } from 'src/modules/contacts/contact-drawer/contact-form'
import {
	selectors as contactSelectors,
	actions as contactActions,
} from 'src/store/modules/contacts'
import { actions as myTasksActions } from 'src/store/modules/my-tasks-v3'
import { Box, Button, Modal, Text } from 'src/components'

type CreateContactProps = {
	task: V3ClientTypes.Project.Task
	useDrawer?: boolean
	onCreateContact?: (contactId: string) => void
}

export const CreateContact: ComponentType<CreateContactProps> = ({
	task,
	useDrawer = false,
	onCreateContact,
}) => {
	const dispatch = useDispatch()
	const [showContactDrawer, setShowContactDrawer] = useState(false)
	const mappedContacts = useSelector(contactSelectors.mappedContacts)
	const [isSaving, setIsSaving] = useState(false)
	const contact = useMemo(() => {
		return mappedContacts.get(task.lifecycle.resource ?? '')
	}, [mappedContacts, task.lifecycle.resource])

	const handleCreatedContact = useCallback(
		async (id: string) => {
			if (onCreateContact) {
				setIsSaving(true)
				await onCreateContact(id)
				setIsSaving(false)
			} else {
				dispatch(
					myTasksActions.updateTask({
						taskId: task._id,
						update: {
							'lifecycle.resource': id,
						},
					}),
				)
			}
		},
		[onCreateContact],
	)

	if (contact) {
		return (
			<Box>
				<Text>
					<b>Name:</b> {contact.name} {contact.surname}
				</Text>
				{!!contact.company && (
					<Text>
						<b>Company:</b> {contact.company}
					</Text>
				)}
				{!!contact.phone && (
					<Text>
						<b>Phone:</b> {contact.phone}
					</Text>
				)}
				{!!contact.email && (
					<Text>
						<b>Email:</b> {contact.email}
					</Text>
				)}
				{!!contact.address?.line1 && (
					<Text>
						<b>Address:</b> {contact.address.line1}, {contact.address?.line2 || ''},{' '}
						{contact.address?.line3 || ''},
					</Text>
				)}
			</Box>
		)
	}

	if (useDrawer) {
		return (
			<>
				<Modal open={showContactDrawer} onClose={() => setShowContactDrawer(false)}>
					<Box width={700}>
						<ContactForm setNewContactId={handleCreatedContact} />
					</Box>
				</Modal>
				<Button fullWidth onClick={() => setShowContactDrawer(true)} isLoading={isSaving}>
					Create Contact
				</Button>
			</>
		)
	}

	return <ContactForm setNewContactId={handleCreatedContact} isSaving={isSaving} />
}
