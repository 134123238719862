import { TableTypes, ClientTypes } from '@cango-app/types'
import { CangoTable } from '@cango-app/types/lib/server/table'
import { FieldType } from '@cango-app/types/lib/table'
import excellentexport, { SheetOptions } from 'excellentexport'
import * as formulajs from '@formulajs/formulajs'
import _ from 'lodash'

const formulas = Object.keys(formulajs)

function splitAfterBracket(input: string) {
	// Use a regular expression to match the pattern
	const regex = /(\[[^\]]*\])(.*)/
	const match = input.match(regex)

	if (match) {
		// match[1] contains the content inside the brackets
		// match[2] contains the rest of the string
		return [match[1], match[2].substring(1)]
	}

	// If no match is found, return the input as is
	return [input]
}

const evaluateExpression = (expression: string): number => {
	// Evaluate formula.js expression and finally regular JavaScript math operations
	const replaceFormulas = (expr: string): string => {
		// Match all function calls in the expression such as AVERAGE(), SUM(), etc...
		const regex = /([A-Z]+)\(([^()]*)\)/g
		let match
		try {
			while ((match = regex.exec(expr)) !== null) {
				const [fullMatch, functionName, args] = match
				const matchArray = splitAfterBracket(args)
				// Handle specific functions that may have special requirements
				let result
				if (functionName.includes('IF')) {
					const matchArray = splitAfterBracket(args)
					if (matchArray.length !== 2) {
						throw new Error(`Function ${functionName} requires exactly 2 arguments`)
					}
					const range: string = JSON.parse(matchArray[0])
					const criteria = matchArray[1]
					result =
						functionName === 'COUNTIF'
							? formulajs[functionName](range, criteria)
							: formulajs[functionName as 'SUMIF'](range, criteria, 0)
				} else {
					const matchArray = args.split(',').map((arg) => evaluateExpression(arg.trim()))
					// Evaluate the arguments recursively if they are expressions

					if (formulajs[functionName as keyof typeof formulajs]) {
						result = (formulajs[functionName as keyof typeof formulajs] as any)(...matchArray)
					} else {
						throw new Error(`Function ${functionName} is not supported`)
					}
				}

				expr = expr.replace(fullMatch, result.toString())
				// Reset regex to start over from the beginning
				regex.lastIndex = 0
			}
		} catch {
			return ''
		}
		return expr
	}
	// Replace formulas and evaluate the resulting expression
	const replacedExpression = replaceFormulas(expression)
	try {
		return eval(replacedExpression)
	} catch {
		return NaN
	}
}

export const isEvaluable = (expression: string) => {
	try {
		const result = evaluateExpression(expression) as number
		return !_.isNaN(result)
	} catch (e) {
		return false
	}
}

const performRowLevelCalculation = (
	calculation: ClientTypes.Table.FormulaSlice[],
	recordData: ClientTypes.Table.Record['data'],
): number => {
	const stringCalculation = calculation
		.map(({ value, type }) => {
			if (type === TableTypes.FormulaSliceType.FIELD) {
				return recordData[value] ? +recordData[value as string] : 0
			}
			if (TableTypes.Operator[value as keyof typeof TableTypes.Operator]) {
				return TableTypes.Operator[value as keyof typeof TableTypes.Operator]
			}
			return value
		})
		.join('')
	const result = evaluateExpression(stringCalculation)
	return _.isNaN(result) ? 0 : result
}

const buildDependencyGraph = (fields: ClientTypes.Table.Field[]) => {
	const graph = new Map<string, Set<string>>()
	fields.forEach((field) => {
		if (field.type === 'calculation') {
			field.calculation.forEach((part) => {
				if (part.type === TableTypes.FormulaSliceType.FIELD) {
					if (!graph.has(field._id)) {
						graph.set(field._id, new Set())
					}
					graph.get(field._id)?.add(part.value)
				}
			})
		}
	})
	return graph
}

const topologicalSort = (
	fields: ClientTypes.Table.Field[],
	dependencyGraph: Map<string, Set<string>>,
) => {
	const visitedFields = new Map()
	const sortedFields: ClientTypes.Table.Field[] = []

	const visitField = (fieldId: string) => {
		if (visitedFields.get(fieldId)) {
			return
		}
		visitedFields.set(fieldId, true)

		const dependencies = dependencyGraph.get(fieldId)
		if (dependencies) {
			dependencies.forEach((dependentFieldId) => {
				visitField(dependentFieldId)
			})
		}

		const field = fields.find((field) => field._id === fieldId)
		if (field) {
			sortedFields.push(field)
		}
	}

	dependencyGraph.forEach((_, fieldId: string) => {
		visitField(fieldId)
	})

	fields.forEach((field) => {
		if (!visitedFields.get(field._id)) {
			sortedFields.push(field)
		}
	})

	return sortedFields
}

export type ResolvedRowData = { _id: string } & ClientTypes.Table.Record['data']

export const resolveAnyRowCalculations = (
	fields: ClientTypes.Table.Field[],
	row: ClientTypes.Table.Record,
): ResolvedRowData => {
	const dependencyGraph = buildDependencyGraph(fields)
	const sortedFields = topologicalSort(fields, dependencyGraph)

	const rowData: ClientTypes.Table.Record['data'] = {}
	sortedFields.forEach((field) => {
		const rowKey = field._id
		if (field.type === 'calculation') {
			rowData[rowKey] = performRowLevelCalculation(field.calculation, rowData)
		} else {
			rowData[rowKey] = row.data[rowKey]
		}
	})
	return { _id: row._id, ...rowData }
}

export const getCellsFormat = (
	columns: ClientTypes.Table.Field[],
	tableLength: number,
): SheetOptions['formats'] => {
	return columns.map(({ type, isCurrency }, index) => {
		const columnsLetter = String.fromCharCode(index + 65) // get capital letter
		const range = `${columnsLetter}2:${columnsLetter}${tableLength}`
		if (type === TableTypes.FieldType.NUMBER || type === TableTypes.FieldType.CALCULATION) {
			return {
				range,
				format: {
					type: excellentexport.cellTypes.NUMBER,
					pattern: isCurrency
						? excellentexport.cellPatterns.CURRENCY
						: excellentexport.cellPatterns.DECIMAL,
				},
			}
		}
		if (type === TableTypes.FieldType.DATE) {
			return {
				range,
				format: {
					type: excellentexport.cellTypes.DATE,
					pattern: excellentexport.cellTypes.DATE,
				},
			}
		}
		if (type === FieldType.BOOLEAN) {
			return {
				range,
				format: {
					type: excellentexport.cellTypes.BOOLEAN,
					pattern: excellentexport.cellTypes.BOOLEAN,
				},
			}
		}
		return {
			range,
			format: {
				type: excellentexport.cellTypes.TEXT,
				pattern: excellentexport.cellPatterns.TEXT,
			},
		}
	})
}

export const hasMismatchColumnAndRecord = (
	records: ClientTypes.Table.Record[],
	fields: ClientTypes.Table.Field[],
) => {
	const mismatchColumnNames: string[] = []
	const areRowsWithCorrectType = records.reduce<boolean[]>((acc, record) => {
		const hasErrors = fields.every(({ _id, type, name, isCurrency }) => {
			let isCorrectType = true
			const data = record.data[_id]
			if (data === null) return true // if data is empty ignore
			if (type == FieldType.STRING) {
				isCorrectType = _.isString(data)
			}
			if (type === FieldType.NUMBER && !isCurrency) {
				isCorrectType = _.isNumber(data)
			}
			if (type === FieldType.BOOLEAN) {
				isCorrectType = _.isBoolean(data)
			}
			if (!isCorrectType && !mismatchColumnNames.includes(name)) {
				mismatchColumnNames.push(name)
			}
			return isCorrectType
		})
		return [...acc, hasErrors]
	}, [])
	return { hasMismatch: !areRowsWithCorrectType.every((match) => match), mismatchColumnNames }
}
