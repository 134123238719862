import { ComponentType } from 'react'
import { useSelector } from 'react-redux'

import { Box } from 'src/components'
import type { RootState } from 'src/store/types'
import { selectors as myTasksSelectors, TaskWithCompletable } from 'src/store/modules/my-tasks-v3'
import { TaskTitle } from 'src/modules/my-tasks-v3/components/task-title'

import TaskListItemCta from '../ctas'
import { TaskItemWrapper } from '../task-item-wrapper'

type InstanceListItemProps = {
	task: TaskWithCompletable
	isLastTask: boolean
	onClick: (taskId: string, sectionId?: string) => void
	shouldShowSubtasks: boolean
	onOpenSubtasksClick: (taskId: string) => void
}

export const InstanceListItem: ComponentType<InstanceListItemProps> = ({
	task,
	isLastTask,
	onClick,
	shouldShowSubtasks,
	onOpenSubtasksClick,
}) => {
	const hasSubtasks = useSelector((state: RootState) =>
		myTasksSelectors.hasSubtasks(state, task._id),
	)

	return (
		<TaskItemWrapper
			key={task._id}
			isMultiUse={task.isMultiUse}
			isFirstTask={false}
			isLastTask={isLastTask}
			onClick={() => onClick(task._id, task.section_id)}
			hasIndent={true}>
			<Box display="flex" alignItems="center">
				<TaskTitle task={task} containerProps={{ flex: 1 }} isSubtask={false} />
				<TaskListItemCta
					task={task}
					hasSubtasks={hasSubtasks}
					shouldShowSubtasks={shouldShowSubtasks}
					onOpenSubtasksClick={() => onOpenSubtasksClick(task._id)}
				/>
			</Box>
		</TaskItemWrapper>
	)
}
