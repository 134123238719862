import { ComponentType, useContext, useMemo, useState } from 'react'
import { V3BlueprintTypes } from '@cango-app/types'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'

import { Box, Button, Select, TextField } from 'src/components'
import {
	actions as projectActions,
	selectors as projectSelectors,
} from 'src/store/modules/projects-v3'
import { TaskContext } from 'src/providers/task-provider'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { RootState } from 'src/store/types'
import { ArchiveButtons } from 'src/modules/my-tasks-v3/components/archive-buttons'

export const CompleteTaskOps: ComponentType = () => {
	const dispatch = useDispatch()
	const project = useSelector(projectSelectors.getSelectedProject)
	const { task, onCloseDrawer } = useContext(TaskContext)
	const [instanceName, setInstanceName] = useState('')
	const [selectedOptions, setSelectedOptions] = useState<string[]>([])
	const [isCompleting, setIsCompleting] = useState(false)
	const taskDescendants = useSelector((state: RootState) =>
		projectSelectors.getTaskDescendants(state, task?._id),
	)

	const isTaskCompletable = useMemo(() => {
		return taskDescendants.every(({ lifecycle }) => lifecycle.complete)
	}, [taskDescendants])

	const filteredChildren = useMemo(() => {
		if (!task?.children) {
			return []
		}
		if (!task.children) {
			return []
		}
		return task.children.filter(
			({ _id }) =>
				![V3BlueprintTypes.TaskPhase.Commence, V3BlueprintTypes.TaskPhase.Complete].includes(
					_id as V3BlueprintTypes.TaskPhase,
				),
		)
	}, [task?.children])

	const handleArchiveProject = async () => {
		if (!task) return
		const archiveDate = dayjs().unix()
		await dispatch(
			projectActions.updateProject({
				projectId: task.project_id,
				update: { archived: { state: true, at: archiveDate } },
			}),
		)

		if (onCloseDrawer) {
			onCloseDrawer()
		}
	}

	const handleCompleteTask = async (data?: {
		selectedOptions?: string[]
		instanceName?: string
	}) => {
		if (!task) return
		setIsCompleting(true)
		const blockedTask = project?.tasks.find(
			// remove when PR type is merged
			//@ts-ignore
			({ isBlockedBy }) => task?.instance?.instance_of?._id === isBlockedBy,
		)

		try {
			await dispatch(
				projectActions.completeTask({
					projectId: task.project_id,
					optionIds: data?.selectedOptions || [],
					instanceName: data?.instanceName || undefined,
					taskId: task._id,
				}),
			)
			if (blockedTask && task.isLastStepInChain) {
				await dispatch(
					projectActions.resolveFlaggedTaskFromProjectView({
						projectId: blockedTask.project_id,
						taskId: blockedTask._id,
					}),
				)
			}
		} catch (error) {
			showSnackbar('Error completing task', { variant: 'error' })
		} finally {
			setIsCompleting(false)
		}

		if (onCloseDrawer) {
			onCloseDrawer()
		}
	}

	if (!task) {
		return null
	}

	if (task.isMultiUse) {
		return (
			<>
				<TextField
					value={instanceName}
					onChange={(e) => setInstanceName(e.target.value)}
					fullWidth
					sx={{ mb: 1 }}
				/>
				<Button
					variant="contained"
					onClick={() => {
						handleCompleteTask({ selectedOptions, instanceName })
					}}
					disabled={isCompleting || (task.isMultiUse && instanceName.length < 1)}
					isLoading={isCompleting}
				>
					Use
				</Button>
			</>
		)
	}

	if (!isTaskCompletable) return <>Task has incomplete children</>

	if (task.actions.some((_action) => _action.type === V3BlueprintTypes.ActionEnum.Archive)) {
		return (
			<ArchiveButtons onArchiveProject={handleArchiveProject} onCompleteTask={handleCompleteTask} />
		)
	}

	if (!filteredChildren.length) {
		return (
			<Button
				variant="contained"
				onClick={() => {
					handleCompleteTask({ selectedOptions })
				}}
				disabled={isCompleting || (task.isMultiUse && instanceName.length < 1)}
				isLoading={isCompleting}
			>
				Complete
			</Button>
		)
	}

	return (
		<>
			{!project?.archived.state && (
				<Box>
					<Select
						multiline={task.isMenu}
						multiple={task.isMenu}
						options={filteredChildren}
						value={selectedOptions}
						onChange={(e) =>
							task.isMenu
								? setSelectedOptions(e.target.value as string[])
								: setSelectedOptions([e.target.value] as string[])
						}
						placeholder="Choose an option"
						containerProps={{ mb: 1, maxWidth: 600 }}
					/>
					<Button
						onClick={() => {
							handleCompleteTask({ selectedOptions, instanceName })
						}}
						disabled={!selectedOptions.length || isCompleting}
						isLoading={isCompleting}
						sx={{ mb: 1 }}
					>
						Confirm selection
					</Button>
				</Box>
			)}
		</>
	)
}
