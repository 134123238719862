/**
 * Address book view
 */

import React, { useState, ComponentType } from 'react'
import capitalize from 'lodash/capitalize'
import { useSelector } from 'react-redux'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { useEffectOnce } from 'usehooks-ts'
import { UserSdk } from '@cango-app/sdk'
import _orderBy from 'lodash/orderBy'

import { Button, Box, Text } from 'src/components'
import { selectors as userSelectors } from 'src/store/modules/user'
import { selectors as roleSelectors } from 'src/store/modules/roles'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { readableList } from 'src/helpers/labels'
import { RenderFor } from 'src/components/utils/Permissions'
import { dataGridStandard } from 'src/helpers/ui'
import { NewUser } from 'src/assets/icons'
import { showSnackbar } from 'src/helpers/snackbarManager'

import UserDrawer from './user-drawer'

enum UserTableColumns {
	name = 'name',
	permissions = 'permissions',
	assignments = 'assignments',
	roles = 'roles',
	email = 'email',
	phone = 'phone',
	//last_active = 'last active',
}

export const columns: GridColDef[] = Object.values(UserTableColumns).map((id) => ({
	field: id,
	headerName: capitalize(id),
}))

const Users: ComponentType = () => {
	const users = useSelector(userSelectors.getAllUsers)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const [selectedUser, setSelectedUser] = useState<string | undefined>()
	const [mappedAssignments, setMappedAssignments] = useState<Map<string, number>>(new Map())
	const [showingUserDrawer, setShowUserDrawer] = useState(false)
	const mappedRoles = useSelector(roleSelectors.getMappedRoles)
	const [isLoadingAssignments, setIsLoadingAssignments] = useState(false)
	const isLoadingUsers = useSelector(userSelectors.isLoadingUsers)

	const rows = users.map((row) => ({
		id: row._id,
		name: row.name + ' ' + row.surname,
		permissions: capitalize(row.permissions),
		assignments: isLoadingAssignments ? 'Loading' : mappedAssignments.get(row._id) ?? 'None',
		roles: readableList(row.roles.map((role) => mappedRoles.get(role)?.label || '')),
		email: row.email,
		phone: row.phone,
		//last_active: row.last_active[0].when,
		__reorder__: row.name,
	}))

	const columnsWithSharedFields = columns.map((column) => ({
		...column,
		flex: 1,
	}))

	const handleFetchAssignments = async () => {
		try {
			setIsLoadingAssignments(true)
			const response = await UserSdk.getAssignments(import.meta.env.VITE_API as string, authHeaders)
			setMappedAssignments(new Map(response.map(({ _id, assignments }) => [_id, assignments])))
		} catch (error) {
			showSnackbar('Unable to fetch assignments')
		} finally {
			setIsLoadingAssignments(false)
		}
	}

	useEffectOnce(() => {
		handleFetchAssignments()
	})

	return (
		<>
			<RenderFor permissions={['consultant']}>
				<UserDrawer
					open={showingUserDrawer}
					userId={selectedUser}
					setShowUserDrawer={setShowUserDrawer}
					setSelectedUser={setSelectedUser}
				/>
			</RenderFor>

			<Box pt={4} px={4} display="flex" justifyContent="space-between">
				<Text variant="h4">Users</Text>
				<RenderFor permissions={['consultant']}>
					<Button
						onClick={() => setShowUserDrawer(true)}
						variant="contained"
						startIcon={<NewUser fill="#fff" />}>
						New User
					</Button>
				</RenderFor>
			</Box>

			<DataGridPro
				rows={_orderBy(rows, 'name')}
				columns={columnsWithSharedFields}
				onRowClick={({ id }) => {
					setShowUserDrawer(true)
					setSelectedUser(String(id))
				}}
				sx={{ ...dataGridStandard, m: 4 }}
				getRowHeight={() => 'auto'}
				hideFooter
				loading={isLoadingUsers}
			/>
		</>
	)
}

export default Users
