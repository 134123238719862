import { ComponentType, useContext, useMemo } from 'react'
import { TableTypes } from '@cango-app/types'
import { Control, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { TableContext } from 'src/providers/table-provider'
import { Chip } from 'src/components'
import { selectors as tableSelectors } from 'src/store/modules/tables'

import { ConfigureCalculationForm, allOperators } from './utils'

export const Slice: ComponentType<{
	sliceIndex: number
	onClick: () => void
	onDelete: () => void
	control: Control<ConfigureCalculationForm>
}> = ({ sliceIndex, onClick, control, onDelete }) => {
	const slice = useWatch({ control, name: `calculation.${sliceIndex}` })
	const { mappedColumns } = useContext(TableContext)
	const tableList = useSelector(tableSelectors.getAllTables)

	const label = useMemo(() => {
		switch (slice.type) {
			case TableTypes.FormulaSliceType.FIELD:
				return mappedColumns.get(slice.value)?.name
			case TableTypes.FormulaSliceType.OPERATOR:
				return allOperators.find(({ _id }) => _id === slice.value)?.label
			case TableTypes.FormulaSliceType.NUMBER:
				return slice.value
			case TableTypes.FormulaSliceType.LOOKUP:
				return `Lookup from ${
					tableList.find(({ _id }) => _id === slice.lookup?.tableId)?.name ?? 'TBC'
				}`
			default:
				return ''
		}
	}, [slice])

	return (
		<Chip
			sx={{ mr: 1 }}
			label={label}
			onClick={onClick}
			onDelete={onDelete}
			variant="filled"
			color="primary"
		/>
	)
}
