import React, { ComponentType, useEffect, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { RouteId } from 'src/constants/routes'
import { LoadingDocument } from 'src/components'
import type { AsyncDispatchType } from 'src/store/types'
import { actions as authActions } from 'src/store/modules/auth'
import {
	selectors as projectSelectors,
	actions as projectActions,
} from 'src/store/modules/projects-v3'
import { actions as tableActions } from 'src/store/modules/tables'
import { SwitchOrgState, selectors as configSelectors } from 'src/store/modules/config'
import { DriveFilesProvider } from 'src/providers'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { usePrevious } from 'src/hooks/usePrevious'

import { ProjectComponent } from './project-component'
import 'src/assets/css/Task.css'

export const ProjectContainer: ComponentType = () => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const [searchParams] = useSearchParams()
	const { projectId: currentProjectId } = useParams<{
		projectId: string
	}>()
	const orgId = searchParams.get('orgId')
	const previousProjectId = usePrevious(currentProjectId)
	const organisationId = useSelector(configSelectors.getOrganisationId)
	const [newTaskProps, setNewTaskProps] = useState<{ projectId: string } | undefined>()
	const [newTaskFormShowing, setNewTaskFormShowing] = useState(false)
	const project = useSelector(projectSelectors.getSelectedProject)
	const [isLoadingProject, setIsLoadingProject] = useState(false)
	const [switchOrgState, setSwitchOrgState] = useState(SwitchOrgState.NotSwitching)
	const [errorFetchingProject, setErrorFetchingProject] = useState('')
	const selectedTask = useSelector(projectSelectors.getSelectedTask)

	const fetchProject = async () => {
		if (!currentProjectId) return
		window.scrollTo(0, 0)
		setIsLoadingProject(true)
		const response = await dispatch(
			projectActions.getProject({
				projectId: currentProjectId,
			}),
		)
		setIsLoadingProject(false)
		if (response.meta.requestStatus === 'rejected') {
			setErrorFetchingProject(`Project with ID ${currentProjectId} not found`)
		}
	}

	const handleEditTask = (taskId?: string) => {
		dispatch(projectActions.setSelectedTaskId(taskId))
	}

	const handleCloseTaskDrawer = () => {
		if (newTaskFormShowing) {
			setNewTaskProps(undefined)
		}
		if (selectedTask) {
			handleEditTask(undefined)
		}
	}

	const handleRevertInstance = async (instanceId: string, sectionId: string) => {
		if (!currentProjectId) return
		try {
			showSnackbar('Unable to revert instance', { variant: 'error' })
			// await V3ProjectSdk.deleteAsRequiredInstance(import.meta.env.VITE_API as string, authHeaders, {
			// 	instanceId,
			// 	sectionId,
			// })
			// await dispatch(
			// 	projectActions.getById({
			// 		projectId: currentProjectId,
			// 	}),
			// )
		} catch (error) {
			showSnackbar('Error deleting instance', { variant: 'error' })
		}
	}

	const switchOrgAndFetchBlueprint = async () => {
		if (!orgId) {
			setSwitchOrgState(SwitchOrgState.NoOrgId)
			return
		}
		setSwitchOrgState(SwitchOrgState.Switching)
		const response = await dispatch(authActions.switchOrganisation({ organisationId: orgId }))
		if (response.meta.requestStatus === 'rejected') {
			setSwitchOrgState(SwitchOrgState.Failed)
			setErrorFetchingProject(`Failed to switch organisation to ${orgId}`)
			return
		}
		setSwitchOrgState(SwitchOrgState.NotSwitching)
		fetchProject()
	}

	const loadingText = useMemo(() => {
		switch (switchOrgState) {
			case SwitchOrgState.Switching:
				return `Switching organisation to ${orgId}`
			case SwitchOrgState.Failed:
				return 'Failed to switch organisation'
			case SwitchOrgState.NoOrgId:
				return 'No organisation ID provided'
		}

		if (errorFetchingProject) {
			return ''
		}

		return ''
	}, [switchOrgState, project, errorFetchingProject])

	useEffect(() => {
		setNewTaskFormShowing(!!newTaskProps?.projectId)
	}, [newTaskProps])

	useEffect(() => {
		if (orgId !== organisationId) {
			switchOrgAndFetchBlueprint()
		}

		if (previousProjectId && currentProjectId && previousProjectId !== currentProjectId) {
			fetchProject()
		}
	}, [orgId, organisationId, previousProjectId, currentProjectId])

	useEffect(() => {
		if (orgId === organisationId) {
			fetchProject()
		}

		return () => {
			dispatch(projectActions.clearSelectedProject())
			dispatch(tableActions.unmountTable())
		}
	}, [])

	if (isLoadingProject || !project) {
		return (
			<LoadingDocument
				errorText={errorFetchingProject}
				docType="Project"
				returnToRoute={RouteId.Projects}
				customText={loadingText}
			/>
		)
	}

	return (
		<DriveFilesProvider parentFolderId={project.googleDriveId}>
			<ProjectComponent
				orgId={orgId as string}
				onEditTask={handleEditTask}
				onCloseTaskDrawer={handleCloseTaskDrawer}
				onRevertInstance={handleRevertInstance}
			/>
		</DriveFilesProvider>
	)
}
