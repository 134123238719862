import CircularProgress from '@mui/material/CircularProgress'
import { TableTypes, V3ClientTypes } from '@cango-app/types'
import React, { useMemo, useRef } from 'react'
import { z } from 'zod'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
} from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import Input from '@mui/material/Input'
import Stack from '@mui/material/Stack'

import { Box, Grid, Text, Button } from '../../../components'
import { ChevronRight } from '../../../assets/icons'

import { BidOverviewColumn } from './components/bid-overview-column'
import { ColumnStepsLayout } from './components/column-steps-layout'
import { ProductionItemsModal } from './components/production-items-modal'
import { BidItemData, PricingTableMap, ProductionItem } from './types'
import { bidItemSchema } from './schemas'

type AddBidItemProps = {
	loading: boolean
	data: V3ClientTypes.Project.BidItem | null
	goToPrevStep: () => void
	goToNextStep: () => void
	onSave: (data: BidItemData) => Promise<void>
	bid: V3ClientTypes.Project.Bid | null
	bidItems: V3ClientTypes.Project.BidItem[]
	laborPricing: PricingTableMap
	materialPricing: PricingTableMap
	equipmentPricing: PricingTableMap
	productionItems: ProductionItem[]
}

export function BidItemForm(props: AddBidItemProps) {
	const {
		loading,
		bid,
		data,
		onSave,
		goToPrevStep,
		goToNextStep,
		bidItems,
		materialPricing,
		equipmentPricing,
		laborPricing,
		productionItems,
	} = props
	const form = useForm<z.infer<typeof bidItemSchema>>({
		defaultValues: {
			_id: undefined,
			bidId: undefined,
			quantity: undefined,
			profitMargin: undefined,
			hours: undefined,
			proposalGroupId: undefined,
			productionItemId: undefined,
			laborContents: [],
			equipmentContents: [],
			materialContents: [],
			...data,
		},
		resolver: zodResolver(bidItemSchema),
	})
	const [showModal, setShowModal] = React.useState<boolean>(false)
	const shouldShowConfirm = useRef(false)
	const [showConfirm, setShowConfirm] = React.useState<boolean>(false)
	const isEditing = data?._id != null
	const title = isEditing ? 'Edit bid item' : 'Add bid item'

	const selectedProductionItem = productionItems.find(
		(item) => item._id === form.getValues('productionItemId'),
	)

	const inputs = form.watch()
	const { isValid, isDirty } = form.formState

	let baselineQty
	let unit = ''
	let baselineHours = 0
	let unitPerHour = ''
	let baselineUnitPerHour = 0

	if (selectedProductionItem != null) {
		baselineQty = selectedProductionItem?.[TableTypes.ProductionFieldId.QUANTITY] ?? 0
		unit = selectedProductionItem?.unitOfMeasurement ?? ''
		baselineHours = selectedProductionItem?.[TableTypes.ProductionFieldId.HOURS] ?? 0
		unitPerHour = (inputs.quantity / inputs.hours).toFixed(2)
		baselineUnitPerHour = Number((baselineQty / baselineHours).toFixed(2))
	}

	const onSubmit = (newData: BidItemData) => {
		if (shouldShowConfirm.current) {
			setShowConfirm(true)
			return
		}
		onSave(newData)
	}

	const reactiveBidItems = useMemo(
		() =>
			bidItems.map((item) => {
				if (item._id === inputs._id) {
					return {
						...item,
						...inputs,
					}
				}
				return item
			}),
		[bidItems, inputs],
	)

	return (
		<ColumnStepsLayout
			columns={[
				<BidOverviewColumn
					key={bid?._id}
					bid={bid}
					bidItems={reactiveBidItems}
					equipmentPricing={equipmentPricing}
					laborPricing={laborPricing}
					materialPricing={materialPricing}
				/>,
				<Box key={bid?._id} display="flex" flexDirection="column" px={4} width="100%">
					<Box width="100px">
						<Button
							variant="text"
							sx={{ minWidth: 'auto' }}
							startIcon={<ChevronRight style={{ transform: 'rotate(180deg)' }} />}
							onClick={goToPrevStep}
						>
							Back
						</Button>
					</Box>
					<Box display="flex" alignItems="center">
						<Text variant="h4">{title}</Text>
						{loading && <CircularProgress size={20} thickness={5} sx={{ ml: 2 }} />}
					</Box>
					<form onSubmit={form.handleSubmit(onSubmit)}>
						<Grid container py={2} columnSpacing={12} rowSpacing={6}>
							<Grid item md={8}>
								<Text variant="h5" mb={4}>
									Basic Information
								</Text>
								<Stack direction="column" useFlexGap spacing={{ md: 2 }}>
									<Box display="flex" alignItems="baseline" justifyContent="space-between">
										<FormControl sx={{ mr: 2, flex: 1 }}>
											<InputLabel htmlFor="production-item">Production item</InputLabel>
											<Input
												id="production-item"
												readOnly
												onClick={() => setShowModal(true)}
												value={selectedProductionItem?.[TableTypes.ProductionFieldId.NAME] ?? ''}
												sx={{ input: { cursor: 'pointer' } }}
											/>
										</FormControl>
										<Button
											id="production-items"
											variant="contained"
											color="feldgrau40"
											onClick={() => setShowModal(true)}
										>
											Select production item
										</Button>
									</Box>
									<FormControl>
										<InputLabel htmlFor="proposal-group">Proposal group</InputLabel>
										<Input
											id="proposal-group"
											readOnly
											disabled
											value={selectedProductionItem?.proposalGroup ?? ''}
										/>
									</FormControl>
									<Controller
										name="quantity"
										control={form.control}
										render={({ field, fieldState }) => (
											<FormControl>
												<InputLabel htmlFor={field.name}>Quantity</InputLabel>
												<Input
													{...field}
													id={field.name}
													type="number"
													value={field.value ?? ''}
													disabled={selectedProductionItem == null}
												/>
												<Text color="error">{fieldState.error?.message}</Text>
											</FormControl>
										)}
									/>
									<FormControl>
										<InputLabel htmlFor="unit">Unit of measurement</InputLabel>
										<Input id="unit" readOnly disabled value={unit} />
									</FormControl>
								</Stack>
							</Grid>
							<Grid item md={4}>
								<Text variant="h5" mb={4}>
									Time
								</Text>
								<Stack direction="column" useFlexGap spacing={{ md: 2 }}>
									<Controller
										name="hours"
										control={form.control}
										render={({ field, fieldState }) => (
											<FormControl>
												<InputLabel htmlFor={field.name}>Hours</InputLabel>
												<Input
													{...field}
													type="number"
													id={field.name}
													value={field.value ?? ''}
													disabled={selectedProductionItem == null}
												/>
												<Text color="error">{fieldState.error?.message}</Text>
											</FormControl>
										)}
									/>
									<FormControl>
										<InputLabel htmlFor="unit-per-hour">Unit / Hour</InputLabel>
										<Input id="unit-per-hour" readOnly disabled value={unitPerHour} />
									</FormControl>
									<FormControl>
										<InputLabel htmlFor="baseline-hours">Baseline hours</InputLabel>
										<Input
											id="baseline-hours"
											readOnly
											disabled
											value={(inputs.quantity / baselineUnitPerHour).toFixed(2)}
										/>
									</FormControl>
									<FormControl>
										<InputLabel htmlFor="baseline-unit-per-hour">Baseline Unit / Hour</InputLabel>
										<Input
											id="baseline-unit-per-hour"
											readOnly
											disabled
											value={baselineUnitPerHour}
										/>
									</FormControl>
								</Stack>
							</Grid>
							<Grid item md={12}>
								<Stack direction="row" spacing={{ md: 2 }} justifyContent="flex-end">
									<Button
										variant="contained"
										color="primary"
										type="submit"
										disabled={!isDirty || !isValid}
									>
										Save
									</Button>
									{isEditing && !isDirty && (
										<Button variant="outlined" color="feldgrau40" onClick={() => goToNextStep()}>
											Continue
										</Button>
									)}
									{isEditing && isDirty && (
										<Button variant="outlined" color="neutral" onClick={() => form.reset()}>
											Reset
										</Button>
									)}
								</Stack>
							</Grid>
						</Grid>
					</form>
					<ProductionItemsModal
						productionItems={productionItems}
						onClose={() => setShowModal(false)}
						onSelect={(item) => {
							if (isEditing) {
								shouldShowConfirm.current = true
							}
							const options = { shouldDirty: true, shouldValidate: true }
							form.setValue('bidId', bid?._id ?? '', options)
							form.setValue('productionItemId', item._id, options)
							form.setValue('quantity', item[TableTypes.ProductionFieldId.QUANTITY], options)
							form.setValue('hours', item[TableTypes.ProductionFieldId.HOURS], options)
							form.setValue(
								'proposalGroupId',
								item[TableTypes.ProductionFieldId.PROPOSAL_GROUP],
								options,
							)
							form.setValue('laborContents', item.laborContents, options)
							form.setValue('materialContents', item.materialContents, options)
							form.setValue('equipmentContents', item.equipmentContents, options)
							form.trigger(['bidId', 'productionItemId', 'quantity', 'hours', 'proposalGroupId'])
						}}
						open={showModal}
					/>
					<Dialog
						open={showConfirm}
						onClose={() => setShowConfirm(false)}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
					>
						<DialogTitle id="alert-dialog-title">
							Overwrite existing bid item and all its content.
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								You modified the production item. This will reset the labor, material, and equipment
								contents.
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								onClick={() => {
									shouldShowConfirm.current = false
									form.reset()
									setShowConfirm(false)
								}}
							>
								Cancel
							</Button>
							<Button
								onClick={() => {
									form.handleSubmit(onSubmit)()
									shouldShowConfirm.current = false
									setShowConfirm(false)
								}}
								autoFocus
							>
								Continue
							</Button>
						</DialogActions>
					</Dialog>
				</Box>,
			]}
		/>
	)
}
