import { ClientTypes, TableTypes } from '@cango-app/types'
import MenuItem from '@mui/material/MenuItem'
import {
	GridApiPro,
	GridColumnMenu,
	GridColumnMenuItemProps,
	GridColumnMenuProps,
} from '@mui/x-data-grid-pro'
import { ComponentType, MutableRefObject, useContext, useMemo, useState } from 'react'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { PulseLoader } from 'react-spinners'

import { Box, Button, Checkbox, Select, TextField } from 'src/components'
import { TableContext } from 'src/providers/table-provider'
import { TrashIcon } from 'src/assets/icons'

import { ColumnSettingsModal, columnsWithSettings } from './column-settings'

const fieldTypeOptions = [
	{ label: 'Text', _id: TableTypes.FieldType.STRING },
	{ label: 'Number', _id: TableTypes.FieldType.NUMBER },
	{ label: 'Date', _id: TableTypes.FieldType.DATE },
	{ label: 'True / False', _id: TableTypes.FieldType.BOOLEAN },
	{ label: 'Single Select', _id: TableTypes.FieldType.SINGLE_SELECT },
	// { label: 'Table Select', _id: TableTypes.FieldType.TABLE_SELECT },
	{ label: 'Contact', _id: TableTypes.FieldType.CONTACT },
	{ label: 'Formula', _id: TableTypes.FieldType.CALCULATION },
	{
		label: 'Resources',
		_id: TableTypes.FieldType.RESOURCES,
	},
]

const ColumnNameField: ComponentType<GridColumnMenuItemProps & { name: string }> = (props) => {
	const { onUpdateColumn, isUpdatingTable } = useContext(TableContext)
	const [columnName, setColumnName] = useState(props.name)

	return (
		<Box m={1}>
			<TextField
				label="Column name"
				value={columnName}
				onChange={(event) => setColumnName(event.target.value)}
				disabled={isUpdatingTable}
				size="small"
				fullWidth
			/>
			{props.name !== columnName && !!columnName && (
				<Button
					variant="text"
					onClick={() => onUpdateColumn({ fieldId: props.colDef.field, name: columnName })}
					size="small"
					fullWidth
					isLoading={isUpdatingTable}
					sx={{ minWidth: 50, mt: 0.5 }}
				>
					Save
				</Button>
			)}
		</Box>
	)
}

const FieldTypeSelector: ComponentType<
	GridColumnMenuItemProps & {
		column: ClientTypes.Table.Field
		apiRef?: MutableRefObject<GridApiPro>
	}
> = (props) => {
	const { onUpdateColumn, isUpdatingTable } = useContext(TableContext)
	const [isSettingsOpen, setIsSettingsOpen] = useState(false)

	const handleUpdateColumnType = async (type: TableTypes.FieldType) => {
		if (props.apiRef?.current) {
			// remove filters when updating column
			props.apiRef.current.setFilterModel({ items: [] })
		}
		await onUpdateColumn({ fieldId: props.colDef.field, type })
		if (props.apiRef) {
			props.apiRef.current.autosizeColumns({
				includeHeaders: true,
				includeOutliers: true,
				columns: [props.colDef.field],
			})
		}
	}

	return (
		<>
			<ColumnSettingsModal
				shouldOpen={isSettingsOpen}
				onClose={() => setIsSettingsOpen(false)}
				column={props.column}
			/>
			<Box m={1}>
				<Select
					onChange={(event) => handleUpdateColumnType(event.target.value as TableTypes.FieldType)}
					label="Column type"
					options={fieldTypeOptions}
					disabled={isUpdatingTable}
					value={props.column.type}
					size="small"
					containerProps={{ width: '100%' }}
					fullWidth
				/>
				{props.column.type === TableTypes.FieldType.NUMBER && (
					<Checkbox
						checked={props.column.isCurrency}
						onChange={(event, checked) => {
							onUpdateColumn({
								fieldId: props.colDef.field,
								isCurrency: checked,
							})
						}}
						size="small"
						label="Currency"
						disabled={isUpdatingTable}
					/>
				)}
				{columnsWithSettings.includes(props.column.type) && (
					<Button variant="text" fullWidth onClick={() => setIsSettingsOpen(true)}>
						Settings
					</Button>
				)}
			</Box>
		</>
	)
}

const DeleteColumnMenuItem: ComponentType<GridColumnMenuItemProps> = (props) => {
	const { onDeleteColumn } = useContext(TableContext)
	const [isDeleting, setIsDeleting] = useState(false)

	const handleDeleteColumn = async () => {
		const confirmed = confirm('Are you sure you want to delete this column?')
		if (confirmed) {
			setIsDeleting(true)
			await onDeleteColumn(props.colDef.field)
			setIsDeleting(false)
		}
	}

	return (
		<MenuItem onClick={handleDeleteColumn}>
			<ListItemIcon>
				<TrashIcon width={16} />
			</ListItemIcon>
			{isDeleting ? <PulseLoader size={4} /> : <ListItemText>Delete column</ListItemText>}
		</MenuItem>
	)
}

export const CustomColumnMenu: ComponentType<GridColumnMenuProps> = (props) => {
	const { mappedColumns } = useContext(TableContext)

	const column = useMemo(
		() => mappedColumns.get(props.colDef.field),
		[mappedColumns, props.colDef.field],
	)

	if (!column) {
		return null
	}

	return (
		<GridColumnMenu
			{...props}
			slots={{
				columnMenuSortItem: null,
				columnMenuPinningItem: null,
				columnMenuColumnsItem: null,
				columnMenuFieldSelector: FieldTypeSelector,
				columnMenuNameItem: ColumnNameField,
				columnMenuDeleteColumnItem: DeleteColumnMenuItem,
			}}
			slotProps={{
				columnMenuFieldSelector: {
					displayOrder: 15,
					column: column,
					apiRef: props.slotProps?.apiRef,
				},
				columnMenuNameItem: {
					name: column.name,
					displayOrder: 14,
				},
				columnMenuDeleteColumnItem: {
					displayOrder: 21,
				},
			}}
		/>
	)
}
