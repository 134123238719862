import { ComponentType, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'

import { actions as authActions, selectors as authSelectors } from 'src/store/modules/auth'
import { selectors as configSelectors } from 'src/store/modules/config'
import { Box, UniversalAdd, ForceUpdateOverlay } from 'src/components'
import { RouteId } from 'src/constants/routes'
import { useIsMobile } from 'src/hooks/useMobile'
import { DesktopNavigation, MobileNavigation } from 'src/routing/navigation'
import { APP_BAR_HEIGHT } from 'src/routing/navigation/desktop-navigation'
import { useRoles } from 'src/hooks/useRoles'
import { useContacts } from 'src/hooks/useContacts'
import { useUsers } from 'src/hooks/useUsers'
import { useInbox } from 'src/hooks/useInbox'
import { useTables } from 'src/hooks/useTables'

import { getRouteName } from './utils'

export const Dashboard: ComponentType = () => {
	const dispatch = useDispatch()
	const location = useLocation()
	const navigate = useNavigate()
	const isMobile = useIsMobile()
	useRoles()
	useContacts()
	useUsers()
	useTables()
	useInbox()
	const organisationId = useSelector(configSelectors.getOrganisationId)
	const firebaseToken = useSelector(authSelectors.getFirebaseToken)
	const currentPath = location.pathname

	useEffect(() => {
		if (!organisationId) {
			dispatch(authActions.logoutUser())
		}
	}, [organisationId, dispatch])

	useEffect(() => {
		const routeName = getRouteName(currentPath)
		document.title = `${organisationId} - ${routeName} - Cango`

		if (organisationId && isMobile && !currentPath.includes(`/${RouteId.MyTasks}`)) {
			navigate(`/${RouteId.MyTasks}`)
		}
	}, [currentPath, isMobile, organisationId])

	useEffect(() => {
		if (organisationId && !firebaseToken) {
			dispatch(authActions.fetchFirebaseToken())
		}
	}, [])

	if (!organisationId) {
		return <Navigate to="/login" />
	}

	return (
		<>
			{!isMobile && <DesktopNavigation />}
			<Box
				display="flex"
				flexDirection="column"
				height={{ laptop: `calc(100vh - ${APP_BAR_HEIGHT}px)`, mobile: '100dvh' }}
			>
				<Box flexGrow={1} overflow="auto">
					<UniversalAdd />
					<Box
						width={{ laptop: '100%' }}
						height={{ laptop: '100%' }}
						style={{ display: 'flex', flexDirection: 'column' }}
					>
						<Outlet />
					</Box>
				</Box>
				{!!isMobile && <MobileNavigation />}
			</Box>
			<ForceUpdateOverlay />
		</>
	)
}
