import { ComponentType } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ContactAndAssignment } from 'src/components'
import {
	selectors as myTasksSelectors,
	actions as myTasksActions,
} from 'src/store/modules/my-tasks-v3'

type ContactInfoProps = {
	project: { _id: string }
}

export const ContactInfo: ComponentType<ContactInfoProps> = ({ project }) => {
	const dispatch = useDispatch()
	const taskRoles = useSelector(myTasksSelectors.getTaskRoles)
	const taskExternals = useSelector(myTasksSelectors.getTaskExternals)

	const handleRoleAssignChange = async (roleId: string, userId: string) => {
		await dispatch(
			myTasksActions.assignUsersToRoles({
				projectId: project._id,
				assignments: [{ roleId, userId }],
			}),
		)
	}

	const handleExternalAssignChange = async (roleId: string, contactId: string) => {
		await dispatch(
			myTasksActions.assignContactsToProject({
				projectId: project._id,
				assignments: [{ roleId, contactId }],
			}),
		)
	}

	return (
		<ContactAndAssignment
			taskExternals={taskExternals}
			taskRoles={taskRoles}
			onAssignExternal={handleExternalAssignChange}
			onAssignRole={handleRoleAssignChange}
		/>
	)
}
