import { ComponentType, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PulseLoader from 'react-spinners/PulseLoader'
import { useParams } from 'react-router-dom'

import { Box, Text, TextField } from 'src/components'
import {
	selectors as myTasksSelectors,
	actions as myTasksActions,
	MyTasksLoadingState,
} from 'src/store/modules/my-tasks-v3'
import { selectors as configSelectors } from 'src/store/modules/config'
import { actions as tableActions } from 'src/store/modules/tables'
import { colors } from 'src/theme/colors'
import { useIsMobile } from 'src/hooks/useMobile'
import { AsyncDispatchType } from 'src/store/types'

import { SectionListItem } from './section-list-item'

const ProjectListContent: ComponentType<{
	filterText: string
	newProjectDOMNodeRef: React.MutableRefObject<HTMLDivElement | null>
}> = ({ filterText, newProjectDOMNodeRef }) => {
	const isMobile = useIsMobile()
	const projects = useSelector(myTasksSelectors.getProjectsList)
	const organisationId = useSelector(configSelectors.getOrganisationId)
	const selectedProjectId = useSelector(myTasksSelectors.getSelectedProjectId)
	const projectListState = useSelector(myTasksSelectors.getProjectListState)

	const filteredProjects = useMemo(() => {
		return projects.filter((project) => {
			if (project.name.toLowerCase().includes(filterText.toLowerCase())) return true
			if (
				project.sections.some((section) =>
					section.name.toLowerCase().includes(filterText.toLowerCase()),
				)
			)
				return true
			return false
		})
	}, [filterText, projects])

	const handleGoToProject = (projectId: string) => {
		window.open(`/project/${projectId}?orgId=${organisationId}`, '_blank')
	}

	if (projectListState === MyTasksLoadingState.Loading) {
		return (
			<Box display="flex" justifyContent="center">
				<PulseLoader size={8} />
			</Box>
		)
	}

	if (!projects.length) {
		return (
			<Box>
				<Text>No projects found</Text>
			</Box>
		)
	}

	return (
		<Box sx={{ overflowY: 'auto' }}>
			{filteredProjects.map((project) => {
				const isProjectSelected = project._id === selectedProjectId
				return (
					<div key={project._id} ref={isProjectSelected ? newProjectDOMNodeRef : undefined}>
						<Box
							width="100%"
							bgcolor="white"
							mb={1}
							borderRadius="10px"
							overflow="hidden"
							boxShadow="0px 1px 10px 0px rgba(0, 0, 0, 0.05)">
							<Box
								display="flex"
								justifyContent="space-between"
								alignItems="center"
								borderBottom="1px solid rgba(0, 0, 0, 0.10)"
								bgcolor={isProjectSelected ? colors.sunglow['10'] : 'white'}>
								<Text
									py={2}
									px={2}
									fontWeight={600}
									onClick={!isMobile ? () => handleGoToProject(project._id) : undefined}
									sx={{ mr: 1 }}>
									{project.name}
								</Text>
							</Box>
							{project.sections.map((section) => (
								<SectionListItem key={section._id} section={section} />
							))}
						</Box>
					</div>
				)
			})}
		</Box>
	)
}

export const ProjectsList: ComponentType = () => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const isLoadingProjects = useSelector(myTasksSelectors.isLoadingProjects)
	const selectedProjectId = useSelector(myTasksSelectors.getSelectedProjectId)
	const newProjectDOMNodeRef = useRef<HTMLDivElement | null>(null)
	const isMobile = useIsMobile()
	const [filterText, setFilterText] = useState('')
	const { selectedSectionId: sectionIdParam } = useParams<{
		selectedSectionId: string
	}>()

	useEffect(() => {
		if (filterText && isLoadingProjects) {
			setFilterText('')
		}
	}, [isLoadingProjects, filterText])

	useEffect(() => {
		if (newProjectDOMNodeRef.current) {
			setTimeout(() => {
				if (newProjectDOMNodeRef.current) {
					newProjectDOMNodeRef.current.scrollIntoView({ behavior: 'smooth' })
				}
			}, 500)
		}
	}, [newProjectDOMNodeRef.current])

	useEffect(() => {
		if (selectedProjectId) {
			dispatch(tableActions.getTableForProject({ projectId: selectedProjectId }))
		}
	}, [selectedProjectId])

	const fetchProjects = useCallback(async () => {
		await dispatch(myTasksActions.fetchAllProjects({ isMobile }))
	}, [sectionIdParam])

	useEffect(() => {
		fetchProjects()
	}, [])

	return (
		<Box display="flex" flexDirection="column" width="100%" height="100%">
			<Box height="56px" mb={{ laptop: 3, mobile: 1 }}>
				<Box display="flex" justifyContent="space-between" alignItems="center">
					{!isMobile && (
						<Text fontSize={18} fontWeight={500} color={colors.neutral['80']} flex={1}>
							Projects
						</Text>
					)}
					<TextField
						containerProps={{
							flex: 2,
							pt: 2,
						}}
						value={filterText}
						onChange={(e) => setFilterText(e.target.value)}
						placeholder="Search projects"
						disabled={isLoadingProjects}
						fullWidth
						type="search"
						bgColor={colors.feldgrau['10']}
						sx={{
							'& fieldset': { border: 'none' },
						}}
					/>
				</Box>
			</Box>
			<ProjectListContent filterText={filterText} newProjectDOMNodeRef={newProjectDOMNodeRef} />
		</Box>
	)
}
