import { ComponentType } from 'react'

import { Box, LinkBox } from 'src/components'

type VideoSource = {
	platform: 'youtube' | 'loom' | 'unknown'
	videoId: string | null
}

const identifyVideoSource = (url: string): VideoSource => {
	// YouTube RegEx
	const youtubeRegex =
		/(?:youtube(?:-nocookie)?\.com\/(?:[^/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
	const youtubeMatch = url.match(youtubeRegex)

	if (youtubeMatch && youtubeMatch[1]) {
		return { platform: 'youtube', videoId: youtubeMatch[1] }
	}

	// Loom RegEx
	const loomRegex = /loom\.com\/share\/([a-zA-Z0-9_-]+)/
	const loomMatch = url.match(loomRegex)

	if (loomMatch && loomMatch[1]) {
		return { platform: 'loom', videoId: loomMatch[1] }
	}

	return { platform: 'unknown', videoId: null }
}

interface VideoEmbedProps {
	url: string
}

const Video: ComponentType<VideoEmbedProps> = ({ url }) => {
	const { platform, videoId } = identifyVideoSource(url)

	let src = ''

	switch (platform) {
		case 'youtube':
			src = `https://www.youtube.com/embed/${videoId}`
			break
		case 'loom':
			src = `https://www.loom.com/embed/${videoId}`
			break
		default:
			return <LinkBox link={url} />
	}

	return (
		<Box
			sx={{
				position: 'relative',
				width: '100%',
				paddingBottom: '56.25%',
				overflow: 'hidden',
				borderRadius: '10px',
				boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.50)',
			}}>
			<iframe
				src={src}
				style={{
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					border: 'none',
				}}
				allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
				allowFullScreen
			/>
		</Box>
	)
}

export const VideoEmbed: ComponentType<{ urls: { _id: string; link: string }[] }> = ({ urls }) => {
	return (
		<>
			{urls.map((url) => (
				<Box key={url._id} mb={2}>
					<Video url={url.link} />
				</Box>
			))}
		</>
	)
}
