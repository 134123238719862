import { useDispatch, useSelector } from 'react-redux'
import Skeleton from '@mui/material/Skeleton'

import {
	selectors as projectSelectors,
	actions as projectActions,
} from 'src/store/modules/projects-v3'
import { Box } from 'src/components'
import { AsyncDispatchType } from 'src/store/types'

import SectionTasks from './section-tasks'

export const ProjectTasks = () => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const isFetchingProjectTasks = useSelector(projectSelectors.isFetchingProjectTasks)

	const projectSections = useSelector(projectSelectors.getProjectSectionsWithMasterTasks)

	const handleEditTask = (taskId?: string) => {
		dispatch(projectActions.setSelectedTaskId(taskId))
	}
	return (
		<>
			{!!isFetchingProjectTasks && (
				<Box mx={4} mt={4}>
					<Skeleton variant="rectangular" height={300} sx={{ borderRadius: 4, mb: 2 }} />
					<Skeleton variant="rectangular" height={300} sx={{ borderRadius: 4, mb: 2 }} />
					<Skeleton variant="rectangular" height={300} sx={{ borderRadius: 4, mb: 2 }} />
				</Box>
			)}

			{!isFetchingProjectTasks && !!projectSections.length && (
				<Box mx={4} mt={4}>
					{projectSections.map((section) => (
						<Box key={section._id} id={section._id}>
							<SectionTasks section={section} onEditTask={handleEditTask} />
						</Box>
					))}
				</Box>
			)}
		</>
	)
}
