import React, { ComponentType, useContext } from 'react'

import { AttachedFile, Box, Text } from 'src/components'
import { DriveFilesContext } from 'src/providers'

type TaskFileHistoryProps = {
	file_history: string[]
}

export const TaskFileHistory: ComponentType<TaskFileHistoryProps> = ({ file_history }) => {
	const { allFiles, isLoadingFiles, parentFolderId } = useContext(DriveFilesContext)

	return (
		<Box sx={{ mt: 1 }}>
			<Text variant="overline" fontWeight="bold">
				File History
			</Text>
			<Box display="flex" flexWrap="wrap">
				{file_history.map((fileId) => {
					return (
						<AttachedFile
							containerStyles={{ mb: 1 }}
							key={`historical-${fileId}`}
							fileId={fileId}
							_file={allFiles.find((file) => file.id === fileId)}
							_isLoadingFile={isLoadingFiles}
							folderId={parentFolderId}
						/>
					)
				})}
			</Box>
		</Box>
	)
}
