import { ComponentType, useState } from 'react'
import { useSelector } from 'react-redux'
import Tooltip from '@mui/material/Tooltip'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import { useNavigate } from 'react-router-dom'
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined'

import { selectors as projectSelectors } from 'src/store/modules/projects-v3'
import { selectors as userSelectors } from 'src/store/modules/user'
import { selectors as remoteConfigSelectors } from 'src/store/modules/remote-config'
import { Box, Grid, Text, Button, TabPanel, IconButton } from 'src/components'
import { AnalyticsEvent, analytics } from 'src/biz'
import { RouteId } from 'src/constants/routes'

import { ProjectTasks } from './project-tasks'
import { FileTable } from './file-table'
import { TaskDrawer } from './task-drawer'
import 'src/assets/css/Task.css'
import { DatabaseTable } from './database-table'
import { ProjectSettings } from './project-settings'
import { ProjectAssignments } from './project-assignments'

type ProjectComponentType = {
	orgId: string
	onEditTask: (taskId?: string) => void
	onCloseTaskDrawer: () => void
	onRevertInstance: (instanceId: string, sectionId: string) => Promise<void>
}

enum TABS {
	TASKS = 'show_tasks',
	DATABASE = 'show_database',
	FILES = 'view_files',
	SETTINGS = 'project_settings',
	ASSIGNMENTS = 'project_assignments',
}

export const ProjectComponent: ComponentType<ProjectComponentType> = ({
	orgId,
	onEditTask,
	onCloseTaskDrawer,
	onRevertInstance,
}) => {
	const navigate = useNavigate()
	const [isConfigDrawerOpen, setConfigDrawerOpen] = useState(false)

	const project = useSelector(projectSelectors.getSelectedProject)
	const selectedTaskId = useSelector(projectSelectors.getSelectedTaskId)
	const userPermissions = useSelector(userSelectors.getPermissions)
	const isBidBuilderEnabled = useSelector(remoteConfigSelectors.isBidBuilderEnabled)

	const showSettings = userPermissions
		? ['techmaster', 'consultant'].includes(userPermissions)
		: false

	const [currentTab, setCurrentTab] = useState<TABS>(TABS.TASKS)

	const handleTabChange = (_e: any, newTab: TABS) => {
		analytics.track({
			eventName: AnalyticsEvent.buttonClicked,
			properties: {
				name: newTab,
				screen: RouteId.Project,
			},
		})

		setCurrentTab(newTab)
	}

	const handleShowBidClick = () => {
		analytics.track({
			eventName: AnalyticsEvent.buttonClicked,
			properties: {
				name: 'show_bid',
				screen: RouteId.Project,
			},
		})
		navigate(`/${RouteId.Project}/${project?._id}/${RouteId.Bid}?orgId=${orgId}`)
	}

	return (
		<>
			<TaskDrawer
				onClose={onCloseTaskDrawer}
				onRevertInstance={onRevertInstance}
				taskId={selectedTaskId}
			/>
			<Grid container>
				<Grid xs={2} />
				<Grid xs={8}>
					<Text variant="h3" my={3} textAlign="center">
						{project?.name}
					</Text>
				</Grid>
				{isBidBuilderEnabled &&
					<Grid
						xs={2}
						sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', pr: 4 }}>
						{!!project && (
							<Tooltip title="Bid">
								<div>
									<IconButton sx={{ mr: 2 }} onClick={handleShowBidClick}>
										<CalculateOutlinedIcon style={{ fill: 'black' }} />
									</IconButton>
								</div>
							</Tooltip>
						)}
					</Grid>
				}
			</Grid>
			{!!project?.archived.state && (
				<Box display="flex" justifyContent="center">
					<Alert
						severity="warning"
						sx={{
							width: 600,
						}}
						action={
							<Button
								size="small"
								onClick={() => setCurrentTab(TABS.SETTINGS)}
								variant="text"
								color="warning">
								Go to settings
							</Button>
						}>
						<AlertTitle>Project Archived</AlertTitle>
						You can restore this project from the settings panel
					</Alert>
				</Box>
			)}

			<Tabs sx={{ paddingX: 4 }} value={currentTab} onChange={handleTabChange}>
				<Tab
					value={TABS.TASKS}
					label="tasks"
					id={`tab-${TABS.TASKS}`}
					aria-controls={`tabpanel-${TABS.TASKS}`}
				/>
				{project?.table ? (
					<Tab
						value={TABS.DATABASE}
						label="database"
						id={`tab-${TABS.DATABASE}`}
						aria-controls={`tabpanel-${TABS.DATABASE}`}
					/>
				) : null}
				{project ? (
					<Tab
						value={TABS.FILES}
						label="files"
						id={`tab-${TABS.FILES}`}
						aria-controls={`tabpanel-${TABS.FILES}`}
					/>
				) : null}
				{showSettings ? (
					<Tab
						value={TABS.ASSIGNMENTS}
						label="assignments"
						id={`tab-${TABS.ASSIGNMENTS}`}
						aria-controls={`tabpanel-${TABS.ASSIGNMENTS}`}
					/>
				) : null}
				{showSettings ? (
					<Tab
						value={TABS.SETTINGS}
						label="settings"
						id={`tab-${TABS.SETTINGS}`}
						aria-controls={`tabpanel-${TABS.SETTINGS}`}
					/>
				) : null}
			</Tabs>
			<TabPanel value={currentTab} index={TABS.TASKS}>
				<ProjectTasks />
			</TabPanel>
			{project?.table ? (
				<TabPanel value={currentTab} index={TABS.DATABASE}>
					<DatabaseTable tableId={project.table} />
				</TabPanel>
			) : null}

			{project ? (
				<TabPanel value={currentTab} index={TABS.FILES}>
					<FileTable
						projectId={project._id}
						projectName={project.name}
						driveFolderId={project.googleDriveId}
					/>
				</TabPanel>
			) : null}

			{showSettings ? (
				<TabPanel value={currentTab} index={TABS.ASSIGNMENTS}>
					<ProjectAssignments />
				</TabPanel>
			) : null}
			{showSettings ? (
				<TabPanel value={currentTab} index={TABS.SETTINGS}>
					<ProjectSettings />
				</TabPanel>
			) : null}
		</>
	)
}
