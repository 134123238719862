import React, { ComponentType, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HashLink } from 'react-router-hash-link'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import LinkIcon from '@mui/icons-material/Link'
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd'
import { PulseLoader } from 'react-spinners'
import { Collapse } from '@mui/material'
import { TransitionGroup } from 'react-transition-group'

import { selectors as configSelectors } from 'src/store/modules/config'
import { Box, Text, IconButton, Button } from 'src/components'
import {
	selectors as blueprintSelectors,
	actions as blueprintActions,
	V3BlueprintStep,
} from 'src/store/modules/blueprints-v3'
import { selectors as roleSelectors } from 'src/store/modules/roles'
import { RouteId } from 'src/constants/routes'
import { readableList } from 'src/helpers/labels'
import { moveItemInArray } from 'src/helpers/operations'
import { colors } from 'src/theme/colors'
import { ChevronDown } from 'src/assets/icons'

import SectionDrawer from './section-drawer'

const SectionListItem: ComponentType<{
	section: V3BlueprintStep
	isDisabled: boolean
	index: number
	onSectionClick: () => void
	linkUrl: string
}> = ({ section, index, isDisabled, onSectionClick, linkUrl }) => {
	const mappedRoles = useSelector(roleSelectors.getMappedRoles)

	const sectionFollowsText = useMemo(() => {
		const { parents } = section
		if (!parents || parents.length === 0) {
			return 'Start of Project'
		}
		let text = 'Follows '
		text += readableList(parents[0].options.map(({ label }) => label))
		text += ' of '
		text += parents[0].name
		if (parents.length > 1) text += ' and ' + (parents.length - 1) + ' more'
		return text
	}, [section])

	return (
		<Draggable
			draggableId={section._id}
			index={index}
			key={section._id}
			isDragDisabled={isDisabled}>
			{(provided) => (
				<ListItem
					ref={provided.innerRef}
					{...provided.draggableProps}
					{...provided.dragHandleProps}
					className="section-list-item"
					onClick={onSectionClick}>
					<Box display="flex" flexDirection="column" alignItems="center">
						<HashLink to={linkUrl} smooth onClick={(event) => event.stopPropagation()}>
							<IconButton
								sx={{ mx: 1 }}
								analytics={{
									eventName: 'section_hashLink',
									eventParameters: { sectionNumber: index + 1 },
									routeId: RouteId.Blueprint,
								}}>
								<LinkIcon fontSize="small" />
							</IconButton>
						</HashLink>
					</Box>
					<Box>
						<ListItemText primary={section.name} secondary={sectionFollowsText} />
					</Box>
					<ListItemText
						secondary={mappedRoles.get(section.role)?.label}
						sx={{ textAlign: 'right' }}
					/>
				</ListItem>
			)}
		</Draggable>
	)
}

export const SectionsConfiguration: ComponentType = () => {
	const dispatch = useDispatch()
	const [showNewSectionDrawer, setShowNewSectionDrawer] = useState(false)
	const blueprintSections = useSelector(blueprintSelectors.getSections)
	const organisationId = useSelector(configSelectors.getOrganisationId)
	const blueprintId = useSelector(blueprintSelectors.getSelectedBlueprintId)
	const selectedSection = useSelector(blueprintSelectors.getSelectedSection)
	const [isReOrderLoading, setIsReOrderLoading] = useState(false)
	const [showFullList, setShowFullList] = useState(false)

	const handleEditSectionClick = (sectionId: string) => {
		dispatch(blueprintActions.setSelectedSectionId(sectionId))
	}

	const handleDragEnd = async (result: DropResult) => {
		if (
			!blueprintId ||
			typeof result?.destination?.index === 'undefined' ||
			result.source.index === result.destination.index
		) {
			return
		}
		setIsReOrderLoading(true)
		await dispatch(
			blueprintActions.updateBlueprint({
				blueprintId,
				update: {
					section_order: moveItemInArray(
						blueprintSections,
						result.source.index,
						result.destination.index,
					).map(({ _id }) => _id),
				},
			}),
		)
		setIsReOrderLoading(false)
	}

	const handleCloseSectionDrawer = () => {
		if (showNewSectionDrawer) {
			setShowNewSectionDrawer(false)
		}
		if (selectedSection) {
			dispatch(blueprintActions.setSelectedSectionId(undefined))
		}
	}

	return (
		<>
			<SectionDrawer
				open={!!selectedSection || !!showNewSectionDrawer}
				onClose={handleCloseSectionDrawer}
			/>
			<Box maxWidth="100%" width={700} sx={{ margin: 'auto', position: 'relative' }}>
				<Box display="flex" alignItems="center">
					<Text variant="h5" fontWeight="bold">
						Sections
					</Text>
					<Button variant="outlined" onClick={() => setShowNewSectionDrawer(true)} sx={{ ml: 2 }}>
						Add section
					</Button>
				</Box>
				<DragDropContext onDragEnd={handleDragEnd}>
					<Droppable droppableId="section-list">
						{(droppableProvider) => (
							<List
								disablePadding
								ref={droppableProvider.innerRef}
								{...droppableProvider.droppableProps}>
								<TransitionGroup>
									{blueprintSections.map((section, index) => {
										if (!showFullList && index > 2) return null
										return (
											<Collapse key={section._id}>
												<SectionListItem
													section={section}
													index={index}
													isDisabled={isReOrderLoading}
													onSectionClick={() => handleEditSectionClick(section._id)}
													linkUrl={`/${RouteId.Blueprint}/${blueprintId}?orgId=${organisationId}#${section._id}`}
												/>
											</Collapse>
										)
									})}
								</TransitionGroup>
								{droppableProvider.placeholder}
							</List>
						)}
					</Droppable>
				</DragDropContext>
				<Box display="flex" justifyContent="flex-end">
					{blueprintSections.length > 3 && (
						<Button
							endIcon={
								showFullList ? (
									<ChevronDown style={{ transform: 'rotate(180deg)' }} />
								) : (
									<ChevronDown />
								)
							}
							variant="text"
							onClick={() => setShowFullList(!showFullList)}>
							{!showFullList
								? `Show ${blueprintSections.length - 3} more sections`
								: 'Collapse list'}
						</Button>
					)}
				</Box>
				{isReOrderLoading && (
					<Box
						position="absolute"
						width="100%"
						height="100%"
						top={0}
						display="flex"
						alignItems="center"
						justifyContent="center">
						<PulseLoader color={colors.feldgrau['80']} />
					</Box>
				)}
			</Box>
		</>
	)
}
