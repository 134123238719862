import React, { ComponentType, useMemo } from 'react'
import { ClientTypes, V3BlueprintTypes } from '@cango-app/types'
import { Controller, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { selectors as roleSelectors } from 'src/store/modules/roles'
import { Select, TextField, FormCard, Toggle } from 'src/components'

import { StepFormControl } from './step-form-container'

const REGULAR_TASK_TYPE_OPTIONS = [
	{ label: 'Use once', value: V3BlueprintTypes.TaskType.Standard },
	{ label: 'Use multiple times', value: V3BlueprintTypes.TaskType.Multiuse },
]

const BLOCK_TYPE_OPTION = { label: 'Block Type', value: V3BlueprintTypes.TaskType.BlockType }

type CoreFieldsProps = {
	control: StepFormControl
	isMasterSection: boolean
}

export const CoreFields: ComponentType<CoreFieldsProps> = ({ control, isMasterSection }) => {
	const children = useWatch({ control, name: 'children' })
	const roles = useSelector(roleSelectors.getRoles).filter((role) => role.internal)
	const taskTypeOptions = isMasterSection
		? [...REGULAR_TASK_TYPE_OPTIONS, BLOCK_TYPE_OPTION]
		: REGULAR_TASK_TYPE_OPTIONS

	const hasExtraChildren = useMemo(() => {
		return children.some(
			(child) =>
				![V3BlueprintTypes.TaskPhase.Commence, V3BlueprintTypes.TaskPhase.Complete].includes(
					child._id as V3BlueprintTypes.TaskPhase,
				),
		)
	}, [children])

	return (
		<FormCard title="Basic info" mb={2}>
			<Controller
				control={control}
				name="name"
				rules={{ required: 'Step name is required' }}
				render={({ field, fieldState: { error } }) => (
					<TextField
						error={!!error}
						label="Step name"
						{...field}
						required
						fullWidth
						sx={{ mb: 2, mt: 1 }}
					/>
				)}
			/>

			<Controller
				control={control}
				name="description"
				render={({ field, fieldState: { error } }) => (
					<TextField
						error={!!error}
						multiline
						label="Description (optional)"
						{...field}
						fullWidth
						sx={{ mb: 2, mt: 1 }}
					/>
				)}
			/>

			<Controller
				control={control}
				name="role"
				rules={{ required: 'Role is required' }}
				render={({ field: { value, onChange }, fieldState: { error } }) => (
					<Select
						error={!!error}
						label="Who should do this?"
						onChange={(event) => onChange(event.target.value as ClientTypes.Role)}
						value={value}
						options={roles}
						sx={{ mb: 2, mt: 1 }}
					/>
				)}
			/>

			<Controller
				control={control}
				name="task_type"
				render={({ field: { value, onChange } }) => (
					<Toggle
						options={taskTypeOptions}
						defaultValue={V3BlueprintTypes.TaskType.Standard}
						value={value}
						onChange={onChange}
						containerProps={{ mb: 1 }}
					/>
				)}
			/>

			<Controller
				control={control}
				name="isParent"
				render={({ field: { value, onChange } }) => (
					<Toggle
						label="Can this step have subtasks?"
						options={[
							{ label: 'No', value: false },
							{ label: 'Yes', value: true },
						]}
						value={value}
						onChange={onChange}
						containerProps={{ mb: 1 }}
						disabled={!!hasExtraChildren}
					/>
				)}
			/>
		</FormCard>
	)
}
