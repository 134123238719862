import { ComponentType, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Badge } from '@mui/material'
import { useNavigate } from 'react-router-dom'

import { Box, Button, IconButton, Modal } from 'src/components'
import { BackArrowOutlined, HistoryIcon, InboxNavIcon } from 'src/assets/icons'
import { colors } from 'src/theme/colors'
import { useIsMobile } from 'src/hooks/useMobile'
import {
	selectors as myTasksSelectors,
	actions as myTasksActions,
} from 'src/store/modules/my-tasks-v3'
import { selectors as notesSelectors } from 'src/store/modules/notes'
import { RouteId } from 'src/constants/routes'
import TaskHierarchyComponent from 'src/modules/projects-v3/project-detail/task-drawer/configure-task/task-hierarchy'

import { TaskTitle } from './task-title'

export const TopButtonBar: ComponentType = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const selectedTask = useSelector(myTasksSelectors.getSelectedTask)
	const unreadTasks = useSelector(notesSelectors.getTasksWithUnreadNotes)
	const selectedSectionId = useSelector(myTasksSelectors.getSelectedSectionId)
	const [isHistoryOpen, setIsHistoryOpen] = useState(false)
	const isMobile = useIsMobile()

	const hasUnreadTasks = useMemo(() => {
		if (!selectedTask) return false
		return unreadTasks.find(({ taskId }) => taskId === selectedTask._id)
	}, [unreadTasks, selectedTask])

	const handleCommentsClick = () => {
		dispatch(myTasksActions.toggleChatModal(true))
	}

	const handleBackClick = () => {
		navigate(`/${RouteId.MyTasks}/${selectedSectionId}`)
	}

	if (!selectedTask) return null

	return (
		<>
			<Modal open={isHistoryOpen} onClose={() => setIsHistoryOpen(false)}>
				<Box minHeight={400} maxHeight="90vh" minWidth={700}>
					<TaskHierarchyComponent
						_task={selectedTask}
						onResetTaskComplete={(data) => dispatch(myTasksActions.resetTask(data))}
					/>
				</Box>
			</Modal>
			<Box
				display="flex"
				flex={1}
				borderBottom="1px solid"
				borderColor={colors.neutral['40']}
				py={2}
			>
				<Box display="flex" flex={1}>
					<Box display="flex" alignItems="center">
						{!isMobile && (
							<IconButton onClick={handleBackClick}>
								<BackArrowOutlined />
							</IconButton>
						)}
						<TaskTitle
							task={selectedTask}
							containerProps={{ flex: 1, ml: { laptop: 2 } }}
							fontSize={isMobile ? 14 : 16}
							hideIcon={isMobile}
						/>
					</Box>
				</Box>
				<Box display="flex" alignItems="center">
					<Button
						startIcon={<HistoryIcon width={20} />}
						variant="text"
						onClick={() => setIsHistoryOpen(true)}
					>
						History
					</Button>
					<Badge
						color="error"
						badgeContent={hasUnreadTasks || selectedTask.isFlagged ? '!' : undefined}
					>
						{isMobile ? (
							<IconButton onClick={handleCommentsClick}>
								<InboxNavIcon fill={colors.feldgrau['60']} />
							</IconButton>
						) : (
							<Button
								variant="text"
								onClick={handleCommentsClick}
								startIcon={<InboxNavIcon fill={colors.feldgrau['60']} />}
							>
								Comments
							</Button>
						)}
					</Badge>
				</Box>
			</Box>
		</>
	)
}
