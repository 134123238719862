import { V3BlueprintTypes } from '@cango-app/types'
import React, { ComponentType } from 'react'
import { Controller, useWatch } from 'react-hook-form'

import { Select, FormCard } from 'src/components'
import { getBlueprintWhenTypeLabel } from 'src/helpers/labels'

import { RecurringFields } from './recurring-fields'
import { StepFormControl } from './step-form-container'

type WhenFieldsProps = {
	control: StepFormControl
}

const UndecoratedWhenFields: ComponentType<WhenFieldsProps> = ({ control }) => {
	const whenType = useWatch({ control, name: 'when.type' })
	const whenTypeOptions = Object.values(V3BlueprintTypes.WhenEnum).map((item) => ({
		_id: item,
		label: getBlueprintWhenTypeLabel(item),
	}))

	return (
		<FormCard title="When" mb={2}>
			<Controller
				control={control}
				name="when.type"
				render={({ field: { value, onChange } }) => (
					<Select
						label="When does this need to be done?"
						defaultValue={V3BlueprintTypes.WhenEnum.ASAP}
						onChange={(event) => onChange(event.target.value as V3BlueprintTypes.WhenEnum)}
						value={value}
						options={whenTypeOptions}
						containerProps={{ mb: 3, mt: 1 }}
					/>
				)}
			/>

			{whenType === V3BlueprintTypes.WhenEnum.Recurring && <RecurringFields control={control} />}
		</FormCard>
	)
}

export const WhenFields = React.memo(UndecoratedWhenFields)
