import React, { ComponentType, useCallback } from 'react'
import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import {
	DataGridPro,
	DataGridProProps,
	GridCellParams,
	GridColDef,
	GridRenderCellParams,
	GridRowClassNameParams,
} from '@mui/x-data-grid-pro'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { uniq } from 'lodash'
import Tooltip from '@mui/material/Tooltip'

import { RowTitle } from 'src/components/section-tasks-v3/row-title'
import { dataGridStandard } from 'src/helpers/ui'
import { Box } from 'src/components'
import {
	selectors as projectSelectors,
	actions as projectActions,
	ListedTask,
} from 'src/store/modules/projects-v3'
import { selectors as userSelectors } from 'src/store/modules/user'
import { selectors as roleSelectors } from 'src/store/modules/roles'
import { AsyncDispatchType } from 'src/store/types'
import { getActionLabel } from 'src/helpers/labels'
import { SectionTitleBar } from 'src/modules/projects-v3/project-detail/section-tasks/title-bar'
import { ActionIcon } from 'src/assets/icons'
import { colors } from 'src/theme/colors'
import ChipGroup from 'src/components/chip-group'
import { recursivelyGetParents } from 'src/helpers/chains'
import { ProjectSection } from 'src/store/modules/projects-v3/selectors'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { TaskTitleContent } from 'src/modules/my-tasks-v3/components/task-title'

import { assigneeLabel } from './assignee-label'

type SectionTasksProps = {
	section: ProjectSection
	onEditTask: (taskId: string) => void
}

export enum ColumnId {
	Name = 'name',
	Action = 'action',
	Options = 'completedOptions',
	Assignee = 'assignee',
	LatestNote = 'note',
}

type ProjectRowType = {
	id: string
	complete: boolean
	name: string
	assignee: string
	unassigned: boolean
	missingdate: boolean
	parent: string
	chain: V3ClientTypes.Project.Task['chain']
	iteration: number
	hierarchy: string[]
	action: V3BlueprintTypes.ActionEnum
	when: V3ClientTypes.Project.Task['when']
	instanceName?: string
	numberOfUses?: number
	groupingParentName?: string
	groupingParentOptions?: string[]
	completedOptions?: string[]
	options?: string[]
	isFlagged?: boolean
	isBlockedType?: boolean
	selectedParentOptions: V3ClientTypes.Project.Task['selected_parent_options']
	__reorder__?: string
}

const columns: GridColDef<ProjectRowType>[] = [
	{
		headerName: '',
		field: ColumnId.Action,
		type: 'actions',
		renderCell: (params: GridRenderCellParams<ProjectRowType>) => (
			<Tooltip title={getActionLabel(params.row.action)}>
				<ActionIcon actionType={params.row.action} width={24} stroke={colors.neutral['80']} />
			</Tooltip>
		),
		maxWidth: 50,
	},
	{
		headerName: 'Selection(s)',
		field: ColumnId.Options,
		type: 'actions',
		renderCell: (params: GridRenderCellParams<ProjectRowType>) => (
			<ChipGroup labels={params.row.completedOptions} />
		),
		align: 'left',
		headerAlign: 'left',
	},
	{
		headerName: 'Assignee',
		field: ColumnId.Assignee,
		type: 'string',
		maxWidth: 200,
	},
]

const getCellClassnames = (params: GridCellParams<ProjectRowType>) => {
	const classnames = classNames({
		'task-flagged-note':
			params.field === ColumnId.LatestNote && !!params.row.isFlagged && !!params.formattedValue,
		'cango-error-text':
			(params.field === 'assignee' && params.row.unassigned) ||
			(params.field === 'info' && params.row.missingdate),
	})

	return classnames
}

const getRowClassnames = (params: GridRowClassNameParams<ProjectRowType>) => {
	const classnames = classNames({
		'task-flagged': !!params.row.isFlagged,
		'task-last-visible': !!params.isLastVisible,
		'task-state-complete': !!params.row.complete,
		'task-state.outstanding': !params.row.complete,
	})
	return classnames
}

const ProjectSectionTasks: ComponentType<SectionTasksProps> = ({ section, onEditTask }) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const [isSectionCollapsed, setIsSectionCollapsed] = React.useState(section.lifecycle.complete)
	const mappedUsers = useSelector(userSelectors.getMappedUsers)
	const mappedRoles = useSelector(roleSelectors.getMappedRoles)
	const [expandedRows, setExpandedRows] = React.useState<string[]>([])

	const projectId = useSelector(projectSelectors.getSelectedProjectId)

	const getTaskHierarchy = useCallback(
		(task: ListedTask) => {
			return recursivelyGetParents([], task, section.tasks)
		},
		[section.tasks],
	)

	const handleExpandRow = (rowId: string, shouldExpand: boolean) => {
		if (shouldExpand) {
			setExpandedRows(uniq([...expandedRows, rowId]))
		} else {
			setExpandedRows(expandedRows.filter((id) => id !== rowId))
		}
	}

	const completeSection = async () => {
		if (!section || !projectId) return
		try {
			await dispatch(
				projectActions.completeTask({
					projectId: projectId,
					taskId: section?._id,
				}),
			)
		} catch (error) {
			showSnackbar('Error completing Section', { variant: 'error' })
		}
	}

	const rows = section.tasks.map((row): ProjectRowType => {
		const assignee = mappedUsers.get(row.assignee || '')
		const role = mappedRoles.get(row.role)
		return {
			id: row._id,
			complete: !!row.lifecycle?.complete,
			name: row.name,
			instanceName: row.instance?.name,
			numberOfUses: row.isMultiUse ? row.instance?.instances.length : 0,
			action: row.actions.length ? row.actions[0].type : V3BlueprintTypes.ActionEnum.None,
			parent: row.parent?.name,
			chain: row.chain,
			iteration: row.iteration,
			completedOptions: row.lifecycle.completed_options
				? row.lifecycle.completed_options
						.map((optionId) => {
							const child = row.children.find(({ _id }) => _id === optionId)
							return child ? child.label : ''
						})
						.filter(Boolean)
				: [],
			when: row.when,
			assignee: assignee ? assigneeLabel(assignee) : `${role?.label} role`,
			unassigned: !assignee,
			missingdate: row.when.type === V3BlueprintTypes.WhenEnum.Milestone && !row.when.date,
			hierarchy: getTaskHierarchy(row),
			isFlagged: !!row.isFlagged,
			isBlockedType: row.task_type === V3BlueprintTypes.TaskType.BlockType,
			selectedParentOptions: row.selected_parent_options,
			__reorder__: row.name,
		}
	})

	const rowCount = section.tasks.length
	const shouldLazyLoad = !!rowCount && rowCount >= 12
	const getTreeDataPath: DataGridProProps['getTreeDataPath'] = (row) => row.hierarchy

	const groupingColDef: DataGridProProps['groupingColDef'] = {
		headerName: 'Name',
		width: 400,
		headerClassName: ColumnId.Name,
		renderCell: (params: GridRenderCellParams<ProjectRowType>) => (
			<RowTitle
				{...params}
				name={
					<TaskTitleContent
						instanceName={params.row.instanceName}
						taskName={params.row.name}
						chain={params.row.chain}
						iteration={params.row.iteration}
						numberOfUses={params.row.numberOfUses}
						isBlockType={params.row.isBlockedType}
						selectedParentOptions={params.row.selectedParentOptions}
					/>
				}
				isRowExpanded={expandedRows.includes(params.row.id)}
				onRowExpand={handleExpandRow}
			/>
		),
	}

	if (!section) {
		return null
	}

	return (
		<Box
			p={2}
			boxShadow="rgb(0 0 0 / 12%) 2px 4px 24px;"
			borderRadius="0 20px 20px 20px"
			mb={2}
			bgcolor="#fff"
		>
			<SectionTitleBar
				sectionName={`${
					section.instance && section.instance.name ? `[${section.instance.name}]` + ' ' : ''
				}${section.name}`}
				sectionId={section.step_id}
				completedAt={section.lifecycle.completed_at}
				rowCount={rowCount}
				onSectionComplete={
					section.lifecycle.complete || !section.isCompletable ? undefined : completeSection
				}
				onSectionCollapseToggle={() => setIsSectionCollapsed(!isSectionCollapsed)}
				isSectionCollapsed={isSectionCollapsed}
				sectionChain={section.chain}
			/>
			{!isSectionCollapsed && (
				<Box height={shouldLazyLoad ? 700 : undefined} mb={shouldLazyLoad ? 4 : undefined}>
					<DataGridPro
						autoHeight={!shouldLazyLoad}
						rows={rows}
						columns={columns.map((column) => ({ ...column, sortable: false, flex: 1 }))}
						sx={{
							...dataGridStandard,
							borderRadius: '0 15px 15px 15px',
						}}
						treeData
						getTreeDataPath={getTreeDataPath}
						getRowHeight={() => 'auto'}
						disableRowSelectionOnClick
						hideFooter
						onCellClick={(cell) => {
							onEditTask(cell.row.id)
						}}
						disableColumnMenu
						rowCount={rowCount}
						groupingColDef={groupingColDef}
						pinnedColumns={{ left: [ColumnId.Action] }}
						getRowClassName={getRowClassnames}
						getCellClassName={getCellClassnames}
					/>
				</Box>
			)}
		</Box>
	)
}

export default ProjectSectionTasks
