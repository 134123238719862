import { ComponentType } from 'react'
import { Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { Box, FormCard, Select } from 'src/components'
import { selectors as blueprintSelectors } from 'src/store/modules/blueprints-v3'

import { StepFormControl } from './step-form-container'

type SectionFieldsProps = {
	control: StepFormControl
}

export const SectionFields: ComponentType<SectionFieldsProps> = ({ control }) => {
	const sections = useSelector(blueprintSelectors.getSectionNames)

	return (
		<FormCard title="Section" mb={2}>
			<Controller
				name="section_id"
				control={control}
				render={({ field: { value, onChange } }) => {
					return (
						<Box>
							<Select
								options={sections}
								label="Section"
								value={value}
								onChange={(e) => onChange(e.target.value as string)}
							/>
						</Box>
					)
				}}
			/>
		</FormCard>
	)
}
