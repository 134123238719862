import React from 'react'
import Stack from '@mui/material/Stack'
import { TableTypes } from '@cango-app/types'

import { Box, Text } from '../../../../components'
import { colors } from '../../../../theme/colors'
import { BidItemData, ProductionItem } from '../types'

export function BidItemOverviewColumn({
	bidItem,
	productionItem,
}: {
	bidItem: BidItemData | null
	productionItem: ProductionItem | null
}) {
	type ItemList = { title: string; value: string }[]

	let basicInformation: ItemList = []

	let time: ItemList = []
	if (bidItem && productionItem) {
		basicInformation = [
			{ title: 'Name', value: productionItem[TableTypes.ProductionFieldId.NAME] },
			{ title: 'Proposal group', value: productionItem.proposalGroup },
			{ title: 'Quantity', value: String(bidItem.quantity) },
			{ title: 'Unit of measurement', value: productionItem.unitOfMeasurement },
		]

		time = [
			{ title: 'Hours', value: bidItem.hours.toString() },
			{
				title: `${productionItem.unitOfMeasurement} / hour`,
				value: (bidItem.quantity / bidItem.hours).toFixed(2),
			},
			{
				title: 'Baseline hours',
				value: (
					bidItem.quantity /
					(productionItem[TableTypes.ProductionFieldId.QUANTITY] /
						productionItem[TableTypes.ProductionFieldId.HOURS])
				).toFixed(2),
			},
			{
				title: `Baseline ${productionItem.unitOfMeasurement} / hour`,
				value: (
					productionItem[TableTypes.ProductionFieldId.QUANTITY] /
					productionItem[TableTypes.ProductionFieldId.HOURS]
				).toFixed(2),
			},
		]
	}

	return (
		<Stack px={4} direction="column" spacing={{ md: 6 }} useFlexGap>
			<Box>
				<Text variant="h5">Bid Item {bidItem?._id?.substring(0, 6)}</Text>
			</Box>
			<Box>
				<Text variant="h6">Basic information</Text>
				<Stack direction="column" mt={2} spacing={{ md: 1 }} minHeight="148px" useFlexGap>
					{basicInformation.map((info) => (
						<Box key={info.title} display="column">
							<Text variant="body1">{info.title}</Text>
							<Text variant="body2" color={colors.neutral[60]}>
								{info.value}
							</Text>
						</Box>
					))}
				</Stack>
			</Box>
			<Box>
				<Text variant="h6">Time</Text>
				<Stack direction="column" mt={2} spacing={{ md: 1 }} minHeight="248px" useFlexGap>
					{time.map((info) => (
						<Box key={info.title} display="column">
							<Text variant="body1">{info.title}</Text>
							<Text variant="body2" color={colors.neutral[60]}>
								{info.value}
							</Text>
						</Box>
					))}
				</Stack>
			</Box>
		</Stack>
	)
}
