import { ComponentType, useContext, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box, Button, FormCard, Select } from 'src/components'
import { TaskContext } from 'src/providers/task-provider'
import { actions as projectActions } from 'src/store/modules/projects-v3'
import { selectors as userSelectors } from 'src/store/modules/user'
import { AsyncDispatchType } from 'src/store/types'

export const TaskSettingsPanel: ComponentType<{ onDelete: () => void }> = ({ onDelete }) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const [isSavingAssignee, setIsSavingAssignee] = useState(false)
	const { task, updateTask } = useContext(TaskContext)
	const users = useSelector(userSelectors.getAllUsersForSelect)
	const mappedUsers = useSelector(userSelectors.getMappedUsers)

	const handleAssignTask = async (userId: string) => {
		if (!task) return
		setIsSavingAssignee(true)
		await updateTask('assignee', userId, { updateDb: true })
		dispatch(
			projectActions.updateStoreTask({
				projectId: task.project_id,
				taskId: task._id,
				key: 'assignee',
				value: userId,
			}),
		)
		setIsSavingAssignee(false)
	}

	const showUserRoleWarning = useMemo(() => {
		if (!task) return false
		const assignee = mappedUsers.get(task.assignee || '')
		if (!assignee) return false
		const userRolesForTask = assignee.roles ?? []
		return !!userRolesForTask.length && !userRolesForTask.includes(task.role ? task.role : '')
	}, [task?.role, task?.assignee, mappedUsers])

	return (
		<Box>
			<FormCard title="Ownership">
				<Select
					label="Which user is owning this task"
					onChange={(event) => handleAssignTask(event.target.value as string)}
					value={task?.assignee}
					options={users || []}
					containerProps={{
						mt: 1,
					}}
					helperText={showUserRoleWarning ? "Warning: assignee does not perform task's role" : ''}
					disabled={!!isSavingAssignee}
					isLoading={isSavingAssignee}
				/>
			</FormCard>
			<Box mt={2}>
				<Button color="error" onClick={onDelete} variant="outlined">
					Delete
				</Button>
			</Box>
		</Box>
	)
}
