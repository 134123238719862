import React, { ComponentType, useState } from 'react'
import { useDispatch } from 'react-redux'
import { PulseLoader } from 'react-spinners'

import { actions as authActions } from 'src/store/modules/auth'
import { Box, Button, Text, TextField } from 'src/components'
import { colors } from 'src/theme/colors'

type LoginComponentProps = {
	organisations: { id: string; name: string }[]
	onSelectOrganisation: (organisationId: string, isManualLogin?: boolean) => Promise<void>
}

export const OrganisationList: ComponentType<LoginComponentProps> = ({
	organisations,
	onSelectOrganisation,
}) => {
	const dispatch = useDispatch()
	const [selectedOrganisation, setSelectedOrganisation] = useState<string>()
	const [showManualInput, setShowManualInput] = useState(false)
	const [manualInput, setManualInput] = useState('')

	const handleSelectOrganisation = async (organisationId: string, isManualLogin?: boolean) => {
		if (isManualLogin && organisationId.length > 3) return
		if (manualInput) {
			setManualInput('')
		}
		setSelectedOrganisation(organisationId)
		await onSelectOrganisation(organisationId, isManualLogin)
		setSelectedOrganisation(undefined)
	}

	const handleClearOrganisations = () => {
		dispatch(authActions.setOrganisations({}))
	}

	if (showManualInput) {
		return (
			<Box>
				<Text color={colors.neutral['10']} mb={2}>
					Select an account:
				</Text>
				<TextField
					value={manualInput}
					onChange={(e) => setManualInput(e.target.value)}
					placeholder="Company ID"
					sx={{ mb: 2 }}
				/>
				<Box display="flex" justifyContent="space-between">
					<Button onClick={() => setShowManualInput(false)} variant="text" color="neutral">
						Go back
					</Button>
					<Button
						color="sunglow"
						sx={{ minWidth: 120 }}
						onClick={() => handleSelectOrganisation(manualInput, true)}
						isLoading={!!selectedOrganisation}>
						Log in
					</Button>
				</Box>
			</Box>
		)
	}

	return (
		<Box>
			<Text color={colors.neutral['10']} mb={4}>
				Select an account:
			</Text>
			<Box
				overflow={organisations.length > 3 ? 'scroll' : undefined}
				maxHeight={organisations.length > 3 ? 300 : undefined}
				mb={2}>
				{organisations.map((organisation, index) => (
					<Box
						key={organisation.id}
						bgcolor={colors.neutral['10']}
						textAlign="left"
						px={5}
						py={1}
						sx={{
							borderTopLeftRadius: index === 0 ? '5px' : '0px',
							borderTopRightRadius: index === 0 ? '5px' : '0px',
							borderBottomLeftRadius: index === organisations.length - 1 ? '5px' : '0px',
							borderBottomRightRadius: index === organisations.length - 1 ? '5px' : '0px',
							borderBottom: index === organisations.length - 1 ? 'none' : '1px solid #C1C1C1',
							cursor: 'pointer',
							'&:hover': {
								backgroundColor: colors.neutral['30'], // Define the hover color here
							},
						}}
						onClick={() => handleSelectOrganisation(organisation.id)}>
						<Text fontSize={12} fontWeight={600}>
							{organisation.name}
						</Text>
						{selectedOrganisation === organisation.id ? (
							<PulseLoader size={3} />
						) : (
							<Text fontSize={12} fontWeight={300}>
								{organisation.id}
							</Text>
						)}
					</Box>
				))}
			</Box>
			<Box>
				<Text fontSize={14} color={colors.neutral['10']} mb={1}>
					<span>
						None of the above?{' '}
						<span
							style={{ fontWeight: 600, textDecoration: 'underline', cursor: 'pointer' }}
							onClick={() => setShowManualInput(true)}>
							Enter manually
						</span>
					</span>
				</Text>
				<Text fontSize={14} color={colors.neutral['10']}>
					<span>
						or{' '}
						<span
							style={{ textDecoration: 'underline', cursor: 'pointer' }}
							onClick={handleClearOrganisations}>
							sign in with a different account
						</span>
					</span>
				</Text>
			</Box>
		</Box>
	)
}
