import { ComponentType, useCallback, useMemo, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Tooltip from '@mui/material/Tooltip'
import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import { useNavigate } from 'react-router-dom'
import { Alert, AlertTitle } from '@mui/material'

import { Box, Button, Select } from 'src/components'
import {
	actions as myTasksActions,
	selectors as myTasksSelectors,
} from 'src/store/modules/my-tasks-v3'
import { useIsMobile } from 'src/hooks/useMobile'
import { AsyncDispatchType } from 'src/store/types'
import { DriveFilesContext } from 'src/providers'

import { ReportBlockedTaskModal } from './report-blocked-task-modal'
import { Resources } from './resource-window'

export const BottomButtonBar: ComponentType = () => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const navigate = useNavigate()
	const { allFiles, addNewFilesToCollection } = useContext(DriveFilesContext)
	const task = useSelector(myTasksSelectors.getSelectedTask)
	const activeTasks = useSelector(myTasksSelectors.getActiveTasks)
	const selectedProject = useSelector(myTasksSelectors.getSelectedProject)

	const isMobile = useIsMobile()
	const [isCompleting, setIsCompleting] = useState(false)
	const [selectedOptions, setSelectedOptions] = useState<string[]>([])
	const [isResolving, setIsResolving] = useState(false)

	const taskOptions = useMemo(() => {
		if (!task || !task.children) return []
		return task.children.filter(
			({ _id }) =>
				![V3BlueprintTypes.TaskPhase.Commence, V3BlueprintTypes.TaskPhase.Complete].includes(
					_id as V3BlueprintTypes.TaskPhase,
				),
		)
	}, [task])

	const handleCompleteTaskClick = async (selectedOptions?: string[]) => {
		if (!task) return
		setIsCompleting(true)
		const blockedTask = activeTasks.find(
			({ isBlockedBy }) => task?.instance?.instance_of?._id === isBlockedBy,
		)
		const response = await dispatch(
			myTasksActions.completeTask({
				taskId: task._id,
				optionIds: selectedOptions,
			}),
		)

		if (blockedTask && task.isLastStepInChain) {
			await dispatch(
				myTasksActions.resolveFlaggedTask({
					projectId: blockedTask.project_id,
					taskId: blockedTask._id,
					sectionId: blockedTask?.section_id as string,
				}),
			)
		}
		if (response.meta.requestStatus === 'fulfilled') {
			const payload = response.payload as {
				newTasks: V3ClientTypes.Project.Task[]
				nextTaskId: string | undefined
			}
			const newTasks = payload.newTasks
			const allFileIds = allFiles.map((_file) => _file.id)
			const hasNewFiles = newTasks.some((_task) =>
				_task.actions.some((_action) =>
					_action.file_ids.some((_fileId) => !allFileIds.includes(_fileId)),
				),
			)
			if (hasNewFiles) {
				addNewFilesToCollection()
			}
			navigate(
				`/my-tasks/${task?.section_id}${payload.nextTaskId ? `/${payload.nextTaskId}` : ''}`,
				{ replace: true },
			)
		}
		setSelectedOptions([])
		setIsCompleting(false)
	}

	const handleResolveBlockedTaskClick = async () => {
		if (!task || !task.section_id) return
		setIsResolving(true)
		await dispatch(
			myTasksActions.resolveFlaggedTask({
				taskId: task._id,
				sectionId: task.section_id,
				projectId: task.project_id,
			}),
		)
		setIsResolving(false)
	}

	const renderButtonComplete = useCallback(() => {
		if (!task) return null

		const buttonComplete = () => (
			<Button
				disabled={!task?.isCompletable}
				onClick={() => handleCompleteTaskClick()}
				isLoading={isCompleting}
			>
				Mark as completed
			</Button>
		)

		if (!task.isCompletable) {
			return <Tooltip title={'Subtasks are incomplete'}>{buttonComplete()}</Tooltip>
		}

		return buttonComplete()
	}, [task, isCompleting, handleCompleteTaskClick])

	const renderOptionComplete = useCallback(() => {
		if (!task) return null

		return (
			<Box display="flex" alignItems="center" flexDirection={{ mobile: 'column', laptop: 'row' }}>
				<Select
					multiline={task.isMenu}
					multiple={task.isMenu}
					options={taskOptions}
					value={selectedOptions}
					onChange={(e) =>
						task.isMenu
							? setSelectedOptions(e.target.value as string[])
							: setSelectedOptions([e.target.value] as string[])
					}
					placeholder="Choose an option"
					sx={{ maxWidth: 300 }}
					containerProps={{
						width: { mobile: '100%', laptop: undefined },
						minWidth: 300,
						mr: { laptop: 1 },
						mb: { mobile: 1, laptop: 0 },
					}}
				/>

				<Button
					variant="contained"
					onClick={() => {
						setIsCompleting(true)
						handleCompleteTaskClick(selectedOptions)
					}}
					disabled={isCompleting || !selectedOptions.length}
					isLoading={isCompleting}
					fullWidth={isMobile}
				>
					Confirm
				</Button>
			</Box>
		)
	}, [task, isCompleting, handleCompleteTaskClick, selectedOptions])

	return (
		<>
			<ReportBlockedTaskModal />
			<Box
				display="flex"
				flexDirection={{ mobile: 'column', laptop: 'row' }}
				justifyContent="flex-end"
				flexGrow={{ mobile: 1, laptop: 0 }}
				py={2}
			>
				<Box display="flex" flexDirection={{ mobile: 'column', laptop: 'row' }}>
					{!!task?.resource.filters.items.length && (
						<Box mr={{ laptop: 2, mobile: 0 }}>
							<Resources task={task} tableId={selectedProject?.table} />
						</Box>
					)}
					{!task?.isFlagged ? (
						<Button
							variant="text"
							sx={{ mr: { laptop: 2 }, mb: { mobile: 2, laptop: 0 } }}
							onClick={() => dispatch(myTasksActions.setFlaggedTaskId(task?._id))}
						>
							Report blocked task
						</Button>
					) : (
						<Button
							sx={{ mr: { laptop: 2 }, mb: { mobile: 2, laptop: 0 } }}
							color="sunglow"
							onClick={handleResolveBlockedTaskClick}
							isLoading={isResolving}
						>
							Resolve blocked task
						</Button>
					)}
					<Box>
						{taskOptions.length ? renderOptionComplete() : renderButtonComplete()}
						{task?.actions.some(
							(_action) => _action.type === V3BlueprintTypes.ActionEnum.Archive,
						) && (
							<Alert severity="warning">
								<AlertTitle>Warning</AlertTitle>
								One of the actions of this task is to archive the project. By completing this task
								you will <b>NOT</b> be archiving the project
							</Alert>
						)}
					</Box>
				</Box>
			</Box>
		</>
	)
}
