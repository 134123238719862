import React, { ComponentType, useReducer, type Reducer, useEffect, useCallback } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import LinearProgress from '@mui/material/LinearProgress'
import Button from '@mui/material/Button'

import { Box, Text } from '../../../components'
import { ChevronRight } from '../../../assets/icons'

import * as bidBuilder from './bid-builder'
import { useProjectBid } from './hooks/use-project-bid'
import { BidForm } from './bid-form'
import { BidItemsTable } from './bid-items-table'
import { useBidItems } from './hooks/use-bid-items'
import { BidItemForm } from './bid-item-form'
import { BidItemContentsForm } from './bid-item-contents-form'
import { BidItemSummary } from './bid-item-summary'
import { useResourceTables } from './hooks/use-resource-tables'
import { useProductionItems } from './hooks/use-production-items'

const STEPS_CONFIG: Record<bidBuilder.Steps, any> = {
	[bidBuilder.Steps.idle]: {
		showBreadcrumb: false,
	},
	[bidBuilder.Steps.bidForm]: {
		showBreadcrumb: true,
	},
	[bidBuilder.Steps.bidItemsTable]: {
		showBreadcrumb: true,
	},
	[bidBuilder.Steps.bidItemForm]: {
		showBreadcrumb: false,
	},
	[bidBuilder.Steps.bidItemContentsForm]: {
		showBreadcrumb: false,
	},
	[bidBuilder.Steps.bidSummary]: {
		showBreadcrumb: false,
	},
}

export const BidContainer: ComponentType = () => {
	const navigate = useNavigate()
	const {
		projectId,
		bidItemId = null,
		step = bidBuilder.Steps.idle,
	} = useParams<{
		projectId: string
		bidItemId?: string
		step: bidBuilder.Steps
	}>()
	const [searchParams] = useSearchParams()
	const [state, dispatch] = useReducer<
		Reducer<bidBuilder.BidBuilderState, bidBuilder.BidBuilderAction>
	>(bidBuilder.reducer, {
		...bidBuilder.initialState,
		resolvedStep: step,
		activeBidItemId: bidItemId,
	})
	const { resolvedStep, bid, bidItems, loading, activeBidItemId, pricingTables } = state
	const baseUri = `/project/${projectId}`
	const bidItem = bidItems.find((item) => item._id === state.activeBidItemId) ?? null

	useEffect(() => {
		if (loading) return
		if (resolvedStep !== step || activeBidItemId !== bidItemId) {
			const idSegment = activeBidItemId ? `/${activeBidItemId}` : ''
			navigate(`${baseUri}/bid/${resolvedStep}${idSegment}?${searchParams}`, {
				replace: true,
			})
		}
	}, [resolvedStep, loading])

	const { onSaveBid } = useProjectBid({ projectId: projectId as string, dispatch })
	useResourceTables({ bid, dispatch })
	const { onSaveBidItem, onDeleteBidItem } = useBidItems({
		dispatch,
		bidId: bid?._id,
		projectId: projectId as string,
	})
	const productionItems = useProductionItems(state)
	const goToPrevStep = useCallback(
		(payload?: { activeBidItemId: string | null }) => dispatch(bidBuilder.goToPrevStep(payload)),
		[],
	)
	const goToNextStep = useCallback(
		(payload?: { activeBidItemId: string | null }) => dispatch(bidBuilder.goToNextStep(payload)),
		[],
	)
	const closeBidItemView = useCallback(() => dispatch(bidBuilder.closeBidItemView()), [])

	const config = STEPS_CONFIG[resolvedStep]

	return (
		<Box height="100%" display="flex" flexDirection="column" px={4} py={6}>
			{config.showBreadcrumb && (
				<Box display="flex" alignItems="center" mb={3}>
					<Button
						component={Link}
						variant="text"
						sx={{ minWidth: 'auto', px: 3 }}
						startIcon={<ChevronRight style={{ transform: 'rotate(180deg)' }} />}
						to={`${baseUri}?${searchParams}`}
					>
						Back to project
					</Button>
				</Box>
			)}
			<Box flex={1}>
				{resolvedStep === bidBuilder.Steps.idle && (
					<Box
						px={4}
						py={4}
						height="100%"
						width="100%"
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<Box
							display="flex"
							flexDirection="column"
							alignItems="center"
							justifyContent="center"
							width={300}
						>
							<LinearProgress color="primary" style={{ width: '100%' }} />
							<Text>Loading Bid...</Text>
						</Box>
					</Box>
				)}
				{resolvedStep === bidBuilder.Steps.bidForm && (
					<BidForm
						key={bid?.updated_at}
						loading={loading}
						projectId={projectId as string}
						data={bid}
						onSave={onSaveBid}
						goToNextStep={goToNextStep}
					/>
				)}
				{resolvedStep === bidBuilder.Steps.bidItemsTable && (
					<BidItemsTable
						bid={bid}
						loading={loading}
						bidItems={bidItems}
						onEditBid={goToPrevStep}
						productionItems={productionItems}
						onDeleteBidItem={onDeleteBidItem}
						onAddBidItem={() => goToNextStep({ activeBidItemId: null })}
						onRowClick={(row) => goToNextStep({ activeBidItemId: row._id })}
					/>
				)}
				{resolvedStep === bidBuilder.Steps.bidItemForm && (
					<BidItemForm
						key={bidItem?.updated_at}
						bid={bid}
						data={bidItem}
						loading={loading}
						bidItems={bidItems}
						onSave={onSaveBidItem}
						productionItems={productionItems}
						goToPrevStep={() => goToPrevStep({ activeBidItemId: null })}
						goToNextStep={goToNextStep}
						laborPricing={pricingTables.labor}
						materialPricing={pricingTables.material}
						equipmentPricing={pricingTables.equipment}
					/>
				)}
				{resolvedStep === bidBuilder.Steps.bidItemContentsForm && (
					<BidItemContentsForm
						key={bidItem?.updated_at}
						bid={bid}
						data={bidItem}
						loading={loading}
						bidItems={bidItems}
						onSave={onSaveBidItem}
						goToPrevStep={goToPrevStep}
						goToNextStep={goToNextStep}
						productionItems={productionItems}
						laborPricing={pricingTables.labor}
						materialPricing={pricingTables.material}
						equipmentPricing={pricingTables.equipment}
					/>
				)}
				{resolvedStep === bidBuilder.Steps.bidSummary && (
					<BidItemSummary
						key={bidItem?.updated_at}
						bid={bid}
						data={bidItem}
						loading={loading}
						bidItems={bidItems}
						onSave={onSaveBidItem}
						close={closeBidItemView}
						goToPrevStep={goToPrevStep}
						productionItems={productionItems}
						laborPricing={pricingTables.labor}
						materialPricing={pricingTables.material}
						equipmentPricing={pricingTables.equipment}
					/>
				)}
			</Box>
		</Box>
	)
}
