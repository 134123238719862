import { useMemo } from 'react'
import { TableTypes } from '@cango-app/types'

import { ProductionItem } from '../types'
import { BidBuilderState } from '../bid-builder'

export function useProductionItems({ productionItemsTable }: BidBuilderState): ProductionItem[] {
	return useMemo(
		() =>
			productionItemsTable.map((item) => {
				const proposalGroup = item[TableTypes.ProductionFieldId.PROPOSAL_GROUP]
				const unitOfMeasurement = item[TableTypes.ProductionFieldId.UNIT_OF_MEASUREMENT]

				const contents = item[TableTypes.ProductionFieldId.RESOURCES]

				const productionItem: ProductionItem = {
					...item,
					proposalGroup,
					unitOfMeasurement,
					laborContents: contents.laborContents,
					materialContents: contents.materialContents,
					equipmentContents: contents.equipmentContents,
				}

				return productionItem
			}),
		[productionItemsTable],
	)
}
