import React, { Fragment } from 'react'

import { Box } from '../../../../components'
import { colors } from '../../../../theme/colors'

type ColumnsStepsLayoutProps = {
	columns: React.ReactNode[]
}

export function ColumnStepsLayout(props: ColumnsStepsLayoutProps) {
	const { columns } = props
	return (
		<Box height="100%" display="flex" flexDirection="row" alignItems="start" justifyContent="start">
			{columns.map((column, index) => {
				const isLast = index === columns.length - 1
				return (
					<Fragment key={index}>
						<Box
							height="100%"
							key={index}
							display="flex"
							flexDirection="row"
							flex={isLast ? 1 : undefined}
						>
							{column}
						</Box>
						{!isLast && (
							<Box height="100%" display="flex" justifyContent="center">
								<Box borderLeft={`solid 1px ${colors.feldgrau['10']}`} />
							</Box>
						)}
					</Fragment>
				)
			})}
		</Box>
	)
}
