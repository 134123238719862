import { z } from 'zod'

export const bidItemSchema = z.object({
	_id: z.string().optional(),
	bidId: z.string(),
	quantity: z.coerce.number().min(1),
	profitMargin: z.coerce.number().optional(),
	hours: z.coerce.number(),
	proposalGroupId: z.string(),
	productionItemId: z.string(),
	laborContents: z.array(z.any()),
	equipmentContents: z.array(z.any()),
	materialContents: z.array(z.any()),
})
