import React, { ComponentType, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useSelector } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'
import { GridFilterModel, GridColumnVisibilityModel } from '@mui/x-data-grid-premium'
import { Alert } from '@mui/material'

import { Box, Button, FormCard, Modal } from 'src/components'
import { selectors as blueprintSelectors } from 'src/store/modules/blueprints-v3'
import { CoreTable } from 'src/modules/tables/core-table'
import { TableProvider } from 'src/providers/table-provider'

import { StepFormType } from './step-form-container'

const UndecoratedResourceFields: ComponentType = () => {
	const [showTableModal, setShowTableModal] = useState(false)
	const databaseTable = useSelector(blueprintSelectors.getSelectedBlueprintDatabaseTable)
	const { setValue, watch } = useFormContext<StepFormType>()
	const resource = watch('resource')
	const hasFiltersOrColumnsApplied =
		!_isEmpty(resource.filters?.items) || !_isEmpty(resource.columns)

	const handleFilterChange = (filters: GridFilterModel) => {
		setValue('resource.filters', filters, { shouldDirty: true })
	}

	const handleColumnFiltersChange = (columns: GridColumnVisibilityModel) => {
		setValue('resource.columns', columns, { shouldDirty: true })
	}

	if (!databaseTable) {
		return null
	}

	return (
		<>
			<Modal onClose={() => setShowTableModal(false)} open={showTableModal}>
				<Box width="80vw" minHeight={200}>
					<Box display="flex" justifyContent="flex-end" mb={1}>
						<Alert severity="info">
							Remember, the filters and columns you apply here will only be saved when you save the
							task
						</Alert>
					</Box>
					<Box height={700}>
						<TableProvider tableId={databaseTable}>
							<CoreTable
								filters={resource.filters}
								columnFilters={resource.columns}
								onFilterChange={handleFilterChange}
								onColumnFiltersChange={handleColumnFiltersChange}
								isStatic
								isLocked
							/>
						</TableProvider>
					</Box>
				</Box>
			</Modal>
			<FormCard title="Resources" mb={2}>
				<Box>
					<Button variant="outlined" onClick={() => setShowTableModal(true)}>
						Set filters and columns
					</Button>
					{hasFiltersOrColumnsApplied && (
						<Alert severity="info" sx={{ mt: 1 }}>
							Filters applied
						</Alert>
					)}
				</Box>
			</FormCard>
		</>
	)
}

export const ResourceFields = React.memo(UndecoratedResourceFields)
