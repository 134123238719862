import { ComponentType, useContext, useState } from 'react'
import { TablesSdk } from '@cango-app/sdk'

import { Box, Button, Divider, Text } from 'src/components'
import { TableContext, TableProvider } from 'src/providers/table-provider'
import { ChevronDown } from 'src/assets/icons'

import { TableMenu } from './table-menu'
import { CoreTable } from './core-table'

const TablesContent: ComponentType<{
	numberOfTables: number
	isMenuCollapsed: boolean
	onMenuToggleClick: () => void
}> = ({ numberOfTables, isMenuCollapsed, onMenuToggleClick }) => {
	const { table, isLoadingTable } = useContext(TableContext)

	if (table?._id || !!isLoadingTable) {
		return (
			<Box
				width={`calc(100% - ${!isMenuCollapsed ? '316px' : '16px'})`}
				height="calc(100vh - 150px)"
				pt={1}
				pl={isMenuCollapsed ? 2 : 0}>
				<Button
					variant="text"
					size="small"
					startIcon={
						<ChevronDown style={{ transform: `rotate(${isMenuCollapsed ? '270' : '90'}deg)` }} />
					}
					sx={{ minWidth: 75, mb: 1 }}
					onClick={onMenuToggleClick}>
					{isMenuCollapsed ? 'Show ' : 'Hide '}menu
				</Button>
				<CoreTable rowReordering />
			</Box>
		)
	}

	return (
		<Box display="flex" flexDirection="column" alignItems="center" mt={10} flex={1}>
			<Text variant="h4" color={'grey'}>
				{numberOfTables > 0 ? 'Select a table' : 'Create a table to get started'}
			</Text>
		</Box>
	)
}

export const TablesContainer: ComponentType = () => {
	const [tableList, setTableList] = useState<TablesSdk.MenuTable[]>([])
	const [isMenuCollapsed, setIsMenuCollapsed] = useState(false)
	return (
		<TableProvider>
			<Box display="flex" mr={2} flex={1}>
				{!isMenuCollapsed && <TableMenu tableList={tableList} setTableList={setTableList} />}
				{!isMenuCollapsed && (
					<Divider orientation="vertical" flexItem sx={{ borderRightWidth: 2, mr: 2 }} />
				)}
				<TablesContent
					numberOfTables={tableList.length}
					isMenuCollapsed={isMenuCollapsed}
					onMenuToggleClick={() => {
						if (!isMenuCollapsed) {
							setTableList([])
						}
						setIsMenuCollapsed(!isMenuCollapsed)
					}}
				/>
			</Box>
		</TableProvider>
	)
}

export default TablesContainer
