import { V3ProjectSdk } from '@cango-app/sdk'
import { ComponentType, useCallback, useContext, useEffect, useState } from 'react'
import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import { useDispatch, useSelector } from 'react-redux'
import PulseLoader from 'react-spinners/PulseLoader'

import { Box, DriveUpload } from 'src/components'
import { DriveFilesContext } from 'src/providers'
import { Project, actions as myTasksActions } from 'src/store/modules/my-tasks-v3'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { errorHandler } from 'src/helpers/api'

import { TaskFiles } from './task-files'

type TemplateProject = Pick<Project, 'name' | 'googleDriveId'>

type FileTemplateProps = {
	task: Pick<V3ClientTypes.Project.Task, '_id' | 'name' | 'project_id' | 'section_id' | 'actions'>
	project?: TemplateProject
	onAddFilesToTask?: (data: {
		fileIds: string[]
		taskId: string
		dependencyId: string
		projectId: string
		actionIndex: number
	}) => Promise<void>
	action: V3ClientTypes.Project.TaskAction
}

export const FileTemplate: ComponentType<FileTemplateProps> = ({
	task,
	project: _project,
	onAddFilesToTask,
	action,
}) => {
	const dispatch = useDispatch()
	const { parentFolderId } = useContext(DriveFilesContext)
	const [isProjectLoading, setIsProjectLoading] = useState(false)
	const [project, setProject] = useState<TemplateProject | undefined>(_project)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const fileTemplateIndex = task.actions.findIndex(
		(_task) => _task.type === V3BlueprintTypes.ActionEnum.FileTemplate,
	)

	const fetchProject = useCallback(async () => {
		if (!task.project_id) return
		try {
			setIsProjectLoading(true)
			const response = await V3ProjectSdk.getProject({
				baseURL: import.meta.env.VITE_API as string,
				authHeaders,
				projectId: task.project_id,
				params: {
					withSectionOrder: 'true',
				},
			})

			setProject({ name: response.name, googleDriveId: response.googleDriveId })
		} catch (error) {
			errorHandler({ error, dispatch })
		} finally {
			setIsProjectLoading(false)
		}
	}, [task?.project_id])

	const handleChangeFileIds = async (fileIds: string[]) => {
		if (onAddFilesToTask) {
			await onAddFilesToTask({
				fileIds,
				taskId: task._id,
				dependencyId: task._id,
				projectId: task.project_id,
				actionIndex: fileTemplateIndex,
			})
			return
		}

		if (!task.section_id) return
		await dispatch(
			myTasksActions.addFileIdsToTask({
				taskId: task._id,
				fileIds,
				referencedTaskId: task._id,
				sectionId: task.section_id,
				projectId: task.project_id,
				actionIndex: fileTemplateIndex,
			}),
		)
	}

	useEffect(() => {
		if (!_project) {
			fetchProject()
		}
	}, [])

	if (isProjectLoading) {
		return (
			<Box>
				<PulseLoader size={4} />
			</Box>
		)
	}

	if (!parentFolderId || !project) {
		return null
	}

	return (
		<Box>
			<TaskFiles action={action} />
			<DriveUpload
				fileIds={task.actions[fileTemplateIndex].file_ids}
				parentFolderId={parentFolderId}
				parentFolderName={project.name}
				onChange={handleChangeFileIds}
				ctaVariant={task.actions[fileTemplateIndex].file_ids.length ? 'replaceIcon' : 'button'}
			/>
		</Box>
	)
}
