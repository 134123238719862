import React from 'react'
import Stack from '@mui/material/Stack'
import { TableTypes } from '@cango-app/types'

import { BidItemData, PricingTableMap } from '../types'
import { Box, Text } from '../../../../components'
import { colors } from '../../../../theme/colors'
import { formatCurrency } from '../format-number'
import {
	getEquipmentContentsTotalCost,
	getLaborContentsTotalCost,
	getMaterialContentsTotalCost,
} from '../total-costs'

type Props = {
	bidItem: BidItemData | null
	laborPricing: PricingTableMap
	materialPricing: PricingTableMap
	equipmentPricing: PricingTableMap
}
export function BidItemContentsOverviewColumn({
	bidItem,
	laborPricing,
	materialPricing,
	equipmentPricing,
}: Props) {
	const laborCost = getLaborContentsTotalCost(bidItem?.laborContents, laborPricing)
	const equipmentCost = getEquipmentContentsTotalCost(bidItem?.equipmentContents, equipmentPricing)
	const materialCost = getMaterialContentsTotalCost(bidItem?.materialContents, materialPricing)

	return (
		<Stack px={4} direction="column" spacing={{ md: 4 }} useFlexGap>
			<Box>
				<Text variant="h5">Bid Item contents</Text>
			</Box>
			<Box mt={2}>
				<Text variant="h6">Labor</Text>
				<Stack direction="column" mt={2} spacing={{ md: 1 }} minHeight="100px" useFlexGap>
					{bidItem?.laborContents.map((row) => (
						<Box
							key={row._id}
							display="flex"
							flexDirection="row"
							alignItems="center"
							justifyContent="space-between"
						>
							<Text width="40px">{row.quantity}</Text>
							<Text flex={1}>{laborPricing[row._id]?.[TableTypes.PricingTableFieldId.NAME]}</Text>
							<Text minWidth="55px" textAlign="right">
								{formatCurrency(laborPricing[row._id]?.[TableTypes.PricingTableFieldId.PRICE])}
							</Text>
						</Box>
					))}
					<Box
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="flex-end"
						color={colors.neutral[60]}
					>
						<Text>Total:</Text>
						<Text ml={1}>{formatCurrency(laborCost.totalCostWithOH)}</Text>
					</Box>
				</Stack>
			</Box>
			<Box>
				<Text variant="h6">Material</Text>
				<Stack
					width="100%"
					direction="column"
					mt={2}
					spacing={{ md: 1 }}
					minHeight="100px"
					useFlexGap
				>
					{bidItem?.materialContents.map((row) => (
						<Box
							key={row._id}
							display="flex"
							flexDirection="row"
							alignItems="center"
							justifyContent="space-between"
						>
							<Text width="40px">{row.quantity}</Text>
							<Text flex={1}>
								{materialPricing[row._id]?.[TableTypes.PricingTableFieldId.NAME]}
							</Text>
							<Text minWidth="55px" textAlign="right">
								{formatCurrency(materialPricing[row._id]?.[TableTypes.PricingTableFieldId.PRICE])}
							</Text>
						</Box>
					))}
					<Box
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="flex-end"
						color={colors.neutral[60]}
					>
						<Text>Total:</Text>
						<Text ml={1}>{formatCurrency(materialCost.totalCostWithOH)}</Text>
					</Box>
				</Stack>
			</Box>
			<Box>
				<Text variant="h6">Equipment</Text>
				<Stack direction="column" mt={2} spacing={{ md: 1 }} minHeight="100px" useFlexGap>
					{bidItem?.equipmentContents.map((row) => (
						<Box
							key={row._id}
							display="flex"
							flexDirection="row"
							alignItems="center"
							justifyContent="space-between"
						>
							<Text width="40px">{row.quantity}</Text>
							<Text flex={1}>
								{equipmentPricing[row._id]?.[TableTypes.PricingTableFieldId.NAME]}
							</Text>
							<Box mx={3} justifyContent="space-between" display="flex" width="60px">
								<Text display="block">at</Text>
								<Text display="block">{row.usage * 100}%</Text>
							</Box>
							<Text minWidth="55px" textAlign="right">
								{formatCurrency(equipmentPricing[row._id]?.[TableTypes.PricingTableFieldId.PRICE])}
							</Text>
						</Box>
					))}
					<Box
						display="flex"
						flexDirection="row"
						alignItems="center"
						justifyContent="flex-end"
						color={colors.neutral[60]}
					>
						<Text>Total:</Text>
						<Text ml={1}>{formatCurrency(equipmentCost.totalCostWithOH)}</Text>
					</Box>
				</Stack>
			</Box>
		</Stack>
	)
}
