import {
	GridColDef,
	GridActionsCellItem,
	GridRowId,
	GridFilterModel,
	GridColumnVisibilityModel,
	DataGridPro,
	useGridApiRef,
	DataGridProProps,
	GridColumnHeaderParams,
	GridValueFormatterParams,
	GridCellParams,
	GridValidRowModel,
	GridRowModelUpdate,
	GridRowParams,
} from '@mui/x-data-grid-pro'
import LockIcon from '@mui/icons-material/Lock'
import FunctionsIcon from '@mui/icons-material/Functions'
import React, { ComponentType, useContext, useMemo, useState } from 'react'
import RestoreIcon from '@mui/icons-material/Restore'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { useSelector } from 'react-redux'
import { ClientTypes, TableTypes } from '@cango-app/types'
import dayjs from 'dayjs'
import LinearProgress from '@mui/material/LinearProgress'
import PulseLoader from 'react-spinners/PulseLoader'
import { v4 } from 'uuid'
import _isEmpty from 'lodash/isEmpty'

import { dataGridTables } from 'src/helpers/ui'
import { selectors as contactSelectors } from 'src/store/modules/contacts'
import { Box, Text } from 'src/components'
import { TableContext } from 'src/providers/table-provider'
import { ContactForSelect } from 'src/store/modules/contacts/selectors'

import { getFieldType } from './mui-formatter'
import { CustomColumnMenu } from './column-menu'
import { resolveAnyRowCalculations } from './utils'
import { CustomToolbar } from './toolbar'
import { CustomFooter } from './footer'
import { ProductionItemsContentsModal } from './contents-modal'

type CoreTableProps = {
	rowReordering?: boolean
	hideFooter?: boolean
	filters?: GridFilterModel
	columnFilters?: GridColumnVisibilityModel
	onFilterChange?: (filters: GridFilterModel) => void
	onColumnFiltersChange?: (columns: GridColumnVisibilityModel) => void
	isStatic?: boolean
	onCellClick?: (data: { columnId: string; rowId: string; cellId: string }) => void
	selectedCell?: string
	isLocked?: boolean
}

const renderTableHeader = (params: GridColumnHeaderParams, field: ClientTypes.Table.Field) => (
	<>
		{field.locked ? <LockIcon sx={{ marginRight: 1 }} /> : null}
		{field.type === TableTypes.FieldType.CALCULATION ? (
			<FunctionsIcon sx={{ marginRight: 1 }} />
		) : null}
		<b>{params.colDef.headerName}</b>
	</>
)

export const CoreTable: ComponentType<CoreTableProps> = ({
	filters,
	columnFilters,
	onFilterChange,
	rowReordering = false,
	hideFooter = false,
	onColumnFiltersChange,
	isStatic,
	onCellClick,
	selectedCell,
	isLocked,
}) => {
	const apiRef = useGridApiRef()
	const {
		table,
		cacheRowUpdate,
		unsavedChanges,
		discardChange,
		deleteRow,
		isUpdatingTable,
		onColumnOrderChange,
		onRowOrderChange,
		isLoadingTable,
		cacheNewRow,
		mappedRows,
	} = useContext(TableContext)
	const contacts = useSelector(contactSelectors.getContactsForSelect)
	const mappedContacts = useSelector(contactSelectors.mappedContacts)
	const [defaultContentsId, setDefaultContentsId] = useState<string>()

	const columns = useMemo(() => {
		if (!table?.fields) return []
		return table.fields.map((field): GridColDef => {
			const columnType = getFieldType(field.type)

			return {
				field: field._id,
				headerName: field.name,
				maxWidth: 400,
				minWidth: 100,
				renderHeader: (params: GridColumnHeaderParams) => renderTableHeader(params, field),
				sortable: false,
				type: columnType,
				...(!isStatic &&
					[TableTypes.FieldType.SINGLE_SELECT, TableTypes.FieldType.TABLE_SELECT].includes(
						field.type,
					) && {
						valueOptions: field.valueOptions,
					}),
				valueFormatter: (params: GridValueFormatterParams) => {
					if (field.type === TableTypes.FieldType.DATE) {
						return dayjs(params.value).format('MMM DD, YYYY')
					}

					if (field.isCurrency) {
						return params.value ? '$' + Number(params.value).toFixed(2) : null
					}

					if (field.type === TableTypes.FieldType.CONTACT) {
						const contact = mappedContacts.get(params.value)
						if (!contact) return params.value
						return `${contact?.name} ${contact?.surname}`
					}

					return params.value
				},
				...(!isStatic &&
					field.type === TableTypes.FieldType.CONTACT && {
						valueOptions: contacts,
						getOptionLabel: (option: ContactForSelect) => option.label,
						getOptionValue: (option: ContactForSelect) => option._id,
					}),
				editable: !isLocked && !field.locked && field.type !== TableTypes.FieldType.CALCULATION,
				...(field.type === TableTypes.FieldType.RESOURCES && {
					getActions: (params: GridRowParams) => [
						<GridActionsCellItem
							key={`${params.id}-resources`}
							icon={<Text p={1}>View</Text>}
							label="Resources"
							onClick={() => {
								const resouceColumnId = table.fields.find(
									(_field) => _field.type === TableTypes.FieldType.RESOURCES,
								)?._id
								if (!resouceColumnId) return
								setDefaultContentsId(params.id as string)
							}}
						/>,
					],
				}),
			}
		})
	}, [table?.fields, contacts])

	const rows = useMemo(() => {
		if (!table?.records) return []
		return table.records.map((row) => {
			return resolveAnyRowCalculations(table.fields, row)
		})
	}, [table?.records, table?.fields])

	const processRowUpdate: NonNullable<DataGridProProps['processRowUpdate']> = (newRow, oldRow) => {
		const cachedValue = cacheRowUpdate(newRow, oldRow)
		return cachedValue
	}

	const processNewRow = (newRow: GridValidRowModel) => {
		const cachedValue = cacheNewRow(newRow)
		return cachedValue
	}

	const handlePaste = async (event: React.ClipboardEvent) => {
		event.preventDefault()

		const pastedText = event.clipboardData.getData('text')
		const pastedRows = pastedText.split(/\r\n|\r|\n/)
		const rowsByCell = pastedRows.map((row: string) => row.split('\t'))

		const eventTarget = event.target as HTMLElement

		const columnId = eventTarget.closest('div[data-field]')?.getAttribute('data-field')
		const rowId = eventTarget.closest('div[data-rowindex]')?.getAttribute('data-id')
		apiRef.current.stopRowEditMode({ id: rowId as GridRowId, ignoreModifications: true })

		if (!columnId || !rowId) return
		const rowIndex = rows.findIndex((row) => row._id === rowId)
		const columnIndex = columns.findIndex((column) => column.field === columnId)
		if (rowIndex === -1 || columnIndex === -1) return

		const rowUpdates: GridRowModelUpdate[] = []
		rowsByCell.forEach((_row, distanceFromPastingRow) => {
			const updatedCells: GridValidRowModel = {}

			_row.forEach((_cell, distanceFromPastingColumn) => {
				const cellColumn = columns[columnIndex + distanceFromPastingColumn]
				if (!cellColumn) return
				let value: any = _cell
				if (cellColumn.type === 'number') {
					value = Number(_cell.replace(/[^0-9.-]+/g, ''))
				}
				updatedCells[cellColumn.field] = value
			})

			const dataGridRow = rows[rowIndex + distanceFromPastingRow]
			if (!dataGridRow) {
				const id = v4()
				const newRow = processNewRow({ id, _id: id, ...updatedCells })
				rowUpdates.push({ id: newRow._id, ...newRow })
				return
			}
			const rowCopy = { ...dataGridRow, ...updatedCells }
			const updatedRow = processRowUpdate(rowCopy, dataGridRow)
			rowUpdates.push({ id: updatedRow._id, ...updatedRow })
		})

		apiRef.current.updateRows(rowUpdates)
	}

	const columnsWithActions = useMemo<GridColDef[]>(() => {
		if (isStatic) return columns
		return [
			{
				field: 'Actions',
				type: 'actions',
				getActions: ({ id, row }) => {
					return [
						<GridActionsCellItem
							key={`${id}-restore`}
							icon={<RestoreIcon />}
							label="Discard changes"
							disabled={
								unsavedChanges.unsavedRows[id] === undefined &&
								unsavedChanges.newRows[id] === undefined
							}
							onClick={() => {
								const isNewRow = !!unsavedChanges.newRows[id]
								if (isNewRow) {
									apiRef.current.updateRows([{ id, _id: id, _action: 'delete' }])
								} else {
									apiRef.current.updateRows([{ ...unsavedChanges.rowsBeforeChange[id], id }])
								}

								discardChange(id as string)
							}}
						/>,
						<GridActionsCellItem
							key={`${id}-delete`}
							icon={<DeleteIcon />}
							label="Delete"
							disabled={!!unsavedChanges.newRows[id]}
							onClick={() => deleteRow(id as string, row)}
						/>,
					]
				},
			},
			...columns,
		]
	}, [columns, JSON.stringify(unsavedChanges)])

	if (isLoadingTable) {
		return (
			<Box display="flex" alignItems="center" justifyContent="center" mt={10}>
				<PulseLoader size={8} />
			</Box>
		)
	}

	if (!table) return null

	return (
		<>
			<ProductionItemsContentsModal
				onCloseModal={() => setDefaultContentsId(undefined)}
				row={mappedRows.get(defaultContentsId ?? '')}
			/>
			<Box width="100%" height="100%" onPaste={handlePaste}>
				<DataGridPro
					apiRef={apiRef}
					rows={rows}
					columns={columnsWithActions}
					getRowId={(row) => row._id}
					rowReordering={rowReordering}
					hideFooter={hideFooter}
					onRowOrderChange={onRowOrderChange}
					onColumnOrderChange={onColumnOrderChange}
					processRowUpdate={processRowUpdate}
					filterModel={filters}
					sx={dataGridTables}
					autosizeOnMount
					showCellVerticalBorder
					showColumnVerticalBorder
					unstable_ignoreValueFormatterDuringExport
					disableRowSelectionOnClick
					disableColumnMenu={isStatic}
					loading={isUpdatingTable}
					onCellClick={(params) => {
						if (onCellClick) {
							onCellClick({
								columnId: params.field,
								rowId: params.row._id,
								cellId: params.id as string,
							})
						}
					}}
					columnVisibilityModel={columnFilters}
					onColumnVisibilityModelChange={(columns) => {
						if (onColumnFiltersChange) {
							onColumnFiltersChange(columns)
						}
					}}
					onFilterModelChange={(newFilters) => {
						if (onFilterChange) {
							onFilterChange(newFilters)
						}
					}}
					getCellClassName={(params: GridCellParams) => {
						if (selectedCell) {
							const [columnId, rowId] = selectedCell.split('-')
							if (params.id === rowId && params.field === columnId) {
								return 'cell--selected'
							}
						}
						return ''
					}}
					getRowClassName={(params) => {
						const unsavedRow = unsavedChanges.unsavedRows[params.id]
						if (unsavedRow) {
							if (unsavedRow._action === 'delete') {
								return 'row--removed'
							}
							return 'row--edited'
						}
						const newRow = unsavedChanges.newRows[params.id]
						if (newRow) {
							return 'row--added'
						}
						return ''
					}}
					editMode={!isStatic ? 'row' : 'cell'}
					density="compact"
					slots={{
						toolbar: CustomToolbar,
						columnSortedDescendingIcon: null,
						columnSortedAscendingIcon: null,
						columnMenu: CustomColumnMenu,
						footer: !isStatic ? CustomFooter : undefined,
						loadingOverlay: LinearProgress,
					}}
					slotProps={{
						toolbar: {
							printOptions: { disableToolbarButton: true },
							csvOptions: { disableToolbarButton: true },
							apiRef,
							isStatic,
							isLocked,
						},
						baseCheckbox: {
							size: 'small',
						},
						columnMenu: {
							slotProps: {
								apiRef,
							},
						},
					}}
				/>
			</Box>
		</>
	)
}
