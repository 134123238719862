import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Drawer, Grid, Typography } from '@mui/material'

import {
	selectors as projectSelectors,
	actions as projectActions,
} from 'src/store/modules/projects-v3'
import { selectors as userSelectors } from 'src/store/modules/user'
import { selectors as contactSelectors } from 'src/store/modules/contacts'
import { selectors as rolesSelectors } from 'src/store/modules/roles'
import { Box, Select, ContactSelect, Text } from 'src/components'
import { AsyncDispatchType } from 'src/store/types'
import { ContactForm } from 'src/modules/contacts/contact-drawer/contact-form'

export const ProjectAssignments = () => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const project = useSelector(projectSelectors.getSelectedProject)
	const users = useSelector(userSelectors.getAllUsersForSelectWithUnassigned)
	const mappedRoles = useSelector(rolesSelectors.getMappedRoles)
	const mappedContacts = useSelector(contactSelectors.mappedContacts)
	const contactsWithChips = useSelector(contactSelectors.getContactsWithRoleChips)
	const [isSavingRole, setIsSavingRole] = useState(false)
	const [newContactForProjectRole, setNewContactForProjectRole] = useState<string>()

	const assignUserToProjectRole = async (roleId: string, userId: string) => {
		if (!project) return
		setIsSavingRole(true)
		await dispatch(projectActions.assignUserToRole({ userId, roleId, projectId: project._id }))
		setIsSavingRole(false)
	}

	const assignContactToProjectExternal = async (contactRole: string, contactId: string) => {
		if (!project) return
		setIsSavingRole(true)
		await dispatch(
			projectActions.assignContactToExternal({
				assignments: [{ contactId, roleId: contactRole }],
				projectId: project._id,
			}),
		)
		setIsSavingRole(false)
	}
	return (
		<>
			<Drawer
				anchor="right"
				open={!!newContactForProjectRole}
				onClose={() => setNewContactForProjectRole(undefined)}>
				{!!newContactForProjectRole && (
					<Box width={400} p={2} display="flex" flexDirection="column">
						<Text sx={{ mb: 2 }} variant="h4">
							Contact for {mappedRoles.get(newContactForProjectRole)?.label}
						</Text>
						<ContactForm
							closeDrawer={() => setNewContactForProjectRole(undefined)}
							setNewContactId={(newContactId) =>
								assignContactToProjectExternal(newContactForProjectRole, newContactId)
							}
						/>
					</Box>
				)}
			</Drawer>
			<Box
				p={2}
				display="flex"
				flexDirection="column"
				boxShadow="rgb(0 0 0 / 12%) 2px 4px 24px;"
				borderRadius="0 20px 20px 20px"
				margin={4}>
				<Text variant="h5" mb={2}>
					Assignments
				</Text>
				<Grid container columnSpacing={[4, 6]}>
					<Grid item xs={12} md={6}>
						<Typography variant="overline" fontWeight="bold">
							Internal user
						</Typography>
						<Box mt={2}>
							{!!project &&
								project.roles.map((assignment) => {
									const role = mappedRoles.get(assignment.role)
									const selectedUser = users.find((user) => user._id === assignment.user)
									if (!role) return null
									return project.archived.state ? (
										<Box mb={7}>
											<Typography
												variant="body2"
												mb={1}
												fontWeight={500}>{`Contact for ${role?.label}`}</Typography>
											<Typography variant="body1">
												{selectedUser ? selectedUser?.label : 'No assigned'}
											</Typography>
										</Box>
									) : (
										<Select
											key={assignment.role}
											label={`User for ${role?.label} role`}
											onChange={(event) =>
												assignUserToProjectRole(role?._id, event.target.value as string)
											}
											value={assignment.user}
											options={users}
											containerProps={{ mb: 3 }}
											disabled={!!isSavingRole || project.archived.state}
										/>
									)
								})}
						</Box>
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="overline" fontWeight="bold">
							External user
						</Typography>
						<Box mt={2}>
							{project?.externals.map((assignment) => {
								const role = mappedRoles.get(assignment.role)
								const contactAssignment = assignment.contact
								const selectedContact = mappedContacts.get(contactAssignment ?? '')

								const selectedContactHasRole =
									selectedContact?.roles.length &&
									selectedContact.roles.some((role) => role === assignment.role)
								return project.archived.state ? (
									<Box mb={7}>
										<Typography
											variant="body2"
											mb={1}
											fontWeight={500}>{`Contact for ${role?.label}`}</Typography>
										<Typography variant="body1">
											{contactAssignment ? contactAssignment : 'No assigned'}
										</Typography>
									</Box>
								) : (
									<ContactSelect
										key={assignment.role}
										label={`Contact for ${role?.label}`}
										onChange={(event) =>
											assignContactToProjectExternal(assignment.role, event.target.value as string)
										}
										helperText={
											selectedContact && !selectedContactHasRole
												? `Warning: ${selectedContact?.name} ${selectedContact?.surname} is not a ${
														mappedRoles.get(assignment.role)?.label
												  }`
												: ''
										}
										helperError
										value={contactAssignment ?? ''}
										renderValue={() => {
											return `${selectedContact?.name} ${selectedContact?.surname}`
										}}
										options={contactsWithChips}
										containerProps={{ mb: 3 }}
										onNewContactClick={() => setNewContactForProjectRole(assignment.role)}
										disabled={!!isSavingRole || project.archived.state}
									/>
								)
							})}
						</Box>
					</Grid>
				</Grid>
			</Box>
		</>
	)
}
