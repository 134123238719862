import React, { useState } from 'react'
import { TableTypes, V3ClientTypes } from '@cango-app/types'
import CircularProgress from '@mui/material/CircularProgress'
import { DataGridPro, GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid-pro'
import Stack from '@mui/material/Stack'
import EditIcon from '@mui/icons-material/Edit'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/DeleteOutlined'
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import dayjs from 'dayjs'

import { dataGridStandard } from '../../../helpers/ui'
import { Box, Button, Text } from '../../../components'

import { ProductionItem } from './types'

const columnsDef: GridColDef[] = [
	{ field: '_id', headerName: 'ID', width: 90 },
	{
		field: 'quantity',
		headerName: 'Quantity',
		type: 'number',
		width: 110,
	},
	{
		field: 'hours',
		headerName: 'Hours',
		type: 'number',
		width: 110,
	},
	{
		field: 'proposalGroup',
		headerName: 'Proposal Group',
		type: 'string',
		width: 160,
	},
	{
		field: 'productionItem',
		headerName: 'Production Item',
		type: 'string',
		width: 160,
	},
	{
		field: 'unitOfMeasurement',
		headerName: 'uom',
		type: 'string',
		width: 110,
	},
	{
		field: 'created_at',
		headerName: 'Created',
		type: 'datetime',
		valueGetter: (params) => params.value,
		valueFormatter: (params) => dayjs.unix(params.value).format('DD MMM YYYY HH:mm'),
	},
	{
		field: 'updated_at',
		headerName: 'Updated',
		type: 'datetime',
		valueGetter: (params) => params.value,
		valueFormatter: (params) => dayjs.unix(params.value).format('DD MMM YYYY HH:mm'),
	},
]

type Props = {
	loading: boolean
	onEditBid: () => void
	onAddBidItem: () => void
	bid: V3ClientTypes.Project.Bid | null
	productionItems: ProductionItem[]
	bidItems: V3ClientTypes.Project.BidItem[]
	onRowClick?: (row: V3ClientTypes.Project.BidItem) => void
	onDeleteBidItem: (id: string) => void
}

export function BidItemsTable(props: Props) {
	const { loading, bid, bidItems, onRowClick, onEditBid, onAddBidItem, onDeleteBidItem } = props
	const [itemToDelete, setItemToDelete] = useState<string | null>(null)

	if (bid == null) {
		return null
	}

	const rows = bidItems.map((item) => {
		const productionItem = props.productionItems.find((pi) => pi._id === item.productionItemId)

		return {
			...item,
			proposalGroup: productionItem?.proposalGroup,
			productionItem: productionItem?.[TableTypes.ProductionFieldId.NAME],
			unitOfMeasurement: productionItem?.unitOfMeasurement,
		}
	})

	const columns = [
		...columnsDef,
		{
			field: 'Actions',
			type: 'actions',
			getActions: ({ row }: GridRowParams) => {
				return [
					<GridActionsCellItem
						key={`${row._id}-delete`}
						icon={<DeleteIcon />}
						label="Delete"
						onClick={() => setItemToDelete(row._id)}
					/>,
				]
			},
		},
	]

	return (
		<Box px={4}>
			<Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
				<Box display="flex" alignItems="center">
					<Text variant="h5">Bid {bid._id.substring(0, 6)}</Text>
					{loading && <CircularProgress size={20} thickness={5} sx={{ ml: 2 }} />}
				</Box>
				<Stack direction="row" spacing={{ md: 1 }} useFlexGap>
					<Button
						variant="text"
						color="primary"
						size="medium"
						sx={{ minWidth: 'auto' }}
						onClick={() => onEditBid()}
					>
						Edit bid
						<EditIcon fontSize="inherit" sx={{ ml: 1 }} />
					</Button>
					<Button
						variant="text"
						color="primary"
						size="medium"
						sx={{ minWidth: 'auto' }}
						onClick={() => onAddBidItem()}
					>
						Add bid item
						<AddIcon fontSize="inherit" sx={{ ml: 1 }} />
					</Button>
				</Stack>
			</Box>
			<DataGridPro
				columns={columns}
				rows={rows}
				sx={{
					...dataGridStandard,
					borderRadius: '0 15px 15px 15px',
				}}
				getRowId={(row) => row._id}
				disableRowSelectionOnClick
				hideFooter
				disableColumnMenu
				disableColumnResize
				autosizeOnMount
				getRowHeight={() => 'auto'}
				autoHeight
				onRowClick={(params) => onRowClick?.(params.row as V3ClientTypes.Project.BidItem)}
			/>
			<Dialog
				open={itemToDelete != null}
				onClose={() => setItemToDelete(null)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Delete Bid Item {itemToDelete}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure you want to delete this bid item? This action cannot be undone.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						color="neutral"
						onClick={() => {
							setItemToDelete(null)
						}}
					>
						Cancel
					</Button>
					<Button
						color="error"
						onClick={() => {
							if (typeof itemToDelete !== 'string') {
								return
							}
							onDeleteBidItem(itemToDelete)
							setItemToDelete(null)
						}}
						autoFocus
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	)
}
