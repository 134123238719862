import React, { ComponentType, useEffect, useState } from 'react'
import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import { useDispatch, useSelector } from 'react-redux'
import { Action, ThunkDispatch } from '@reduxjs/toolkit'
import { Control, Controller, FormProvider, useForm } from 'react-hook-form'
import { useUpdateEffect } from 'usehooks-ts'
import { V3BlueprintSdk } from '@cango-app/sdk'

import { Box, Drawer, TextField, Text, Button, Select } from 'src/components'
import {
	selectors as blueprintSelectors,
	actions as blueprintActions,
	V3BlueprintStep,
} from 'src/store/modules/blueprints-v3'
import { selectors as roleSelectors } from 'src/store/modules/roles'
import type { RootState } from 'src/store/types'
import { parentOrSectionOptions } from 'src/store/modules/blueprints-v3/selectors'

import { StepFormType } from '../step-drawer/step-form/step-form-container'
import { DependencyFields } from '../step-drawer/step-form/dependency-fields'

type SectionDrawerProps = {
	open: boolean
	onClose: () => void
}

export type SectionAssignationType = {
	assigned: boolean
	assignee: string
	role: string
}

export type SectionFormControl = Control<StepFormType>

const getDefaultValues = (section?: V3BlueprintStep): Partial<StepFormType> => {
	return {
		...(section ?? {}),
		children: section?.children ?? (parentOrSectionOptions as V3ClientTypes.Blueprint.Child[]),
		role: section?.role || '',
		isMultiUse: section?.isMultiUse ?? false,
		isMenu: section?.isMenu ?? false,
		isParent: section?.isParent ?? false,
		createForEveryOption: section?.createForEveryOption ?? false,
		section_id: undefined,
		parents: section?.parents ?? [],
		requiresEveryParent: section?.requiresEveryParent ?? false,
		training: section?.training ?? [],
		actions: section?.actions
			? Object.keys(section?.actions).reduce(
					(_acc: Record<string, V3BlueprintSdk.UnpopulatedStepAction[]>, key) => {
						const chainActions = section?.actions[key]
						_acc[key] = chainActions.map((_action) => ({
							..._action,
						}))
						return _acc
					},
					{},
				)
			: {},
		when: {
			...(section?.when ?? {}),
			type: section?.when?.type || V3BlueprintTypes.WhenEnum.ASAP,
			recurrence: {
				...(section?.when?.recurrence ?? {}),
				frequency: section?.when?.recurrence?.frequency || 1,
				interval:
					section?.when?.recurrence?.interval || V3BlueprintTypes.RecurrenceIntervalEnum.Day,
				weekdays: section?.when?.recurrence?.weekdays || [],
				monthday: section?.when?.recurrence?.monthday || 1,
				defined_in_blueprint: section?.when?.recurrence?.defined_in_blueprint || false,
			},
		},
	}
}

const SectionDrawer: ComponentType<SectionDrawerProps> = ({ open, onClose }) => {
	const dispatch = useDispatch<ThunkDispatch<RootState, void, Action>>()
	const [isSaving, setIsSaving] = useState(false)
	const [isDeletingSection, setIsDeletingSection] = useState(false)
	const roles = useSelector(roleSelectors.getRoles).filter((role) => role.internal)
	const section = useSelector(blueprintSelectors.getSelectedSection)
	const blueprintId = useSelector(blueprintSelectors.getSelectedBlueprintId)
	const dependantSteps = useSelector((state: RootState) =>
		blueprintSelectors.getStepDescendants(state, section?._id),
	)
	const { stepOptions } = useSelector((state: RootState) =>
		blueprintSelectors.getBlueprintSelectDependancyOptions(state, true),
	)
	const methods = useForm<StepFormType>({
		defaultValues: getDefaultValues(section),
	})
	const handleCloseDrawer = () => {
		onClose()
	}

	const { control, handleSubmit, reset } = methods

	const handleSaveSection = async (data: StepFormType) => {
		if (!blueprintId) return
		setIsSaving(true)

		if (section?._id) {
			await dispatch(
				blueprintActions.updateStep({
					...data,
					_id: section._id,
					blueprint_id: blueprintId,
				}),
			)
		} else {
			await dispatch(
				blueprintActions.createNewStep({
					...data,
					actions: {},
					isSection: true,
					blueprint_id: blueprintId,
				}),
			)
		}
		setIsSaving(false)
		handleCloseDrawer()
	}

	const handleDeleteBlueprintSection = async () => {
		if (!blueprintId) return
		const confirmed = confirm(`Are you sure you want to delete ${name}?`)
		if (confirmed && section?._id) {
			setIsDeletingSection(true)
			await dispatch(blueprintActions.deleteStep({ blueprintId, stepId: section._id }))
			setIsDeletingSection(false)
		}
	}

	useEffect(() => {
		if (section) {
			reset(getDefaultValues(section))
		}
	}, [section])

	useUpdateEffect(() => {
		if (!open) {
			reset({
				name: '',
				role: '',
				parents: [],
			})
		}
	}, [open])

	return (
		<Drawer open={open} onClose={handleCloseDrawer}>
			<FormProvider {...methods}>
				<Box width={500} p={3} role="presentation">
					<Text variant="h4" sx={{ mb: 3 }}>
						{section?.name || 'New section'}
					</Text>
					<Controller
						name="name"
						rules={{ required: 'Please enter a section name' }}
						control={control}
						render={({ field: { value, onChange }, fieldState: { error } }) => (
							<TextField
								label="Section name"
								fullWidth
								value={value}
								onChange={(event) => onChange(event.target.value)}
								required
								sx={{ mb: 3 }}
								error={!!error}
								helperText={error?.message}
							/>
						)}
					/>
					<Controller
						name="role"
						control={control}
						rules={{ required: 'Please select a role' }}
						render={({ field: { value, onChange }, fieldState: { error } }) => (
							<Select
								label="Select a role"
								onChange={(event) => onChange(event.target.value as string)}
								value={value}
								options={roles}
								containerProps={{ mb: 3 }}
								error={!!error}
								helperText={error?.message}
							/>
						)}
					/>

					{!!stepOptions.length && <DependencyFields control={control} shouldShowSections />}
					<Box display="flex">
						<Button
							variant="contained"
							sx={{ mr: 2 }}
							onClick={handleSubmit(handleSaveSection)}
							isLoading={isSaving}
						>
							Save
						</Button>
						<Button
							disabled={!!dependantSteps.length}
							color="error"
							onClick={handleDeleteBlueprintSection}
							isLoading={isDeletingSection}
						>
							{dependantSteps.length ? 'Section has dependant steps' : 'Delete'}
						</Button>
					</Box>
					{!!dependantSteps.length &&
						dependantSteps.map((dependency) => (
							<Box key={dependency._id} mt={2}>
								<Box display="flex">
									<Text fontSize={14} mr={1}>
										Step name:
									</Text>
									<Text fontSize={14} fontWeight="bold">
										{dependency.name}
									</Text>
								</Box>
							</Box>
						))}
				</Box>
			</FormProvider>
		</Drawer>
	)
}

export default SectionDrawer
