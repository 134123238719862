import React, { ComponentType, useState } from 'react'
import IconButton from '@mui/material/IconButton'
import CloseOutlined from '@mui/icons-material/CloseOutlined'
import { useDispatch, useSelector } from 'react-redux'
import { Action, ThunkDispatch } from '@reduxjs/toolkit'

import { Box, Button, Text } from 'src/components'
import type { RootState } from 'src/store/types'
import {
	actions as blueprintActions,
	selectors as blueprintSelectors,
} from 'src/store/modules/blueprints-v3'

type DeleteStepProps = {
	onCloseDrawer: () => void
	sectionId?: string
}

export const DeleteStep: ComponentType<DeleteStepProps> = ({ onCloseDrawer }) => {
	const dispatch = useDispatch<ThunkDispatch<RootState, void, Action>>()
	const blueprintId = useSelector(blueprintSelectors.getSelectedBlueprintId)
	const selectedStep = useSelector(blueprintSelectors.getSelectedStep)
	const stepDescendants = useSelector((state: RootState) =>
		blueprintSelectors.getStepDescendants(state, selectedStep?._id),
	)
	const [isDeleting, setIsDeleting] = useState(false)

	const handleDeleteStep = async () => {
		if (!blueprintId || !selectedStep) return
		setIsDeleting(true)

		const response = await dispatch(
			blueprintActions.deleteStep({
				blueprintId,
				stepId: selectedStep?._id,
			}),
		)
		setIsDeleting(false)
		if (response.meta.requestStatus === 'fulfilled') {
			onCloseDrawer()
		}
	}

	return (
		<>
			<Box m={1}>
				<IconButton onClick={onCloseDrawer}>
					<CloseOutlined />
				</IconButton>
			</Box>
			<Box width={400} p={3}>
				<Text variant="h6" mb={3}>
					Are you sure you want to delete: <b>{selectedStep?.name}</b>
				</Text>
				<Box mb={2}>
					<Button
						color="error"
						variant="contained"
						onClick={() => handleDeleteStep()}
						disabled={!!stepDescendants.length}
						isLoading={isDeleting}>
						{stepDescendants.length ? 'This step has a dependency' : 'Delete'}
					</Button>
					{!!stepDescendants.length &&
						stepDescendants.map((descendant) => (
							<Box key={descendant._id} mt={2}>
								<Box display="flex">
									<Text fontSize={14} mr={1}>
										Step name:
									</Text>
									<Text fontSize={14} fontWeight="bold">
										{descendant.name}
									</Text>
								</Box>
							</Box>
						))}
				</Box>
			</Box>
		</>
	)
}
