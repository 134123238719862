/**
 * Blueprint Builder-Manager
 *
 * @returns JSX Element
 */

import React, { ComponentType } from 'react'
import { useSelector } from 'react-redux'

import { RenderFor } from 'src/components/utils/Permissions'
import { selectors as blueprintSelectors } from 'src/store/modules/blueprints-v3'
import { Box, Grid, Text } from 'src/components'

import { SectionsConfiguration } from './sections-configuration'
import BlueprintOps from './blueprint-ops'
import BlueprintOrphanedSteps from './section-tasks/orphaned-steps'
import SectionTasks from './section-tasks'

type BlueprintComponentType = {
	blueprintId: string
	blueprintName: string
	isBlueprintPublished: boolean
	relatedProjects?: { name: string; _id: string }[]
}

export const BlueprintComponent: ComponentType<BlueprintComponentType> = ({
	blueprintId,
	blueprintName,
	isBlueprintPublished,
	relatedProjects,
}) => {
	const blueprintSections = useSelector(blueprintSelectors.getSectionsWithDescendants)
	const beginsWithSteps = useSelector(blueprintSelectors.getBeginsWithSteps)
	return (
		<Box>
			<Grid container>
				<Grid item xs={2} display="flex" alignItems="center"></Grid>
				<Grid xs={8} item>
					<Text variant="h3" my={3} textAlign="center">
						{blueprintName}
					</Text>
				</Grid>
				<Grid xs={2} item>
					<RenderFor permissions={['consultant', 'admin']}>
						<BlueprintOps
							blueprintId={blueprintId}
							isPublished={isBlueprintPublished}
							blueprintName={blueprintName}
							relatedProjects={relatedProjects}
						/>
					</RenderFor>
				</Grid>
			</Grid>
			<SectionsConfiguration />

			<Box mx={4} mt={4}>
				<BlueprintOrphanedSteps />
			</Box>

			<Box mx={4} mt={4}>
				<SectionTasks sectionId={blueprintId} steps={beginsWithSteps} />
			</Box>

			{!!blueprintSections.length && (
				<Box mx={4} mt={4}>
					{blueprintSections.map((section) => (
						<Box key={section._id} id={section._id}>
							<SectionTasks sectionId={section._id} steps={section.steps} canOpenSectionDrawer />
						</Box>
					))}
				</Box>
			)}
		</Box>
	)
}
