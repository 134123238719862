/**
 * Blueprint Builder-Manager
 *
 * @returns ComponentType
 */

import React, { ComponentType, useEffect, useMemo, useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Action, ThunkDispatch } from '@reduxjs/toolkit'
import { useEffectOnce } from 'usehooks-ts'

import { RouteId } from 'src/constants/routes'
import {
	selectors as blueprintSelectors,
	actions as blueprintActions,
} from 'src/store/modules/blueprints-v3'
import { actions as authActions } from 'src/store/modules/auth'
import type { RootState } from 'src/store/types'
import { LoadingDocument } from 'src/components'
import { SwitchOrgState, selectors as configSelectors } from 'src/store/modules/config'
import { DriveFilesProvider } from 'src/providers'

import { BlueprintComponent } from './blueprint-component'
import 'src/assets/css/Task.css'

export const BlueprintContainer: ComponentType = () => {
	const dispatch = useDispatch<ThunkDispatch<RootState, void, Action>>()
	const [searchParams] = useSearchParams()
	const { blueprintId: id } = useParams<{
		blueprintId: string
	}>()
	const orgId = searchParams.get('orgId')
	const stepIdFromQS = searchParams.get('step')
	const organisationId = useSelector(configSelectors.getOrganisationId)
	const [switchOrgState, setSwitchOrgState] = useState(SwitchOrgState.NotSwitching)
	const blueprint = useSelector(blueprintSelectors.getSelectedBlueprint)
	const isBlueprintPublished = useSelector((state: RootState) =>
		blueprintSelectors.isBlueprintActive(state, id),
	)
	const [errorFetchingBlueprint, setErrorFetchingBlueprint] = useState('')
	const [isLoadingBlueprint, setIsLoadingBlueprint] = useState(false)

	const fetchBlueprint = async () => {
		if (!id) return
		setIsLoadingBlueprint(true)
		const response = await dispatch(blueprintActions.getById({ blueprintId: id }))
		setIsLoadingBlueprint(false)
		if (response.meta.requestStatus === 'rejected') {
			setErrorFetchingBlueprint(`Blueprint with ID ${id} not found`)
		}
		window.scrollTo(0, 0)
	}

	const switchOrgAndFetchBlueprint = async () => {
		if (!orgId) {
			setSwitchOrgState(SwitchOrgState.NoOrgId)
			return
		}
		setSwitchOrgState(SwitchOrgState.Switching)
		const response = await dispatch(authActions.switchOrganisation({ organisationId: orgId }))
		if (response.meta.requestStatus === 'rejected') {
			setSwitchOrgState(SwitchOrgState.Failed)
			setErrorFetchingBlueprint(`Failed to switch organisation to ${orgId}`)
			return
		}
		setSwitchOrgState(SwitchOrgState.NotSwitching)
		fetchBlueprint()
	}

	const loadingText = useMemo(() => {
		switch (switchOrgState) {
			case SwitchOrgState.Switching:
				return `Switching organisation to ${orgId}`
			case SwitchOrgState.Failed:
				return 'Failed to switch organisation'
			case SwitchOrgState.NoOrgId:
				return 'No organisation ID provided'
		}

		if (errorFetchingBlueprint) {
			return ''
		}

		return ''
	}, [switchOrgState, blueprint, errorFetchingBlueprint])

	useEffect(() => {
		if (stepIdFromQS) {
			dispatch(blueprintActions.setSelectedStepId(stepIdFromQS))
		}
	}, [stepIdFromQS])

	useEffect(() => {
		if (orgId !== organisationId) {
			switchOrgAndFetchBlueprint()
		}
	}, [orgId, organisationId])

	useEffectOnce(() => {
		if (orgId === organisationId) {
			fetchBlueprint()
		}

		return () => {
			dispatch(blueprintActions.clearSelectedBlueprint())
		}
	})

	if (isLoadingBlueprint || !blueprint) {
		return (
			<LoadingDocument
				errorText={errorFetchingBlueprint}
				docType="Blueprint"
				returnToRoute={RouteId.Blueprints}
				customText={loadingText}
			/>
		)
	}

	return (
		<DriveFilesProvider parentFolderId={blueprint.googleDriveId}>
			<BlueprintComponent
				blueprintId={blueprint._id}
				blueprintName={blueprint.name}
				isBlueprintPublished={isBlueprintPublished}
				relatedProjects={blueprint.metadata?.related_projects}
			/>
		</DriveFilesProvider>
	)
}
