import { ComponentType } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Skeleton from '@mui/material/Skeleton'
import { Tab, Tabs } from '@mui/material'

import {
	TaskListType,
	selectors as myTasksSelectors,
	actions as myTasksActions,
} from 'src/store/modules/my-tasks-v3'
import { Box } from 'src/components'

import { SingleTaskContainer } from './single-task-container'
import { TaskList } from './task-list'

export const MobileSectionContainer: ComponentType = () => {
	const dispatch = useDispatch()
	const sectionIdLoading = useSelector(myTasksSelectors.getSectionIdLoading)
	const taskListType = useSelector(myTasksSelectors.getTaskListType)
	const activeTasks = useSelector(myTasksSelectors.getActiveTasks)
	const masterTasks = useSelector(myTasksSelectors.getMasterTasks)
	const selectedTask = useSelector(myTasksSelectors.getSelectedTask)

	const handleTaskTypeChange = (type: TaskListType) => {
		dispatch(myTasksActions.setTaskListType(type))
	}

	if (sectionIdLoading) {
		return (
			<Skeleton variant="rectangular" width="100%" height={400} sx={{ borderRadius: '10px' }} />
		)
	}

	return (
		<Box flex={1}>
			<Box display="flex" mb={1}>
				<Tabs value={taskListType} onChange={(e, value) => handleTaskTypeChange(value)}>
					<Tab value={TaskListType.Active} label={`Active tasks (${activeTasks.length})`} />
					<Tab
						value={TaskListType.ProjectTasks}
						label={`Project tasks (${masterTasks.length})`}
						disabled={!masterTasks.length}
					/>
				</Tabs>
			</Box>
			<Box
				flex={1}
				display="flex"
				flexDirection="column"
				bgcolor="white"
				borderRadius="10px"
				boxShadow="0px 1px 10px 0px rgba(0, 0, 0, 0.05)"
				p={1}>
				{selectedTask ? <SingleTaskContainer /> : <TaskList />}
			</Box>
		</Box>
	)
}
