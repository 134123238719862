import React, { ComponentType, useState } from 'react'

import { Drawer } from 'src/components'

import { DeleteStep } from './delete-step'
import StepForm from './step-form'

type StepDrawerProps = {
	open: boolean
	onClose: () => void
	parentId?: string
}

export type SectionAssignationType = {
	assigned: boolean
	assignee: string
	role: string
}

const StepDrawer: ComponentType<StepDrawerProps> = ({ open, onClose, parentId }) => {
	const [shouldPromptDelete, setShouldPromptDelete] = useState(false)
	const [dirtiedFields, setDirtiedFields] = useState(false)

	const manualCloseDrawer = () => {
		if (!dirtiedFields) {
			handleCloseDrawer()
			return
		}

		if (confirm('Step drawer contains unsaved changes, are you sure you wish to discard them?')) {
			handleCloseDrawer()
		}
	}

	const handleCloseDrawer = () => {
		setShouldPromptDelete(false)
		onClose()
	}

	return (
		<Drawer open={!!open} onClose={manualCloseDrawer}>
			{shouldPromptDelete ? (
				<DeleteStep onCloseDrawer={handleCloseDrawer} />
			) : (
				<StepForm
					onDelete={() => setShouldPromptDelete(true)}
					parentId={parentId}
					onCloseDrawer={handleCloseDrawer}
					setDirtiedFields={setDirtiedFields}
				/>
			)}
		</Drawer>
	)
}

export default StepDrawer
