import React, { ComponentType, useMemo, useState } from 'react'
import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import { useDispatch, useSelector } from 'react-redux'
import {
	GRID_REORDER_COL_DEF,
	GridColDef,
	GridRenderCellParams,
	GridRowParams,
} from '@mui/x-data-grid-pro'
import Tooltip from '@mui/material/Tooltip'
import { V3BlueprintSdk } from '@cango-app/sdk'
import _isEmpty from 'lodash/isEmpty'

import {
	selectors as blueprintSelectors,
	actions as blueprintActions,
	V3BlueprintStep,
	ChainMap,
} from 'src/store/modules/blueprints-v3'
import { selectors as roleSelectors } from 'src/store/modules/roles'
import { getActionLabel } from 'src/helpers/labels'
import { AnalyticsEvent, analytics } from 'src/biz'
import { ActionIcon } from 'src/assets/icons'
import { colors } from 'src/theme/colors'
import Options from 'src/components/options'
import Parents from 'src/components/parents'
import SectionTasks from 'src/components/section-tasks-v3'
import { recursivelyGetBlueprintParents } from 'src/helpers/chains'
import { HEADER_CLASSNAME } from 'src/components/section-tasks-v3/section-tasks-container'

import StepDrawer from '../step-drawer'

type SectionTasksProps = {
	sectionId: string
	steps: V3BlueprintStep[]
	canOpenSectionDrawer?: boolean
}

export type BlueprintRow = {
	id: string
	name: string
	owner?: string
	action: string
	parents: V3BlueprintSdk.Parent[]
	requiresEveryParent: boolean
	options: V3ClientTypes.Blueprint.Child[]
	taskWarning?: string
	onWarningClick?: () => void
	hierarchy: string[]
	when: V3BlueprintTypes.WhenEnum
	createForEveryOption: boolean
	isMenu: boolean
	chains?: ChainMap
	__reorder__: string
}

export const columns: GridColDef[] = [
	GRID_REORDER_COL_DEF,
	{
		field: 'action',
		headerName: '',
		sortable: false,
		align: 'center',
		renderCell: (params: GridRenderCellParams<BlueprintRow>) => (
			<Tooltip title={getActionLabel(params.row.action)}>
				<ActionIcon actionType={params.row.action} width={24} stroke={colors.neutral['80']} />
			</Tooltip>
		),
		maxWidth: 50,
		headerClassName: HEADER_CLASSNAME,
	},
	{
		field: 'parents',
		headerName: 'Follows',
		renderCell: (params: GridRenderCellParams<BlueprintRow>) => (
			<Parents
				parents={params.row.parents}
				requireEvery={params.row.requiresEveryParent}
				shouldCreateForEveryOption={params.row.createForEveryOption}
			/>
		),
		headerClassName: HEADER_CLASSNAME,
		sortable: false,
		flex: 1,
	},
	{
		field: 'options',
		headerName: 'Options',
		renderCell: (params) => <Options options={params.row.options} isMenu={params.row.isMenu} />,
		headerClassName: HEADER_CLASSNAME,
		sortable: false,
		flex: 1,
	},
	{
		field: 'owner',
		headerName: 'Owner',
		headerClassName: HEADER_CLASSNAME,
		sortable: false,
		flex: 0.5,
	},
]

const getStepActionType = (step: V3BlueprintStep): V3BlueprintTypes.ActionEnum => {
	if (_isEmpty(step.actions) || !step.actions[V3BlueprintTypes.Task_Action_All]) {
		return V3BlueprintTypes.ActionEnum.None
	}

	const actions = step.actions[V3BlueprintTypes.Task_Action_All]

	if (!actions.length) {
		return V3BlueprintTypes.ActionEnum.None
	}

	return actions[0].type
}

const BlueprintSectionTasks: ComponentType<SectionTasksProps> = ({
	sectionId,
	steps,
	canOpenSectionDrawer,
}) => {
	const dispatch = useDispatch()
	const [showNewStepDrawer, setShowNewStepDrawer] = useState(false)
	const blueprintId = useSelector(blueprintSelectors.getSelectedBlueprintId) ?? ''
	const selectedStep = useSelector(blueprintSelectors.getSelectedStep)
	const mappedSteps = useSelector(blueprintSelectors.getMappedSteps)
	const mappedRoles = useSelector(roleSelectors.getMappedRoles)
	const masterSection = { _id: blueprintId, name: 'Master Steps' }
	const section = useMemo(() => {
		if (!sectionId) return undefined
		if (sectionId === blueprintId) return masterSection
		return mappedSteps.get(sectionId)
	}, [mappedSteps, sectionId])

	const shouldOpenStepDrawer = useMemo(() => {
		if (showNewStepDrawer) return true
		if (!selectedStep) return false
		const stepIds = steps.map((step) => step._id)
		return stepIds.includes(selectedStep._id)
	}, [selectedStep?._id, showNewStepDrawer, steps])

	const handleStepEditClick = (row: GridRowParams<BlueprintRow>) => {
		dispatch(blueprintActions.setSelectedStepId(row.id as string))
		analytics.track({ eventName: AnalyticsEvent.stepRowClick, properties: row })
	}

	const rows: BlueprintRow[] = steps.map((step: V3BlueprintStep): BlueprintRow => {
		return {
			id: step._id,
			name: step.name,
			action: getStepActionType(step),
			when: step.when.type,
			owner: mappedRoles.get(step.role)?.label,
			parents: step.parents,
			requiresEveryParent: step.requiresEveryParent,
			createForEveryOption: !!step.createForEveryOption,
			isMenu: step.isMenu,
			options: step.children,
			hierarchy: recursivelyGetBlueprintParents({ hierarchy: [], step, allSteps: steps }),
			chains: step.chains,
			__reorder__: step.name,
		}
	})

	const handleCloseStepDrawer = () => {
		if (showNewStepDrawer) {
			setShowNewStepDrawer(false)
		}
		if (selectedStep) {
			dispatch(blueprintActions.setSelectedStepId(undefined))
		}
	}

	const handleOpenSectionDrawer = () => {
		if (canOpenSectionDrawer) {
			dispatch(blueprintActions.setSelectedSectionId(sectionId))
		}
	}

	if (!section) {
		return null
	}

	return (
		<>
			<StepDrawer
				open={shouldOpenStepDrawer}
				onClose={handleCloseStepDrawer}
				parentId={sectionId}
			/>
			<SectionTasks
				isBlueprint={true}
				sectionId={sectionId}
				sectionName={section.name}
				columns={columns}
				rows={rows}
				rowCount={rows.length}
				selectedTaskId={selectedStep?._id}
				onNewTaskClick={() => setShowNewStepDrawer(true)}
				onRowClick={(e) => handleStepEditClick(e.row)}
				pinnedColumns={{ left: ['action'] }}
				canReorder={sectionId !== blueprintId}
				onSectionNameClick={canOpenSectionDrawer ? handleOpenSectionDrawer : undefined}
			/>
		</>
	)
}

export default BlueprintSectionTasks
