import React, { ComponentType, useMemo } from 'react'
import { Controller, FieldArrayWithId, useFieldArray, useWatch } from 'react-hook-form'
import { V3BlueprintTypes } from '@cango-app/types'
import { V3BlueprintSdk } from '@cango-app/sdk'

import { Box, Button, FormCard, IconButton, TextField, Toggle } from 'src/components'
import { TrashIcon } from 'src/assets/icons'

import { StepFormControl } from './step-form-container'

type ChildFieldProps = {
	control: StepFormControl
}

type ChildItemProps = {
	indexInFilteredArray: number
	originalIndex: number
	onRemove: () => void
	control: StepFormControl
}

const ChildItem: ComponentType<ChildItemProps> = ({
	control,
	originalIndex,
	onRemove,
	indexInFilteredArray,
}) => {
	const allChildren = useWatch({ control, name: 'children' })

	const otherChildren = useMemo(() => {
		return allChildren?.filter((child, i) => i !== originalIndex)
	}, [allChildren])

	return (
		<Controller
			control={control}
			name={`children.${originalIndex}.label`}
			rules={{
				required: 'Option cannot be empty',
				validate: (value) => {
					if (otherChildren.map((child) => child.label).includes(value)) {
						return 'Option must be unique'
					}
					return true
				},
			}}
			render={({ field: { value, onChange }, fieldState: { error } }) => {
				return (
					<Box display="flex" alignItems="center">
						<TextField
							label={`Option ${indexInFilteredArray + 1}`}
							onChange={(e) => onChange(e.target.value)}
							value={value}
							fullWidth
							containerProps={{ mr: 1.5, my: 1.5, flex: 1 }}
							error={!!error}
							helperText={error?.message}
						/>
						<IconButton onClick={onRemove} sx={{ mt: 2 }}>
							<TrashIcon />
						</IconButton>
					</Box>
				)
			}}
		/>
	)
}

export const UndecoratedChildFields: ComponentType<ChildFieldProps> = ({ control }) => {
	const [isParent, isMultiUse, children, stepId, isSection] = useWatch({
		control,
		name: ['isParent', 'isMultiUse', 'children', '_id', 'isSection'],
	})

	const { fields, append, remove } = useFieldArray({ control, name: 'children' })

	const stepIsParentOrSection = useMemo(() => {
		if (!stepId) return false
		return isParent || isSection
	}, [stepId, isParent, isSection])

	const fieldsWithoutPhases = useMemo(() => {
		return fields.reduce(
			(
				acc: {
					field: FieldArrayWithId<V3BlueprintSdk.UnpopulatedStep, 'children', 'id'>
					originalIndex: number
				}[],
				field,
				originalIndex,
			) => {
				if (stepIsParentOrSection) {
					acc.push({ field, originalIndex })
					return acc
				}
				if (
					![V3BlueprintTypes.TaskPhase.Commence, V3BlueprintTypes.TaskPhase.Complete].includes(
						field._id as V3BlueprintTypes.TaskPhase,
					)
				) {
					acc.push({ field, originalIndex })
				}
				return acc
			},
			[],
		)
	}, [fields])

	const hasChildrenThatAreNotPhases = useMemo(() => {
		const filteredChildren = children.filter(
			(child) =>
				![V3BlueprintTypes.TaskPhase.Commence, V3BlueprintTypes.TaskPhase.Complete].includes(
					child._id as V3BlueprintTypes.TaskPhase,
				),
		)
		return filteredChildren.length > 1
	}, [children])

	if (isParent || isMultiUse) {
		return null
	}

	return (
		<FormCard
			title="Options"
			mb={2}
			cta={
				<Button
					sx={{ mt: 0, minWidth: 100 }}
					variant="outlined"
					size="small"
					onClick={() =>
						append({
							_id: `new-${fields.length}`,
							label: '',
							steps: [],
						})
					}>
					Add
				</Button>
			}>
			{fieldsWithoutPhases.map((item, indexInFilteredArray) => {
				return (
					<Box key={item.field._id}>
						<ChildItem
							indexInFilteredArray={indexInFilteredArray}
							originalIndex={item.originalIndex}
							onRemove={() => remove(item.originalIndex)}
							control={control}
						/>
					</Box>
				)
			})}
			{hasChildrenThatAreNotPhases && (
				<Controller
					name={'isMenu'}
					control={control}
					render={({ field: { onChange, value } }) => (
						<Toggle
							label="How many options can be chosen?"
							value={value}
							options={[
								{
									label: 'Only one',
									value: false,
								},
								{
									label: 'Multiple',
									value: true,
								},
							]}
							onChange={onChange}
							containerProps={{ mb: 1.5 }}
						/>
					)}
				/>
			)}
		</FormCard>
	)
}

export const ChildFields = React.memo(UndecoratedChildFields)
