import { GridFooterContainer } from '@mui/x-data-grid-pro'
import { ComponentType, useContext } from 'react'

import { PlusIcon } from 'src/assets/icons'
import { Button } from 'src/components'
import { TableContext } from 'src/providers/table-provider'

export const CustomFooter: ComponentType = () => {
	const { onAddRow } = useContext(TableContext)
	return (
		<GridFooterContainer>
			<Button
				variant="text"
				size="small"
				sx={{ minWidth: 100, px: 2 }}
				startIcon={<PlusIcon width={16} />}
				onClick={onAddRow}>
				New row
			</Button>
		</GridFooterContainer>
	)
}
