import { ComponentType } from 'react'
import { V3BlueprintTypes } from '@cango-app/types'

import { Box } from 'src/components'
import { TaskWithCompletable } from 'src/store/modules/my-tasks-v3'

import { TaskTitle } from '../../../components/task-title'
import { TaskItemWrapper } from '../task-item-wrapper'

import { InstanceListItem } from './instance-list-item'

type InstanceListGroupProps = {
	isFirstTask: boolean
	isLastTask: boolean
	isLastInstance: boolean
	parentName: string
	tasks: TaskWithCompletable[]
	onTaskClick: (taskId: string, sectionId?: string) => void
	openParentIds: string[]
	onOpenSubtasksClick: (taskId: string) => void
}

export const InstanceListGroup: ComponentType<InstanceListGroupProps> = ({
	isFirstTask,
	isLastTask,
	parentName,
	tasks,
	isLastInstance,
	onTaskClick,
	openParentIds,
	onOpenSubtasksClick,
}) => {
	return (
		<Box>
			<TaskItemWrapper isMultiUse isFirstTask={isFirstTask} isLastTask={isLastTask}>
				<TaskTitle
					task={{
						isMultiUse: true,
						name: parentName,
						lifecycle: {
							complete: false,
							completed_options: [],
						},
						iteration: 0,
						actions: [],
						selected_parent_options: [],
					}}
					containerProps={{ flex: 1 }}
					isSubtask={false}
				/>
				{tasks.map((task, taskIndex) => {
					if (task.lifecycle.complete) return null
					return (
						<InstanceListItem
							key={task._id}
							task={task}
							isLastTask={taskIndex === tasks.length - 1 && isLastInstance}
							onClick={onTaskClick}
							shouldShowSubtasks={openParentIds.includes(task._id)}
							onOpenSubtasksClick={onOpenSubtasksClick}
						/>
					)
				})}
			</TaskItemWrapper>
		</Box>
	)
}
