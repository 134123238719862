import React, { ComponentType, useEffect, useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import { V3BlueprintTypes, V3ClientTypes } from '@cango-app/types'
import { GithubPicker, ColorResult } from 'react-color'
import { Popover } from '@mui/material'
import { useSelector } from 'react-redux'
import { v4 } from 'uuid'

import { Text ,
	Box,
	Checkbox,
	FormCard,
	GroupedSelect,
	GroupedSelectOption,
	IconButton,
	TextField,
} from 'src/components'
import { selectors as blueprintSelectors } from 'src/store/modules/blueprints-v3'

import { StepFormControl, StepFormType } from './step-form-container'

type ChainFieldsProps = {
	control: StepFormControl
	defaultChain?: V3ClientTypes.Blueprint.Step['chain']
}

export const ChainFields: ComponentType<ChainFieldsProps> = ({ control, defaultChain }) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
	const { setValue } = useFormContext<StepFormType>()
	const stepName = useWatch({ control, name: 'name' })
	const createForEveryOption = useWatch({ control, name: 'createForEveryOption' })
	const parents = useWatch({ control, name: 'parents' })
	const chain = useWatch({ control, name: 'chain' })
	const parentOptions = useSelector(blueprintSelectors.getStepChainOptions)

	const handleOpenColorPicker = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget)
	}

	const handleSelectColor = (color: ColorResult) => {
		setValue('chain.color', color.hex, { shouldDirty: true })
		setAnchorEl(null)
	}

	const hasMultipleExtraOptionsSelected = useMemo(() => {
		const extraOptions = parents.reduce(
			(acc: { options: GroupedSelectOption[]; hasMultiple: boolean }, parent) => {
				const parentOptions = parent.options
				const parentExtraOptions = parentOptions.filter(
					(option) =>
						![V3BlueprintTypes.TaskPhase.Commence, V3BlueprintTypes.TaskPhase.Complete].includes(
							option._id as V3BlueprintTypes.TaskPhase,
						),
				)
				if (!parentExtraOptions.length) {
					return acc
				}

				const combinedOptions = [
					...acc.options,
					{ groupName: parent.name, options: parentExtraOptions },
				]

				return {
					options: combinedOptions,
					hasMultiple:
						combinedOptions.reduce(
							(options: { _id: string; label: string }[], groupItem) => [
								...options,
								...groupItem.options,
							],
							[],
						).length > 1,
				}
			},
			{
				options: [],
				hasMultiple: false,
			},
		)

		return extraOptions.hasMultiple
	}, [parents])

	useEffect(() => {
		if (!hasMultipleExtraOptionsSelected && createForEveryOption) {
			setValue('createForEveryOption', false, { shouldDirty: true })
		}

		if (createForEveryOption && !chain) {
			setValue(
				'chain',
				defaultChain ?? {
					label: stepName,
					color: '#c4def6',
					option_conditions: [],
					chain_id: v4(),
				},
				{
					shouldDirty: true,
				},
			)
		} else if (!createForEveryOption && chain) {
			setValue('chain', null, { shouldDirty: true })
		}
	}, [hasMultipleExtraOptionsSelected, createForEveryOption])

	return (
		<FormCard title="Chains" mb={2}>
			<Box>
				{!!hasMultipleExtraOptionsSelected && (
					<Controller
						control={control}
						name="createForEveryOption"
						render={({ field: { onChange, value } }) => (
							<Checkbox
								label="Create a new chain for each parent option selected"
								checked={value}
								onChange={onChange}
								containerStyle={{ px: 1 }}
							/>
						)}
					/>
				)}
				{!!parentOptions.length && (
					<Controller
						control={control}
						name="chain.option_conditions"
						render={({ field: { onChange, value } }) => {
							return (
								<GroupedSelect
									label="This is specific to the following parent options"
									multiple
									options={parentOptions}
									onChange={onChange}
									value={value ?? []}
									placeholder="All chains"
								/>
							)
						}}
					/>
				)}
				<Controller
					control={control}
					name="isLastStepInChain"
					render={({ field: { onChange, value } }) => (
						<Checkbox
							label="This is the last step in a chain"
							checked={value}
							onChange={onChange}
							containerStyle={{ my: 1, px: 1 }}
							disabled={!!hasMultipleExtraOptionsSelected}
						/>
					)}
				/>
				<Box flex={1} mb={1}>
					<Text variant="caption">
						Please ensure to check the checkbox as true if this is the final task of a blocking
						type.
					</Text>
				</Box>
				{!!hasMultipleExtraOptionsSelected && !!chain && (
					<Box display="flex" flex={1} alignItems="flex-end">
						<Box display="flex" alignItems="center" mb={0.5}>
							<IconButton aria-describedby="color-picker" onClick={handleOpenColorPicker}>
								<Box
									sx={{ borderRadius: 100, backgroundColor: chain.color, width: 20, height: 20 }}
								/>
							</IconButton>
							<Popover
								id="color-picker"
								open={!!anchorEl}
								anchorOrigin={{
									vertical: 'bottom',
									horizontal: 'left',
								}}
								anchorEl={anchorEl}
								onClose={() => setAnchorEl(null)}
							>
								<GithubPicker onChange={handleSelectColor} />
							</Popover>
						</Box>
						<Controller
							control={control}
							name="chain.label"
							render={({ field: { onChange, value } }) => (
								<TextField
									label="Custom chain name"
									fullWidth
									onChange={(event) => onChange(event.target.value)}
									value={value}
									containerProps={{ flex: 1 }}
								/>
							)}
						/>
					</Box>
				)}
			</Box>
		</FormCard>
	)
}
