import {
	DataGridPro,
	GridColDef,
	DataGridProProps,
	useGridApiRef,
	GridValidRowModel,
} from '@mui/x-data-grid-pro'

import { Box, Select, Text } from '../../../../components'
import { dataGridStandard } from '../../../../helpers/ui'

type Props = {
	title: string
	columns: GridColDef[]
	data: Record<string, unknown>[]
	onRowUpdate?: DataGridProProps['processRowUpdate']
	addRowOptions?: { _id: string; label: string }[]
	onAddOption?: (optionId: string) => void
}
export const ContentsTable = (props: Props) => {
	const apiRef = useGridApiRef()
	const { title, columns, data, onRowUpdate, addRowOptions, onAddOption } = props

	const handleRowUpdate = async (newRow: GridValidRowModel, oldRow: GridValidRowModel) => {
		if (!onRowUpdate) return
		const response = await onRowUpdate(newRow, oldRow)
		return response
	}

	return (
		<Box px={4}>
			<Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
				<Box display="flex" alignItems="center">
					<Text variant="h5">{title}</Text>
				</Box>
			</Box>
			{!!addRowOptions && !!onAddOption && (
				<Select
					size="small"
					containerProps={{ width: 300 }}
					options={addRowOptions}
					onChange={(event) => onAddOption(event.target.value as string)}
					label="Add new row"
					value={''}
				/>
			)}
			<DataGridPro
				apiRef={apiRef}
				processRowUpdate={handleRowUpdate}
				columns={columns}
				rows={data}
				sx={{
					...dataGridStandard,
					mb: 1,
					borderRadius: '0 15px 15px 15px',
				}}
				getRowId={(row) => row?.id ?? row?._id}
				disableRowSelectionOnClick
				autosizeOnMount
				hideFooter
				disableColumnMenu
				disableColumnResize
				getRowHeight={() => 'auto'}
				autoHeight
				disableColumnReorder
				disableColumnFilter
				disableColumnSelector
			/>
		</Box>
	)
}
