import React, { ComponentType, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Action, ThunkDispatch } from '@reduxjs/toolkit'
import { V3BlueprintSdk } from '@cango-app/sdk'
import { useNavigate } from 'react-router-dom'
import { useEffectOnce } from 'usehooks-ts'

import {
	selectors as blueprintSelectors,
	actions as blueprintActions,
} from 'src/store/modules/blueprints-v3'
import { selectors as configSelectors, actions as configActions } from 'src/store/modules/config'
import type { RootState } from 'src/store/types'
import { CardMenu } from 'src/components'
import { RouteId } from 'src/constants/routes'

export const BlueprintMenu: ComponentType = () => {
	const dispatch = useDispatch<ThunkDispatch<RootState, void, Action>>()
	const navigate = useNavigate()
	const [isLoading, setIsLoading] = useState(false)
	const organisationId = useSelector(configSelectors.getOrganisationId)
	const blueprintCards = useSelector(blueprintSelectors.getCards)
	const [isCreatingBlueprint, setIsCreatingBlueprint] = useState(false)

	const fetchAllBlueprints = async () => {
		setIsLoading(true)
		await dispatch(blueprintActions.getCards())
		setIsLoading(false)
	}

	const handleCreateFromScratch = async () => {
		try {
			setIsCreatingBlueprint(true)
			const response = await dispatch(blueprintActions.createBlankBlueprint())
			const { requestStatus } = response.meta
			if (requestStatus === 'fulfilled') {
				navigate(
					`/${RouteId.Blueprint}/${
						(response.payload as V3BlueprintSdk.CreateBlankBlueprintResponse).newBlueprint._id
					}?orgId=${organisationId}`,
				)
			}
			return { requestStatus }
		} finally {
			setIsCreatingBlueprint(false)
		}
	}

	const handleCloneBlueprint = async (blueprintId?: string) => {
		if (!blueprintId) {
			handleCreateFromScratch()
			return
		}
		const response = await dispatch(blueprintActions.clone(blueprintId))
		const { requestStatus } = response.meta
		if (requestStatus === 'fulfilled') {
			return {
				requestStatus,
				_id: (response.payload as V3BlueprintSdk.CloneBlueprintResponse)._id,
			}
		}
		return { requestStatus }
	}

	useEffectOnce(() => {
		fetchAllBlueprints()
	})

	return (
		<CardMenu
			cards={blueprintCards}
			forwardUrlRef="blueprint"
			onCreateNewDocument={handleCloneBlueprint}
			isV3={true}
			isLoading={isLoading || isCreatingBlueprint}
		/>
	)
}
