import React, { V3BlueprintTypes } from '@cango-app/types'
import { V3BlueprintSdk } from '@cango-app/sdk'
import { Action, ThunkDispatch } from '@reduxjs/toolkit'
import { ComponentType, useEffect, useState } from 'react'
import { Control, FieldPath, FormProvider, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'
import _every from 'lodash/every'
import _some from 'lodash/some'
import _forEach from 'lodash/forEach'
import { UnpopulatedStepAction } from '@cango-app/sdk/lib/blueprints/v3'

import { Box, Text } from 'src/components'
import type { RootState } from 'src/store/types'
import {
	actions as blueprintActions,
	selectors as blueprintSelectors,
} from 'src/store/modules/blueprints-v3'
import { AnalyticsEvent, analytics } from 'src/biz'
import { selectors as remoteConfigSelectors } from 'src/store/modules/remote-config'

import { CoreFields } from './core-fields'
import { SubmitButtons } from './submit-buttons'
import { TrainingFields } from './training-fields'
import { WhenFields } from './when-fields'
import { ChildFields } from './child-fields'
import { DependencyFields, parentOrSectionOptions } from './dependency-fields'
import { SectionFields } from './section-fields'
import { ChainFields } from './chain-fields'
import { ResourceFields } from './resource-fields'
import { MultipleStepActions } from './multiple-action-fields'

type StepFormProps = {
	onDelete: () => void
	parentId?: string
	onCloseDrawer: () => void
	setDirtiedFields: (tf: boolean) => void
}

export type StepFormType = Required<V3BlueprintSdk.UnpopulatedStep>
export type StepFormControl = Control<StepFormType>

const getItemsDirtyData = <
	TData extends Record<keyof TDirtyItems, unknown>,
	TDirtyItems extends Record<string, unknown>,
>(
	data: TData,
	dirtyItems: TDirtyItems,
): Partial<TData> => {
	const dirtyItemsEntries = Object.entries(dirtyItems)

	return dirtyItemsEntries.reduce((dirtyData, [name, value]) => {
		if (name === 'action') {
			return { ...dirtyData, [name]: data.action }
		}

		if (name === 'actions') {
			return { ...dirtyData, [name]: data.actions }
		}

		if (name === 'training') {
			return { ...dirtyData, [name]: data.training }
		}

		if (name === 'when') {
			return { ...dirtyData, [name]: data.when }
		}

		if (name === 'chain') {
			return { ...dirtyData, [name]: data.chain }
		}

		if (name === 'resource') {
			return { ...dirtyData, [name]: data.resource }
		}

		if (typeof value !== 'object' || Array.isArray(value)) {
			return { ...dirtyData, [name]: data[name] }
		}

		return {
			...dirtyData,
			[name]: getItemsDirtyData(data[name] as TData, dirtyItems[name] as TDirtyItems),
		}
	}, {})
}

const validateTemplates = (
	fileIds: string[],
	chainFileIds: Record<string, string[]>,
): { field: FieldPath<StepFormType>; message: string }[] => {
	if (fileIds.length) {
		return []
	}
	// if (_isEmpty(chainFileIds) || _every(chainFileIds, (fileIds) => !fileIds.length)) {
	// 	return [
	// 		{
	// 			field: 'action.file_ids',
	// 			message: 'Select a file, or assign one to each of the selections',
	// 		},
	// 	]
	// }

	// if (_some(chainFileIds, (fileIds) => !fileIds.length)) {
	// 	const errorFields: { field: FieldPath<StepFormType>; message: string }[] = []
	// 	_forEach(chainFileIds, (fileIds, chainId) => {
	// 		if (!fileIds.length) {
	// 			errorFields.push({
	// 				field: `action.chain_file_ids.${chainId}`,
	// 				message: 'There is no scenario where this selection would have a template',
	// 			})
	// 		}
	// 	})
	// 	return errorFields
	// }

	return []
}

export const StepForm: ComponentType<StepFormProps> = ({
	onDelete,
	parentId,
	onCloseDrawer,
	setDirtiedFields,
}) => {
	const dispatch = useDispatch<ThunkDispatch<RootState, void, Action>>()
	const step = useSelector(blueprintSelectors.getSelectedStep)
	const blueprintId = useSelector(blueprintSelectors.getSelectedBlueprintId)
	const sectionIds = useSelector(blueprintSelectors.getSectionIds)
	const [isSaving, setIsSaving] = useState(false)

	const isMasterSection = blueprintId === parentId

	const formMethods = useForm<StepFormType>({
		defaultValues: {
			...(step ?? {}),
			description: step?.description ?? '',
			children: step?.children ?? parentOrSectionOptions,
			role: step?.role,
			isMultiUse: step?.isMultiUse ?? false,
			isMenu: step?.isMenu ?? false,
			task_type: step?.task_type ?? V3BlueprintTypes.TaskType.Standard,
			isParent: step?.isParent ?? false,
			createForEveryOption: step?.createForEveryOption ?? false,
			section_id: step?.section_id ?? parentId,
			parents: step?.parents?.filter((parent) => !sectionIds.includes(parent._id)) ?? [],
			requiresEveryParent: step?.requiresEveryParent ?? false,
			chain: step?.chain ?? null,
			isLastStepInChain: step?.isLastStepInChain ?? false,
			actions: step?.actions ?? {},
			resource: step?.resource ?? {
				filters: {
					items: [],
				},
				columns: {},
			},
			training: step?.training ?? [],
			when: {
				...(step?.when ?? {}),
				type: step?.when?.type || V3BlueprintTypes.WhenEnum.ASAP,
				recurrence: {
					...(step?.when?.recurrence ?? {}),
					frequency: step?.when?.recurrence?.frequency || 1,
					interval: step?.when?.recurrence?.interval || V3BlueprintTypes.RecurrenceIntervalEnum.Day,
					weekdays: step?.when?.recurrence?.weekdays || [],
					monthday: step?.when?.recurrence?.monthday || 1,
				},
			},
		},
	})

	const {
		control,
		watch,
		handleSubmit,
		formState: { dirtyFields, errors },
		setError,
	} = formMethods

	const onSave = async (data: StepFormType) => {
		if (!blueprintId) {
			return
		}
		// if (data.action.type === V3BlueprintTypes.ActionEnum.FileTemplate) {
		// 	const errorFields = validateTemplates(data.action.file_ids, data.action.chain_file_ids)
		// 	if (errorFields.length) {
		// 		errorFields.forEach(({ field, message }) => setError(field, { type: 'custom', message }))
		// 		return
		// 	}
		// }

		data.parents = data.parents.filter(({ _id }) => !!_id)
		// data.actions = Object.keys(data.actions).reduce(
		// 	(_acc: Record<string, UnpopulatedStepAction[]>, key) => {
		// 		const chainActions = data.actions[key]
		// 		_acc[key] = chainActions.map((_action) => ({
		// 			..._action,
		// 			links: _action.links.map((link) => (link as unknown as { value: string }).value),
		// 		}))
		// 		return _acc
		// 	},
		// 	{},
		// )

		const isUpdate = !!step?._id

		setIsSaving(true)

		let response

		if (isUpdate) {
			const dirtyValues = getItemsDirtyData(data, dirtyFields)
			response = await dispatch(
				blueprintActions.updateStep({
					...dirtyValues,
					_id: step._id,
					blueprint_id: blueprintId,
				}),
			)
		} else {
			response = await dispatch(
				blueprintActions.createNewStep({
					...data,
					isMultiUse: [
						V3BlueprintTypes.TaskType.Multiuse,
						V3BlueprintTypes.TaskType.BlockType,
					].includes(data.task_type),
					blueprint_id: blueprintId,
				}),
			)
		}
		setIsSaving(false)

		if (response.meta.requestStatus === 'fulfilled') {
			analytics.track(
				isUpdate
					? { eventName: AnalyticsEvent.stepUpdated }
					: { eventName: AnalyticsEvent.stepCreated, properties: data },
			)
			onCloseDrawer()
		}
	}

	const canSubmit = () => {
		if (_isEmpty(dirtyFields)) {
			return false
		}

		return true
	}

	useEffect(() => {
		setDirtiedFields(canSubmit())
	}, [_isEmpty(dirtyFields)])

	return (
		<FormProvider {...formMethods}>
			<Box width={500} p={3} role="presentation">
				<Text variant="h5" sx={{ mb: 3 }}>
					{step?.name ? step.name : 'New step'}
				</Text>

				<CoreFields control={control} isMasterSection={isMasterSection} />

				<SectionFields control={control} />

				<DependencyFields control={control} />

				<ChainFields control={control} defaultChain={step?.chain} />

				<ChildFields control={control} />

				<MultipleStepActions />

				<ResourceFields />

				<TrainingFields control={control} />

				<WhenFields control={control} />
				<SubmitButtons
					onSubmit={handleSubmit(onSave)}
					onDelete={onDelete}
					isLoading={isSaving}
					stepId={step?._id}
					canSubmit={canSubmit()}
					errors={errors}
				/>
			</Box>
		</FormProvider>
	)
}
