import { ComponentType, useCallback, useContext, useMemo, useState } from 'react'
import { ClientTypes, TableTypes } from '@cango-app/types'
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form'
import { v4 } from 'uuid'
import { IconButton, Stack } from '@mui/material'
import _orderBy from 'lodash/orderBy'
import _toNumber from 'lodash/toNumber'
import { FormulaSliceType } from '@cango-app/types/lib/table'
import { useSelector } from 'react-redux'

import { TableContext } from 'src/providers/table-provider'
import { showSnackbar } from 'src/helpers/snackbarManager'
import { Box, Text, Button, Grid, Chip, TextField, Select } from 'src/components'
import { SaveIcon } from 'src/assets/icons'
import { colors } from 'src/theme/colors'
import { selectors as tableSelectors } from 'src/store/modules/tables'

import { isEvaluable } from '../utils'

import {
	ConfigureCalculationForm,
	allOperators,
	areOnlyOneParameter,
	arithmeticOperators,
	closeBracket,
	closeSquareBracket,
	conditionOptions,
	formulas,
	formulasWithLabels,
	openBracket,
	openSquareBracket,
} from './utils'
import { Slice } from './slice'
import { TableLookup } from './table-lookup'

type TypeAndLabel = {
	type: FormulaSliceType
	value: string
	lookup?: ClientTypes.Table.Lookup
}

type TypeLabelAndIndex = TypeAndLabel & {
	index: number
}

type CalculationModalProps = {
	defaultCalculation: ClientTypes.Table.FormulaSlice[]
	columnId: string
	onClose: () => void
}

type SliceWithId = ClientTypes.Table.FormulaSlice & { id: string }

export const CalculationModal: ComponentType<CalculationModalProps> = ({
	columnId,
	defaultCalculation,
	onClose,
}) => {
	const {
		control,
		handleSubmit,
		formState: { isDirty },
		resetField,
	} = useForm<ConfigureCalculationForm>({
		defaultValues: {
			calculation: defaultCalculation.map((_slice) => ({ ..._slice, id: v4() })),
			formula: {
				comparator: '',
				condition: '',
				fields: [],
			},
		},
	})
	const { fields, append, remove, update } = useFieldArray({ control, name: 'calculation' })
	const calculations = useWatch({ control, name: 'calculation' })
	const { onUpdateColumn, isUpdatingTable, mappedColumns } = useContext(TableContext)
	const tableList = useSelector(tableSelectors.getAllTables)
	const [number, setNumber] = useState<string>('')
	const [selectedField, setSelectedField] = useState<{ value: string; index: number } | undefined>()
	const [isEditionMode, setIsEditionMode] = useState(false)
	const formula = useWatch({ control, name: 'formula' })

	const handleSaveCalculation = async (data: ConfigureCalculationForm) => {
		if (!isEvaluable(partialCalculation)) {
			showSnackbar('Invalid calculation', { variant: 'error' })
			return
		}
		const response = await onUpdateColumn({ fieldId: columnId, calculation: data.calculation })
		if (response.result === 'success') {
			onClose()
		}
	}

	const columns = useMemo(() => {
		const filteredColumns = [...mappedColumns.values()].filter(
			(_column) => _column.type !== TableTypes.FieldType.STRING,
		)
		const orderList = filteredColumns.map((_column) => ({
			_id: _column._id,
			label: _column.name,
		}))

		return _orderBy(orderList, ['label'])
	}, [mappedColumns])

	const onEdit = ({ type, value, index }: TypeLabelAndIndex) => {
		if (selectedField?.value === value) {
			setSelectedField(undefined)
			setNumber('')
			return
		}
		if (type === TableTypes.FormulaSliceType.NUMBER) {
			setSelectedField({
				value: 'number',
				index,
			})
			setNumber(value)
			return
		}
		if (type === TableTypes.FormulaSliceType.LOOKUP) {
			setSelectedField({
				value: type,
				index,
			})
			return
		}
		if (type === TableTypes.FormulaSliceType.FIELD || TableTypes.FormulaSliceType.OPERATOR) {
			setSelectedField({ value, index })
		}
	}

	const getLabel = ({ type, value, lookup }: TypeAndLabel) => {
		switch (type) {
			case TableTypes.FormulaSliceType.FIELD:
				return mappedColumns.get(value)?.name
			case TableTypes.FormulaSliceType.OPERATOR:
				return allOperators.find(({ _id }) => _id === value)?.label
			case TableTypes.FormulaSliceType.NUMBER:
				return value
			case TableTypes.FormulaSliceType.LOOKUP:
				return `Lookup from ${tableList.find(({ _id }) => _id === lookup?.tableId)?.name ?? 'TBC'}`
			default:
				return ''
		}
	}

	const addNumber = useCallback(
		(newNumber: string) => {
			if (selectedField?.value === 'number' && isEditionMode) {
				const currentNumber = fields[selectedField.index]
				update(selectedField.index, { ...currentNumber, value: newNumber })
				setIsEditionMode(false)
				return
			}
			append({ id: v4(), type: FormulaSliceType.NUMBER, value: newNumber })
			setNumber('')
		},
		[append, setNumber, selectedField, setIsEditionMode, isEditionMode],
	)

	const addField = useCallback(
		(newField: string) => {
			if (isEditionMode && selectedField) {
				const currentField = fields[selectedField.index]
				update(selectedField?.index, { ...currentField, value: newField })
				setIsEditionMode(false)
				return
			}
			append({ id: v4(), type: FormulaSliceType.FIELD, value: newField })
		},
		[append, setIsEditionMode, isEditionMode],
	)

	const addOperator = useCallback(
		(newOperator: SliceWithId) => {
			if (selectedField && isEditionMode) {
				const currentNumber = fields[selectedField.index]
				update(selectedField.index, { ...currentNumber, value: newOperator.value })
				setSelectedField({
					value: newOperator.value,
					index: selectedField.index,
				})
				setIsEditionMode(false)
				return
			}
			if (newOperator.type === FormulaSliceType.LOOKUP) {
				append({ ...newOperator, value: '' })
			} else {
				append(newOperator)
			}
			onEdit({ ...newOperator, index: fields.length })
		},
		[selectedField, fields, setSelectedField, append, isEditionMode, setIsEditionMode],
	)

	const addFormula = useCallback(() => {
		if (!selectedField) return
		const flatExpression = formula.fields.flatMap((variable, index) => {
			if (index === formula.fields.length - 1) {
				return [
					{
						type: FormulaSliceType.FIELD,
						value: mappedColumns.get(variable)?._id || '',
					},
				]
			}
			return [
				{
					type: FormulaSliceType.FIELD,
					value: mappedColumns.get(variable)?._id || '',
				},
				{ type: FormulaSliceType.OPERATOR, value: 'COMMA' },
			]
		})
		const expressions = selectedField.value.includes('IF')
			? [
					{
						type: FormulaSliceType.OPERATOR,
						value: selectedField.value,
					},
					openBracket,
					openSquareBracket,
					...flatExpression,
					closeSquareBracket,
					{
						type: FormulaSliceType.OPERATOR,
						value: 'COMMA',
					},
					{
						type: FormulaSliceType.OPERATOR,
						value: formula.condition,
					},
					{
						type: FormulaSliceType.FIELD,
						value: formula.comparator,
					},
					closeBracket,
				]
			: [
					{
						type: FormulaSliceType.OPERATOR,
						value: selectedField.value,
					},
					openBracket,
					...flatExpression,
					closeBracket,
				]

		expressions.forEach((expression) => {
			switch (expression.type) {
				case FormulaSliceType.FIELD: {
					return addField(expression.value)
				}
				case FormulaSliceType.NUMBER: {
					return addNumber(expression.value)
				}
				case FormulaSliceType.OPERATOR: {
					return addOperator({
						id: v4(),
						type: FormulaSliceType.OPERATOR,
						value: expression.value,
					})
				}
			}
		})
		resetField('formula')
	}, [selectedField, formula, resetField])

	const partialCalculation = useMemo(() => {
		return fields
			.map(({ value, type }) => {
				if (type === TableTypes.FormulaSliceType.FIELD) {
					return 1 // convert fields to any number for evaluating formula
				}
				if (type === TableTypes.FormulaSliceType.LOOKUP) {
					return 1 // convert lookup to any number for evaluating formula
				}
				if (TableTypes.Operator[value as keyof typeof TableTypes.Operator]) {
					return TableTypes.Operator[value as keyof typeof TableTypes.Operator]
				}
				return value
			})
			.join('')
	}, [fields])

	return (
		<Box>
			<Grid container columnSpacing={2}>
				<Grid item xs={3} />
				<Grid item xs={6} display="flex" alignItems="center" justifyContent="center">
					<Text fontWeight="bold" variant="overline" textAlign="center" mt={2}>
						Configure Calculation
					</Text>
				</Grid>
				<Grid item xs={3}>
					<Box display="flex" justifyContent="flex-end">
						<Button
							startIcon={<SaveIcon fill="#fff" />}
							size="small"
							onClick={handleSubmit(handleSaveCalculation)}
							isLoading={isUpdatingTable}
							disabled={!isDirty}
							sx={{ mt: 2, minWidth: 100 }}
						>
							Save
						</Button>
					</Box>
				</Grid>
				<Grid item xs={12}>
					<Stack direction="column" spacing={1}>
						<Text variant="overline">Arithmetical Operators</Text>
						<Stack
							direction="row"
							sx={{
								overflowX: 'auto',
							}}
						>
							{arithmeticOperators.map(({ _id, label }) => (
								<Box key={_id} marginRight={1}>
									<Chip
										variant={selectedField?.value === _id ? 'filled' : 'outlined'}
										color="primary"
										label={label}
										onClick={() =>
											addOperator({
												id: v4(),
												type: FormulaSliceType.OPERATOR,
												value: arithmeticOperators.find((element) => element.label === label)
													?._id as string,
											})
										}
									/>
								</Box>
							))}
						</Stack>
					</Stack>
				</Grid>
				<Grid item xs={12} marginBottom={2}>
					<Stack direction="column" spacing={1}>
						<Text variant="overline">Formulas</Text>
						<Stack
							direction="row"
							sx={{
								overflowX: 'auto',
							}}
						>
							{formulasWithLabels.map(({ _id, label }) => (
								<Box key={_id} marginRight={1}>
									<Chip
										variant={selectedField?.value === _id ? 'filled' : 'outlined'}
										color="primary"
										label={label.toUpperCase()}
										onClick={() => {
											if (_id === FormulaSliceType.LOOKUP) {
												addOperator({
													id: v4(),
													type: FormulaSliceType.LOOKUP,
													value: label,
												})
												return
											}
											setSelectedField({
												index: calculations.length - 1,
												value: label,
											})
										}}
									/>
								</Box>
							))}
						</Stack>
					</Stack>
				</Grid>
				{selectedField?.value && formulas.includes(selectedField?.value) ? (
					<Grid item xs={12}>
						<Grid
							container
							columnSpacing={2}
							display="flex"
							justifyContent="flex-start"
							alignItems="center"
						>
							<Grid item xs={4}>
								<Controller
									name="formula.fields"
									control={control}
									render={({ field: { value, onChange } }) => (
										<Select
											label="Fields"
											multiple={!areOnlyOneParameter.includes(selectedField.value)}
											options={columns}
											value={value}
											onChange={onChange}
										/>
									)}
								/>
							</Grid>
							{selectedField.value.includes('IF') ? (
								<>
									<Grid item xs={3}>
										<Controller
											name="formula.condition"
											control={control}
											render={({ field: { value, onChange } }) => (
												<Select
													label="Condition"
													value={value}
													onChange={onChange}
													options={conditionOptions}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={2}>
										<Controller
											name="formula.comparator"
											control={control}
											render={({ field: { value, onChange } }) => (
												<Select
													label="Comparator"
													value={value}
													onChange={onChange}
													options={columns}
												/>
											)}
										/>
									</Grid>
								</>
							) : null}
							<Grid item xs={3}>
								<Button
									fullWidth
									disabled={
										selectedField.value.includes('IF')
											? !Object.values(formula).every(Boolean)
											: formula.fields.length < 1
									}
									onClick={addFormula}
								>
									Add fields to formula
								</Button>
							</Grid>
						</Grid>
					</Grid>
				) : (
					<>
						<Grid item xs={4}>
							<TextField
								label="Number"
								fullWidth
								value={number}
								onChange={(event) => setNumber(String(event.target.value))}
								type="number"
								onKeyDown={(ev) => {
									const numberKey = _toNumber(ev.key)
									if (isNaN(numberKey) && ev.key !== 'Backspace' && ev.key !== 'Delete') {
										ev.preventDefault()
									}
									if (ev.key === 'Enter') {
										addNumber(number)
									}
								}}
								InputProps={{
									endAdornment: (
										<IconButton onClick={() => addNumber(number)}>
											<SaveIcon />
										</IconButton>
									),
								}}
							/>
						</Grid>
						<Grid item xs={8}>
							<Box>
								<Text component="label" fontSize={14} color={colors.neutral['80']} fontWeight={500}>
									Fields
								</Text>
								<Stack
									direction="row"
									marginY={0.5}
									sx={{
										overflowX: 'auto',
									}}
								>
									{columns.map(({ label, _id }) => (
										<Box key={_id} marginRight={1}>
											<Chip
												variant={selectedField?.value === _id ? 'filled' : 'outlined'}
												color="primary"
												label={label}
												onClick={() => addField(_id)}
											/>
										</Box>
									))}
								</Stack>
							</Box>
						</Grid>
					</>
				)}
				<Grid item xs={12} marginTop={3}>
					<Stack direction="row" alignItems="center">
						<Text flex={1}>Calculation:</Text>
						<Button size="small" variant="text" onClick={() => remove()}>
							Clear all
						</Button>
					</Stack>
				</Grid>
				<Grid item xs={12}>
					<Stack
						direction="row"
						marginY={1}
						paddingY={2}
						sx={{
							overflowX: 'auto',
						}}
					>
						{fields.map(({ id, value, type }, index) => (
							<Slice
								control={control}
								onClick={() => {
									onEdit({ type, value, index })
									setIsEditionMode(true)
								}}
								onDelete={() => {
									remove(index)
									if (selectedField?.index === index) {
										setSelectedField(undefined)
									}
								}}
								sliceIndex={index}
								key={id}
							/>
						))}
					</Stack>
				</Grid>
				<Grid item xs={12} display="flex" justifyContent="center">
					<Text color={isEvaluable(partialCalculation) ? 'inherit' : colors['error']['main']}>
						{calculations
							.map(({ value, type, lookup }) => getLabel({ type, value, lookup }))
							.join('')}
					</Text>
				</Grid>
			</Grid>
			{selectedField?.value === 'lookup' ? (
				<TableLookup control={control} sliceIndex={selectedField?.index} />
			) : null}
		</Box>
	)
}
