import React, { ComponentType, useState } from 'react'

import { Box, IconButton } from 'src/components'
import { colors } from 'src/theme/colors'
import { SettingsCogIcon } from 'src/assets/icons'

import { BlueprintOpsDrawer } from './blueprint-ops-drawer'

type BlueprintOpsProps = {
	blueprintId: string
	isPublished: boolean
	blueprintName: string
	blueprintDatabaseTable?: string
	relatedProjects?: { name: string; _id: string }[]
}

const BlueprintOps: ComponentType<BlueprintOpsProps> = (props) => {
	const [isConfigDrawerOpen, setConfigDrawerOpen] = useState(false)

	return (
		<Box mr={4} mt={4} display="flex" flexDirection="column" alignItems="flex-end">
			<Box display="flex">
				<IconButton onClick={() => setConfigDrawerOpen(true)} color="inherit">
					<SettingsCogIcon stroke={colors.neutral['80']} />
				</IconButton>
			</Box>
			<BlueprintOpsDrawer
				open={isConfigDrawerOpen}
				onClose={() => setConfigDrawerOpen(false)}
				{...props}
			/>
		</Box>
	)
}

export default BlueprintOps
