import React, { ComponentType, useEffect, useMemo } from 'react'
import { V3BlueprintTypes, ClientTypes } from '@cango-app/types'
import { SelectChangeEvent } from '@mui/material'
import {
	Controller,
	UseFieldArrayUpdate,
	useFieldArray,
	useFormContext,
	useWatch,
} from 'react-hook-form'
import { useSelector } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'
import _reduce from 'lodash/reduce'
import { UnpopulatedStepAction } from '@cango-app/sdk/lib/blueprints/v3'
import { v4 } from 'uuid'

import { Box, Chip, TextField, FormCard, Select, IconButton, Button, Divider } from 'src/components'
import { getActionLabel } from 'src/helpers/labels'
import { selectors as roleSelectors } from 'src/store/modules/roles'
import { ChainMap, selectors as blueprintSelectors } from 'src/store/modules/blueprints-v3'
import { usePrevious } from 'src/hooks/usePrevious'
import { RootState } from 'src/store/types'
import { PlusIcon, TrashIcon } from 'src/assets/icons'
import { colors } from 'src/theme/colors'

import { StepFormControl, StepFormType } from './step-form-container'
import { TemplateUploadFields } from './template-upload-fields'

export const ACTIONS_REQUIRING_ROLES = [
	V3BlueprintTypes.ActionEnum.Call,
	V3BlueprintTypes.ActionEnum.Email,
	V3BlueprintTypes.ActionEnum.Invite,
	V3BlueprintTypes.ActionEnum.Meeting,
]

export const ACTIONS_REQUIRING_FILE_SELECT = [V3BlueprintTypes.ActionEnum.FileView]

export const ACTIONS_REQUIRING_LINK = [
	V3BlueprintTypes.ActionEnum.Software,
	V3BlueprintTypes.ActionEnum.Video,
]

const SoftwareInputs: React.FC<{ itemId: string | undefined; index: number }> = ({
	itemId,
	index,
}) => {
	const { control } = useFormContext<StepFormType>() // Make sure to have the correct generic <StepFormType>
	const { fields, append, remove } = useFieldArray({
		control,
		name: `actions.${itemId}.${index}.links`,
	})

	return (
		<Box>
			{fields.map((field, fieldIndex) => (
				<Controller
					key={field._id} // It's important to use a unique key for list items
					control={control}
					name={`actions.${itemId}.${index}.links.${fieldIndex}.link`}
					render={({ field: { value, onChange }, fieldState: { error } }) => (
						<Box display="flex">
							<TextField
								error={!!error}
								label="Link"
								value={value}
								fullWidth
								onChange={onChange}
								containerProps={{ flex: 1, mb: 2 }}
							/>
							<Box>
								<IconButton sx={{ mt: 3, ml: 1 }} onClick={() => remove(fieldIndex)}>
									<TrashIcon width={14} />
								</IconButton>
							</Box>
						</Box>
					)}
				/>
			))}
			<Button
				variant="outlined"
				size="small"
				onClick={() => {
					append({
						_id: v4(),
						link: '',
					})
				}}
			>
				Add new link
			</Button>
		</Box>
	)
}

const UndecoratedActionFields: ComponentType<{
	control: StepFormControl
	index: number
	itemId: string | undefined
	onRemove: () => void
}> = ({ control, index, itemId, onRemove }) => {
	const { setValue } = useFormContext<StepFormType>()
	const action = useWatch({ control, name: `actions.${itemId}.${index}` })
	const actions = useWatch({ control, name: `actions.${itemId}` })
	const children = useWatch({ control, name: 'children' })
	const previousActionType = usePrevious(action.type)
	const roles = useSelector(roleSelectors.getRoles)
	const { stepIds: fileStepIds, options: fileStepOptions } = useSelector(
		blueprintSelectors.getBlueprintStepsWithFiles,
	)
	const { stepIds: linkStepIds, options: linkStepOptions } = useSelector(
		blueprintSelectors.getBlueprintStepsWithLinks,
	)
	const usedActions = actions.map((_action) => _action.type)

	const actionTypeOptions = useMemo(() => {
		const options = Object.values(V3BlueprintTypes.ActionEnum).map((type) => ({
			_id: type,
			label: getActionLabel(type),
		}))

		return options.filter((_option) => {
			if (_option._id === action.type) {
				return true
			}
			return !usedActions.includes(_option._id)
		})
	}, [usedActions, action.type])

	useEffect(() => {
		if (
			previousActionType &&
			!ACTIONS_REQUIRING_FILE_SELECT.includes(action.type) &&
			previousActionType !== action.type &&
			!!action.files_from_tasks?.length
		) {
			setValue(`actions.${itemId}.${index}.files_from_tasks`, [], { shouldDirty: true })
		}

		if (
			previousActionType &&
			action.type !== V3BlueprintTypes.ActionEnum.LinkView &&
			previousActionType !== action.type &&
			!!action.links_from_tasks?.length
		) {
			setValue(`actions.${itemId}.${index}.links_from_tasks`, [], { shouldDirty: true })
		}

		if (
			previousActionType &&
			!ACTIONS_REQUIRING_LINK.includes(action.type) &&
			previousActionType !== action.type &&
			action.links.length
		) {
			setValue(`actions.${itemId}.${index}.links`, [], { shouldDirty: true })
		}

		if (
			previousActionType &&
			previousActionType !== action.type &&
			action.type === V3BlueprintTypes.ActionEnum.Archive
		) {
			setValue(
				'children',
				children.filter((_child) =>
					[V3BlueprintTypes.TaskPhase.Commence, V3BlueprintTypes.TaskPhase.Complete].includes(
						_child._id as V3BlueprintTypes.TaskPhase,
					),
				),
				{ shouldDirty: true },
			)
		}
	}, [action.type, action.files_from_tasks, action.link, children, action.links_from_tasks])

	return (
		<Box
			sx={{
				mb: 2,
				display: 'flex',
			}}
		>
			<Box flex={1}>
				<Controller
					control={control}
					name={`actions.${itemId}.${index}.type`}
					render={({ field: { value, onChange }, fieldState: { error } }) => (
						<Select
							error={!!error}
							disabled={
								action.type === V3BlueprintTypes.ActionEnum.FileTemplate &&
								!!action.file_ids?.length
							}
							label="What needs to be done?"
							onChange={(event) => onChange(event.target.value as V3BlueprintTypes.ActionEnum)}
							value={value}
							options={actionTypeOptions}
							containerProps={{ my: 2, maxWidth: 375 }}
							helperText={error?.message}
						/>
					)}
				/>

				{action.type && ACTIONS_REQUIRING_ROLES.includes(action.type) && (
					<Controller
						control={control}
						name={`actions.${itemId}.${index}.roles`}
						render={({ field: { value, onChange }, fieldState: { error } }) => {
							const handleChange = (event: SelectChangeEvent<unknown>) => {
								const { value } = event.target
								onChange(typeof value === 'string' ? value.split(',') : value)
							}
							return (
								<Select
									error={!!error}
									label={`${action.type} who?`}
									onChange={handleChange}
									value={value || []}
									multiple
									multiline
									options={roles}
									containerProps={{ mb: 2, maxWidth: 375 }}
									renderValue={(selected: any) => {
										const roleNames = selected.map((id: string) =>
											roles.find((role) => role._id === id),
										)
										return (
											<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
												{roleNames.map((role: ClientTypes.Role) => {
													return role ? <Chip key={role._id} label={role.label} /> : ''
												})}
											</Box>
										)
									}}
								/>
							)
						}}
					/>
				)}

				{action.type === V3BlueprintTypes.ActionEnum.FileTemplate && (
					<TemplateUploadFields optionId={itemId} actionIndex={index} />
				)}

				{action.type && ACTIONS_REQUIRING_FILE_SELECT.includes(action.type) && (
					<Controller
						control={control}
						name={`actions.${itemId}.${index}.files_from_tasks`}
						rules={{ required: action.type === V3BlueprintTypes.ActionEnum.Video }}
						render={({ field: { value, onChange }, fieldState: { error } }) => (
							<Select
								multiple
								error={!!error}
								label="Refer to the file(s) from which Step?"
								onChange={onChange}
								required
								value={value?.filter((_id) => fileStepIds.has(_id)) || []}
								options={fileStepOptions}
								containerProps={{ mb: 2, maxWidth: 375 }}
							/>
						)}
					/>
				)}

				{action.type === V3BlueprintTypes.ActionEnum.LinkView && (
					<Controller
						control={control}
						name={`actions.${itemId}.${index}.links_from_tasks`}
						rules={{ required: action.type === V3BlueprintTypes.ActionEnum.LinkView }}
						render={({ field: { value, onChange }, fieldState: { error } }) => (
							<Select
								multiple
								error={!!error}
								label="Refer to the link(s) from which Step?"
								onChange={onChange}
								required
								value={value?.filter((_id) => linkStepIds.has(_id)) || []}
								options={linkStepOptions}
								containerProps={{ mb: 2, maxWidth: 375 }}
							/>
						)}
					/>
				)}

				{action.type && action.type === V3BlueprintTypes.ActionEnum.Note && (
					<Controller
						control={control}
						name={`actions.${itemId}.${index}.note`}
						render={({ field: { value, onChange } }) => (
							<TextField
								label="Describe the action"
								value={value}
								fullWidth
								multiline
								onChange={(event) => onChange(event.target.value)}
								sx={{ mb: 2 }}
							/>
						)}
					/>
				)}

				{action.type && ACTIONS_REQUIRING_LINK.includes(action.type) && (
					<SoftwareInputs itemId={itemId} index={index} />
				)}
			</Box>
			<Box>
				<IconButton sx={{ mt: 5, ml: 1 }} onClick={onRemove}>
					<TrashIcon />
				</IconButton>
			</Box>
		</Box>
	)
}

const OptionActions: ComponentType<{
	parentsList: { _id: string; label: string }[]
	itemId: string | undefined
	onRemove: () => void
}> = ({ parentsList, itemId, onRemove }) => {
	const { control, watch, setValue } = useFormContext<StepFormType>()
	const { fields, append, remove } = useFieldArray({ control, name: `actions.${itemId}` })
	const allChainActions = watch('actions')
	const actions = watch(`actions.${itemId}`)

	const hasNoExtraParents = useMemo(() => {
		return parentsList.length === 1 && parentsList[0]._id === V3BlueprintTypes.Task_Action_All
	}, [parentsList])

	const filteredParentsList = useMemo(() => {
		return parentsList.filter((_option) => {
			if (_option._id === itemId) {
				return true
			}
			return !allChainActions[_option._id]
		})
	}, [parentsList])

	const handleChangeChain = (newKey: string) => {
		if (!itemId) return
		const actionsCopy = [...actions]
		const allChainActionsCopy = { ...allChainActions }
		delete allChainActionsCopy[itemId]
		allChainActionsCopy[newKey] = actionsCopy
		setValue('actions', allChainActionsCopy, { shouldDirty: true })
	}

	return (
		<Box
			sx={{
				backgroundColor: 'rgba(164, 198, 188, 0.1)',
				p: 2,
				borderRadius: '8px',
				mb: 2,
			}}
		>
			{!hasNoExtraParents && (
				<Select
					label="Which chain is this for?"
					options={filteredParentsList}
					value={itemId}
					onChange={(event) => handleChangeChain(event.target.value as string)}
				/>
			)}

			{fields.map((_item, index) => (
				<>
					<UndecoratedActionFields
						key={_item._id}
						control={control}
						index={index}
						itemId={itemId}
						onRemove={() => remove(index)}
					/>
					{index !== fields.length - 1 && <Divider />}
				</>
			))}
			<Box display="flex" justifyContent="space-between">
				<Button
					size="small"
					variant="outlined"
					onClick={() =>
						append({
							_id: v4(),
							type: V3BlueprintTypes.ActionEnum.None,
							roles: [],
							files_from_tasks: [],
							note: '',
							links: [],
							file_ids: [],
							links_from_tasks: [],
						})
					}
					startIcon={<PlusIcon width={16} stroke={colors.feldgrau['60']} />}
				>
					{`Add an action${!hasNoExtraParents ? ' for this chain' : ''}`}
				</Button>
				<Button
					size="small"
					variant="outlined"
					color="error"
					onClick={onRemove}
					startIcon={<TrashIcon stroke={colors.error.main} width={12} />}
				>
					{`Remove all actions${!hasNoExtraParents ? ' for chain' : ''}`}
				</Button>
			</Box>
		</Box>
	)
}

export const MultipleStepActions: ComponentType = () => {
	const { control, setValue } = useFormContext<StepFormType>()
	const formParents = useWatch({ control, name: 'parents' })
	const formSectionId = useWatch({ control, name: 'section_id' })
	const actions = useWatch({ control, name: 'actions' })
	const parents = useSelector((state: RootState) =>
		blueprintSelectors.getTempStepChainOptions(state, formParents, formSectionId),
	)

	const actionsList = useMemo(() => {
		const mappedParents = parents.reduce(
			(_acc: Map<string, string>, group) => {
				group.options.forEach((_option) => {
					_acc.set(_option._id, _option.label)
				})
				return _acc
			},
			new Map([[V3BlueprintTypes.Task_Action_All, 'All']]),
		)

		return Object.keys(actions).reduce(
			(_acc: { _id: string; label: string; actions: UnpopulatedStepAction[] }[], curr) => {
				return [
					..._acc,
					{ _id: curr, label: mappedParents.get(curr) || '', actions: actions[curr] },
				]
			},
			[],
		)
	}, [actions])

	const parentsList = useMemo(() => {
		return parents.reduce(
			(_acc: { _id: string; label: string }[], group) => {
				const options = group.options.reduce((_acc: { _id: string; label: string }[], _option) => {
					return [..._acc, _option]
				}, [])

				if (!options.length) {
					return _acc
				}

				return [..._acc, ...options]
			},
			[
				{
					_id: V3BlueprintTypes.Task_Action_All,
					label: 'All',
				},
			],
		)
	}, [parents])

	const handleRemoveChainSpecificActions = (itemId: string) => {
		const actionsCopy = { ...actions }
		delete actionsCopy[itemId]
		setValue('actions', actionsCopy, { shouldDirty: true })
	}

	const handleAddChain = () => {
		const availableChains = parentsList.filter(
			(_chain) => !Object.keys(actions).includes(_chain._id),
		)
		if (!availableChains.length) return
		const newChainId = availableChains[0]._id
		setValue(`actions.${newChainId}`, [], { shouldDirty: true })
	}

	return (
		<FormCard
			title="Actions"
			mb={2}
			cta={
				<Button
					size="small"
					variant="outlined"
					sx={{ minWidth: 100 }}
					onClick={handleAddChain}
					disabled={parentsList.length === Object.keys(actions).length}
				>
					Add
				</Button>
			}
		>
			{actionsList.map((item) => (
				<OptionActions
					key={item._id}
					parentsList={parentsList}
					itemId={item._id}
					onRemove={() => handleRemoveChainSpecificActions(item._id)}
				/>
			))}
		</FormCard>
	)
}
