import { ComponentType, useContext, useMemo } from 'react'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'

import { MessageStatus, NoteWithMessageStatus } from 'src/store/modules/notes'
import { selectors as userSelectors } from 'src/store/modules/user'
import { DriveFilesContext } from 'src/providers'
import { getNameInitials } from 'src/routing/navigation/utils'
import { AttachedFile, Box, Text, UserAvatar } from 'src/components'
import { MentionedText } from 'src/components/mentions/mentioned-text'

const formatTime = (timestamp: number) => {
	const now = dayjs()
	const time = dayjs(timestamp * 1000)
	const diffInMinutes = now.diff(time, 'minute')

	if (diffInMinutes < 1) {
		return time.from(now)
	}

	return time.format('hh:mm A')
}

export const Note: ComponentType<{
	note: NoteWithMessageStatus
}> = ({ note }) => {
	const currentUser = useSelector(userSelectors.getUserDetails)
	const currentUserInitials = useSelector(userSelectors.getUserInitials)
	const mappedUsers = useSelector(userSelectors.getMappedUsers)
	const { allFiles, isLoadingFiles, parentFolderId } = useContext(DriveFilesContext)

	const isUserNote = note.created_by === currentUser?._id
	const createdUser = mappedUsers.get(note.created_by)
	const userInitials = useMemo(() => {
		const user = createdUser
		if (!user) {
			return '?'
		}
		return isUserNote ? currentUserInitials : getNameInitials(user.name, user.surname)
	}, [createdUser, isUserNote, currentUserInitials])

	return (
		<Box
			display="flex"
			justifyContent="flex-end"
			flexDirection={isUserNote ? 'row' : 'row-reverse'}
			flex={1}
			mb={2}>
			<Box
				bgcolor="#fff"
				px={2}
				py={1}
				borderRadius={isUserNote ? '8px 0px 8px 8px' : '0px 8px 8px 8px'}
				boxShadow="0px 2px 4px 0px rgba(0, 0, 0, 0.10)"
				minWidth={200}
				maxWidth={500}
				mr={isUserNote ? 2 : 0}
				ml={!isUserNote ? 2 : 0}>
				<Text fontWeight="bold" fontSize={14}>
					{mappedUsers.get(note.created_by)?.name ?? 'Unknown author'}
				</Text>
				<Text fontWeight={400} fontSize={12}>
					<MentionedText raw={note.text} />
				</Text>
				{note.file_ids.map((fileId) => (
					<AttachedFile
						key={fileId}
						fileId={fileId}
						size="small"
						containerStyles={{
							display: 'flex',
							justifyContent: isUserNote ? 'flex-end' : 'flex-start',
						}}
						_file={allFiles.find((file) => file.id === fileId)}
						_isLoadingFile={isLoadingFiles}
						folderId={parentFolderId}
					/>
				))}
				<Text fontSize="8px" textAlign="right">
					{note.messageStatus === MessageStatus.IsSending
						? 'Sending...'
						: formatTime(note.timestamp)}
				</Text>
			</Box>
			<UserAvatar
				userInitials={userInitials}
				isWarning={note.isFlag}
				picture={createdUser?.picture}
			/>
		</Box>
	)
}
