import { ComponentType, useEffect, useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { V3ProjectSdk } from '@cango-app/sdk'

import {
	selectors as myTasksSelectors,
	actions as myTasksActions,
	MyTasksLoadingState,
} from 'src/store/modules/my-tasks-v3'
import { useIsMobile } from 'src/hooks/useMobile'
import { DriveFilesContext, DriveFilesProvider } from 'src/providers'
import { RouteId } from 'src/constants/routes'
import { selectors as authSelectors } from 'src/store/modules/auth'
import usePolling from 'src/hooks/usePolling'
import { showSnackbar } from 'src/helpers/snackbarManager'

import { MobileSectionContainer } from './mobile-section-container'
import { DesktopSectionContainer } from './desktop-section-container'

const SectionContainer: ComponentType = () => {
	const dispatch = useDispatch()
	const isMobile = useIsMobile()
	const navigate = useNavigate()
	const { addNewFilesToCollection } = useContext(DriveFilesContext)
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const projectList = useSelector(myTasksSelectors.getProjectsList)
	const projectListState = useSelector(myTasksSelectors.getProjectListState)
	const { selectedSectionId: sectionIdParam, selectedTaskId: taskIdParam } = useParams<{
		selectedSectionId?: string
		selectedTaskId?: string
	}>()
	const selectedSection = useSelector(myTasksSelectors.getSelectedSection)
	const sectionTasksPendingUpload = useSelector(myTasksSelectors.getSectionTasksPendingUpload)
	const isPendingFileUploads = sectionTasksPendingUpload.length

	const getTasksPendingUploadedFiles = useCallback(async () => {
		if (!selectedSection) return
		try {
			const response = await V3ProjectSdk.getTasksPendingUploadedFiles({
				baseURL: import.meta.env.VITE_API as string,
				authHeaders,
				data: {
					taskIds: sectionTasksPendingUpload.map((_task) => _task._id),
				},
			})
			if (response.length) {
				dispatch(myTasksActions.updateTaskFileIds(response))
				addNewFilesToCollection()
			}
		} catch (error) {
			showSnackbar('Failed to fetch pending uploads. Please refresh and try again.', {
				variant: 'error',
			})
		}
	}, [sectionTasksPendingUpload, selectedSection])
	const [isPolling, startPolling, stopPolling] = usePolling(getTasksPendingUploadedFiles, 1000)

	useEffect(() => {
		if (!isPolling && selectedSection && isPendingFileUploads) {
			startPolling()
		}

		if (isPolling && (!isPendingFileUploads || !selectedSection)) {
			dispatch(myTasksActions.setFilesTotalToUpload(0))
			stopPolling()
		}

		return () => {
			if (isPolling) {
				stopPolling()
			}
		}
	}, [isPendingFileUploads, isPolling, selectedSection])

	useEffect(() => {
		if (!sectionIdParam && !projectList.length) {
			return
		}

		if (selectedSection?.tasks.some((_task) => _task.section_id === sectionIdParam)) {
			return
		}

		if (!sectionIdParam && isMobile) {
			return
		}

		if (!sectionIdParam) {
			const sectionId = projectList[0].sections[0]?._id
			navigate(`/${RouteId.MyTasks}/${sectionId}`)
			return
		}

		const sectionSplit = sectionIdParam.split('--')
		if (sectionSplit.length > 1 && sectionSplit[1] === 'blocked') {
			const projectId = sectionSplit[0]
			dispatch(myTasksActions.fetchBlockedTasks(projectId))
		} else {
			const project = projectList.find((_project) =>
				_project.sections.some((section) => section._id === sectionIdParam),
			)
			const section = project?.sections.find((section) => section._id === sectionIdParam)

			if (!section && projectListState === MyTasksLoadingState.Fetched) {
				navigate(`/${RouteId.MyTasks}`)
				return
			}

			if (!section) {
				return
			}

			dispatch(
				myTasksActions.fetchSectionTasks({
					projectId: section.project_id,
					sectionId: section._id,
					instanceId: section.instance?._id,
				}),
			)
		}
	}, [sectionIdParam, projectList.length])

	useEffect(() => {
		if (selectedSection?.project_id) {
			getTasksPendingUploadedFiles()
			dispatch(myTasksActions.getTableId({ projectId: selectedSection.project_id }))
		}
	}, [selectedSection?.project_id])

	useEffect(() => {
		dispatch(myTasksActions.setSelectedTaskId(taskIdParam))
	}, [taskIdParam])

	if (isMobile) {
		return <MobileSectionContainer />
	}

	return <DesktopSectionContainer />
}

const SectionWithDriveProvider: ComponentType = () => {
	const project = useSelector(myTasksSelectors.getSelectedProject)

	return (
		<DriveFilesProvider parentFolderId={project?.googleDriveId}>
			<SectionContainer />
		</DriveFilesProvider>
	)
}

export default SectionWithDriveProvider
