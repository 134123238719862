const numberFormat = new Intl.NumberFormat('en-US', {
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
})

const currencyFormat = new Intl.NumberFormat('en-US', {
	style: 'currency',
	currency: 'USD',
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
})

export const formatNumber = (value: number) => numberFormat.format(value)
export const formatCurrency = (value: number) => currencyFormat.format(value)
