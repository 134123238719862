import React, { ComponentType, useState } from 'react'

import { relativeTimeLabel } from 'src/helpers/labels'
import { ChevronDown } from 'src/assets/icons'
import { ListedTask } from 'src/store/modules/projects-v3'
import { ChainsList } from 'src/components/section-tasks-v3/chains-list'

import { Box } from '../../../../components/box'
import { Button } from '../../../../components/button'
import { Grid } from '../../../../components/grid'
import { Text } from '../../../../components/text'

type SectionTitleBarProps = {
	sectionName: string
	sectionId: string
	onSectionStart?: (sectionId: string) => Promise<void>
	onSectionComplete?: (sectionId: string) => Promise<void>
	startedAt?: number
	completedAt?: number[]
	rowCount?: number
	onSectionCollapseToggle?: () => void
	isSectionCollapsed?: boolean
	sectionChain: ListedTask['chain']
}

export const SectionTitleBar: ComponentType<SectionTitleBarProps> = ({
	sectionName,
	sectionId,
	onSectionStart,
	onSectionComplete,
	startedAt,
	completedAt,
	rowCount,
	onSectionCollapseToggle,
	isSectionCollapsed = false,
	sectionChain,
}) => {
	const [isSectionStartLoading, setSectionStartLoading] = useState(false)

	const handleSectionStart = async () => {
		if (!onSectionStart) return
		setSectionStartLoading(true)
		await onSectionStart(sectionId)
		setSectionStartLoading(false)
	}

	const handleSectionComplete = async () => {
		if (!onSectionComplete) return
		setSectionStartLoading(true)
		await onSectionComplete(sectionId)
		setSectionStartLoading(false)
	}

	return (
		<Grid container mb={1}>
			<Grid xs={6}>
				<Box display="flex" alignItems="flex-start" minHeight="50px" flexDirection="column">
					<Text variant="h5" fontWeight={500}>
						{sectionName}
					</Text>
					{!!sectionChain?.chain_id && (
						<ChainsList
							chains={sectionChain.labels.map(({ color, custom_chain_label, selected_option }) => ({
								color,
								label: custom_chain_label,
								selectedOption: selected_option,
							}))}
						/>
					)}
				</Box>

				{!!rowCount && !completedAt?.length && (
					<Text fontSize={14} variant="overline" lineHeight={1}>{`${rowCount} task${
						rowCount !== 0 ? 's' : ''
					}`}</Text>
				)}
			</Grid>
			<Grid xs={6} display="flex" justifyContent="flex-end" alignItems="center">
				{!!onSectionStart && (
					<Button
						onClick={handleSectionStart}
						sx={{ width: 150 }}
						isLoading={isSectionStartLoading}
					>
						Start Section
					</Button>
				)}
				{!!startedAt && <Text fontSize={14}>Started {relativeTimeLabel(startedAt)}</Text>}
				{!!onSectionComplete && (
					<Button
						onClick={handleSectionComplete}
						sx={{ width: 150 }}
						isLoading={isSectionStartLoading}
					>
						Complete Section
					</Button>
				)}
				{!!completedAt?.length && (
					<Box display="flex" alignItems="center">
						<Button
							variant="outlined"
							endIcon={
								!isSectionCollapsed ? (
									<ChevronDown style={{ transform: 'rotate(180deg)' }} />
								) : (
									<ChevronDown />
								)
							}
							sx={{ mr: 1 }}
							onClick={onSectionCollapseToggle}
						>
							{isSectionCollapsed ? `Show ${rowCount} tasks` : 'Hide tasks'}
						</Button>
						<Text fontSize={14}>
							Completed {relativeTimeLabel(completedAt[completedAt.length - 1])}
						</Text>
					</Box>
				)}
			</Grid>
		</Grid>
	)
}
