import { ComponentType, MouseEvent } from 'react'

import { ChevronRight } from 'src/assets/icons'
import { TaskWithCompletable } from 'src/store/modules/my-tasks-v3'

import { MultiUseCta } from './multi-use-cta'
import { ParentTaskCta } from './parent-task-cta'

type TaskListItemCtaProps = {
	task: TaskWithCompletable
	hasSubtasks: boolean
	shouldShowSubtasks: boolean
	onOpenSubtasksClick: (event: MouseEvent<HTMLButtonElement>) => void
}

export const TaskListItemCta: ComponentType<TaskListItemCtaProps> = ({
	task,
	hasSubtasks,
	shouldShowSubtasks,
	onOpenSubtasksClick,
}) => {
	if (task.isMultiUse) {
		return <MultiUseCta task={task} />
	}

	if (hasSubtasks) {
		return <ParentTaskCta isOpen={shouldShowSubtasks} onClick={onOpenSubtasksClick} />
	}

	return <ChevronRight style={{ marginRight: 8 }} />
}
