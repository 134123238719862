import { Dispatch, useEffect } from 'react'
import { AxiosError, TablesSdk } from '@cango-app/sdk'
import { useSelector } from 'react-redux'
import { TableTypes, V3ClientTypes, ClientTypes } from '@cango-app/types'

import { showSnackbar } from 'src/helpers/snackbarManager'
import { selectors as authSelectors } from 'src/store/modules/auth'

import * as bidBuilder from '../bid-builder'

type UsePricingTableProps = {
	bid: V3ClientTypes.Project.Bid | null
	dispatch: Dispatch<bidBuilder.BidBuilderAction>
}

export function useResourceTables(props: UsePricingTableProps) {
	const { bid, dispatch } = props
	const authHeaders = useSelector(authSelectors.getAuthHeaders)

	useEffect(() => {
		async function fetchTable(
			tableId: string | undefined,
			onSuccess: (table: ClientTypes.Table.CangoTable) => void,
		) {
			if (!tableId) return
			try {
				dispatch(bidBuilder.setLoading(true))
				const response = await TablesSdk.getTable(import.meta.env.VITE_API as string, authHeaders, {
					tableId,
				})
				onSuccess(response)
			} catch (error) {
				if ((error as AxiosError).response?.status === 404) {
					showSnackbar('Pricing table not found', { variant: 'error' })
					return
				}
			}
		}

		async function fetchResourceTables() {
			if (bid) {
				try {
					await Promise.allSettled([
						fetchTable(bid.laborPricingTableId, (data) =>
							dispatch(
								bidBuilder.setPricingTable({
									key: 'labor',
									data: data as unknown as TableTypes.PricingTable,
								}),
							),
						),
						fetchTable(bid.equipmentPricingTableId, (data) =>
							dispatch(
								bidBuilder.setPricingTable({
									key: 'equipment',
									data: data as unknown as TableTypes.PricingTable,
								}),
							),
						),
						fetchTable(bid.materialPricingTableId, (data) =>
							dispatch(
								bidBuilder.setPricingTable({
									key: 'material',
									data: data as unknown as TableTypes.PricingTable,
								}),
							),
						),
						fetchTable(bid.productionItemsTableId, (data) =>
							dispatch(bidBuilder.setProductionItemsTable(data)),
						),
					])
				} finally {
					dispatch(bidBuilder.setLoading(false))
				}
			}
		}

		fetchResourceTables()
	}, [bid?.updated_at])
}
