import { MakeOptional } from '@mui/x-date-pickers/internals'
import { TableTypes } from '@cango-app/types'

import { ByQuantityAndUsagePricing, PricingTableMap } from './types'

function getContentsTotalCost(
	contents: MakeOptional<ByQuantityAndUsagePricing, 'usage'>[] | undefined = [],
	pricingMap: PricingTableMap,
	markupPercentage = 0,
	{ hours = 1, profitMargin = 0 }: { hours?: number; profitMargin?: number } = {},
): {
	totalRate: number
	totalCostWithOH: number
	priceWithProfitMargin: number
} {
	const totalRate = contents.reduce((acc, next) => {
		const pricing = pricingMap[next._id]
		if (typeof pricing === 'undefined') {
			return acc
		}

		const { quantity, usage = 1 } = next
		const amount = quantity * usage
		const subtotal = pricing[TableTypes.PricingTableFieldId.PRICE] * amount

		return acc + subtotal
	}, 0)

	const totalRateWithOH = totalRate * (1 + markupPercentage / 100)
	const totalCostWithOH = totalRateWithOH * hours
	const priceWithProfitMargin = totalCostWithOH * (1 + profitMargin / 100)

	return {
		totalRate,
		totalCostWithOH,
		priceWithProfitMargin,
	}
}

// Although a single function is needed, it is error-prone to have a single function that can handle all three types of contents.
// It is better to have separate functions for each type of content.
export function getMaterialContentsTotalCost(
	contents: MakeOptional<ByQuantityAndUsagePricing, 'usage'>[] | undefined = [],
	pricingMap: PricingTableMap,
	markupPercentage = 0,
	{ profitMargin = 0 } = {},
) {
	return getContentsTotalCost(contents, pricingMap, markupPercentage, { profitMargin })
}

export function getEquipmentContentsTotalCost(
	contents: MakeOptional<ByQuantityAndUsagePricing, 'usage'>[] | undefined = [],
	pricingMap: PricingTableMap,
	markupPercentage = 0,
	{ hours = 1, profitMargin = 0 }: { hours?: number; profitMargin?: number } = {},
): {
	totalRate: number
	totalCostWithOH: number
	priceWithProfitMargin: number
} {
	return getContentsTotalCost(contents, pricingMap, markupPercentage, {
		hours,
		profitMargin,
	})
}

export function getLaborContentsTotalCost(
	contents: MakeOptional<ByQuantityAndUsagePricing, 'usage'>[] | undefined = [],
	pricingMap: PricingTableMap,
	markupPercentage = 0,
	{ hours = 1, profitMargin = 0 }: { hours?: number; profitMargin?: number } = {},
): {
	totalRate: number
	totalCostWithOH: number
	priceWithProfitMargin: number
} {
	return getContentsTotalCost(contents, pricingMap, markupPercentage, {
		hours,
		profitMargin,
	})
}
