import { ComponentType, useEffect, useState } from 'react'
import { Control, Controller, useWatch } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { TableProvider } from 'src/providers/table-provider'
import { Box, Button, Select } from 'src/components'
import { ChevronDown } from 'src/assets/icons'
import { selectors as tableSelectors } from 'src/store/modules/tables'

import { CoreTable } from '../core-table'

import { ConfigureCalculationForm } from './utils'

export const TableLookup: ComponentType<{
	control: Control<ConfigureCalculationForm>
	sliceIndex: number
}> = ({ control, sliceIndex }) => {
	const tableList = useSelector(tableSelectors.getTablesList)
	const tableId = useWatch({ control, name: `calculation.${sliceIndex}.lookup.tableId` })
	const fieldId = useWatch({ control, name: `calculation.${sliceIndex}.lookup.fieldId` })
	const [openTable, setOpenTable] = useState(false)

	useEffect(() => {
		if (tableId && !fieldId && !openTable) {
			setOpenTable(true)
		}
	}, [tableId, fieldId])

	return (
		<Box width="100%">
			<Controller
				control={control}
				name={`calculation.${sliceIndex}.lookup`}
				render={({ field: { onChange, value } }) => (
					<Select
						value={value?.tableId ?? ''}
						onChange={(event) => {
							onChange({ tableId: event.target.value, recordId: '', fieldId: '' })
						}}
						options={tableList}
						label="Select Table"
						fullWidth
						sx={{ mb: 1 }}
					/>
				)}
			/>

			<Box display="flex" justifyContent="center">
				<Button
					onClick={() => setOpenTable(!openTable)}
					variant="text"
					sx={{ mb: 1 }}
					endIcon={<ChevronDown style={{ transform: openTable ? 'rotate(180deg)' : undefined }} />}
				>
					{openTable ? 'Close ' : 'Open '} table
				</Button>
			</Box>

			{!!tableId && openTable && (
				<TableProvider tableId={tableId}>
					<Controller
						control={control}
						name={`calculation.${sliceIndex}.lookup`}
						render={({ field }) => {
							return (
								<CoreTable
									isStatic
									selectedCell={`${field.value?.fieldId}-${field.value?.recordId}`}
									onCellClick={(params) => {
										field.onChange({
											tableId,
											recordId: params.rowId,
											fieldId: params.columnId,
										})
									}}
								/>
							)
						}}
					/>
				</TableProvider>
			)}
		</Box>
	)
}
