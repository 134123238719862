import React, { ComponentType, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import { CommsSdk } from '@cango-app/sdk'

import { RouteId } from 'src/constants/routes'
import { selectors as authSelectors, actions as authActions } from 'src/store/modules/auth'
import { selectors as persistedConfigSelectors } from 'src/store/modules/persisted-config'
import { CangoLogo, CangoLogoText } from 'src/assets/icons'
import { Box, Text } from 'src/components'
import { AsyncDispatchType } from 'src/store/types'
import { AnalyticsEvent, analytics } from 'src/biz'

import { MainNav } from './containers/main-nav'
import { SettingsNav } from './containers/settings'
import { InboxNav } from './containers/inbox-nav'

export const APP_BAR_HEIGHT = 73

export const DesktopNavigation: ComponentType = () => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const navigate = useNavigate()
	const selectedOrganisationName = useSelector(authSelectors.getOrganisationName)
	const [userPopover, setUserPopover] = useState<HTMLButtonElement | null>(null)
	const [settingsPopover, setSettingsPopover] = useState<HTMLButtonElement | null>(null)
	const [projectsPopover, setProjectsPopover] = useState<HTMLButtonElement | null>(null)

	const handleAccountClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setUserPopover(event.currentTarget)
	}

	const handleAccountClose = () => {
		setUserPopover(null)
	}

	const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setSettingsPopover(event.currentTarget)
	}

	const handleSettingsClose = () => {
		setSettingsPopover(null)
	}

	const handleProjectsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setProjectsPopover(event.currentTarget)
	}

	const handleProjectsClose = () => {
		setProjectsPopover(null)
	}

	const handleLogout = async () => {
		await dispatch(authActions.logoutUser())
	}

	const handleRouteClick = useCallback(
		(routeId: string) => {
			if (userPopover) {
				handleAccountClose()
			}
			if (settingsPopover) {
				handleSettingsClose()
			}
			if (projectsPopover) {
				handleProjectsClose()
			}
			if (routeId !== RouteId.Projects) {
				analytics.track({ eventName: AnalyticsEvent.navMenuItemClicked, properties: { routeId } })
			}
			navigate(`/${routeId}`)
		},
		[userPopover, settingsPopover, projectsPopover, navigate],
	)

	return (
		<AppBar position="sticky">
			<Toolbar variant="dense" sx={{ height: APP_BAR_HEIGHT }}>
				<Box display="flex" alignItems="center" mr={6}>
					<Box mr={2}>
						<CangoLogo />
					</Box>
					<Box display="flex" flexDirection="column" justifyContent="center" pt={0.5}>
						<Box mb={0.5}>
							<CangoLogoText />
						</Box>
						<Text maxWidth={200} lineHeight={1}>
							{selectedOrganisationName}
						</Text>
					</Box>
				</Box>

				<MainNav
					onProjectsClick={handleProjectsClick}
					onProjectsClose={handleProjectsClose}
					projectsPopover={projectsPopover}
					onRouteClick={handleRouteClick}
				/>

				<InboxNav
					onClick={() => handleRouteClick(`${RouteId.Inbox}/${CommsSdk.InboxNavState.MyMessages}`)}
				/>

				<SettingsNav
					onSettingsClick={handleSettingsClick}
					onSettingsClose={handleSettingsClose}
					settingsPopover={settingsPopover}
					onRouteClick={handleRouteClick}
					onUserClick={handleAccountClick}
					onUserClose={handleAccountClose}
					userPopover={userPopover}
					onLogout={handleLogout}
				/>
			</Toolbar>
		</AppBar>
	)
}
