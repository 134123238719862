import { ComponentType, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Chip from '@mui/material/Chip'
import Badge from '@mui/material/Badge'
import { useNavigate } from 'react-router-dom'
import { PulseLoader } from 'react-spinners'

import { ChevronRight } from 'src/assets/icons'
import { Box, Text } from 'src/components'
import {
	type Section as SectionType,
	selectors as myTasksSelectors,
	actions as myTasksActions,
	TaskListType,
} from 'src/store/modules/my-tasks-v3'
import { colors } from 'src/theme/colors'
import { RouteId } from 'src/constants/routes'

type SectionListItemProps = {
	section: Pick<
		SectionType,
		'_id' | 'userPendingTaskCount' | 'project_id' | 'name' | 'isBlocked' | 'instance'
	>
}

const COMPLETED_COLOR = colors.success.main

export const SectionListItem: ComponentType<SectionListItemProps> = ({ section }) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const selectedSectionId = useSelector(myTasksSelectors.getSelectedSectionId)
	const selectedProjectId = useSelector(myTasksSelectors.getSelectedProjectId)
	const unreadTaskIds = useSelector(myTasksSelectors.unreadTaskIdsInSection)
	const sectionIdLoading = useSelector(myTasksSelectors.getSectionIdLoading)

	const isBlockedTasksSection = useMemo(() => {
		const splitId = section._id.split('--')
		return splitId.length > 1 && splitId[1] === 'blocked'
	}, [section._id])

	const borderColor = useMemo(() => {
		if (section.userPendingTaskCount === 0) {
			return COMPLETED_COLOR
		}

		return colors.sunglow['60']
	}, [section.userPendingTaskCount])

	const isProjectSelected = useMemo(() => {
		return selectedProjectId === section.project_id && selectedSectionId === section._id
	}, [selectedSectionId, section._id, section.project_id, selectedProjectId])

	const backgroundColor = useMemo(() => {
		if (isProjectSelected && isBlockedTasksSection) {
			return colors.error.main
		} else if (isProjectSelected) {
			return colors.sunglow['10']
		}

		if (isBlockedTasksSection) {
			return colors.error.light['50']
		}

		return 'white'
	}, [isProjectSelected, isBlockedTasksSection])

	const handleSectionClick = () => {
		if (sectionIdLoading) return
		if (selectedSectionId === section._id) {
			dispatch(myTasksActions.setTaskListType(TaskListType.Active))
		}
		navigate(`/${RouteId.MyTasks}/${section._id}`)
	}

	return (
		<Box
			onClick={handleSectionClick}
			borderLeft="4px solid"
			bgcolor={backgroundColor}
			borderColor={borderColor}
			color={isBlockedTasksSection ? 'white' : undefined}
			fontSize={16}
			px={2}
			display="flex"
			justifyContent="space-between"
			alignItems="center"
			sx={{
				cursor: 'pointer',
				':hover': {
					backgroundColor: isBlockedTasksSection ? colors.error.main : colors.sunglow['10'],
				},
			}}>
			<Box py={2}>
				{!!section.instance && (
					<Text fontSize={12}>
						<b>{section.instance.name}</b>
					</Text>
				)}
				<Text fontSize={14}>{section.name}</Text>
			</Box>
			<Box display="flex" py={1} alignItems="center">
				{section.isBlocked ? (
					!isBlockedTasksSection && (
						<Chip label="blocked" color="error" variant="status" size="small" sx={{ mr: 3 }} />
					)
				) : (
					<Badge color="error" badgeContent={unreadTaskIds.length}>
						<Box>
							<Box
								bgcolor={colors.sunglow['40']}
								mr={1}
								borderRadius="10px"
								p={1}
								width={30}
								height={30}
								display="flex"
								alignItems="center"
								justifyContent="center">
								<Text color={colors.sunglow['100']} variant="overline">
									{section.userPendingTaskCount}
								</Text>
							</Box>
						</Box>
					</Badge>
				)}
				{sectionIdLoading === section._id ? (
					<PulseLoader size={4} />
				) : (
					<ChevronRight stroke={isBlockedTasksSection ? '#fff' : undefined} />
				)}
			</Box>
		</Box>
	)
}
