import React, { ComponentType, useContext, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import { V3BlueprintTypes } from '@cango-app/types'

import { Box, FormCard, Text } from 'src/components'
import { TabPanel } from 'src/components/tab-panel'
import { TaskContext } from 'src/providers/task-provider'
import {
	actions as projectActions,
	selectors as projectSelectors,
} from 'src/store/modules/projects-v3'
import { relativeTimeLabel } from 'src/helpers/labels'
import { Resources } from 'src/modules/my-tasks-v3/components/resource-window'

import { CompleteTaskOps } from './complete-task-ops'
import { AssignDateField } from './assign-date-field'
import { ActionFields } from './action-fields'
import { TaskDrawerTab } from './task-form-container'

type TaskInfoProps = {
	activeTab: TaskDrawerTab
}

export const TaskInfo: ComponentType<TaskInfoProps> = ({ activeTab }) => {
	const dispatch = useDispatch()
	const { task } = useContext(TaskContext)
	const selectedProject = useSelector(projectSelectors.getSelectedProject)

	const handleAssignDate = async (data: { projectId: string; taskId: string; date: number }) => {
		await dispatch(projectActions.assignTaskDate({ ...data, unixDate: data.date }))
	}

	const requiresDate = useMemo(() => {
		if (!task) return false
		return [V3BlueprintTypes.WhenEnum.Milestone, V3BlueprintTypes.WhenEnum.Date].includes(
			task.when.type,
		)
	}, [task?.when.type])

	const completedWording = useMemo(() => {
		if (!task) return ''
		if (task.isMultiUse) return 'Instance created'
		if (task.children.length > 2) return 'Decision made'
		return 'Completed'
	}, [task?.isMultiUse, task?.children.length])

	if (!task) {
		return null
	}

	return (
		<TabPanel index={TaskDrawerTab.Info} value={activeTab} containerProps={{ pt: 2 }}>
			<Box>
				{!!task.lifecycle.completed_at?.length && (
					<Box mb={2}>
						<Alert severity={task.isMultiUse ? 'info' : 'success'}>
							<AlertTitle>{completedWording}</AlertTitle>
							{relativeTimeLabel(
								task.lifecycle.completed_at[task.lifecycle.completed_at?.length - 1],
							)}
						</Alert>
					</Box>
				)}
				{(!task.lifecycle.complete || !!task.lifecycle.completed_options?.length) && (
					<>
						{!!task.lifecycle.completed_options?.length &&
							task.lifecycle.completed_options.map((option, i) => (
								<Text style={{ fontWeight: 'bold' }} key={`decision-${i}`}>
									{task.children.find(({ _id }) => _id === option)?.label}
								</Text>
							))}
						{!task.lifecycle.complete && <CompleteTaskOps />}
					</>
				)}
				{requiresDate && (
					<FormCard title="Date" mb={2}>
						<AssignDateField task={task} onAssignDate={handleAssignDate} />
					</FormCard>
				)}
			</Box>

			<ActionFields />
			{!!selectedProject?.table && (
				<FormCard title="Resources" sx={{ mt: 3 }}>
					<Resources task={task} tableId={selectedProject.table} />
				</FormCard>
			)}
		</TabPanel>
	)
}
