/**
 * Cango Project Database View
 */

import React, { ComponentType } from 'react'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

import { Box } from 'src/components'
import { CoreTable } from 'src/modules/tables/core-table'
import { TableProvider } from 'src/providers/table-provider'

dayjs.extend(relativeTime)

type DatabaseTableProps = {
	tableId: string
}

export const DatabaseTable: ComponentType<DatabaseTableProps> = ({ tableId }) => {
	return (
		<Box px={4}>
			<TableProvider tableId={tableId}>
				<CoreTable rowReordering={false} isStatic />
			</TableProvider>
		</Box>
	)
}
