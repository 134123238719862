import { ComponentType } from 'react'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import Stack from '@mui/material/Stack'
import FoundationIcon from '@mui/icons-material/Foundation'
import { TableTypes } from '@cango-app/types'

import { Box, Modal, Text } from '../../../../components'
import { ProductionItem } from '../types'

type ProductionItemsModalProps = {
	open: boolean
	onClose: () => void
	onSelect: (productionItem: ProductionItem) => void
	productionItems: ProductionItem[]
}

export const ProductionItemsModal: ComponentType<ProductionItemsModalProps> = (props) => {
	const { productionItems, onClose, open, onSelect } = props
	const onClick = (productionItem: ProductionItem) => {
		onSelect(productionItem)
		onClose()
	}

	return (
		<Modal open={open} onClose={onClose} title="Select Production Item">
			<Stack direction="column" maxHeight="100%">
				<Box display="flex" alignItems="center">
					<Text mr={2} variant="h5">
						Browse production items
					</Text>
					<FoundationIcon />
				</Box>
				<Box flex={1} overflow="auto">
					<List dense={true}>
						{productionItems.map((productionItem) => (
							<ListItemButton onClick={() => onClick(productionItem)} key={productionItem._id}>
								<ListItemText primary={productionItem[TableTypes.ProductionFieldId.NAME]} />
							</ListItemButton>
						))}
					</List>
				</Box>
			</Stack>
		</Modal>
	)
}
