import { ComponentType } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { V3ProjectSdk } from '@cango-app/sdk'
import dayjs from 'dayjs'
import { V3ClientTypes } from '@cango-app/types'

import { AsyncDispatchType } from 'src/store/types'
import { actions as myTasksActions } from 'src/store/modules/my-tasks-v3'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { showSnackbar } from 'src/helpers/snackbarManager'

import { ArchiveButtons } from '../archive-buttons'

type ArchiveProps = {
	task: Pick<V3ClientTypes.Project.Task, '_id' | 'project_id'>
}

export const Archive: ComponentType<ArchiveProps> = ({ task }) => {
	const dispatch = useDispatch<AsyncDispatchType>()
	const authHeaders = useSelector(authSelectors.getAuthHeaders)

	const handleArchiveProject = async () => {
		try {
			const archiveDate = dayjs().unix()
			await V3ProjectSdk.updateProject({
				baseURL: import.meta.env.VITE_API as string,
				authHeaders,
				data: {
					projectId: task.project_id,
					update: { archived: { state: true, at: archiveDate } },
				},
			})
			showSnackbar('Project archived', { variant: 'success' })
			dispatch(myTasksActions.fetchAllProjects())
		} catch (error) {
			showSnackbar('Error archiving project', { variant: 'error' })
		}
	}

	const handleCompleteTaskClick = async () => {
		await dispatch(
			myTasksActions.completeTask({
				taskId: task._id,
			}),
		)
	}

	return (
		<ArchiveButtons
			onArchiveProject={handleArchiveProject}
			onCompleteTask={handleCompleteTaskClick}
		/>
	)
}
