import React, { ComponentType, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Action, ThunkDispatch } from '@reduxjs/toolkit'

import {
	actions as blueprintActions,
	selectors as blueprintSelectors,
} from 'src/store/modules/blueprints-v3'
import { selectors as tableSelectors } from 'src/store/modules/tables'
import type { RootState } from 'src/store/types'
import { Box, Button, Drawer, Text, NameInput, FormCard, Select, Toggle } from 'src/components'
import { selectors as configSelectors } from 'src/store/modules/config'
import { AnalyticsEvent, analytics } from 'src/biz'

import { DeleteBlueprintModal } from './delete-blueprint-modal'

type BlueprintOpsDrawerProps = {
	open: boolean
	onClose: () => void
	blueprintName: string
	blueprintId: string
	isPublished: boolean
	relatedProjects?: { name: string; _id: string }[]
}

export const BlueprintOpsDrawer: ComponentType<BlueprintOpsDrawerProps> = ({
	open,
	onClose,
	blueprintName,
	blueprintId,
	isPublished,
	relatedProjects,
}) => {
	const dispatch = useDispatch<ThunkDispatch<RootState, void, Action>>()
	const organisationId = useSelector(configSelectors.getOrganisationId)
	const sections = useSelector(blueprintSelectors.getSections)
	const blueprintDatabaseTable = useSelector(blueprintSelectors.getSelectedBlueprintDatabaseTable)
	const tableList = useSelector(tableSelectors.getTablesList)
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [isPublishLoading, setIsPublishLoading] = useState(false)
	const [isSavingTableSelection, setIsSavingTableSelection] = useState(false)

	const handleUpdateName = async (newName: string) => {
		await dispatch(blueprintActions.updateName({ name: newName, blueprintId }))
		analytics.track({
			eventName: AnalyticsEvent.blueprintNameUpdated,
			properties: {
				oldName: blueprintName,
				newName,
			},
		})
	}
	const handlePublishSwitch = async (shouldPublish: boolean) => {
		setIsPublishLoading(true)
		await dispatch(blueprintActions.publish({ shouldPublish, blueprintId }))
		setIsPublishLoading(false)
		analytics.track({
			eventName: AnalyticsEvent.blueprintPublishSwitched,
			properties: {
				published: shouldPublish,
			},
		})
	}

	const handleSelectDatabaseTable = async (tableId: string) => {
		setIsSavingTableSelection(true)
		await dispatch(blueprintActions.updateDatabaseTable({ tableId, blueprintId }))
		setIsSavingTableSelection(false)
	}

	return (
		<>
			<DeleteBlueprintModal open={showDeleteModal} onClose={() => setShowDeleteModal(false)} />
			<Drawer open={open} onClose={onClose}>
				<Box width={400} p={2} display="flex" flexDirection="column">
					<Text variant="h5" mb={2}>
						Configuration
					</Text>
					<FormCard title="Blueprint name" mb={2}>
						<NameInput name={blueprintName} onUpdateClick={handleUpdateName} />
					</FormCard>
					{!!sections.length && (
						<FormCard title="Status" mb={2}>
							<Toggle
								options={[
									{ label: 'Published', value: true },
									{ label: 'Unpublished', value: false },
								]}
								value={isPublished}
								onChange={(newValue) => handlePublishSwitch(newValue)}
								containerProps={{ mb: 1 }}
								disabled={isPublishLoading}
							/>
						</FormCard>
					)}
					<FormCard title="Database" mb={2}>
						<Select
							label="Select a database table for this blueprint to use"
							onChange={(event) => handleSelectDatabaseTable(event.target.value as string)}
							value={blueprintDatabaseTable}
							options={tableList}
							isLoading={isSavingTableSelection}
							disabled={isSavingTableSelection}
						/>
					</FormCard>
					{!!relatedProjects?.length && (
						<FormCard title="Related Projects" mb={2}>
							<ul>
								{relatedProjects.map((project) => (
									<li key={project._id}>
										<Link to={`/project?id=${project._id}&orgId=${organisationId}`}>
											{project.name}
										</Link>
									</li>
								))}
							</ul>
						</FormCard>
					)}
					<FormCard title="Danger zone">
						<Box>
							{relatedProjects?.length ? (
								<Text fontSize={12}>{"Cannot delete Blueprint as it's in use"}</Text>
							) : (
								<Button
									color="error"
									size="small"
									variant="outlined"
									fullWidth
									onClick={() => setShowDeleteModal(true)}>
									Delete Blueprint
								</Button>
							)}
						</Box>
					</FormCard>
				</Box>
			</Drawer>
		</>
	)
}
