import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import { ComponentType, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent'
import { useNavigate } from 'react-router-dom'

import { Box, Button, Modal, Text, TextField } from 'src/components'
import {
	selectors as blueprintSelectors,
	actions as blueprintActions,
} from 'src/store/modules/blueprints-v3'
import { AnalyticsEvent, analytics } from 'src/biz'

type DeleteBlueprintModalProps = {
	open: boolean
	onClose: () => void
}

export const DeleteBlueprintModal: ComponentType<DeleteBlueprintModalProps> = ({
	open,
	onClose,
}) => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const blueprint = useSelector(blueprintSelectors.getSelectedBlueprint)
	const [deleteInput, setDeleteInput] = useState('')
	const [isDeletingBlueprint, setIsDeletingBlueprint] = useState(false)

	const handleDeleteBlueprintClick = async () => {
		if (!blueprint) return
		setIsDeletingBlueprint(true)
		await dispatch(blueprintActions.deleteBlueprint({ blueprintId: blueprint._id, navigate }))
		setIsDeletingBlueprint(false)
		analytics.track({ eventName: AnalyticsEvent.blueprintDeleted })
	}

	if (!blueprint) {
		return null
	}

	return (
		<Modal open={open} onClose={onClose}>
			<Box>
				<Text fontWeight={600} mb={1}>
					Delete {blueprint.name}
				</Text>
				<Alert severity="warning">
					<AlertTitle>Unexpected bad things will happen if you don’t read this!</AlertTitle>
				</Alert>
				<Box sx={{ maxWidth: 500 }} display="flex" flexDirection="column" alignItems="center">
					<Timeline sx={{ mb: 0 }}>
						<TimelineItem>
							<TimelineOppositeContent sx={{ flex: 0 }} />
							<TimelineSeparator>
								<TimelineDot />
								<TimelineConnector />
							</TimelineSeparator>
							<TimelineContent>
								<Text>
									This will permanently delete the <b>{blueprint.name}</b> blueprint and it&apos;s
									steps.
								</Text>
							</TimelineContent>
						</TimelineItem>
						<TimelineItem>
							<TimelineOppositeContent sx={{ flex: 0 }} />
							<TimelineSeparator>
								<TimelineDot />
								<TimelineConnector />
							</TimelineSeparator>
							<TimelineContent>
								Active projects linked to this blueprint will not be able to continue
							</TimelineContent>
						</TimelineItem>
						<TimelineItem>
							<TimelineOppositeContent sx={{ flex: 0 }} />
							<TimelineSeparator>
								<TimelineDot />
							</TimelineSeparator>
							<TimelineContent>
								Files in Google Drive will <b>not</b> be deleted
							</TimelineContent>
						</TimelineItem>
					</Timeline>
					<Box maxWidth={400}>
						<Text sx={{ mb: 2 }} fontSize={14}>
							Enter <b style={{ textDecoration: 'underline' }}>{blueprint.name}</b> into the text
							box below to confirm that you want to delete this blueprint
						</Text>
						<TextField
							fullWidth
							placeholder={blueprint.name}
							value={deleteInput}
							onChange={(e) => setDeleteInput(e.target.value)}
						/>
					</Box>
					<Box display="flex" mt={2}>
						<Button variant="outlined" sx={{ width: 170, mr: 0.5 }} onClick={onClose}>
							Cancel
						</Button>
						<Button
							color="error"
							sx={{ width: 170, ml: 0.5 }}
							disabled={deleteInput !== blueprint.name}
							isLoading={isDeletingBlueprint}
							onClick={handleDeleteBlueprintClick}>
							Delete
						</Button>
					</Box>
				</Box>
			</Box>
		</Modal>
	)
}
