import { MyTasksV3Sdk } from '@cango-app/sdk'
import { BoxProps } from '@mui/material'
import { ComponentType, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { PulseLoader } from 'react-spinners'
import { useNavigate } from 'react-router-dom'

import { AllMyTasksDoneIllustration } from 'src/assets/images/illustrations'
import { Box, Button, Text } from 'src/components'
import {
	selectors as myTasksSelectors,
	actions as myTasksActions,
} from 'src/store/modules/my-tasks-v3'
import { colors } from 'src/theme/colors'
import { selectors as authSelectors } from 'src/store/modules/auth'
import { RouteId } from 'src/constants/routes'
import { AsyncDispatchType } from 'src/store/types'

type SectionStatus = MyTasksV3Sdk.SectionStatus | 'loading' | 'error'

const SectionCompleteCta: ComponentType<{
	status: SectionStatus
	sectionId: string
}> = ({ status, sectionId }) => {
	const navigate = useNavigate()
	const dispatch = useDispatch<AsyncDispatchType>()
	const project = useSelector(myTasksSelectors.getSelectedProject)
	const unselectedSections = project?.sections.filter(({ _id }) => _id !== sectionId) || []
	const nextSectionId = unselectedSections[0]?._id
	const selectedProjectId = useSelector(myTasksSelectors.getSelectedProjectId)
	const selectedOrganisationId = useSelector(authSelectors.getOrganisationId)
	const [isLoading, setIsLoading] = useState(false)

	const completabilityMessage = useMemo(() => {
		switch (status) {
			case MyTasksV3Sdk.SectionStatus.IncompleteTasks:
				return "There are tasks in this section assigned to other users that have not yet been completed. Once completed you'll be able to start the next section"
			case MyTasksV3Sdk.SectionStatus.Complete:
				return 'This section is already complete'
			case MyTasksV3Sdk.SectionStatus.NotAssigned:
				return 'This section is not assigned to you'
			case 'error':
				return 'Error loading section status'
			default:
				return ''
		}
	}, [status])

	const handleCompleteSectionClick = async () => {
		setIsLoading(true)
		const response = await dispatch(myTasksActions.completeTask({ taskId: sectionId }))
		setIsLoading(false)
		if (
			response.meta.requestStatus === 'fulfilled' &&
			(response.payload as { isProjectComplete: boolean }).isProjectComplete
		) {
			navigate(`/${RouteId.Project}/${selectedProjectId}?orgId=${selectedOrganisationId}`)
		}
		if (nextSectionId) {
			navigate(`/${RouteId.MyTasks}/${nextSectionId}`)
		}
	}

	if (status === 'loading') {
		return <PulseLoader size={6} color={colors.feldgrau['80']} />
	}

	if (completabilityMessage) {
		return (
			<Text fontSize={12} color={colors.neutral['60']} mt={1}>
				{completabilityMessage}
			</Text>
		)
	}

	return (
		<Button
			onClick={handleCompleteSectionClick}
			isLoading={isLoading}
			analytics={{
				eventName: 'section_complete',
				routeId: RouteId.MyTasks,
			}}
		>
			{'Complete section'}
		</Button>
	)
}

export const SectionComplete: ComponentType<BoxProps> = (props) => {
	const authHeaders = useSelector(authSelectors.getAuthHeaders)
	const projectId = useSelector(myTasksSelectors.getSelectedProjectId)
	const section = useSelector(myTasksSelectors.getSelectedSection)
	const [sectionStatus, setSectionStatus] = useState<SectionStatus>('loading')
	const isBlockedTasksSection =
		section && section._id.split('--').length > 1 && section._id.split('--')[1] === 'blocked'

	const getSectionStatus = async () => {
		if (!section) return
		setSectionStatus('loading')
		try {
			const response = await MyTasksV3Sdk.getSectionStatus(
				import.meta.env.VITE_API as string,
				authHeaders,
				{ sectionId: section._id },
			)
			setSectionStatus(response.status)
		} catch (error) {
			setSectionStatus('error')
		}
	}

	useEffect(() => {
		if (section?._id && !isBlockedTasksSection) {
			getSectionStatus()
		}
	}, [section?._id])

	if (!projectId) return null

	return (
		<Box flex={3} display="flex" justifyContent="center" py={{ laptop: 5 }} {...props}>
			<Box width={350} textAlign="center">
				{<AllMyTasksDoneIllustration width={300} />}
				<Text fontSize={18} fontWeight={700} textAlign="center">
					{'Great! There are no more tasks left for this section.'}
				</Text>
				{!isBlockedTasksSection && !!section && (
					<Box mt={2}>
						<SectionCompleteCta status={sectionStatus} sectionId={section._id} />
					</Box>
				)}
			</Box>
		</Box>
	)
}
