import React, { ComponentType, useState } from 'react'
import { CredentialResponse } from '@react-oauth/google'

import { Box, Text } from 'src/components'
import { LoginTeamIllustration } from 'src/assets/images/illustrations'
import { colors } from 'src/theme/colors'
import { CangoLogo, CangoLogoText } from 'src/assets/icons'
import { useIsMobile } from 'src/hooks/useMobile'

import { GoogleLogin } from './components/google-login'
import { OrganisationList } from './components/organisations'
import { NewAccountForm } from './components/new-account-form'

type LoginComponentProps = {
	onGoogleLoginSuccess: (credentials: CredentialResponse) => Promise<void>
	onGoogleLoginError: () => void
	organisations: { id: string; name: string }[]
	onSelectOrganisation: (organisationId: string, isManualLogin?: boolean) => Promise<void>
}

export const LoginComponent: ComponentType<LoginComponentProps> = ({
	onGoogleLoginSuccess,
	onGoogleLoginError,
	organisations,
	onSelectOrganisation,
}) => {
	const isMobile = useIsMobile()
	const [createAccount, setCreateAccount] = useState(false)

	return (
		<Box display="flex" height="100dvh">
			<Box bgcolor={colors.feldgrau['100']} flex={1} textAlign="center" p={4} overflow="scroll">
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					pt={{ laptop: '60px' }}
					mb={6}>
					<Box mb={1}>
						<CangoLogo width={75} />
					</Box>
					<CangoLogoText />
				</Box>
				<Box display="flex" justifyContent="center">
					<Box width={300}>
						<Text
							fontSize={36}
							color={colors.neutral['10']}
							fontWeight={500}
							textAlign={createAccount ? 'left' : 'center'}>
							{createAccount ? 'Account creation' : 'Welcome back!'}
						</Text>
						{createAccount ? (
							<NewAccountForm onGoBack={() => setCreateAccount(false)} />
						) : organisations.length ? (
							<OrganisationList
								organisations={organisations}
								onSelectOrganisation={onSelectOrganisation}
							/>
						) : (
							<GoogleLogin
								onGoogleLoginSuccess={onGoogleLoginSuccess}
								onGoogleLoginError={onGoogleLoginError}
								onCreateAccount={() => setCreateAccount(true)}
							/>
						)}
					</Box>
				</Box>
			</Box>
			{!isMobile && (
				<Box flex={2} display="flex" flexDirection="column" alignItems="center" pt="90px">
					<Text fontSize={36} fontWeight={700} color={colors.feldgrau['100']} mb={10}>
						<span>
							Try{' '}
							<span style={{ backgroundColor: colors.sunglow['20'], borderRadius: '10px' }}>
								efficiency
							</span>
							, you might like it.
						</span>
					</Text>
					<LoginTeamIllustration width={500} />
				</Box>
			)}
		</Box>
	)
}
